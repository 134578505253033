import { render, staticRenderFns } from "./FlagFile.vue?vue&type=template&id=da5cc3c0&scoped=true"
import script from "./FlagFile.vue?vue&type=script&lang=js"
export * from "./FlagFile.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da5cc3c0",
  null
  
)

export default component.exports