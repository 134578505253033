<template>
  <v-container class="page-content px-5" style="width: 100%">
    <div v-if="$route.name === names.ROLES.LIST">
      <v-toolbar
          class="removeBtnPaddingRight rounded-t-lg"
          color="#DADADA"
          flat
      >
        <v-select
            v-if="$vuetify.breakpoint.smOnly"
            :items="hidden_filter_items"
            class="dark-primary my-1"
            dense
            outlined
            label="Статус"
            hide-details
            v-model="pagination.filters.is_hidden"
        ></v-select>
        <v-text-field
            v-if="$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.mdAndUp"
            v-model="search.value"
            class="dark-primary my-1"
            clearable
            dense
            outlined
            label="Поиск"
            hide-details
            @keydown.enter="searchData"
            @click:clear="clearSearchData"
        ></v-text-field>
        <v-select
            v-if="$vuetify.breakpoint.mdAndUp"
            :items="hidden_filter_items"
            class="dark-primary my-1 pl-2"
            dense
            outlined
            label="Статус"
            hide-details
            v-model="pagination.filters.is_hidden"
        ></v-select>
        <v-spacer></v-spacer>
        <v-toolbar-items v-if="$vuetify.breakpoint.smAndUp">
          <v-btn
              text
              @click="newUser"
              class="dark-primary mr-0 rounded-tr-lg"
          >
            Назначить пользователя
          </v-btn>
        </v-toolbar-items>
        <v-btn
            v-if="$vuetify.breakpoint.xsOnly"
            icon
            class="dark-primary mr-0"
            @click="newUser"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </v-toolbar>
      <v-slide-y-transition>
        <v-toolbar
            flat
            color="#DADADA"
            v-if="$vuetify.breakpoint.smAndDown"
            elevation="0"
            :height="extensionHeight"
        >
          <v-row no-gutters>
            <v-col cols="12">
              <v-select
                  v-if="$vuetify.breakpoint.xsOnly"
                  :items="hidden_filter_items"
                  class="dark-primary my-1"
                  dense
                  outlined
                  label="Статус"
                  hide-details
                  v-model="pagination.filters.is_hidden"
              ></v-select>
              <v-text-field
                  v-else
                  v-model="search.value"
                  class="dark-primary my-1"
                  clearable
                  dense
                  outlined
                  label="Поиск"
                  hide-details
                  @keydown.enter="searchData"
                  @click:clear="clearSearchData"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
      </v-slide-y-transition>
      <v-data-table
          :headers="headers"
          :items="items"
          :loading="isLoading"
          :page.sync="pagination.page"
          :expanded.sync="expanded"
          show-expand
          single-expand
          :items-per-page.sync="pagination.size"
          :server-items-length="count"
          :options.sync="options"
          :footer-props="{'items-per-page-options':[10,50,100]}"
          :item-class="getExpandedItemClass"
          class="elevation-1 rounded-b-lg rounded-t-0"
      >
        <template v-slot:item.is_admin="{ item }">
          <v-icon
              :color="checkFlag(item.is_admin).color"
          >
            {{ checkFlag(item.is_admin).icon }}
          </v-icon>
        </template>
        <template v-slot:item.is_full_viewer="{ item }">
          <v-icon
              :color="checkFlag(item.is_full_viewer).color"
          >
            {{ checkFlag(item.is_full_viewer).icon }}
          </v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  class="mr-2"
                  @click="editUser(item.id)"
                  v-bind="attrs"
                  v-on="on"
              >
                edit
              </v-icon>
            </template>
            <span>Редактировать пользователя</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  @click="deleteUser(item.id)"
                  v-bind="attrs"
                  v-on="on"
              >
                delete
              </v-icon>
            </template>
            <span>Удалить пользователя</span>
          </v-tooltip>
        </template>
        <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
          <v-btn icon @click="expand(!isExpanded)" :ref="`expand_button_${item.id}`" v-if="item.have_events">
            <v-icon v-if="isExpanded">
              expand_less
            </v-icon>
            <v-icon v-else>
              expand_more
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:expanded-item>
          <InnerRolesDataTable :outer-headers-length="headers.length"></InnerRolesDataTable>
        </template>
        <template v-slot:no-data>
          <v-btn
              color="primary"
              @click="updateData"
          >
            Обновить
          </v-btn>
        </template>
      </v-data-table>
      <EditDialog
          v-if="dialog_add_edit"
          :opened="dialog_add_edit"
          :edit="edit_status"
          :user-id="user_id"
          :editing-expanded-item="editing_expanded_item"
          @close="closeEditDialog"
      ></EditDialog>
      <ApplyDialog
        v-if="dialog_delete"
        :opened="dialog_delete"
        :get-text="() => `Вы уверены, что хотите удалить данного пользователя ?`"
        @close="closeDeleteDialog"
      ></ApplyDialog>
    </div>
    <router-view v-else></router-view>
  </v-container>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import EditDialog from "@/modules/admin/roles/modals/RolesEditDialog";
import ApplyDialog from "@/modules/core/components/ApplyDialog";
import admin from "@/urls/roles/admin";
import names_core from "@/modules/core/routers/names";
import names from "@/modules/admin/routers/names";
import InnerRolesDataTable from "@/modules/admin/roles/components/InnerDataTable.vue";

export default {
  name: "AdminRolesList",
  components: {InnerRolesDataTable, EditDialog, ApplyDialog},
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  data() {
    return {
      page_title: "Список ролей",
      dialog_add_edit: false,
      dialog_delete: false,
      user_id: undefined,
      edit_status: undefined,
      expanded: [],
      force_styles_update: false,
      editing_expanded_item: false,
      search: {
        parameter: 'fio',
      },
      hidden_filter_items: [
        { text: 'Активные', value: false },
        { text: 'Архивные', value: true },
        { text: 'Все', value: undefined },
      ],
      pagination: {
        filters: {
          is_hidden: false,
        }
      },
      headers: [
        {text: 'ФИО', value: 'fio', sortable: false, align: 'start', width: '24%'},
        {text: 'Администратор', value: 'is_admin', sortable: false, align: 'center', width: '24%'},
        {text: 'Наблюдатель', value: 'is_full_viewer', sortable: false, align: 'center', width: '24%'},
        {text: 'Действия', value: 'actions', sortable: false, align: 'center', width: '24%'},
        {text: '', value: 'data-table-expand', sortable: false, width: '4%'}
      ],
    }
  },
  watch: {
    expanded: function (newVal) {
      newVal.forEach(el => {
        if (el.id !== undefined) {
          this.loadEvents(undefined, el.id);
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      items: 'admin/getRolesList',
      rolesDetail: 'admin/getRolesDetail',
      count: 'admin/getRolesCount',
      isLoading: 'admin/isLoadingRoles',
    }),
    names: () => names,
    extensionHeight() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 56
      } else {
        return 0
      }
    },
  },
  methods: {
    ...mapActions({
      loadPersonalEvents: 'admin/loadRolesDetail',
      loadRolesList: 'admin/loadRolesList',
      deleteRoles: 'admin/deleteRoles',
    }),
    getExpandedItemClass(item) {
      if (this.expanded.length > 0 && this.expanded[0].id === item.id || this.expanded.length === 0) {
        return "white";
      }

      return "grey lighten-3";
    },
    loadEvents(url = undefined, rolesId) {
        this.loadPersonalEvents({
          url: url,
          id: rolesId,
        })
    },
    updateData() {
      if (this.search.value) {
        this.search.value = ''
      }
      this.loadData()
    },
    loadData(url = undefined) {
      this.expanded = []
      this.loadRolesList({
        url: url,
        urlAppend: (url) => {
          let new_url = this.addFiltersParameter(this.addPageParameter(this.addSizeParameter(url)))
          if (this.search.value)
            new_url = this.addGetParameter(new_url, 'fio', this.search.value)
          return new_url
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
        }
      })
    },
    newUser() {
      this.dialog_add_edit = true
      this.edit_status = false
      this.user_id = undefined
      this.expanded = []
    },
    editUser(id) {
      if (this.expanded.length > 0 && this.expanded[0].id === id) {
        this.editing_expanded_item = true;
      }
      this.dialog_add_edit = true
      this.edit_status = true
      this.user_id = id
      this.expanded = []
    },
    deleteUser(id) {
      this.dialog_delete = true
      this.user_id = id
    },
    closeEditDialog() {
      this.loadData()
      if (this.expanded.length > 0) {
        this.loadEvents(undefined, this.expanded[0].id)
      }
      this.editing_expanded_item = false;
      this.dialog_add_edit = false
      this.user_id = undefined
    },
    closeDeleteDialog(event) {
      this.expanded = []
      if (event) {
        this.deleteRoles({
          id: this.user_id,
          finalizer: () => {
            this.updateData()
          },
          catcher: (val) => {
            this.mapErrors(val)
          },
        })
      }
      this.dialog_delete = false
      this.user_id = undefined
    },
    checkFlag(flag) {
      if (flag) return {icon: "check_circle", color: "green"}
      else return {icon: "not_interested", color: "gray"}
    },
  },
  created() {
    if (admin.ADMIN.ALLOWED()) {
      if(this.$route.name === names.ROLES.LIST){
        this.loadData()
      }
    } else {
      this.$router.push({name: names_core.MAIN})
    }
  }
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0;
}
</style>
