<template>
  <div>
    <v-toolbar
        class="removeBtnPaddingRight rounded-t-lg"
        color="#DADADA"
        flat
    >
      <slot name="btn"></slot>
    </v-toolbar>
    <v-col cols="12">
      <v-row
          class="mx-1 text-h6 font-weight-bold mt-2"
          :class="($vuetify.breakpoint.smAndUp ? '' : ' justify-center')"
      >
        Информация о пользователе
      </v-row>
    </v-col>
    <v-card-title>
      <v-row no-gutters class="ma-0 pa-0">
        <v-col cols="12" sm="2" md="1" class="ma-0 pa-0">
          <v-row no-gutters class="ma-0 pa-0 ml-3 font-weight-bold text-subtitle-1">
            ФИО:
          </v-row>
        </v-col>
        <v-col cols="12" sm="10" md="11" class="ma-0 pa-0 font-weight-medium  pr-md-6">
          <v-row no-gutters class="ma-0 pa-0 ml-3 font-weight-medium text-subtitle-1">
            {{ item.lastname }} {{ item.firstname }} {{ item.midname }}
          </v-row>
        </v-col>
        <v-col cols="12" sm="2" md="1" class="ma-0 pa-0">
          <v-row no-gutters class="ma-0 pa-0 ml-3 font-weight-bold text-subtitle-1">
            e-mail:
          </v-row>
        </v-col>
        <v-col cols="12" sm="10" md="11" class="ma-0 pa-0 font-weight-medium">
          <v-row no-gutters class="ma-0 pa-0 ml-3 font-weight-medium text-subtitle-1">
            {{ item.email }}
          </v-row>
        </v-col>
      </v-row>
    </v-card-title>
  </div>
</template>

<script>
export default {
  name: "VisitorDetail",
  props: {
    item: Object,
  },
}
</script>

<style scoped>
.v-data-table >>> .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none;
}
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0;
}
</style>
