<template>
  <v-toolbar
      class="removeBtnPaddingRight"
      color="#DADADA"
      flat
  >
    <v-spacer></v-spacer>
    <v-toolbar-items v-if="$vuetify.breakpoint.mdAndUp && edit">
      <v-btn
          text
          class="dark-primary mr-0 rounded-tr-lg"
          @click="$emit('addEmptyRow')"
      >
        Добавить строку
      </v-btn>
    </v-toolbar-items>
    <v-tooltip bottom v-if="$vuetify.breakpoint.smAndDown && edit">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
            class="dark-primary ml-1 mr-3"
            @click="$emit('addEmptyRow')"
            v-bind="attrs"
            v-on="on"
        >
          add
        </v-icon>
      </template>
      <span>Добавить пустую строку</span>
    </v-tooltip>
    <v-toolbar-items v-if="$vuetify.breakpoint.mdAndUp && edit && !errorsFound">
      <v-btn
          text
          class="dark-primary mr-0 rounded-tr-lg"
          @click="$emit('cancelChanges')"
      >
        Отменить изменения
      </v-btn>
    </v-toolbar-items>
    <v-tooltip bottom v-if="$vuetify.breakpoint.smAndDown && edit && !errorsFound">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
            class="dark-primary ml-1 mr-3"
            @click="$emit('cancelChanges')"
            v-bind="attrs"
            v-on="on"
        >
          cancel
        </v-icon>
      </template>
      <span>Отменить изменения</span>
    </v-tooltip>
    <v-toolbar-items v-if="$vuetify.breakpoint.mdAndUp && !edit">
      <v-btn
          text
          class="dark-primary mr-0 rounded-tr-lg"
          @click="$emit('editBlocks')"
      >
        Редактировать блоки описания
      </v-btn>
    </v-toolbar-items>
    <v-tooltip bottom v-if="$vuetify.breakpoint.smAndDown && !edit">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
            class="dark-primary ml-1 mr-3"
            @click="$emit('editBlocks')"
            v-bind="attrs"
            v-on="on"
        >
          edit
        </v-icon>
      </template>
      <span>Редактировать блоки описания</span>
    </v-tooltip>
    <v-toolbar-items v-if="$vuetify.breakpoint.mdAndUp && edit">
      <v-btn
          text
          class="dark-primary mr-0 rounded-tr-lg"
          @click="$emit('formRequestData')"
      >
        Сохранить изменения
      </v-btn>
    </v-toolbar-items>
    <v-tooltip bottom v-if="$vuetify.breakpoint.smAndDown && edit">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
            class="dark-primary ml-1 mr-3"
            @click="$emit('formRequestData')"
            v-bind="attrs"
            v-on="on"
        >
          save
        </v-icon>
      </template>
      <span>Сохранить изменения</span>
    </v-tooltip>
  </v-toolbar>
</template>

<script>
export default {
  props: {
    edit: Boolean,
    errorsFound: Boolean,
  },
  name: "PreviewToolbar"
}
</script>

<style scoped>

</style>
