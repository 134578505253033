<template>
  <div v-if="blocks_loaded">
    <template v-for="(row, i) in rows">
      <v-row
        v-if="rowExists(row)"
        :key="i"
        class="ma-0 px-2 py-0"
      >
        <template v-for="(block, k) in row.cols">
          <v-col
              :key="k"
              v-if="blockExists(block)"
              class="mx-0 my-3 px-2 py-0"
              :cols="getBlockCols(row, block)"
          >
            <div class="card" :class="getCardClasses(block)">
              <component
                  :is="getBlockComponent(block)"
                  :data="block"
                  :edit="false"
              ></component>
            </div>
          </v-col>
        </template>
      </v-row>
    </template>
  </div>
</template>

<script>
import TextBlock from "@/modules/templates/description_blocks/blocks/TextBlock.vue";
import SpacerBlock from "@/modules/templates/description_blocks/blocks/SpacerBlock.vue";
import ImageBlock from "@/modules/templates/description_blocks/blocks/ImageBlock.vue";
import SliderBlock from "@/modules/templates/description_blocks/blocks/SliderBlock.vue";
import EntriesSliderBlock from "@/modules/templates/description_blocks/blocks/EntriesSliderBlock.vue";
import FilesListBlock from "@/modules/templates/description_blocks/blocks/FilesListBlock.vue";
import EntriesListBlock from "@/modules/templates/description_blocks/blocks/EntriesListBlock.vue";
import NullBlock from "@/modules/templates/description_blocks/blocks/NullBlock.vue";
import HeaderBlock from "@/modules/templates/description_blocks/blocks/HeaderBlock.vue";
import BLOCK_COMPONENTS from "@/helper/block_components";
import visitor from "@/urls/roles/visitor";

export default {
  name: "VisitorBlocksView",
  props: {
    blocks: Array,
  },
  components: {
    TextBlock,
    SpacerBlock,
    ImageBlock,
    SliderBlock,
    EntriesSliderBlock,
    FilesListBlock,
    EntriesListBlock,
    NullBlock,
    HeaderBlock
  },
  data() {
    return {
      blocks_loaded: false,
      BLOCK_COMPONENTS: BLOCK_COMPONENTS,
      rows: undefined,
      rows_amount: undefined,
    }
  },
  methods: {
    getBlockComponent(block) {
      if (block.type === 7 && !this.editMode && this.$vuetify.breakpoint.xsOnly) return BLOCK_COMPONENTS[5]
      return BLOCK_COMPONENTS[block.type]
    },
    blockExists(block) {
      return !!block
    },
    rowExists(row) {
      return !!row
    },
    getBlockCols(row, block) {
      if (!this.blockExists(block)) return;

      let cols_amount = row.cols_amount;
      let colspan = block.data.colspan;
      let col_number = block.data.col

      if (this.$vuetify.breakpoint.lgAndUp) {
        return 12 / cols_amount * colspan;
      }
      else if (this.$vuetify.breakpoint.mdOnly) {
        if (cols_amount <= 3) {
          return 12 / cols_amount * colspan;
        }
        else if (cols_amount === 4) {
          if (colspan === 2) {
            if (col_number === 0 || col_number === 2) {
              if (col_number === 0 && this.blockExists(row.cols[2]) && row.cols[2].data.colspan === 2
                  || col_number === 2 && this.blockExists(row.cols[0]) && row.cols[0].data.colspan === 2) {
                return 6;
              }
              return 12;
            }
            return 4;

          }
          else if (colspan === 3) {
            return 6;
          }
          else if (colspan === 1) {
            if (this.blockExists(row.cols[1]) && row.cols[1].data.colspan === 2) {
              return 4
            }
            return 6
          }
          return 12
        }
      }
      else if (this.$vuetify.breakpoint.smOnly) {
        if (cols_amount <= 2) {
          return 12 / cols_amount * colspan;
        }
        else if (cols_amount === 4) {
          if (colspan === 2) {
            if (col_number === 0 || col_number === 2) {
              if (col_number === 0 && this.blockExists(row.cols[2]) && row.cols[2].data.colspan === 2
                  || col_number === 2 && this.blockExists(row.cols[0]) && row.cols[0].data.colspan === 2) {
                return 6;
              }
              return 12;
            }
            return 6;
          }
          else if (colspan === 3) {
            return 6;
          }
          else if (colspan === 1) {
            if (col_number === 3 && this.blockExists(row.cols[1]) && row.cols[1].data.colspan === 2) {
              return 12
            }
            return 6
          }
          return 12
        }
      }
      return 12
    },
    getCardClasses(block) {
      let class_string = ""
      let type = block.type
      if (type === 0 || type === 6 || type === 8) {
        class_string += "card-border "
      }
      if (type === 0 || type === 6 || type === 8) {
        class_string += "card-padding "
      }
      return class_string
    },
    rebuildBlocksToRows() {
      this.blocks_loaded = false;
      this.rows = []
      this.rows_amount = 0;
      for (let i = 0; i < this.blocks.length; i++)
      {
        // берём положение блока на странице
        let row = this.blocks[i].data.row;
        let col = this.blocks[i].data.col;

        // изменяем количество строчек, если положение по строке выходит за границы
        if (row + 1 > this.rows_amount) this.rows_amount = row + 1;

        // если такой строки ещё не существует, то создаём её
        if (!this.rows[row]) {
          this.rows[row] = {};
        }

        // если массива колонок строки ещё не существует, то добавляем его
        if (!this.rows[row].cols) {
          this.rows[row].cols = [];
        }

        // добавляем строке необходимые свойства
        this.rows[row].cols[col] = structuredClone(this.blocks[i]);

        this.rows[row].cols[col].new_files = [];
        this.rows[row].cols[col].all_files = [];
        for (let file of this.rows[row].cols[col].files) {
          this.rows[row].cols[col].all_files.push(file)
        }
        this.rows[row].cols[col].files_delete = [];

        this.rows[row].id = i;
        this.rows[row].cols_amount = this.rows[row].cols[col].data.cols_amount;
        this.rows[row].row_number = this.rows[row].cols[col].data.row;

        // загружаем все файлы блока
        for (let file of this.rows[row].cols[col].files) {
          file.src = this.getImage(file.file);
        }
      }

      this.blocks_loaded = true;
    },
    getImage(image) {
      if (!image) return null;

      let urls = {
        basename: image.name,
        action: image.action,
        params: image.params,
      }

      return visitor.getByRecievedUrlData(urls, image.get_params)
    },
  },
  created() {
    this.rebuildBlocksToRows()
  }
}
</script>

<style scoped lang="scss">
.card {
  height: 100%;
}

.card-padding {
  padding: 10px 15px;
}

.card-border {
  border: 1px solid #BCBCBC;
  border-radius: 6px;
}
</style>
