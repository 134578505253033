<template>
  <v-checkbox
    class="py-0 my-0"
    :class="marginLeft ? 'ml-3' : ''"
    v-model="value"
    :error="!!(errorMessage && errorMessage.length > 0)"
    :errorMessages="errorMessage"
    @change="$emit('update:inputValue', value)"
  >
    <template v-slot:label>
      <div class="label mt-1" :style=" noPadding ? '' : 'padding-right: 68px;!important;'">
        {{ text_before_link }}
        <a class="link" @click.stop :href="file" target="_blank" v-html="link_text"></a>
        {{ text_after_link }}
        <span v-if="isRequired" class="red--text">*</span>
      </div>
    </template>
  </v-checkbox>
</template>

<script>
export default {
  name: "VisitorFormFlagField",
  props: {
    inputValue: Boolean,
    errorMessage: String,
    isRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    text: {
      type: String,
      required: true,
    },
    subStr: {
      type: String,
      required: false,
      default: ''
    },
    file: {
      type: String,
      required: false,
      default: ''
    },
    marginLeft: {
      type: Boolean,
      required: false,
      default: true
    },
    noPadding: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      value: undefined,
      text_before_link: '',
      text_after_link: '',
      link_text: '',
    }
  },
  methods: {
    prepareText() {
      this.link_text = this.subStr.replaceAll(' ', '&ensp;');
      let tmp_str = this.text.replaceAll(' ', '&ensp;');
      tmp_str = this.text.replace(this.subStr,'');
      let link_index = this.text.indexOf(this.subStr);
      this.text_before_link = tmp_str.slice(0, link_index)
      this.text_after_link = tmp_str.slice(link_index, tmp_str.length)
    }
  },
  created() {
    this.value = this.inputValue
    this.prepareText()
  }
}
</script>

<style scoped lang="scss">
.label {
  cursor: default;
}
</style>
