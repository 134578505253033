<template>
  <div>
    <table v-if="$vuetify.breakpoint.smAndUp">
      <thead>
        <tr class="table-row">
          <td class="table-col table-col__date">
            Дата записи
          </td>
          <td class="table-col table-col__time">
            Время записи
          </td>
          <td class="table-col table-col__seats">
            Количество мест
          </td>
          <td class="table-col table-col__button">

          </td>
        </tr>
      </thead>
      <tbody>
        <SingleSlot
          v-for="(item, i) in slots" :key="i"
          :item="item"
          :has-visit="hasVisit"
          :bg-color-index="getBackgroundColorIndex(i)"
          @createRecord="(data) => $emit('createRecord', data)"
          @cancelRecord="(data) => $emit('cancelRecord', data)"
        ></SingleSlot>
      </tbody>
    </table>
    <v-row v-else class="pa-0 ma-0">
      <v-col cols="12" sm="6" md="4" class="pa-0 ma-0" v-for="(item, i) in slots" :key="i">
        <SingleSlot
          :item="item"
          :has-visit="hasVisit"
          :bg-color-index="getBackgroundColorIndex(i)"
          @createRecord="(data) => $emit('createRecord', data)"
          @cancelRecord="(data) => $emit('cancelRecord', data)"
        ></SingleSlot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SingleSlot from "@/modules/templates/visitor/event_detail/SingleSlot.vue";

export default {
  name: "SlotsTable",
  components: {SingleSlot},
  props: {
    slots: Array,
    hasVisit: Boolean,
  },
  methods: {
    getBackgroundColorIndex(index) {
      // Функция страшной математики для шахматного порядка цветов на любом разрешении
      let cards_in_row = 1;

      let row_number = Math.floor(index / cards_in_row)
      if (cards_in_row % 2 === 1 && row_number % 2 === 1) row_number++;
      return (index + row_number + 1) % 2
    },
  }
}
</script>

<style scoped lang="scss">
table {
  width: 100%;
  border-spacing: 0;
}
.table-row {
  width: 100%;
  padding: 25px 0;
  color: #7C7C7C;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}
.table-col {
  padding: 10px 5px;
  @media (min-width: 1713px) {
    padding: 15px 8px;
  }
}

.table-col__date {
  width: 23%;
}

.table-col__time {
  width: 23%;
}

.table-col__seats {
  width: 23%;
}

.table-col__button {
  width: 31%;
  padding-right: 2%;
}
</style>
