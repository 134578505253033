<template>
  <div>
    <div class="header-text">Список записей</div>
    <table v-if="$vuetify.breakpoint.lgAndUp">
      <thead>
        <tr class="table-row">
          <td class="table-col table-col__name">
            Название события
          </td>
          <td class="table-col table-col__date">
            Дата записи
          </td>
          <td class="table-col table-col__time">
            Время записи
          </td>
          <td class="table-col table-col__icon">
            Создана
          </td>
          <td class="table-col table-col__icon">
            Посещена
          </td>
          <td class="table-col table-col__icon">
            Отменена
          </td>
          <td class="table-col table-col__cancel">

          </td>
        </tr>
      </thead>
      <tbody>
        <SingleRecord
            v-for="(item, i) in items" :key="i"
            :item="item"
            :bg-color-index="getBackgroundColorIndex(i)"
            :event-is-end="eventIsEnd"
            :event-is-draft="eventIsDraft"
            :visit-is-cancelled="visitIsCancelled"
            @cancelRecord="cancelRecordEmit"
        ></SingleRecord>
      </tbody>
    </table>
    <v-row v-else class="pa-0 ma-0">
      <v-col cols="12" sm="6" md="4" class="pa-0 ma-0" v-for="(item, i) in items" :key="i">
        <SingleRecord
            :item="item"
            :bg-color-index="getBackgroundColorIndex(i)"
            :event-is-end="eventIsEnd"
            :event-is-draft="eventIsDraft"
            :visit-is-cancelled="visitIsCancelled"
            @cancelRecord="cancelRecordEmit"
        ></SingleRecord>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SingleRecord from "@/modules/templates/visitor/visit_detail/SingleRecord.vue";

export default {
  name: "RecordsTable",
  components: {SingleRecord},
  props: {
    items: Array,
    eventIsEnd: Boolean,
    eventIsDraft: Boolean,
    visitIsCancelled: Boolean,
  },
  methods: {
    cancelRecordEmit(item) {
      this.$emit("cancelRecord", item)
    },
    getBackgroundColorIndex(index) {
      // Функция страшной математики для шахматного порядка цветов на любом разрешении
      let cards_in_row = 1;

      if (this.$vuetify.breakpoint.sm) cards_in_row = 2
      else if (this.$vuetify.breakpoint.md) cards_in_row = 3
      else if (this.$vuetify.breakpoint.lgAndUp) cards_in_row = 1

      let row_number = Math.floor(index / cards_in_row)
      if (cards_in_row % 2 === 1 && row_number % 2 === 1) row_number++;
      return (index + row_number + 1) % 2
    },
  }
}
</script>

<style scoped lang="scss">
table {
  width: 100%;
  border-spacing: 0;
}
.table-row {
  width: 100%;
  padding: 25px 0;
  color: #7C7C7C;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.table-col {
  padding: 10px 5px;
  @media (min-width: 1713px) {
    padding: 15px 8px;
  }
}

.table-col__name {
  text-align: left;
  width: 16%;
  padding-left: 2%;
}

.table-col__date {
  width: 8%;
}

.table-col__time {
  width: 11%;
}

.table-col__icon {
  width: 7%;
}

.table-col__cancel {
  width: 21%;
  padding-right: 44px;
}

.header-text {
  color: #484848;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 20px;
}
</style>
