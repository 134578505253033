<template >
  <div :class="leftMenuOpened ? 'shadow--left-menu-opened' : ''" class="shadow">
    <v-card
        class="window rounded-lg elevation-0"
        :width="$vuetify.breakpoint.xsOnly ? '100%' : width"
        :color="'rgba(0, 0, 0, 0)'"
    >
      <v-container class="header pa-0 ma-0" :width="$vuetify.breakpoint.xsOnly ? '100%' : width">
        <div class="header__image">
          <div class="header__image--text">
            Добро пожаловать на сервис регистрации мероприятий!
          </div>
          <div class="header__image--close">
            <v-icon
                @click="$emit('close')"
                color="white"
            >
              close
            </v-icon>
          </div>
        </div>
      </v-container>
      <v-container class="main pa-0 ma-0 rounded">
        <v-container class="scroll-container">
          <div class="main px-4 pt-5 pb-8">
            Здесь Вы сможете познакомиться с увлекательными событиями Российского химико-технологического университета им. Д.И. Менделеева. Найдите и запишитесь на мероприятия, которые будут Вам по душе. Ваше участие станет значимым шагом как на профессиональном, так и на личном уровне.
            <br/><br/>
            Для регистрации Вам достаточно записаться на событие.
            Для корпоративных пользователей или зарегистрированных участников требуется выполнить вход.
            <br/><br/>
            Желаем Вам увлекательных и интересных мероприятий!
          </div>
        </v-container>
      </v-container>
    </v-card>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "WelcomeDialogWindow",
  props: {
    opened: Boolean,
    width: {
      type: Number,
      required: false,
      default: 600
    },
  },
  computed: {
    ...mapGetters({
      leftMenuOpened: 'leftmenu/getLeftMenuShow'
    })
  },
}
</script>

<style scoped lang="scss">

.shadow {
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.35);
  position: absolute;
  top: -60px;
  left: 0;
  z-index: 200;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.shadow--left-menu-opened {
  left: -300px;
}
.window {
  background: rgba(0, 0, 0, 0.0);
  border-radius: 11px;
}
.scroll-container {
  text-align: left;
  overflow-y: scroll;
  overflow-x: inherit;
  scrollbar-width: thin;
  background: white;
  max-height: calc(100vh - 78px - 61px);
  padding-bottom: 0;
  border-radius: 0 0 11px 11px!important;
}
.header {
  height: 78px;
}
.header__image {
  display: flex;
  align-items: center;
  text-align: start;
  justify-content: space-between;
  height: 78px;
  width: inherit;
  border-radius: 11px 11px 0 0;
  background: url("../../../assets/customDialogHeaderImage.png");
}
.header__image--text {
  margin-left: 30px;
  color: #FFF;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
  @media (max-width: 500px) {
    font-size: 21px;
  }
  @media (max-width: 420px) {
    font-size: 18px;
  }
  @media (max-width: 330px) {
    font-size: 17px;
  }
}
.header__image--close {
  margin-right: 31px;
  margin-left: 10px;
  margin-top: 4px;
}
.main {
  border-radius: 0 0 11px 11px;
  background: white;
  color: #7C7C7C;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 500px) {
    font-size: 17px;
  }
  @media (max-width: 420px) {
    font-size: 16px;
  }
  @media (max-width: 330px) {
    font-size: 15px;
  }
  border-radius: 0 0 11px 11px!important;
}

</style>
