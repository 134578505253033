<template>
  <div
      class="no-events-container"
      :class="getContainerClasses"
  >
    <div :class="$vuetify.breakpoint.xsOnly ? 'no-events-container__text--xs' : 'no-events-container__text--smAndUp'">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptySearchComponent",
  props: {
    text: String,
    muctrUser: Boolean,
    isEventsList: {
      type: Boolean,
      required: false,
      default: false
    },
    isVisitsList: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  computed: {
    getContainerClasses() {
      let class_str = '';
      class_str += this.muctrUser ? 'no-events-container--muctr-user ' : 'no-events-container--outer-user '
      class_str += (this.isEventsList && this.muctrUser ? 'no-events-container--muctr-user--events' : '')
      class_str += (this.isVisitsList && this.muctrUser ? 'no-events-container--muctr-user--visits' : '')
      class_str += (this.isEventsList && !this.muctrUser ? 'no-events-container--outer-user--events' : '')
      class_str += (this.isVisitsList && !this.muctrUser ? 'no-events-container--outer-user--visits' : '')
      return class_str
    }
  }
}
</script>

<style scoped lang="scss">
.no-events-container {
  padding: 5vh 5vw 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #969696;
  font-family: 'Inter normal', serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.no-events-container__text--xs {
  font-size: 26px;
}

.no-events-container__text--smAndUp {
  font-size: 36px;
}

.no-events-container--muctr-user {
  // 60px верхнее меню
  // 75px (600px - 78.74px) шапка сайта с поиском и заголовком
  // 131px (302px - 179px) шапка с бредкрамсом
  // 58px (379px - 94px) футер с количеством элементов на странице
  height: calc(100vh - 60px - 75px - 58px - 131px);
}

.no-events-container--muctr-user--events {
  @media (min-width: 423px) and (max-width: 600px) {
    height: calc(100vh - 60px - 79px - 58px - 131px);
  }
  @media (max-width: 422px) {
    height: calc(100vh - 60px - 79px - 94px - 131px);
  }
}

.no-events-container--muctr-user--visits {
  @media (min-width: 438px) and (max-width: 600px) {
    height: calc(100vh - 60px - 79px - 58px - 131px);
  }
  @media (max-width: 437px) {
    height: calc(100vh - 60px - 79px - 94px - 131px);
  }
}

.no-events-container--outer-user {
  // 60px верхнее меню
  // 75px (600px - 120.84px) шапка сайта с поиском и заголовком
  // 58px (379px - 94px) футер с количеством элементов на странице
  height: calc(100vh - 60px - 75px - 58px);
  @media (min-width: 380px) and (max-width: 600px) {
    height: calc(100vh - 60px - 121px - 58px);
  }
  @media (max-width: 379px) {
    height: calc(100vh - 60px - 121px - 94px);
  }
}

.no-events-container--outer-user--events {
  @media (min-width: 423px) and (max-width: 600px) {
    height: calc(100vh - 60px - 121px - 58px );
  }
  @media (max-width: 422px) {
    height: calc(100vh - 60px - 121px - 94px );
  }
}

.no-events-container--outer-user--visits {
  @media (min-width: 431px) and (max-width: 600px) {
    height: calc(100vh - 60px - 121px - 58px);
  }
  @media (max-width: 430px) {
    height: calc(100vh - 60px - 121px - 94px);
  }
}
</style>
