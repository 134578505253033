import { render, staticRenderFns } from "./EventDetail.vue?vue&type=template&id=885d815e&scoped=true"
import script from "./EventDetail.vue?vue&type=script&lang=js"
export * from "./EventDetail.vue?vue&type=script&lang=js"
import style0 from "./EventDetail.vue?vue&type=style&index=0&id=885d815e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "885d815e",
  null
  
)

export default component.exports