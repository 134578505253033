<template>
  <v-col cols="12">
    <v-row no-gutters>
      <v-text-field
        v-model="data.data.data.headers[entryIndex]"
        outlined
        label="Заголовок"
        class="pa-0 ma-0 mb-2 text-left"
      ></v-text-field>
    </v-row>
    <v-row no-gutters>
      <v-textarea
        outlined
        rows="5"
        no-resize
        v-model="data.data.data.texts[entryIndex]"
        :error="!!data.errors.texts[entryIndex]"
        :error-messages="data.errors.texts[entryIndex]"
        class="pa-0 ma-0 text-left"
      >
        <template v-slot:label>
          Текст<span class="red--text"> *</span>
        </template>
      </v-textarea>
    </v-row>
    <v-card-text class="text-start pa-0 my-4 ml-4 text-subtitle-1 text-sm-h6 font-weight-bold" v-if="data.all_files[entryIndex]">Сохранённое изображение</v-card-text>
    <v-row
        v-if="data.all_files[entryIndex]"
        class="mb-4 mx-1 pa-0"
    >
      <file-chip
        :data="data"
        :i="entryIndex"
        @moveFileUp="moveFileUp"
        @moveFileDown="moveFileDown"
        @deleteOneFile="deleteOneFile"
        :moving-files-allowed="false"
      ></file-chip>
    </v-row>
    <v-card-text class="text-start pa-0 my-4 ml-4 text-subtitle-1 text-sm-h6 font-weight-bold">Загрузите изображение</v-card-text>
    <v-row no-gutters>
      <v-file-input
          v-model="data.new_files[entryIndex]"
          outlined
          show-size
          @change="$emit('loadFilesFromInput', data.id)"
          :error="!!data.errors.files[entryIndex]"
          :error-messages="data.errors.files[entryIndex]"
      >
        <template v-slot:label>
          Загрузите изображение<span class="red--text"> *</span>
        </template>
      </v-file-input>
    </v-row>
  </v-col>
</template>

<script>
import FileChip from "@/modules/templates/description_blocks/blocks/blocks_components/FileChip.vue";

export default {
  name: "EmptyEntry",
  components: {FileChip},
  props: {
    data: Object,
    entryIndex: Number,
  },
  methods: {
    moveFileUp(id, file_index) {
      this.$emit('moveFileUp', id, file_index)
    },
    moveFileDown(id, file_index) {
      this.$emit('moveFileDown', id, file_index)
    },
    deleteOneFile(id, file_index) {
      this.$emit('deleteOneFile', id, file_index)
    },
  }
}
</script>

<style scoped>

</style>
