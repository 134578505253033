import {sendGetRequest} from "@/helper/requests";
import viewer from "@/urls/roles/viewer";


const state = {
    slots:{
        currentUrl: null,
        items: [],
        loading: false,
        count: 0,
        detail: [],
        error: null,
    }
}

const getters = {
    getSlotsList: state => state.slots.items,
    getSlotsCount: state => state.slots.count,
    getSlotsCurrentUrl: state => state.slots.currentUrl,
    getSlotsDetail: state => state.slots.detail,
    isLoadingSlots: state => state.slots.loading,
    saveSlotsError: state => state.slots.error,
}

const actions = {
    loadSlotsList: ({commit, getters}, {url, event_id, urlAppend, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) {
            url = viewer.VIEWER.EVENTS.SLOTS.LIST(event_id)
            if (urlAppend) url = urlAppend(url)
        }
        if (!getters.isLoadingSlots){
            commit('SET_SLOTS_LOADING');
            sendGetRequest(
                url, (data, url) => {
                    commit('SET_SLOTS_LIST', data['results']);
                    commit('SET_SLOTS_COUNT', data['count']);
                    commit('SET_SLOTS_CURRENT_URL', url);
                    commit('SET_SLOTS_LOADING_END');
                    finalizer(data, url);
                }, (data, url) => {
                    commit('SET_SLOTS_LOADING_END');
                    commit('SAVE_SLOTS_ERROR', data);
                    catcher(data, url);
                }
            )
        }
    },
    loadSlotsDetail: ({commit}, {url, event_id, id, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) url = viewer.VIEWER.EVENTS.SLOTS.DETAIL(event_id, id)
        sendGetRequest(
            url, (data, url) => {
                commit('SET_SLOTS_DETAIL', data);
                finalizer(data, url);
            }, (data, url) => {
                commit('SAVE_SLOTS_ERROR', data);
                catcher(data, url);
            }
        )
    },
}

const mutations = {
    SET_SLOTS_LIST: (state, slots) => {
        state.slots.items = slots
    },
    SET_SLOTS_DETAIL: (state, value) => {
        state.slots.detail = value
    },
    SET_SLOTS_COUNT: (state, value) => {
        state.slots.count = value
    },
    SET_SLOTS_CURRENT_URL: (state, value) => {
        state.slots.currentUrl = value
    },
    SET_SLOTS_LOADING: (state) => {
        state.slots.loading = true
    },
    SET_SLOTS_LOADING_END: (state) => {
        state.slots.loading = false
    },
    SAVE_SLOTS_ERROR: (state, error) => {
        state.slots.error = error
    }
}


export default {
    namespaced: false,
    state,
    mutations,
    actions,
    getters
}
