import {sendPostRequest, sendPatchRequest, sendDeleteRequest, sendGetRequest, sendPutRequest} from "@/helper/requests";
import organiser from "@/urls/roles/organiser";
import {executeRequest} from "@/store/utils";

const state = {
    visits:{
        currentUrl: null,
        items: [],
        loading: false,
        count: 0,
        detail: [],
        error: null,
    }
}

const getters = {
    getVisitsList: state => state.visits.items,
    getVisitsCount: state => state.visits.count,
    getVisitsCurrentUrl: state => state.visits.currentUrl,
    getVisitsDetail: state => state.visits.detail,
    isLoadingVisits: state => state.visits.loading,
    saveVisitsError: state => state.visits.error,
}

const actions = {
    loadVisitsList: ({commit, getters}, {url, urlAppend, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) {
            url = organiser.ORGANISER.EVENTS.VISITS.LIST()
            if (urlAppend) url = urlAppend(url)
        }
        if (!getters.isLoadingVisits){
            commit('SET_VISITS_LOADING');
            sendGetRequest(
                url, (data, url) => {
                    commit('SET_VISITS_LIST', data['results']);
                    commit('SET_VISITS_COUNT', data['count']);
                    commit('SET_VISITS_CURRENT_URL', url);
                    commit('SET_VISITS_LOADING_END');
                    finalizer(data, url);
                }, (data, url) => {
                    commit('SET_VISITS_LOADING_END');
                    commit('SAVE_VISITS_ERROR', data);
                    catcher(data, url);
                }
            )
        }
    },
    loadVisitsDetail: ({commit}, {url, id, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) url = organiser.ORGANISER.EVENTS.VISITS.DETAIL(id)
        sendGetRequest(
            url, (data, url) => {
                commit('SET_VISITS_DETAIL', data);
                finalizer(data, url);
            }, (data, url) => {
                commit('SAVE_VISITS_ERROR', data);
                catcher(data, url);
            }
        )
    },
    cancelVisits: ({commit}, {id, finalizer=()=>{}, catcher=()=>{}}) => {
        let  url = organiser.ORGANISER.EVENTS.VISITS.CANCEL(id)
        sendPatchRequest(
            url,
            {},
            (data, url) => {
                finalizer(data, url);
            },
            (data, url) => {
                commit('SAVE_VISITS_ERROR', data);
                catcher(data, url);
            }
        )
    },
    enterVisits: ({commit}, {id, finalizer=()=>{}, catcher=()=>{}}) => {
        let  url = organiser.ORGANISER.EVENTS.VISITS.ENTER(id)
        sendPatchRequest(
            url,
            {},
            (data, url) => {
              finalizer(data, url);
            },
            (data, url) => {
              commit('SAVE_VISITS_ERROR', data);
              catcher(data, url);
            }
        )
    },
    rollBackEnterVisits: ({commit}, {id, finalizer=()=>{}, catcher=()=>{}}) => {
        let  url = organiser.ORGANISER.EVENTS.VISITS.ROLL_BACK_ENTER(id)
        sendPatchRequest(
            url,
            {},
            (data, url) => {
                finalizer(data, url);
            },
            (data, url) => {
                commit('SAVE_VISITS_ERROR', data);
                catcher(data, url);
            }
        )
    },
}

const mutations = {
    SET_VISITS_LIST: (state, visits) => {
        state.visits.items = visits
    },
    SET_VISITS_DETAIL: (state, value) => {
        state.visits.detail = value
    },
    SET_VISITS_COUNT: (state, value) => {
        state.visits.count = value
    },
    SET_VISITS_CURRENT_URL: (state, value) => {
        state.visits.currentUrl = value
    },
    SET_VISITS_LOADING: (state) => {
        state.visits.loading = true
    },
    SET_VISITS_LOADING_END: (state) => {
        state.visits.loading = false
    },
    SAVE_VISITS_ERROR: (state, error) => {
        state.visits.error = error
    }
}


export default {
    namespaced: false,
    state,
    mutations,
    actions,
    getters
}
