<template>
  <component
      :is="previewOpened ? 'div' : 'v-container'"
      class="page-content"
      :class="previewOpened ? '' : ' px-5'"
      style="  width: 100%"
  >
    <div v-if="$route.name === names.EVENTS.LIST">
      <v-toolbar
          class="removeBtnPaddingRight rounded-t-lg"
          color="#DADADA"
          flat
      >
        <v-text-field
            v-model="search.value"
            class="dark-primary my-1"
            clearable
            dense
            outlined
            label="Поиск"
            hide-details
            @keydown.enter="searchData"
            @click:clear="clearSearchData"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-toolbar-items v-if="$vuetify.breakpoint.smAndUp">
          <v-btn
              text
              @click="newEvent"
              class="dark-primary mr-0 rounded-tr-lg"
          >
            Добавить событие
          </v-btn>
        </v-toolbar-items>
        <v-btn
            v-if="$vuetify.breakpoint.xsOnly"
            icon
            class="dark-primary mr-0"
            @click="newEvent"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
          :headers="headers"
          :items="items"
          :loading="isLoading"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.size"
          :server-items-length="count"
          :options.sync="options"
          :footer-props="{'items-per-page-options':[10,50,100]}"
          class="elevation-1 rounded-b-lg"
      >
        <template v-slot:item.name="{ item }">
          <span style="text-decoration: underline; color: #065aad; cursor: pointer" @click="getDetail(item.id)">{{item.name}}</span>
        </template>
        <template v-slot:item.published="{ item }">
          <v-icon
              :color="checkFlag(!item.is_draft).color"
          >
            {{checkFlag(!item.is_draft).icon}}
          </v-icon>
        </template>
        <template v-slot:item.record_start="{ item }">
          <span v-if="item.record_start!==null">{{ correctTime(item.record_start, true) }}</span>
        </template>
        <template v-slot:item.record_end="{ item }">
          <span v-if="item.record_end!==null">{{ correctTime(item.record_end, true) }}</span>
        </template>
        <template v-slot:item.start="{ item }">
          <span v-if="item.start!==null">{{ displayDate(item.start) }}</span>
        </template>
        <template v-slot:item.end="{ item }">
          <span v-if="item.end!==null">{{ displayDate(item.end) }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <div v-if="!(item.is_end && !item.is_draft)">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    class="mr-2"
                    @click="editEvent(item.id)"
                    v-bind="attrs"
                    v-on="on"
                >
                  edit
                </v-icon>
              </template>
              <span>Редактировать</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    @click="deleteEvent(item.id)"
                    v-bind="attrs"
                    v-on="on"
                >
                  delete
                </v-icon>
              </template>
              <span>Удалить</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:no-data>
          <v-btn
              color="primary"
              @click="updateData"
          >
            Обновить
          </v-btn>
        </template>
      </v-data-table>
      <EditDialog
          v-if="dialog_add_edit"
          :opened="dialog_add_edit"
          :edit="edit_status"
          :event-id="event_id"
          @close="closeEditDialog"
      ></EditDialog>
      <ApplyDialog
          v-if="dialog_delete"
          :opened="dialog_delete"
          :get-text="() => `Вы уверены, что хотите удалить ?`"
          @close="closeDeleteDialog"
      ></ApplyDialog>
    </div>
    <router-view v-else></router-view>
  </component>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import EditDialog from "@/modules/admin/events/modals/EventsEditDialog";
import ApplyDialog from "@/modules/core/components/ApplyDialog";
import names from '@/modules/admin/routers/names';
import {displayDate} from "@/helper";
import admin from "@/urls/roles/admin";
import names_core from "@/modules/core/routers/names";

export default {
  name: "AdminEventsList",
  components: {EditDialog, ApplyDialog},
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  data() {
    return {
      page_title: "Список событий",
      dialog_add_edit: false,
      dialog_delete: false,
      edit_status: undefined,
      event_id: undefined,
      search: {
        parameter: 'name',
      },
      headers: [
        {text: 'Название', value: 'name', sortable: false, align: 'start', width: '25%'},
        {text: 'Опубликовано', value: 'published', sortable: false, align: 'center', width: '10%'},
        {text: 'Дата начала регистрации', value: 'record_start', sortable: false, align: 'center', width: '13%'},
        {text: 'Дата конца регистрации', value: 'record_end', sortable: false, align: 'center', width: '13%'},
        {text: 'Дата начала события', value: 'start', sortable: false, align: 'center', width: '13%'},
        {text: 'Дата конца события', value: 'end', sortable: false, align: 'center', width: '13%'},
        {text: 'Действия', value: 'actions', sortable: false, align: 'center', width: '13%'},
      ],
    }
  },
  computed: {
    ...mapGetters({
      items: 'admin/getEventsList',
      count: 'admin/getEventsCount',
      isLoading: 'admin/isLoadingEvents',
      previewOpened: 'mainpage/isPreviewOpened'
    }),
    names: () => names,
  },
  watch: {
    '$route': function(newVal) {
      let preview_opened = (newVal.name.indexOf('|Events|Detail|Preview|Main') !== -1)
      this.updatePreviewOpenedState({
        value: preview_opened
      })
    }
  },
  methods: {
    ...mapActions({
      loadEventsList: 'admin/loadEventsList',
      deleteEvents: 'admin/deleteEvents',
      updatePreviewOpenedState: 'mainpage/updatePreviewOpenedState',
    }),
    displayDate: displayDate,
    correctTime (time, minute = false) {
      if(time!==null) {
        return this.displayDate(time, minute)
      }
    },
    getDetail(id) {
      this.$router.push({name: names.EVENTS.DETAIL, params: {idEvent: id}})
    },
    updateData(){
      if (this.search.value) {
        this.search.value = ''
      }
      this.loadData()
    },
    loadData(url = undefined) {
      this.loadEventsList({
        url: url,
        urlAppend: (url) => {
          let new_url = this.addPageParameter(this.addSizeParameter(url))
          if (this.search.value)
            new_url = this.addGetParameter(new_url, 'name', this.search.value)
          return new_url
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
        }
      })
    },
    newEvent() {
      this.dialog_add_edit = true
      this.edit_status = false
      this.event_id = undefined
    },
    editEvent(id) {
      this.dialog_add_edit = true
      this.edit_status = true
      this.event_id = id
    },
    deleteEvent(item) {
      this.dialog_delete = true
      this.event_id = item
    },
    closeEditDialog() {
      this.loadData()
      this.dialog_add_edit = false
      this.event_id = undefined
    },
    closeDeleteDialog(event) {
      if (event) {
        this.deleteEvents({
          id: this.event_id,
          finalizer: () => {
            this.loadData()
          },
          catcher: (val) => {
            this.mapErrors(val)
          },
        })
      }
      this.dialog_delete = false
      this.event_id = undefined
    },
    checkFlag(flag) {
      if (flag) return {icon: "check_circle", color: "green"}
      else return {icon: "not_interested", color: "grey"}
    },
  },
  beforeRouteUpdate(to, from, next){
    next()
    if (admin.ADMIN.ALLOWED() && this.$route.name === names.EVENTS.LIST){
      this.loadData()
    }
  },
  created() {
    if (admin.ADMIN.ALLOWED()){
      if(this.$route.name === names.EVENTS.LIST){
        this.loadData()
      }
    } else{
      this.$router.push({name: names_core.MAIN})
    }
  },
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0;
}
</style>
