<template>
  <div class="page-content" style="margin: 0px; width: 100%">
<!--    <PageHeader v-if="check"></PageHeader>-->
<!--      {{check}}-->
<!--    <Main-->
<!--        v-if="$route.name === names.MAIN && check"-->
<!--    ></Main>-->
<!--    <WelcomePage v-if ="$route.name === names.MAIN && check"-->
<!--                 @goPortal="goPortal"-->
<!--                 @goVisitor="goVisitor"-->
<!--    ></WelcomePage>-->
<!--    <router-view v-else></router-view>-->
  </div>
</template>

<script>
import names from '@/modules/core/routers/names'
import {mapGetters} from "vuex";
import admin from "@/urls/roles/admin";
import viewer from "@/urls/roles/viewer";
import organiser from "@/urls/roles/organiser";
import operator from "@/urls/roles/operator";
import visitor from "@/urls/roles/visitor";
import admin_names from "@/modules/admin/routers/names";
import viewer_names from "@/modules/viewer/routers/names";
import organiser_names from "@/modules/organiser/routers/names";
import operator_names from "@/modules/operator/routers/names";
import visitor_names from "@/modules/visitor/routers/names";

export default {
  name: "AppBase",
  data() {
    return {
      left_menu_list: [
        {title: 'Главная страница', icon: 'home', included: true, router: {name:names.MAIN, params:{}}},
      ],
      check: false,
    }
  },
  computed: {
    names: () => names,
    ...mapGetters({
      isAuthenticated: 'user/isAuthenticate'
    })
  },
  methods: {
    loadLeftmenu() {
      this.$store.commit('mainpage/changeTitle', 'Регистрация событий');
    },
    getLeftMenu() {
      let with_category_uid = (name) => {
        return {name: name, params: {category: this.$route.params.category}}
      };
      let menu = []
      for (let el in this.left_menu_list){
        menu.push(this.left_menu_list[el])
      }
      return menu
    },
    checkAuthenticate(finalizer=()=>{}){
      if(this.isAuthenticated){
        let is_admin = admin.ADMIN.ALLOWED()
        let is_viewer = viewer.VIEWER.ALLOWED()
        let is_organiser = organiser.ORGANISER.ALLOWED()
        let is_operator = operator.OPERATOR.ALLOWED()
        let is_visitor = visitor.VISITOR.ALLOWED()
        if(is_admin ||is_viewer || is_organiser || is_operator || is_visitor){
            finalizer()
        }
      } else{
        this.$router.push({name: visitor_names.EVENTS.LIST})
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.checkAuthenticate(
        ()=>{
          this.loadLeftmenu()
        }
    )
    next()
  },
  created() {
    this.checkAuthenticate(
      ()=>{
        this.$store.commit('leftmenu/enableLeftMenu');
        this.loadLeftmenu()

        if (admin.ADMIN.ALLOWED())
          this.$router.push({name: admin_names.MAIN})
        else if (viewer.VIEWER.ALLOWED())
          this.$router.push({name: viewer_names.MAIN})
        else if (organiser.ORGANISER.ALLOWED())
            this.$router.push({name: organiser_names.MAIN})
        else if (operator.OPERATOR.ALLOWED())
          this.$router.push({name: operator_names.MAIN})
        else if (visitor.VISITOR.ALLOWED())
          this.$router.push({name: visitor_names.EVENTS.LIST})
      }
    )
  }
}
</script>

<style scoped>
.border-right {
  border-right: 2px solid var(--steel-gray);
}

</style>
