<template>
  <div>
    <slot name="btn"></slot>
    <v-select
      outlined
      class="mt-8"
      label="Тип блока"
      :items="blockTypes"
      v-model="data.selected"
    >
    </v-select>
    <v-btn
        @click="$emit('chooseBlockType', data.selected, data.id)"
    >
      Подтвердить
    </v-btn>
    <v-alert
        v-if="data.errors.empty.length > 0"
        color="red"
        class="elevation-2 mt-5"
        type="error"
    > {{ data.errors.empty }}</v-alert>
  </div>
</template>

<script>

export default {
  props: {
    data: Object,
    blockTypes: Array,
  },
  name: "EmptyBlock",
}
</script>

<style scoped>

</style>
