<template>
  <div>
    <v-expansion-panels
        v-model="panel"
        multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="text-h6 font-weight-bold">
          Список записей на слоты
          <template v-slot:actions>
            <v-icon color="primary">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-toolbar
              class="removeBtnPaddingRight rounded-t-lg"
              color="#DADADA"
              flat
          >
            <!--            <v-text-field-->
            <!--                class="pb-1"-->
            <!--                single-line-->
            <!--                v-model="search.value" label="Поиск" hide-details @keydown.enter="searchData">-->
            <!--            </v-text-field>-->
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-data-table
              :headers="headers"
              :items="this[`itemsRecords${this.role}`]"
              :loading="this[`isLoadingRecords${this.role}`]"
              :page.sync="pagination.page"
              :items-per-page.sync="pagination.size"
              :server-items-length="this[`countRecords${this.role}`]"
              :options.sync="options"
              :footer-props="{'items-per-page-options':[10,50,100]}"
              class="elevation-1 rounded-b-lg"
          >
            <template v-slot:item.date="{ item }">
              <div v-if="item.date">
                {{ displayDate(item.date) }}
              </div>
              <div v-else-if="item.slot.intervals.length > 0">
                {{ displayDate(item.slot.intervals[0].start) + '-' + displayDate(item.slot.intervals[0].end) }}
              </div>
              <div v-else>
                {{ displayDate(item.slot.event.start) + '-' + displayDate(item.slot.event.end) }}
              </div>
            </template>
            <template v-slot:item.slot.start="{ item }">
              {{ deleteSecondsFromTime(item.slot.start) }}
            </template>
            <template v-slot:item.slot.end="{ item }">
              {{ deleteSecondsFromTime(item.slot.end) }}
            </template>
            <template v-slot:item.is_enter="{ item }">
              <v-icon
                  :color="checkEnterFlag(item.is_enter).color"
              >
                {{ checkEnterFlag(item.is_enter).icon }}
              </v-icon>
            </template>
            <template v-slot:item.is_cancel="{ item }">
              <v-icon
                  :color="checkCancelFlag(item.is_cancel).color"
              >
                {{ checkCancelFlag(item.is_cancel).icon }}
              </v-icon>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip v-if="recordType==='slot'" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      class="mr-2"
                      @click="getDetailVisit(item.visit)"
                      v-bind="attrs"
                      v-on="on"
                  >
                    assignment
                  </v-icon>
                </template>
                <span v-if="isAdminOrViewer()">Детальное представление посещения </span>
                <span v-else>Детальное представление посетителя </span>
              </v-tooltip>
              <v-tooltip v-if="!item.is_cancel && !item.is_enter && isAdminOrOrganiserOrVisitor() && !item.slot.is_default && !(lightDetail.is_end && !lightDetail.is_draft)" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      class="mr-2"
                      @click="cancelRecord(item.id)"
                      v-bind="attrs"
                      v-on="on"
                  >
                    cancel
                  </v-icon>
                </template>
                <span>Отменить запись</span>
              </v-tooltip>
              <v-tooltip bottom v-if="!item.is_cancel && isAdminOrOrganiserOrOperator() && !item.is_enter && !(lightDetail.is_end && !lightDetail.is_draft)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    @click="enterRecord(item.id)"
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    add_task
                  </v-icon>
                </template>
                <span>Зафиксировать посещение слота</span>
              </v-tooltip>
              <v-tooltip bottom v-if="!item.is_cancel && isAdminOrOrganiserOrOperator() && item.is_enter && !(lightDetail.is_end && !lightDetail.is_draft)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      class="mr-2"
                      @click="backRecord(item.id)"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                  >
                    undo
                  </v-icon>
                </template>
                <span>Отменить фиксацию посещения слота</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data>
              <v-btn
                  color="primary"
                  @click="updateDataRecords"
              >
                Обновить
              </v-btn>
            </template>
          </v-data-table>
          <ApplyDialog
              v-if="dialog_cancel && isAdminOrOrganiserOrVisitor()"
              :opened="dialog_cancel"
              :get-text="() => `Вы уверены, что хотите отменить запись на слот?`"
              @close="closeCancelDialog"
          ></ApplyDialog>
          <ApplyDialog
            v-if="dialog_enter && isAdminOrOrganiserOrOperator()"
            :opened="dialog_enter"
            :get-text="() => `Вы уверены, что хотите зафиксировать посещение слота ?`"
            @close="closeEnterDialog"
          ></ApplyDialog>
          <ApplyDialog
              v-if="dialog_back && isAdminOrOrganiserOrOperator()"
              :opened="dialog_back"
              :get-text="() => `Вы уверены, что хотите отменить фиксацию посещения слота ?`"
              @close="closeBackDialog"
          ></ApplyDialog>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import ApplyDialog from "@/modules/core/components/ApplyDialog";
import {displayDate} from "@/helper";

export default {
  name: "EventDetailRecordsList",
  props: {
    role:{
      type: String,
      // default: 'Admin',
    },
    recordType:{
      type: String,
      // default: 'Admin',
    },
    lightDetail: {
      type: Object,
      required: false,
      default: () => {
        return {
          is_draft: false,
          is_end: false,
        }
      }
    },
  },
  components: {ApplyDialog},
  mixins: [PaginatedDataMapperMixin],
  data() {
    return {
      dialog_cancel: false,
      dialog_enter: false,
      record_id: undefined,
      panel: [0, 1],
      // search: {
      //   parameter: 'name',
      // },
      headers: [
          this.recordType !== 'slot' ?
        {text: 'Название события', value: 'slot.event.name', sortable: false, align: 'left', width: '28%'} :
        {text: 'ФИО', value: 'fio', sortable: false, align: 'left', width: '28%'}
        ,
        {text: 'Дата', value: 'date', sortable: false, align: 'center', width: '12%'},
        {text: 'Время начала', value: 'slot.start', sortable: false, align: 'center', width: '12%'},
        {text: 'Время окончания', value: 'slot.end', sortable: false, align: 'center', width: '12%'},
        {text: 'Посещено', value: 'is_enter', sortable: false, align: 'center', width: '12%'},
        {text: 'Отменено', value: 'is_cancel', sortable: false, align: 'center', width: '12%'},
        {text: 'Действия', value: 'actions', sortable: false, align: 'center', width: '12%'},
      ],
      dialog_back: false,
    }
  },
  computed: {
    ...mapGetters({
      itemsRecordsAdmin: 'admin/getRecordsList',
      countRecordsAdmin: 'admin/getRecordsCount',
      isLoadingRecordsAdmin: 'admin/isLoadingRecords',
      itemsRecordsViewer: 'viewer/getRecordsList',
      countRecordsViewer: 'viewer/getRecordsCount',
      isLoadingRecordsViewer: 'viewer/isLoadingRecords',
      itemsRecordsOrganiser: 'organiser/getRecordsList',
      countRecordsOrganiser: 'organiser/getRecordsCount',
      isLoadingRecordsOrganiser: 'organiser/isLoadingRecords',
      itemsRecordsOperator: 'operator/getRecordsList',
      countRecordsOperator: 'operator/getRecordsCount',
      isLoadingRecordsOperator: 'operator/isLoadingRecords',
      itemsRecordsVisitor: 'visitor/getRecordsList',
      countRecordsVisitor: 'visitor/getRecordsCount',
      isLoadingRecordsVisitor: 'visitor/isLoadingRecords',
    }),
  },
  methods: {
    ...mapActions({
      loadRecordsListAdmin: 'admin/loadRecordsList',
      cancelRecordsAdmin: 'admin/cancelRecords',
      enterRecordsAdmin: 'admin/enterRecords',
      backRecordsAdmin: 'admin/rollBackEnterRecords',
      loadRecordsListViewer: 'viewer/loadRecordsList',
      loadRecordsListOrganiser: 'organiser/loadRecordsList',
      cancelRecordsOrganiser: 'organiser/cancelRecords',
      enterRecordsOrganiser: 'organiser/enterRecords',
      backRecordsOrganiser: 'organiser/rollBackEnterRecords',
      loadRecordsListOperator: 'operator/loadRecordsList',
      enterRecordsOperator: 'operator/enterRecords',
      backRecordsOperator: 'operator/rollBackEnterRecords',
      loadRecordsListVisitor: 'visitor/loadRecordsList',
      cancelRecordsVisitor: 'visitor/cancelRecords',
    }),
    displayDate: displayDate,
    getDetailVisit(id) {
      this.$router.push({name: this.role + '|Events|Detail|Visits|Detail', params: {idVisit: id}, query: {slot: this.$route.params.idSlot}})
    },
    deleteSecondsFromTime(time_string) {
      let time_array = time_string.split(':');
      return time_array[0] + ':' + time_array[1];
    },
    updateDataRecords(){
      // if (this.search.value) {
      //   this.search.value = ''
      // }
      this.loadData()
    },
    checkEnterFlag(flag) {
      if (flag) return {icon: "check_circle", color: "green"}
      else return {icon: "not_interested", color: "grey"}
    },
    checkCancelFlag(flag) {
      if (flag) return {icon: "check_circle", color: "yellow darken-3"}
      else return {icon: "not_interested", color: "grey"}
    },
    loadData(url = undefined) {
      this[`loadRecordsList${this.role}`]({
        url: url,
        visit_id: this.$route.params.idVisit,
        urlAppend: (url) => {
          return this.addPageParameter(
              this.addSizeParameter(
                  this.recordType ? this.addGetParameter(
                      url,
                      `${this.recordType}`,
                      this.recordType==='visit'? this.$route.params.idVisit : this.$route.params.idSlot
                  ) : url
              )
          )
        },
        finalizer: (data, url) => {
        }
      })
    },
    backRecord(item) {
      this.dialog_back = true
      this.record_id = item
    },
    closeBackDialog(record) {
      if (record) {
        this[`backRecords${this.role}`]({
          visit_id: this.$route.params.idVisit,
          id: this.record_id,
          finalizer: (val) => {
            this.loadData()
            this.$emit('reloadData')
          },
          catcher: (val) => {
            this.mapErrors(val)
          },
        })
      }
      this.dialog_back = false
      this.record_id = undefined
    },
    cancelRecord(item) {
      this.dialog_cancel = true
      this.record_id = item
    },
    closeCancelDialog(record) {
      if (record) {
        this[`cancelRecords${this.role}`]({
          visit_id: this.$route.params.idVisit,
          id: this.record_id,
          finalizer: (val) => {
            this.loadData()
          },
          catcher: (val) => {
            this.mapErrors(val)
          },
        })
      }
      this.dialog_cancel = false
      this.record_id = undefined
    },
    enterRecord(item) {
      this.dialog_enter = true
      this.record_id = item
    },
    closeEnterDialog(record) {
      if (record) {
        this[`enterRecords${this.role}`]({
          visit_id: this.$route.params.idVisit,
          id: this.record_id,
          finalizer: (val) => {
            this.loadData()
            this.$emit('reloadData')
          },
          catcher: (val) => {
            this.mapErrors(val)
          },
        })
      }
      this.dialog_enter = false
      this.record_id = undefined
    },
    isAdminOrOrganiserOrVisitor(){
      if(this.role==='Admin' || this.role==='Organiser' || this.role==='Visitor')
        return true
      else
        return false
    },
    isAdminOrOrganiserOrOperator(){
      if(this.role==='Admin' || this.role==='Organiser' || this.role==='Operator')
        return true
      else
        return false
    },
    isAdminOrViewer(){
      if(this.role==='Admin' || this.role==='Viewer')
        return true
      else
        return false
    },
    isOrganiser(){
      if(this.role==='Organiser')
        return true
      else
        return false
    },
    isOperator(){
      if(this.role==='Operator')
        return true
      else
        return false
    },
    isVisitor(){
      if(this.role==='Visitor')
        return true
      else
        return false
    },
  },
  created() {
    this.loadData()
  },
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0px;
}
</style>
