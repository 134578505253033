<template>
  <div v-if="$route.name === names.EVENTS.DETAIL && loading">
    <EventDetail
      :is-archived="is_archived"
      :is-hidden="is_hidden"
      :not-found="not_found"
      :no-access="no_access"
    ></EventDetail>
  </div>
  <router-view v-else></router-view>
</template>

<script>
import EventDetail from "@/modules/visitor/events/pages/Detail";
import names from "@/modules/visitor/routers/names";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "VisitorBreadcrumbsEvent",
  components: {EventDetail},
  data() {
    return {
      loading: false,
      name: "",
      have_parent: false,
      is_archived: false,
      is_hidden: false,
      not_found: false,
      no_access: false,
    }
  },
  computed: {
    ...mapGetters({
      item: 'visitor/getEventsLightDetail',
      item_detail: 'visitor/getEventsDetail',
    }),
    names: () => names,
  },
  methods: {
    ...mapActions({
      loadEventLightDetail: 'visitor/loadEventsLightDetail',
    }),
    loadData(url=undefined){
      this.loadEventLightDetail({
        url: url,
        id: this.$route.params.idEvent,
        finalizer: (data) => {
          this.name = data.name
          this.parent = data.parent
          this.is_visit = data.is_visit
          this.loading = true
        },
        catcher: (data) => {
          this.updateEventStatus(data)
          this.loading = true
        }
      })
    },
    updateEventStatus(data) {
      this.is_archived = false
      this.is_hidden = false
      this.not_found = false
      this.no_access = false
      if (data['detail'] === 'Архивация') {
        this.is_archived = true
      }
      else if (data['detail'] === 'Скрыто') {
        this.is_hidden = true
      }
      else if (data['detail'] === 'Нет доступа') {
        this.no_access = true
      }
      else {
        this.not_found = true
      }
    },
  },
  created() {
    this.loadData(undefined)
  }
}
</script>

<style scoped>

</style>
