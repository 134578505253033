<template>
  <td
      :colspan="outerHeadersLength"
      v-if="$vuetify.breakpoint.smAndUp"
      style="background-color: rgba(53,106,158,0.5)"
  >
    <v-container class="page-content px-0" fluid style="width: 100%">
      <v-toolbar
          class="removeBtnPaddingRight rounded-t-lg"
          color="#DADADA"
          dense
          flat
      >
        <v-subheader style="color: #065aad; font-size: 1.2rem" class="pl-0">Список записей</v-subheader>
      </v-toolbar>
      <v-data-table
          :headers="records_headers"
          :items="records"
          :loading="isLoadingRecords"
          class="rounded-0 elevation-1"
      >
        <template v-slot:item.date="{ item }">
          {{ displayDate(item.date) }}
        </template>
        <template v-slot:item.slot.start="{ item }">
          {{ deleteSecondsFromTime(item.slot.start) }}
        </template>
        <template v-slot:item.slot.end="{ item }">
          {{ deleteSecondsFromTime(item.slot.end) }}
        </template>
        <template v-slot:item.is_enter="{ item }">
          <v-icon
              :color="checkEnterFlag(item.is_enter).color"
          >
            {{ checkEnterFlag(item.is_enter).icon }}
          </v-icon>
        </template>
        <template v-slot:item.is_cancel="{ item }">
          <v-icon
              :color="checkCancelFlag(item.is_cancel).color"
          >
            {{ checkCancelFlag(item.is_cancel).icon }}
          </v-icon>
        </template>
      </v-data-table>
    </v-container>
  </td>
</template>

<script>
import {displayDate} from "@/helper";
import {mapGetters} from "vuex";

export default {
  name: "ViewerVisitorsVisitsRecordsList",
  props: {
    outerHeadersLength: Number,
    visitCanceled: Boolean,
  },
  data() {
    return {
      records_headers: [
        {text: 'Дата', value: 'date', sortable: false, align: 'center', width: '20%'},
        {text: 'Время начала', value: 'slot.start', sortable: false, align: 'center', width: '20%'},
        {text: 'Время окончания', value: 'slot.end', sortable: false, align: 'center', width: '20%'},
        {text: 'Посещено', value: 'is_enter', sortable: false, align: 'center', width: '20%'},
        {text: 'Отменено', value: 'is_cancel', sortable: false, align: 'center', width: '20%'},
      ],
    }
  },
  computed: {
    ...mapGetters({
      records: 'viewer/getRecordsList',
      isLoadingRecords: 'viewer/isLoadingRecords',
    }),
  },
  methods: {
    deleteSecondsFromTime(time_string) {
      let time_array = time_string.split(':');
      return time_array[0] + ':' + time_array[1];
    },
    displayDate: displayDate,
    checkEnterFlag(flag) {
      if (flag) return {icon: "check_circle", color: "green"}
      else return {icon: "not_interested", color: "grey"}
    },
    checkCancelFlag(flag) {
      if (flag) return {icon: "check_circle", color: "yellow darken-3"}
      else return {icon: "not_interested", color: "grey"}
    },
  },
}
</script>

<style scoped>

</style>
