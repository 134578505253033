<template>
  <scroll-center-dialog v-model="opened" :loading="save_loading" @close="close" @apply="save" :title="formTitle"
                        :scrolled="false" width="800px" class="align-content-center">
    <div v-if="loading" class="px-3">
      <loading-autocomplete
          :outlined="true"
          v-if="!this.edit"
          placeholder="Введите ФИО и выберите из предложенных вариантов"
          :label="`Работник`"
          v-model="selected_user"
          :required="true"
          :return-object="true"
          search-parameter="fio"
          :load-fios="false"
          :url="userSelector"
          :error="hasError('user')"
          :errorMessages="getError('user')"
          class="mt-4 mb-0"
      >
      </loading-autocomplete>
      <v-checkbox
          v-model="user.is_organiser"
          label="Организатор"
          :error="hasError('is_organiser')"
          :error-messages="getError('is_organiser')"
          class="pt-0 mt-5"
          hide-details
      ></v-checkbox>
      <v-checkbox
          v-model="user.is_operator"
          label="Оператор"
          :error="hasError('is_operator')"
          :error-messages="getError('is_operator')"
          class="pt-0 mt-3"
          hide-details
      ></v-checkbox>
<!--      <v-checkbox-->
<!--          v-model="user.is_viewer"-->
<!--          label="Наблюдатель"-->
<!--          class="pt-0 mt-3"-->
<!--          hide-details-->
<!--      ></v-checkbox>-->
      <v-alert
          v-if="hasError('event')"
          color="red"
          class="elevation-2 ma-0 mt-5"
          type="error"
      >{{ String(getError('event')) }}</v-alert>
      <v-alert
          v-if="hasError('role_event')"
          color="red"
          class="elevation-2 ma-0 mt-5"
          type="error"
      >{{ String(getError('role_event')) }}</v-alert>
    </div>
    <div class="action mt-8 mb-4 align-center" v-else>
      <semipolar-spinner
          class="d-inline-block"
          :animation-duration="2000"
          :size="115"
          color="#1935ff"
      />
    </div>
  </scroll-center-dialog>
</template>


<script>

import selectors from "@/urls/selectors";
import LoadingAutocomplete from "@/modules/core/components/LoadingAutocomplete";
import FormErrorsMixin from "@/mixins/FormErrorsMixin"
import {mapActions, mapGetters} from "vuex";
import ScrollCenterDialog from "@/modules/templates/ScrollCenterDialog";
import {SemipolarSpinner} from "epic-spinners";

export default {
  name: "OrganiserRolesEditDialog",
  props: {
    opened: Boolean,
    edit: Boolean,
    userId: Number,
  },
  mixins:[FormErrorsMixin],
  components: {
    ScrollCenterDialog,
    LoadingAutocomplete,
    SemipolarSpinner
  },
  data() {
    return {
      loading: false,
      save_loading: false,
      selected_user: null,
      user_default: {
        user: '',
        is_viewer: false,
        is_organiser: true,
        is_operator: false,
      },
      user: null,
    }
  },
  watch:{
    selected_user() {
      this.user.user = this.selected_user.value;
      this.user.fio = this.selected_user.text;
    },
    'user.is_viewer': {
      handler(newVar) {
        if (newVar && (this.user.is_organiser || this.user.is_operator)) {
          this.user.is_organiser = false;
          this.user.is_operator = false;
        }
      }
    },
    'user.is_operator': {
      handler(newVar) {
        if (newVar && (this.user.is_organiser || this.user.is_viewer)) {
          this.user.is_organiser = false;
          this.user.is_viewer = false;
        }
      }
    },
    'user.is_organiser': {
      handler(newVar) {
        if (newVar && (this.user.is_viewer || this.user.is_operator)) {
          this.user.is_viewer = false;
          this.user.is_operator = false;
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      rolesDetail: 'organiser/getRolesDetail',
    }),
    formTitle() {
      if (!this.edit) {
        return 'Назначить пользователя'
      } else if (this.user) {
        return this.user.fio
      } else {
        return ''
      }
    },
    userSelector() {
      return selectors.SELECTORS.USERS.ALL()
    },
  },
  methods: {
    ...mapActions({
      createRoles: 'organiser/createRoles',
      updateRoles: 'organiser/changeRoles',
      loadRolesDetail: 'organiser/loadRolesDetail',
    }),
    loadUserData(url = undefined) {
      if (this.userId === undefined) {
        this.user = this.user_default
        this.loading = true
      } else{
        this.loadRolesDetail({
          url: url,
          id: this.userId,
          event_id: this.$route.params.idEvent,
          finalizer: (data) => {
            this.user = data
            this.loading = true;
          }
        })
      }
    },
    close() {
      this.$emit('close', false)
    },
    save(){
      this.save_loading = true
      if (this.edit) {
        let user = Object.assign({},this.user)
        this.updateRoles({
          data: user,
          event_id: this.$route.params.idEvent,
          id: this.userId,
          finalizer: (val) => {
            this.$emit('close', user)
            this.save_loading = false
          },
          catcher:(val) => {
            this.mapErrors(val)
            this.save_loading = false
          },
        })
      }else {
        this.createRoles({
          data: this.user,
          event_id: this.$route.params.idEvent,
          finalizer: (val) => {
            this.$emit('close', this.user)
            this.save_loading = false
          },
          catcher:(val) => {
            this.mapErrors(val)
            this.save_loading = false
          },
        })
      }
    },
  },
  created() {
    this.loadUserData()
  },
}
</script>

<style scoped>

</style>
