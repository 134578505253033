import {
    sendPostRequest,
    sendPatchRequest,
    sendDeleteRequest,
    sendGetRequest,
    sendPutRequest,
    sendPostRequestFile, sendPatchRequestFile
} from "@/helper/requests";
import organiser from "@/urls/roles/organiser";
import {executeRequest} from "@/store/utils";

const state = {
    fields:{
        currentUrl: null,
        items: [],
        non_paginated_items: [],
        loading: false,
        count: 0,
        detail: [],
        error: null,
    }
}

const getters = {
    getFieldsList: state => state.fields.items,
    getFieldsCount: state => state.fields.count,
    getFieldsCurrentUrl: state => state.fields.currentUrl,
    getFieldsDetail: state => state.fields.detail,
    isLoadingFields: state => state.fields.loading,
    saveFieldsError: state => state.fields.error,
}

const actions = {
    loadFieldsList: ({commit, getters}, {url, event_id, urlAppend, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) {
            url = organiser.ORGANISER.EVENTS.FIELDS.LIST(event_id)
            if (urlAppend) url = urlAppend(url)
        }
        if ((!getters.isLoadingFields || getters.getFieldsCurrentUrl !== url) && event_id !== undefined){
            commit('SET_FIELDS_LOADING');
            sendGetRequest(
                url, (data, url) => {
                    commit('SET_FIELDS_LIST', data['results']);
                    commit('SET_FIELDS_COUNT', data['count']);
                    commit('SET_FIELDS_CURRENT_URL', url);
                    commit('SET_FIELDS_LOADING_END');
                    finalizer(data, url);
                }, (data, url) => {
                    commit('SET_FIELDS_LOADING_END');
                    commit('SAVE_FIELDS_ERROR', data);
                    catcher(data, url);
                }
            )
        }
    },
    loadNonPaginatedFieldsList: ({commit}, {url, event_id, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) {
            url = organiser.ORGANISER.EVENTS.FIELDS.NON_PAGINATED_LIST(event_id)
        }
        sendGetRequest(
            url, (data, url) => {
                commit('SET_NON_PAGINATED_FIELDS_LIST', data);
                finalizer(data, url);
            }, (data, url) => {
                commit('SAVE_FIELDS_ERROR', data);
                catcher(data, url);
            }
        )
    },
    loadFieldsDetail: ({commit}, {url, event_id, id, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) url = organiser.ORGANISER.EVENTS.FIELDS.DETAIL(event_id, id)
        sendGetRequest(
            url, (data, url) => {
                commit('SET_FIELDS_DETAIL', data);
                finalizer(data, url);
            }, (data, url) => {
                commit('SAVE_FIELDS_ERROR', data);
                catcher(data, url);
            }
        )
    },
    createFields: ({dispatch, commit, getters}, {data, event_id, has_file, finalizer=()=>{}, catcher=()=>{}}) => {
        let url = organiser.ORGANISER.EVENTS.FIELDS.CREATE(event_id)
        if (has_file){
            executeRequest('Fields', sendPostRequestFile, {url, data, finalizer, catcher}, {dispatch, commit, getters});
        } else {
            executeRequest('Fields', sendPostRequest, {url, data, finalizer, catcher}, {dispatch, commit, getters});
        }
    },
    changeFields: ({dispatch, commit, getters}, {data, event_id, has_file, id, finalizer,catcher}) => {
        let url = organiser.ORGANISER.EVENTS.FIELDS.UPDATE(event_id, id)
        if (has_file){
            executeRequest('Fields', sendPatchRequestFile, {url, data, finalizer, catcher}, {dispatch, commit, getters});
        } else {
            executeRequest('Fields', sendPatchRequest, {url, data, finalizer, catcher}, {dispatch, commit, getters});
        }
    },
    deleteFields: ({dispatch, commit, getters}, {event_id, id, finalizer, catcher}) => {
        let url = organiser.ORGANISER.EVENTS.FIELDS.DELETE(event_id, id)
        executeRequest('Fields', sendDeleteRequest, {url, finalizer, catcher}, {dispatch, commit, getters});
    },
    changeFieldsIndexes: ({dispatch, commit, getters}, {event_id, data, finalizer, catcher}) => {
        let url = organiser.ORGANISER.EVENTS.FIELDS.CHANGE_INDEX(event_id)
        executeRequest('Fields', sendPatchRequest, {url, data, finalizer, catcher}, {dispatch, commit, getters});
    },
}

const mutations = {
    SET_FIELDS_LIST: (state, fields) => {
        state.fields.items = fields
    },
    SET_NON_PAGINATED_FIELDS_LIST: (state, fields) => {
        state.fields.non_paginated_items = fields
    },
    SET_FIELDS_DETAIL: (state, value) => {
        state.fields.detail = value
    },
    SET_FIELDS_COUNT: (state, value) => {
        state.fields.count = value
    },
    SET_FIELDS_CURRENT_URL: (state, value) => {
        state.fields.currentUrl = value
    },
    SET_FIELDS_LOADING: (state) => {
        state.fields.loading = true
    },
    SET_FIELDS_LOADING_END: (state) => {
        state.fields.loading = false
    },
    SAVE_FIELDS_ERROR: (state, error) => {
        state.fields.error = error
    }
}

export default {
    namespaced: false,
    state,
    mutations,
    actions,
    getters
}
