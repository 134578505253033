import Vue from 'vue'
import Vuex from 'vuex'
import leftmenu from './modules/leftmenu'
import maintoolbar from './modules/maintoolbar'
import mainpage from './modules/mainpage'
import user from './modules/user'
import urls from './modules/urls'
import admin from "./modules/admin";
import viewer from "./modules/viewer";
import organiser from "./modules/organiser";
import operator from "./modules/operator";
import visitor from "./modules/visitor";
import selectors from "./modules/selectors";
import choices from "./modules/choices";

Vue.use(Vuex);

const anonimusUsername = 'AnonimusUser';

export default new Vuex.Store({
    modules: {
        leftmenu,
        maintoolbar,
        mainpage,
        user,
        urls,
        admin,
        viewer,
        organiser,
        operator,
        visitor,
        selectors,
        choices
    },
    state: {},
    getters: {},
    mutations: {},
})
