<template>
  <div>
    <div class="header-label-text mt-1 ml-3">
      {{ name }}
      <span v-if="isRequired" class="red--text">*</span>
    </div>
    <v-text-field
        type="number"
        class="py-0 my-0 ml-3"
        outlined
        v-model="value"
        :error="!!(errorMessage && errorMessage.length > 0)"
        :errorMessages="errorMessage"
        @change="$emit('update:inputValue', value)"
    >
      <template v-slot:label>
      </template>
      <template v-slot:append-outer>
        <v-tooltip bottom max-width="320px">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                class="mr-5 ml-3"
                v-bind="attrs"
                v-on="on"
                color="primary"
            >
              help
            </v-icon>
          </template>
          <span v-html="getHelpText()"></span>
        </v-tooltip>
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: "VisitorFormNumberField",
  props: {
    inputValue: String,
    errorMessage: String,
    isRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    name: String,
    helpText: String,
    settings: Object,
  },
  data() {
    return {
      value: undefined,
    }
  },
  methods: {
    getHelpText() {
      let text = ''
      if (this.helpText) {
        text += this.helpText + '<br/><br/>'
      }
      text += 'Необходимо ввести '
      text += (this.settings.type === "int" ? "целое " : "дробное ") + "число "
      if (this.settings.min && this.settings.max) {
        text += "в промежутке от " + this.settings.min + " до " + this.settings.max
      } else if (this.settings.min) {
        text += "больше " + this.settings.min
      } else if (this.settings.max) {
        text += "меньше " + this.settings.max
      }

      return text
    }
  },
  created() {
    this.value = this.inputValue
  }
}
</script>

<style scoped>
.header-label-text {
  text-align: left;
  color: #000;
  font-family: "MADE Evolve Sans normal", serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 10px;
  padding-right: 56px;
}
</style>
