<template>
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id="Vector" d="M5.4375 14C3.95312 14 2.67578 13.5022 1.60547 12.5067C0.535156 11.5111 0 10.2822 0 8.82C0 7.35778 0.535156 6.12889 1.60547 5.13333C2.67578 4.13778 3.95312 3.64 5.4375 3.64H12.3281L9.65625 0.98L10.6406 0L15 4.34L10.6406 8.68L9.65625 7.7L12.3281 5.04H5.41406C4.32031 5.04 3.37891 5.40167 2.58984 6.125C1.80078 6.84833 1.40625 7.74667 1.40625 8.82C1.40625 9.89333 1.80078 10.7917 2.58984 11.515C3.37891 12.2383 4.32031 12.6 5.41406 12.6H12.6797V14H5.4375Z" fill="#1D71B8"/>
  </svg>
</template>

<script>
export default {
  name: "ArrowBack"
}
</script>

<style scoped>

</style>
