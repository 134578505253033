<template>
  <div>
    <v-toolbar-items v-if="editMode" :class="editMode ? 'mb-5' : ''">
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              class="dark-primary ml-1 mr-0"
              @click="$emit('moveEntryUp', blockId, entryNumber)"
              v-bind="attrs"
              v-on="on"
          >
            arrow_upward
          </v-icon>
        </template>
        <span>Сдвинуть запись в начало</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              class="dark-primary ml-1 mr-0"
              @click="$emit('moveEntryDown', blockId, entryNumber)"
              v-bind="attrs"
              v-on="on"
          >
            arrow_downward
          </v-icon>
        </template>
        <span>Сдвинуть запись в конец</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              class="dark-primary ml-1 mr-0"
              @click="$emit('deleteEntry', blockId, entryNumber)"
              v-bind="attrs"
              v-on="on"
          >
            delete
          </v-icon>
        </template>
        <span>Удалить запись</span>
      </v-tooltip>
    </v-toolbar-items>
    <slot name="entry"></slot>
  </div>
</template>

<script>
export default {
  props: {
    editMode: Boolean,
    entryNumber: Number,
    blockId: {},
  },
  name: "EntryBlockButtonsWrapper",
}
</script>

<style scoped>

</style>
