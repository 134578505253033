<template>
  <div>

  </div>
</template>

<script>
import admin from "@/urls/roles/admin";
import admin_names from "@/modules/admin/routers/names";
import viewer from "@/urls/roles/viewer";
import viewer_names from "@/modules/viewer/routers/names";
import organiser from "@/urls/roles/organiser";
import organiser_names from "@/modules/organiser/routers/names";
import operator from "@/urls/roles/operator";
import operator_names from "@/modules/operator/routers/names";
import visitor_names from "@/modules/visitor/routers/names";
import {mapActions} from "vuex";
import {goToLoginPage} from "@/helper/auth";

export default {
  name: "VisitViaQrRedirect",
  data() {
    return {
      event_id: undefined,
      visit_id: undefined,
    }
  },

  methods: {
    ...mapActions({
      loadAdminVisitDetail: 'admin/loadVisitsDetail',
      loadOrganiserVisitDetail: 'organiser/loadVisitsDetail',
      loadOperatorVisitDetail: 'operator/loadVisitsDetail',
      loadViewerVisitDetail: 'viewer/loadVisitsDetail',
      loadVisitorVisitDetail: 'visitor/loadVisitsDetail'
    }),
    adminLoadData() {
      this.loadAdminVisitDetail({
        id: this.$route.params.uid,
        finalizer: (data) => {
          this.event_id = data.event.id
          this.visit_id = data.id
          this.$router.push({name: admin_names.EVENTS.VISITS.DETAIL, params: {idVisit: this.visit_id, idEvent: this.event_id}})
        },
        catcher: () => {
          this.$router.push({name: visitor_names.EVENTS.LIST})
        }
      })
    },
    organiserLoadData() {
      this.loadOrganiserVisitDetail({
        id: this.$route.params.uid,
        finalizer: (data) => {
          this.event_id = data.event.id
          this.visit_id = data.id
          this.$router.push({name: organiser_names.EVENTS.VISITS.DETAIL, params: {idVisit: this.visit_id, idEvent: this.event_id}})
        },
        catcher: () => {
          this.$router.push({name: visitor_names.EVENTS.LIST})
        }
      })
    },
    operatorLoadData() {
      this.loadOperatorVisitDetail({
        id: this.$route.params.uid,
        finalizer: (data) => {
          this.event_id = data.event.id
          this.visit_id = data.id
          this.$router.push({name: operator_names.EVENTS.VISITS.DETAIL, params: {idVisit: this.visit_id, idEvent: this.event_id}})
        },
        catcher: () => {
          this.$router.push({name: visitor_names.EVENTS.LIST})
        }
      })
    },
    viewerLoadData() {
      this.loadViewerVisitDetail({
        id: this.$route.params.uid,
        finalizer: (data) => {
          this.event_id = data.event.id
          this.visit_id = data.id
          this.$router.push({name: viewer_names.EVENTS.VISITS.DETAIL, params: {idVisit: this.visit_id, idEvent: this.event_id}})
        },
        catcher: () => {
          this.$router.push({name: visitor_names.EVENTS.LIST})
        }
      })
    },
    visitorLoadData() {
      this.loadVisitorVisitDetail({
        id: this.$route.params.uid,
        finalizer: (data) => {
          this.event_id = data.event.id
          this.visit_id = data.id
          this.$router.push({name: visitor_names.VISITS.DETAIL, params: {idVisit: this.visit_id, idEvent: this.event_id}})
        },
        catcher: () => {
          this.$router.push({name: visitor_names.EVENTS.LIST})
        }
      })
    },
  },
  created() {
    if (admin.ADMIN.ALLOWED()) this.adminLoadData()
    else if (organiser.ORGANISER.ALLOWED()) this.organiserLoadData()
    else if (operator.OPERATOR.ALLOWED()) this.operatorLoadData()
    else if (viewer.VIEWER.ALLOWED()) this.viewerLoadData()
    else if (this.$cookies.get('userMuctr') || this.$cookies.get('visitor_token')) this.visitorLoadData()
    else this.$router.push({name: visitor_names.EVENTS.LIST})
  }
}
</script>

<style scoped>

</style>
