const events = {
    BASE: '',
    MAIN: undefined,

};
events.MAIN = `${events.BASE}|Main`

export default {
    ...events
}
