<template>
  <div>
    <div v-if="slots_loaded && regexp_changed">
      <VisitorEventDetail
          class="detail"
          :item="event_detail"
          :preview="true"
          :light_detail="lightDetail"
          @createVisit="newVisit"
          @cancelVisit="cancelDialog"
      ></VisitorEventDetail>
      <VisitorBlocksView
          class="blocks"
          v-if="blocks && blocks.length > 0"
          :blocks="blocks"
      ></VisitorBlocksView>
      <NestedEventsTable
          class="nested-events"
          v-if="event_detail.children.length > 0"
          :items="event_detail.children"
          :preview="true"
      ></NestedEventsTable>
      <SlotEventsTable
          class="slots"
          v-if="slots_loaded && slots.length > 0"
          :slot-events="slots"
          :has-visit="true"
          @createRecord="createRecordFunction"
          @cancelRecord="cancelRecordFunction"
      ></SlotEventsTable>
    </div>
    <div class="action mt-8 mb-4 align-center" v-else>
      <semipolar-spinner
          class="d-inline-block"
          :animation-duration="2000"
          :size="115"
          color="#1935ff"
      />
    </div>
    <EditDialog
      v-if="dialog_add_edit"
      :event-id="event_detail.id"
      :opened="dialog_add_edit"
      :preview-fields="event_detail.fields"
      :preview="true"
      :edit="true"
      :visit-id="undefined"
      :has-data-fields="event_detail.fields.length > 0"
      :is-required-data-dissemination="event_detail.is_required_data_dissemination"
      @close="closeDialog"
    ></EditDialog>
    <CustomApplyDialog
        v-if="dialog_cancel"
        :question-text="`Вы уверены, что хотите отменить своё посещение?`"
        :apply-text="'Да, отменить'"
        :cancel-text="'Нет, оставить'"
        :apply-color="'#D64141'"
        @close="closeCancelDialog"
    ></CustomApplyDialog>
    <CustomApplyDialog
        v-if="light_dialog_add_edit"
        :question-text="`Вы уверены, что хотите записаться на событие?`"
        @close="closeLightDialog"
    ></CustomApplyDialog>
    <CustomApplyDialog
        v-if="dialog_create_record"
        :question-text="`Вы уверены, что хотите записаться?`"
        @close="closeCreateRecordDialog"
    ></CustomApplyDialog>
    <CustomApplyDialog
        v-if="dialog_cancel_record"
        :question-text="`Вы уверены, что хотите отменить данную запись?`"
        :apply-text="'Да, отменить'"
        :cancel-text="'Нет, оставить'"
        :apply-color="'#D64141'"
        @close="closeCancelRecordDialog"
    ></CustomApplyDialog>
  </div>
</template>

<script>
import CustomApplyDialog from "@/modules/templates/visitor/custom_forms/CustomApplyDialog.vue";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import SlotEventsTable from "@/modules/templates/visitor/event_detail/SlotEventsTable.vue";
import VisitorBlocksView from "@/modules/templates/visitor/description_blocks/VisitorBlocksView.vue";
import NestedEventsTable from "@/modules/templates/visitor/event_detail/NestedEventsTable.vue";
import VisitorEventDetail from "@/modules/templates/visitor/event_detail/VisitorEventDetail.vue";
import {SemipolarSpinner} from "epic-spinners";
import EditDialog from "@/modules/visitor/events/modals/VisitEditDialog.vue";
import {displayDate} from "@/helper";

export default {
  name: "VisitorEventPagePreview",
  props: {
    detail: Object,
    lightDetail: Object,
    regexTypes: Array,
    blocks: Array,
  },
  mixins: [ TitledPageMixin ],
  components: {
    SlotEventsTable,
    VisitorBlocksView,
    NestedEventsTable,
    CustomApplyDialog,
    VisitorEventDetail,
    SemipolarSpinner,
    EditDialog
  },
  data() {
    return {
      page_title: 'Детальное представление события',
      event_detail_loaded: false,
      light_detail_loaded: false,
      slots_loaded: false,
      regexp_changed: false,
      light_dialog_add_edit: false,
      dialog_add_edit: false,
      dialog_cancel: false,
      dialog_create_record: false,
      dialog_cancel_record: false,
      record_slot: undefined,
      slots: [],
      event_detail: undefined,
    }
  },
  computed: {
    userIsAuthenticated() {
      return this.$cookies.get('userMuctr') || this.$cookies.get('visitor_token');
    },
  },
  methods: {
    displayDate: displayDate,
    newVisit() {
      if (!this.$route.name.includes('Viewer')) {
        if (!!this.userIsAuthenticated && !this.detail.fields.length > 0 && !this.detail.is_required_data_dissemination) {
          this.light_dialog_add_edit = true;
        } else {
          this.dialog_add_edit = true;
        }
      }
    },
    closeDialog() {
      this.dialog_add_edit = false
      this.visit_id = undefined
    },
    closeLightDialog() {
      this.light_dialog_add_edit = false;
    },
    cancelDialog() {
      this.dialog_cancel = true
    },
    closeCancelDialog() {
      this.dialog_cancel = false;
    },
    createRecordFunction(item) {
      if (!this.$route.name.includes('Viewer')) {
        this.record_slot = item
        this.dialog_create_record = true;
      }
    },
    closeCreateRecordDialog() {
      this.dialog_create_record = false;
    },
    cancelRecordFunction(item) {
      this.record_slot = item
      this.dialog_cancel_record = true;
    },
    closeCancelRecordDialog() {
      this.dialog_cancel_record = false;
    },
    rebuildSlots() {
      this.slots_loaded = false;
      this.slots = []

      for (let i = 0; i < this.detail.slots.length; i++) {
        let slot_already_used = false;

        for (let k = 0; k < this.slots.length; k++) {
          if (this.detail.slots[i].event_name === this.slots[k].name) {
            slot_already_used = true;
            break;
          }
        }

        if (slot_already_used) continue;

        let tmp_obj = {}
        tmp_obj.name = this.detail.slots[i].event_name
        tmp_obj.data = []
        for (let day of this.detail.slots[i].days) {
          let tmp_slot = {}
          tmp_slot.id = this.detail.slots[i].id

          tmp_slot.date = day.date
          tmp_slot.start = this.detail.slots[i].start
          tmp_slot.end = this.detail.slots[i].end

          tmp_slot.is_default = this.detail.slots[i].is_default
          tmp_slot.record = day.record
          tmp_slot.record_ended = day.record_ended

          tmp_slot.count = this.detail.slots[i].count
          tmp_slot.seats_left = day.seats_left

          tmp_obj.data.push(tmp_slot)
        }

        for (let j = i + 1; j < this.detail.slots.length; j++) {
          if (tmp_obj.name === this.detail.slots[j].event_name) {
            for (let day of this.detail.slots[j].days) {
              let tmp_slot = {}
              tmp_slot.id = this.detail.slots[j].id

              tmp_slot.date = day.date
              tmp_slot.start = this.detail.slots[j].start
              tmp_slot.end = this.detail.slots[j].end

              tmp_slot.is_default = this.detail.slots[j].is_default
              tmp_slot.record = day.record
              tmp_slot.record_ended = day.record_ended

              tmp_slot.count = this.detail.slots[j].count
              tmp_slot.seats_left = day.seats_left

              tmp_obj.data.push(tmp_slot)
            }
          }
        }

        this.slots.push(tmp_obj)
      }

      this.slots_loaded = true;
    },
    updateRegexTypesToText() {
      this.event_detail = structuredClone(this.detail)
      for (let i = 0; i < this.event_detail.fields.length; i++) {
        if (this.event_detail.fields[i].field_type === 0 || this.event_detail.fields[i].field_type === "Текстовое") {
          if ("regexp_type" in this.event_detail.fields[i].settings && this.regexTypes[this.event_detail.fields[i].settings.regexp_type]) {
            this.event_detail.fields[i].settings.regexp_type = this.regexTypes[this.event_detail.fields[i].settings.regexp_type].text
          }
        }
      }

      this.regexp_changed = true
    }
  },
  created() {
    this.rebuildSlots()
    this.updateRegexTypesToText();
  }
}
</script>

<style scoped lang="scss">
.nested-events, .blocks, .detail {
  margin-bottom: 50px;
}
.slots {
  margin-bottom: 10px;
}
</style>
