<template>
    <v-container>
        <v-row>
            <v-col align-content="start" class="text-left">
                <v-alert
                        color="info"
                        icon="info"
                        outlined
                >{{ status }}
                </v-alert>
                <vue-json-pretty
                        :collapsedOnClickBrackets="true"
                        :data="json"
                        :deep="5"
                        :showDoubleQuotes="true"
                        :showLine="true"
                        class="text-xs-left"
                        style="overflow-x: scroll;"
                ></vue-json-pretty>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
    import VueJsonPretty from 'vue-json-pretty'
    import 'vue-json-pretty/lib/styles.css'
    // import VueJson from 'vue-json-pretty'

    export default {
        name: "DebugJsonComponent",
        components: {
            VueJsonPretty,
        },
        props: ['json', 'status'],
    }
</script>

<style scoped>

</style>
