<template>
  <v-container class="page-content pa-0" style="  width: 100%" >
    <div v-if="$route.name === names.EVENTS.SLOTS.LIST">
      <v-toolbar
          class="removeBtnPaddingRight rounded-t-lg"
          color="#DADADA"
          flat
      >
        <!--        <v-text-field-->
        <!--            class="pb-1"-->
        <!--            single-line-->
        <!--            v-model="search.value" label="Поиск" hide-details @keydown.enter="searchData">-->
        <!--        </v-text-field>-->
        <v-spacer></v-spacer>
        <v-toolbar-items v-if="$vuetify.breakpoint.smAndUp">
          <v-btn
              v-if="light_detail.allow_update && !(light_detail.is_end && !light_detail.is_draft)"
              text
              @click="newSlot"
              class="dark-primary mr-0 rounded-tr-lg"
          >
            Добавить слот
          </v-btn>
        </v-toolbar-items>
        <v-btn
            v-if="$vuetify.breakpoint.xsOnly && light_detail.allow_update && !(light_detail.is_end && !light_detail.is_draft)"
            icon
            class="dark-primary mr-0"
            @click="newSlot"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
          :headers="headers"
          :items="items"
          :loading="isLoading"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.size"
          :server-items-length="count"
          :options.sync="options"
          :footer-props="{'items-per-page-options':[10,50,100]}"
          class="elevation-1 rounded-b-lg rounded-t-0"
      >
        <template v-slot:item.type="{ item }">
          <span>{{ getSlotType(item).text }}</span>
        </template>
        <template v-slot:item.date="{ item }">
          <span v-if="getSlotType(item).value === 0">{{ displayDate(item.date) }}</span>
          <span v-else-if="getSlotType(item).value === 1" v-html="getIntervalsList(item)"></span>
          <span v-else>-</span>
        </template>
        <template v-slot:item.is_default="{ item }">
          <v-icon
              :color="checkFlag(item.is_default).color"
          >
            {{checkFlag(item.is_default).icon}}
          </v-icon>
        </template>
        <template v-slot:item.count="{ item }">
          <span v-if="item.count">{{ item.count }}</span>
          <span v-else>-</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  v-if="item.allow_update && light_detail.allow_update && !(light_detail.is_end && !light_detail.is_draft) && !(item.is_default && item.have_visits)"
                  class="mr-2"
                  @click="editSlot(item.id)"
                  v-bind="attrs"
                  v-on="on"
              >
                edit
              </v-icon>
            </template>
            <span>Редактировать</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  class="mr-2"
                  v-if="item.allow_update && light_detail.allow_update && !(light_detail.is_end && !light_detail.is_draft)"
                  @click="deleteSlot(item.id)"
                  v-bind="attrs"
                  v-on="on"
              >
                delete
              </v-icon>
            </template>
            <span>Удалить</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  class="mr-2"
                  @click="getDetailSlot(item.id)"
                  v-bind="attrs"
                  v-on="on"
              >
                assignment
              </v-icon>
            </template>
            <span>Детальное представление слота</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-btn
              color="primary"
              @click="updateData"
          >
            Обновить
          </v-btn>
        </template>
      </v-data-table>
      <EditDialog
          v-if="dialog_add_edit"
          :opened="dialog_add_edit"
          :edit="edit_status"
          :slot-id="slot_id"
          :have-visits="light_detail.have_visits"
          @close="closeEditDialog"
      ></EditDialog>
      <ApplyDialog
          v-if="dialog_delete"
          :opened="dialog_delete"
          :get-text="() => `Вы уверены, что хотите удалить данный слот?`"
          @close="closeDeleteDialog"
      ></ApplyDialog>
    </div>
    <router-view v-else></router-view>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import {displayDate} from "@/helper";
import organiser from "@/urls/roles/organiser";
import names_core from "@/modules/core/routers/names";
import names from "@/modules/organiser/routers/names";
import EditDialog from "@/modules/organiser/events/modals/SlotsEditDialog";
import ApplyDialog from "@/modules/core/components/ApplyDialog";

export default {
  name: "OrganiserEventsSlotsList",
  components: {EditDialog, ApplyDialog},
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  data() {
    return {
      page_title: "Список слотов",
      dialog_add_edit: false,
      dialog_delete: false,
      edit_status: undefined,
      slot_id: undefined,
      headers: [
        {text: 'Тип слота', value: 'type', sortable: false, align: 'start', width: '16%'},
        {text: 'Даты слота', value: 'date', sortable: false, align: 'center', width: '16%'},
        {text: 'Начало слота', value: 'start', sortable: false, align: 'center', width: '16%'},
        {text: 'Конец слота', value: 'end', sortable: false, align: 'center', width: '16%'},
        {text: 'Слот "По умолчанию"', value: 'is_default', sortable: false, align: 'center', width: '16%'},
        {text: 'Максимальное количество посетителей', value: 'count', sortable: false, align: 'center', width: '10%'},
        {text: 'Действия', value: 'actions', sortable: false, align: 'center', width: '10%'},
      ],
      slot_types: [
        {text: 'Обычный слот', value: 0},
        {text: 'Интервальный слот', value: 1},
        {text: 'Слот события', value: 2},
      ],
    }
  },
  computed: {
    ...mapGetters({
      items: 'organiser/getSlotsList',
      count: 'organiser/getSlotsCount',
      isLoading: 'organiser/isLoadingSlots',
      light_detail: 'organiser/getEventsLightDetail',
    }),
    names: () => names,
  },
  watch: {
  },
  methods: {
    ...mapActions({
      loadSlotsList: 'organiser/loadSlotsList',
      deleteSlots: 'organiser/deleteSlots',
      loadLightDetail: 'organiser/loadEventsLightDetail',
      changeEventDetailLoadingState: 'leftmenu/changeEventDetailLoadingState'
    }),
    displayDate: displayDate,
    getDetailSlot(id) {
      this.$router.push({name: names.EVENTS.SLOTS.DETAIL, params: {idSlot: id}})
    },
    getIntervalsList(item) {
      let string = ``;
      for (let i = 0; i < item.intervals.length; i++) {
        string += this.displayDate(item.intervals[i].start) + '-' + this.displayDate(item.intervals[i].end)
        if (i !== item.intervals.length - 1) {
          string += '<br/>'
        }
      }
      return string;
    },
    getSlotType(item) {
      if (item.date) {
        return this.slot_types[0]
      }

      if (item.intervals && item.intervals.length > 0) {
        return this.slot_types[1]
      }

      return this.slot_types[2]
    },
    checkFlag(flag) {
      if (flag) return {icon: "check_circle", color: "green"}
      else return {icon: "not_interested", color: "gray"}
    },
    updateData(){
      // if (this.search.value) {
      //   this.search.value = ''
      // }
      this.loadData()
    },
    loadData(url = undefined) {
      this.loadSlotsList({
        url: url,
        event_id: this.$route.params.idEvent,
        urlAppend: (url) => {
          if(this.$route.query){
            return this.addPageParameter(
                this.addSizeParameter(
                    this.addGetParameter(url, 'interval', this.$route.query.idInterval)
                )
            );
          } else{
            return this.addPageParameter(this.addSizeParameter(url))
          }
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
          this.changeEventDetailLoadingState({loading_state: false})
        }
      })
    },
    newSlot() {
      this.dialog_add_edit = true
      this.edit_status = false
      this.slot_id = undefined
    },
    editSlot(id) {
      this.dialog_add_edit = true
      this.edit_status = true
      this.slot_id = id
    },
    deleteSlot(id) {
      this.dialog_delete = true
      this.slot_id = id
    },
    closeEditDialog() {
      this.loadData()
      this.dialog_add_edit = false
      this.slot_id = undefined
    },
    closeDeleteDialog(slot) {
      if (slot) {
        this.deleteSlots({
          event_id: this.$route.params.idEvent,
          id: this.slot_id,
          finalizer: () => {
            this.loadData()
          },
          catcher: (val) => {
            this.mapErrors(val)
          },
        })
      }
      this.dialog_delete = false
      this.slot_id = undefined
    },
    updatePageTitle() {
      this.loadLightDetail({
        id: this.$route.params.idEvent,
        finalizer: (data) => {
          this.page_title = 'Список слотов' + ': ' + data.name
        }
      })
    },
  },
  created() {
    if (organiser.ORGANISER.ALLOWED()){
      if(this.$route.name === names.EVENTS.SLOTS.LIST){
        this.updatePageTitle()
        this.loadData()
      }
    } else{
      this.$router.push({name: names_core.MAIN})
    }
  },
  beforeRouteUpdate(to, from, next) {
    next()
    if(this.$route.name === names.EVENTS.SLOTS.LIST){
      // Принудительное обновление компонента
      this.page_title = this.page_title + ' '
      this.loadData()
    }
  },
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0;
}
</style>
