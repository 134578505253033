<template>
  <div
      v-if="!edit"
      class="pa-0 ma-0 ql-editor"
      style="min-height: auto !important;"
      v-html="data.data.data.text"
  >
  </div>
  <div v-else>
    <vue-editor
        v-model="data.data.data.text"
        :error="data.errors.text.length > 0"
        :error-messages="data.errors.text"
        :id="'null_block' + data.id"
    ></vue-editor>
    <v-alert
        v-if="data.errors.text.length > 0"
        color="red"
        class="elevation-2 mt-5"
        type="error"
    >
      {{ data.errors.text }}
    </v-alert>
  </div>

<!--  <v-textarea-->
<!--      v-else-->
<!--      outlined-->
<!--      rows="5"-->
<!--      no-resize-->
<!--      v-model="data.data.data.text"-->
<!--      :error="data.errors.text.length > 0"-->
<!--      :error-messages="data.errors.text"-->
<!--  >-->
<!--    <template v-slot:label>-->
<!--      HTML-код<span class="red&#45;&#45;text"> *</span>-->
<!--    </template>-->
<!--  </v-textarea>-->
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  props: {
    edit: Boolean,
    data: Object,
  },
  components: { VueEditor },
  name: "NullBlock",
}
</script>

<style lang="css">
</style>
