<template>
  <div>
    <v-card>
      <VisitDetail
          v-if="loading"
          :item="item"
      >
        <template v-slot:btn>
          <v-btn
              class="dark-primary ml-0"
              icon
              small
              @click="returnParent"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-items v-if="$vuetify.breakpoint.smAndUp && !item.is_cancel && light_detail.allow_update && !(light_detail.is_end && !light_detail.is_draft)">
            <v-btn
                v-if="!item.has_entered_records && !item.is_enter"
                text
                @click="cancelVisit"
                class="dark-primary mr-0 rounded-tr-lg"
            >
              Отменить посещение
            </v-btn>
            <v-btn
                v-if="!item.is_enter"
                text
                @click="enterVisit"
                class="dark-primary mr-0 rounded-tr-lg"
            >
              Зафиксировать посещение мероприятия
            </v-btn>
            <v-btn
                v-else-if="!item.has_entered_records"
                text
                @click="backVisit"
                class="dark-primary mr-0 rounded-tr-lg"
            >
              Отменить фиксацию посещения мероприятия
            </v-btn>
          </v-toolbar-items>
          <v-btn
              v-if="$vuetify.breakpoint.xsOnly && !item.is_cancel && !item.is_enter && light_detail.allow_update && !(light_detail.is_end && !light_detail.is_draft) && !item.has_entered_records"
              icon
              class="dark-primary mr-0"
              @click="cancelVisit"
          >
            <v-icon>cancel</v-icon>
          </v-btn>
          <v-btn
              v-if="$vuetify.breakpoint.xsOnly  && !item.is_cancel && !item.is_enter && light_detail.allow_update && !(light_detail.is_end && !light_detail.is_draft)"
              icon
              class="dark-primary mr-0"
              @click="enterVisit"
          >
            <v-icon>add_task_</v-icon>
          </v-btn>
          <v-btn
              v-if="$vuetify.breakpoint.xsOnly  && !item.is_cancel && item.is_enter && light_detail.allow_update && !(light_detail.is_end && !light_detail.is_draft) && !item.has_entered_records"
              icon
              class="dark-primary mr-0"
              @click="backVisit"
          >
            <v-icon>undo_</v-icon>
          </v-btn>
        </template>
      </VisitDetail>
      <EventDetailVisitsDataList
          :role="role"
          :light-detail="light_detail"
      >
      </EventDetailVisitsDataList>
      <EventDetailRecordsList
          v-if="!need_reload_records_list"
          :role="role"
          :record-type="record_type"
          :light-detail="light_detail"
          @reloadData="loadData"
      >
      </EventDetailRecordsList>
    </v-card>
    <v-skeleton-loader
        v-if="!this.loading"
        type="table-heading, list-item-three-line, image, list-item-two-line, table-tfoot"
    ></v-skeleton-loader>
    <ApplyDialog
        v-if="dialog_cancel"
        :opened="dialog_cancel"
        :get-text="() => `Вы уверены, что хотите отменить посещение ?`"
        @close="closeCancelDialog"
    ></ApplyDialog>
    <ApplyDialog
        v-if="dialog_enter"
        :opened="dialog_enter"
        :get-text="() => `Вы уверены, что хотите зафиксировать посещение мероприятия ?`"
        @close="closeEnterDialog"
    ></ApplyDialog>
    <ApplyDialog
        v-if="dialog_back"
        :opened="dialog_back"
        :get-text="() => `Вы уверены, что хотите отменить фиксацию посещения мероприятия ?`"
        @close="closeBackDialog"
    ></ApplyDialog>
  </div>
</template>

<script>
import TitledPageMixin from "@/mixins/TitledPageMixin";
import {mapActions, mapGetters} from "vuex";
import names from "@/modules/organiser/routers/names";
import VisitDetail from "@/modules/templates/VisitDetail";
import organiser from "@/urls/roles/organiser";
import names_core from "@/modules/core/routers/names";
import ApplyDialog from "@/modules/core/components/ApplyDialog";
import EventDetailVisitsDataList from "@/modules/core/components/event/EventDetailVisitsDataList";
import EventDetailRecordsList from "@/modules/core/components/event/EventDetailRecordsList";

export default {
  name: "OrganiserEventsVisitsDetail",
  mixins: [TitledPageMixin],
  components: {
    VisitDetail,
    ApplyDialog,
    EventDetailVisitsDataList,
    EventDetailRecordsList
  },
  data() {
    return {
      loading: false,
      page_title: "Детальное представление посетителя",
      dialog_cancel: false,
      dialog_enter: false,
      role: 'Organiser',
      record_type: 'visit',
      need_reload_records_list: false,
      dialog_back: false
    }
  },
  watch: {},
  computed: {
    ...mapGetters({
      item: 'organiser/getVisitsDetail',
      light_detail: 'organiser/getEventsLightDetail',
    }),
    names: () => names,
  },
  methods: {
    ...mapActions({
      loadVisitDetail: 'organiser/loadVisitsDetail',
      cancelVisits: 'organiser/cancelVisits',
      enterVisits: 'organiser/enterVisits',
      loadLightDetail: 'organiser/loadEventsLightDetail',
      changeEventDetailLoadingState: 'leftmenu/changeEventDetailLoadingState',
      backVisits: 'organiser/rollBackEnterVisits'
    }),
    returnParent() {
      if (this.$route.query.slot) {
        this.$router.push({name: names.EVENTS.SLOTS.DETAIL, params: {idSlot: this.$route.query.slot}})
      } else {
        this.$router.push({name: names.EVENTS.VISITS.LIST})
      }
    },
    loadData(url=undefined){
      this.loadVisitDetail({
        url: url,
        event_id: this.$route.params.idEvent,
        id: this.$route.params.idVisit,
        finalizer: (data) => {
          this.loading = true
          this.changeEventDetailLoadingState({loading_state: false})
        }
      })
    },
    reloadRecordsList() {
      this.need_reload_records_list = true;
      this.$nextTick(() => {
        this.need_reload_records_list = false;
      })
    },
    backVisit() {
      this.dialog_back = true
    },
    closeBackDialog(visit) {
      if (visit) {
        this.backVisits({
          id: this.item.id,
          finalizer: (val) => {
            this.dialog_back = false
            this.loadData()
          },
        })
      } else {
        this.dialog_back = false
      }
    },
    cancelVisit() {
      this.dialog_cancel = true
    },
    closeCancelDialog(visit) {
      if (visit) {
        this.cancelVisits({
          id: this.item.id,
          finalizer: (val) => {
            this.reloadRecordsList()
            this.item.is_cancel = true
          },
          catcher: (val) => {
            this.mapErrors(val)
          },
        })
      }
      this.dialog_cancel = false
    },
    enterVisit() {
      this.dialog_enter = true
    },
    closeEnterDialog(visit) {
      if (visit) {
        this.enterVisits({
          id: this.item.id,
          finalizer: (val) => {
            this.reloadRecordsList()
            this.item.is_enter = true
          },
        })
      }
      this.dialog_enter = false
    },
    updatePageTitle() {
      this.loadLightDetail({
        id: this.$route.params.idEvent,
        finalizer: (data) => {
          this.page_title = this.page_title + ': ' + data.name
        }
      })
    },
  },
  created () {
    if (organiser.ORGANISER.ALLOWED()){
      if(this.$route.name === names.EVENTS.VISITS.DETAIL){
        this.updatePageTitle()
        this.loadData()
      }
    } else{
      this.$router.push({name: names_core.MAIN})
    }
  },
}
</script>

<style scoped>

</style>
