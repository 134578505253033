import {ACCOUNTS_INSTANCE, EVENTS_INSTANCE} from "@/helper/consts";
import Base from "@/modules/core/Breadcrumbs"
import names from '@/modules/core/routers/names'


export default ([
    {
        path: '',
        name: names.MAIN,
        component: Base,
        meta: {
            breadcrumb: 'Регистрация событий',
            requiredAuth: false,
            instances: [
                EVENTS_INSTANCE,
                ACCOUNTS_INSTANCE
            ]
        },
        children:[

        ]
    },

])


