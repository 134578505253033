<template>
  <v-container class="page-content pa-0" style="  width: 100%" >
    <div v-if="$route.name === names.EVENTS.SLOTS.LIST">
      <v-toolbar
          class="removeBtnPaddingRight rounded-t-lg"
          color="#DADADA"
          flat
      >
        <!--        <v-text-field-->
        <!--            class="pb-1"-->
        <!--            single-line-->
        <!--            v-model="search.value" label="Поиск" hide-details @keydown.enter="searchData">-->
        <!--        </v-text-field>-->
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-data-table
          :headers="headers"
          :items="items"
          :loading="isLoading"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.size"
          :server-items-length="count"
          :options.sync="options"
          :footer-props="{'items-per-page-options':[10,50,100]}"
          class="elevation-1 rounded-b-lg rounded-t-0"
      >
        <template v-slot:item.date="{ item }">
          <span v-if="item.date!==null">{{ displayDate(item.date) }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  class="mr-2"
                  @click="createRecord(item.id)"
                  v-bind="attrs"
                  v-on="on"
              >
                add_task
              </v-icon>
            </template>
            <span>Записаться на слот</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-btn
              color="primary"
              @click="updateData"
          >
            Обновить
          </v-btn>
        </template>
      </v-data-table>
      <ApplyDialog
          v-if="dialog_create"
          :opened="dialog_create"
          :get-text="() => `Вы уверены, что хотите записаться на этот слот?`"
          @close="closeCreateDialog"
      ></ApplyDialog>
    </div>
    <router-view v-else></router-view>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import {displayDate} from "@/helper";
import visitor from "@/urls/roles/visitor";
import names_core from "@/modules/core/routers/names";
import names from "@/modules/visitor/routers/names";
import ApplyDialog from "@/modules/core/components/ApplyDialog";

export default {
  name: "VisitorEventsSlotsList",
  components: {ApplyDialog},
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  data() {
    return {
      page_title: "Список слотов",
      dialog_create: false,
      headers: [
        {text: 'Даты слота', value: 'date', sortable: false, align: 'start', width: '20%'},
        {text: 'Начало слота', value: 'start', sortable: false, align: 'center', width: '25%'},
        {text: 'Конец слота', value: 'end', sortable: false, align: 'center', width: '25%'},
        {text: 'Максимальное количество посетителей', value: 'count', sortable: false, align: 'center', width: '20%'},
        {text: 'Действия', value: 'actions', sortable: false, align: 'center', width: '10%'},
      ],
    }
  },
  computed: {
    ...mapGetters({
      items: 'visitor/getSlotsList',
      count: 'visitor/getSlotsCount',
      isLoading: 'visitor/isLoadingSlots'
    }),
    names: () => names,
  },
  watch: {
  },
  methods: {
    ...mapActions({
      loadSlotsList: 'visitor/loadSlotsList',
      createRecords: 'visitor/createRecords',
    }),
    displayDate: displayDate,
    updateData(){
      // if (this.search.value) {
      //   this.search.value = ''
      // }
      this.loadData()
    },
    loadData(url = undefined) {
      this.loadSlotsList({
        url: url,
        event_id: this.$route.params.idEvent,
        urlAppend: (url) => {
            return this.addPageParameter(this.addSizeParameter(url))
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
        }
      })
    },
    createRecord() {
      this.dialog_create = true
    },
    closeCreateDialog(event) {
      if (event) {
        this.createRecords({
          finalizer: () => {
            this.loadData()
          },
          catcher: (val) => {
            this.mapErrors(val)
          },
        })
      }
      this.dialog_create = false
      this.slot_id = undefined
    },
  },
  created() {
    this.loadData()
  },
  beforeRouteUpdate(to, from, next) {
    next()
    if(this.$route.name === names.EVENTS.SLOTS.LIST){
      this.loadData()
    }
  }
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0;
}
</style>
