<template>
  <scroll-center-dialog v-model="opened" :loading="save_loading" width="760" @close="close" @apply="save"
                        :title="form_title" :scrolled="false">
    <div v-if="loading && access_types_loading" class="px-3">
      <v-text-field
          class=" mt-4 mb-0"
          outlined
          v-model="event.name"
          :error="hasError('name')"
          :errorMessages="getError('name')"
      >
        <template v-slot:label>
          Название события<span class="red--text"> *</span>
        </template>
      </v-text-field>
      <v-text-field
          outlined
          v-model="event.slug"
          :error="hasError('slug')"
          :errorMessages="getError('slug')"
      >
        <template v-slot:label>
          Идентификатор события<span class="red--text"> *</span>
        </template>
      </v-text-field>
      <v-text-field
          label="Место проведения"
          outlined
          clearable
          v-model="event.location"
          :error="hasError('location')"
          :errorMessages="getError('location')"
      ></v-text-field>
      <v-textarea
          label="Описание события"
          outlined
          rows="3"
          no-resize
          v-model="event.description"
          :error="hasError('description')"
          :errorMessages="getError('description')"
      ></v-textarea>
      <v-file-input
        chips
        outlined
        label="Изображение мероприятия"
        v-model="event.image"
        :error="hasError('image')"
        :errorMessages="getError('image')"
      >
        <template v-slot:append-outer>
          <v-tooltip bottom max-width="320px" v-if="edit && event.file_src">
            <template v-slot:activator="{ on, attrs }">
              <a :href="event.file_src" target="_blank">
                <v-icon
                    class="mr-5 ml-3"
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                >
                  download
                </v-icon>
              </a>
            </template>
            <span>Скачать изображение</span>
          </v-tooltip>
        </template>
      </v-file-input>
      <v-row class="pa-0 ma-0">
        <v-col class="pa-0 ma-0" cols="12" sm="6">
          <v-text-field
              v-if="!event.is_have_records && !event.is_have_visits || !edit"
              :class="$vuetify.breakpoint.smAndUp ? 'mr-2' : ''"
              outlined
              type="date"
              v-model="event.start"
              :error="hasError('start') || start_error.length > 0"
              :errorMessages="getError('start') || start_error"
          >
            <template v-slot:label>
              Дата начала события<span class="red--text"> *</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col class="pa-0 ma-0" cols="12" sm="6">
          <v-text-field
              v-if="!event.is_have_records && !event.is_have_visits || !edit"
              :class="$vuetify.breakpoint.smAndUp ? 'ml-2' : ''"
              outlined
              type="date"
              v-model="event.end"
              :error="hasError('end') || end_error.length > 0"
              :errorMessages="getError('end') || end_error"
          >
            <template v-slot:label>
              Дата окончания события<span class="red--text"> *</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col class="pa-0 ma-0" cols="12" sm="6">
          <v-text-field
              :class="$vuetify.breakpoint.smAndUp ? 'mr-2' : ''"
              v-if="rootEvent"
              outlined
              type="datetime-local"
              v-model="event.record_start"
              :error="hasError('record_start') || record_start_error.length > 0"
              :errorMessages="getError('record_start') || record_start_error"
          >
            <template v-slot:label>
              Дата начала записи<span class="red--text"> *</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col class="pa-0 ma-0" cols="12" sm="6">
          <v-text-field
              :class="$vuetify.breakpoint.smAndUp ? 'ml-2' : ''"
              v-if="rootEvent"
              outlined
              type="datetime-local"
              v-model="event.record_end"
              :error="hasError('record_end') || record_end_error.length > 0"
              :errorMessages="getError('record_end') || record_end_error"
          >
            <template v-slot:label>
              Дата окончания записи<span class="red--text"> *</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-select
          v-if="rootEvent"
          outlined
          class="mt-2"
          label="Пользователи, которым будет доступно событие"
          :items="access_types"
          v-model="event.access_type"
          :error="hasError('access_type')"
          :errorMessages="getError('access_type')"
      ></v-select>
      <v-checkbox
          v-if="rootEvent && edit && previous_event_access_type !== event.access_type"
          class="py-0 my-0"
          label="Уведомить по почте пользователей, которые потеряют доступ к событию из-за смены режима доступа"
          v-model="is_send_access_type_email"
          :error="hasError('is_send_access_type_email')"
          :errorMessages="getError('is_send_access_type_email')"
      ></v-checkbox>
      <v-checkbox
          v-if="!rootEvent"
          class="py-0 my-0"
          label="Скрывать событие от посетителя"
          v-model="event.is_hidden"
          @change="deleteError('msg')"
          :error="hasError('is_hidden')"
          :errorMessages="getError('is_hidden')"
      ></v-checkbox>
      <v-textarea
          v-if="!rootEvent && edit && previous_event_is_hidden !== event.is_hidden && (event.is_have_records || event.is_have_visits)"
          class="py-0 my-0"
          outlined
          label="Сообщение посетителям события"
          v-model="msg"
          :error="hasError('msg')"
          :errorMessages="getError('msg')"
      ></v-textarea>
      <v-checkbox
          v-if="rootEvent"
          class="py-0 my-0"
          label="Требовать подтверждение посещения"
          v-model="event.is_required_confirmation"
          :error="hasError('is_required_confirmation')"
          :errorMessages="getError('is_required_confirmation')"
      ></v-checkbox>
<!--      <v-checkbox-->
<!--          v-if="rootEvent"-->
<!--          class="py-0 my-0"-->
<!--          label="Требовать предоставление согласия на передачу данных третьим лицам"-->
<!--          v-model="event.is_required_data_dissemination"-->
<!--          :error="hasError('is_required_data_dissemination')"-->
<!--          :errorMessages="getError('is_required_data_dissemination')"-->
<!--      ></v-checkbox>-->
      <v-alert
          v-if="hasError('parent')"
          color="red"
          class="elevation-2"
          type="error"
      >{{ String(getError('parent')) }}</v-alert>
    </div>
    <v-skeleton-loader
        v-else
        type="table-heading, list-item-three-line, image, list-item-two-line, table-tfoot"
    ></v-skeleton-loader>
  </scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin"
import {mapActions, mapGetters} from "vuex";
import ScrollCenterDialog from "@/modules/templates/ScrollCenterDialog";
import admin from "@/urls/roles/admin";

export default {
  name: "AdminEventsEditDialog",
  props: {
    opened: Boolean,
    edit: Boolean,
    eventId: Number,
    rootEvent: {
      type: Boolean,
      required: false,
      default: true,
    },
    eventStart: {
      required: false,
      type: String,
      default: ''
    },
    eventEnd: {
      required: false,
      type: String,
      default: ''
    }
  },
  mixins: [FormErrorsMixin],
  components: {
    ScrollCenterDialog,
  },
  data() {
    return {
      loading: false,
      access_types_loading: false,
      save_loading: false,
      event: {
        name: '',
        description: '',
        start: null,
        end: null,
        is_hidden: false,
        record_start: null,
        record_end: null,
        is_required_confirmation: true,
        is_required_data_dissemination: false,
        access_type: 0,
        parent: null,
        image: null,
        slug: '',
        location: '',
      },
      access_types: null,
      previous_event_access_type: undefined,
      is_send_access_type_email: false,
      previous_event_is_hidden: undefined,
      msg: '',
      end_error: '',
      start_error: '',
      record_end_error: '',
      record_start_error: '',
    }
  },
  computed: {
    form_title() {
      return !this.edit ? 'Добавить событие' : 'Редактировать событие'
    },
    ...mapGetters({
      getEventDetail: 'admin/getEventsDetail',
    })
  },
  methods: {
    ...mapActions({
      createEvent: 'admin/createEvents',
      changeEvent: 'admin/changeEvents',
      loadAccessTypeChoices: 'choices/loadAccessTypeChoices',
      loadEventDetail: 'admin/loadEventsDetail',
    }),
    createEventFunction(data) {
      let form_data = new FormData();
      for (let key of Object.keys(data))
      {
        if (key === 'location' && data[key] === null) {
          data[key] = ''
        }
        form_data.append(key, data[key])
      }
      this.createEvent({
        data: form_data,
        finalizer: () => {
          this.save_loading = false;
          this.close();
        },
        catcher: (val) => {
          this.mapErrors(val)
          this.save_loading = false
        },
      })
    },
    changeEventFunction(data) {
      let form_data = new FormData();
      for (let key of Object.keys(data))
      {
        if (key === 'location' && data[key] === null) {
          data[key] = ''
        }
        form_data.append(key, data[key])
      }
      this.changeEvent({
        data: form_data,
        id: this.eventId,
        finalizer: () => {
          this.save_loading = false;
          this.close();
        },
        catcher: (val) => {
          this.mapErrors(val)
          this.save_loading = false
        },
      })
    },
    close() {
      this.$emit('close', false)
    },
    save() {
      if (!this.event.end) {
          this.end_error = 'Необходимо ввести корректную дату в формате ДД-ММ-ГГГГ'
      } else {
        this.end_error = ''
      }

      if (!this.event.start) {
        this.start_error = 'Необходимо ввести корректную дату в формате ДД-ММ-ГГГГ'
      } else {
        this.start_error = ''
      }

      if (!this.event.record_end && this.rootEvent) {
        this.record_end_error = 'Необходимо ввести корректную дату в формате ДД-ММ-ГГГГ'
      } else {
        this.record_end_error = ''
      }

      if (!this.event.record_start && this.rootEvent) {
        this.record_start_error = 'Необходимо ввести корректную дату в формате ДД-ММ-ГГГГ'
      } else {
        this.record_start_error = ''
      }

      if (this.record_start_error || this.record_end_error || this.start_error || this.end_error) return;

      this.save_loading = true;
      let data = {
        name: this.event.name,
        description: this.event.description,
        start: this.event.start,
        end: this.event.end,
        slug: this.event.slug,
        is_hidden: this.event.is_hidden,
        location: this.event.location
      }

      if (this.event.image) {
        if (!this.event.image.action) {
          data.image = this.event.image
        }
      }

      if (this.event.image === null) {
        data.image = ''
      }

      if (this.rootEvent) {
        data.record_start = this.event.record_start
        data.record_end = this.event.record_end
        data.is_required_confirmation = this.event.is_required_confirmation
        data.is_required_data_dissemination = false
        data.access_type = this.event.access_type
        if (this.edit && this.previous_event_access_type !== this.event.access_type) {
          data.is_send_access_type_email = this.is_send_access_type_email;
        }
      } else {
        if (this.edit && this.previous_event_is_hidden !== this.event.is_hidden && (this.event.is_have_records || this.event.is_have_visits)) {
          data.msg = this.msg;
        }
        data.parent = this.$route.params.idEvent
      }
      if (this.edit) {
        this.changeEventFunction(data);
      } else {
        this.createEventFunction(data);
      }
    },
    loadData() {
      this.event.is_hidden = false
      this.loadAccessTypeChoices({
        finalizer: (val) => {
          this.access_types = val
          this.access_types_loading = true
        }
      })
      if (this.eventId) {
        this.loadEventDetail({
          id: this.eventId,
          finalizer: (data) => {
            let file_src;
            if (data.image && data.image.action) {
              file_src = this.getImage(data.image)
            }
            this.event = Object.assign({}, data);
            if (file_src) {
              this.event.file_src = file_src
            }
            if (data.file_name) {
              this.event.image.name = data.file_name
            }
            this.previous_event_access_type = data.access_type
            this.previous_event_is_hidden = data.is_hidden
            this.loading = true;
          }
        })
      } else {
        if (!this.rootEvent) {
          if (this.eventEnd && this.eventStart) {
            this.event.start = this.eventStart
            this.event.end = this.eventEnd
          }
        }
        this.loading = true;
      }
    },
    getImage(image) {
      if (!image) return null;
      let urls = {
        basename: image.name,
        action: image.action,
        params: image.params,
      }

      return admin.getByRecievedUrlData(urls, image.get_params)
    },
  },
  created() {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
