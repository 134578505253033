<template>
  <v-container style="height: 100%">
    <v-card
        flat
        class="mt-4"
    >
      <v-data-iterator
          :items=name_list
          hide-default-footer
          no-data-text="Нет данных"
      >
        <v-row class="align-center">
          <v-col
              cols="12"
              sm="6"
              lg="4"
              v-for="(item, index) in name_list"
              :key="`card_${index}`"
          >
            <router-link style="text-decoration: none" :to="{name: item.router.name}">
              <v-card
                  min-height="150"
                  elevation="6"
                  class="ma-3 d-flex"
                  color="style-color-blue-light"
                  rounded
              >
                <v-container class="my-auto">
                  <v-row class="my-auto">
                    <v-col class="my-auto title">
                      <span style="font-size: 26px">{{item.title }}</span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </router-link>
          </v-col>
        </v-row>
      </v-data-iterator>
    </v-card>
  </v-container>
</template>

<script>
import names from "./routers/names";

export default {
  name: "OperatorMain",
  props: ['name_list'],
  data() {
    return {
    }
  },
  created() {
  }
}
</script>

<style scoped>

</style>