<template>
  <v-card v-if="!detail_data_is_loading">
    <VisitorDetail
        :item="item"
        @close="closeDetail"
    >
      <template v-slot:btn>
        <v-btn
            class="dark-primary ml-0"
            icon
            small
            @click="closeDetail"
        >
          <v-icon>arrow_back</v-icon>
        </v-btn>
      </template>
    </VisitorDetail>
    <v-divider class="mx-4"></v-divider>
    <VisitsList></VisitsList>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import viewer from "@/urls/roles/viewer";
import names_core from "@/modules/core/routers/names";
import names from "@/modules/viewer/routers/names";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import formErrorsMixin from "@/mixins/FormErrorsMixin";
import VisitorDetail from "@/modules/templates/VisitorDetail";
import VisitsList from "@/modules/viewer/visitors/components/VisitsList";

export default {
  mixins: [TitledPageMixin, PaginatedDataMapperMixin, formErrorsMixin],
  components: {VisitsList, VisitorDetail},
  name: "ViewerVisitorsDetail",
  data() {
    return {
      page_title: "Детальное представление посетителя",
      detail_data_is_loading: true,
    }
  },
  computed: {
    ...mapGetters({
      item: 'viewer/getVisitorsDetail',
    }),
  },
  methods: {
    ...mapActions({
      loadVisitorDetail: 'viewer/loadVisitorsDetail',
    }),
    loadData(url = undefined) {
      this.loadVisitorDetail({
        url: url,
        id: this.$route.params.idVisitor,
        finalizer: () => {
          this.detail_data_is_loading = false;
        }
      })
    },
    closeDetail() {
      this.$router.push({name: names.VISITORS.LIST})
    },
  },
  created() {
    if (viewer.VIEWER.ALLOWED()) {
      if(this.$route.name === names.VISITORS.DETAIL){
        this.loadData()
      }
    } else {
      this.$router.push({name: names_core.MAIN})
    }
  },
}
</script>

<style scoped>

</style>
