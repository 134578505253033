<template>
  <scroll-center-dialog v-model="opened" :loading="save_loading" width="760" @close="close" @apply="save" title="Создать приглашение" :scrolled="false">
    <v-row class="mt-2 px-2 justify-center align-center">
      <v-col cols="12" class="text-h6" style="white-space: nowrap; text-align: start;">
        Пользователи в системе
      </v-col>
      <v-col cols="12">
        <v-data-table
            :items="visitorsItems"
            :headers="headers"
            disable-pagination
            hide-default-footer
            class="elevation-2"
        >
          <template
              v-slot:item.fio="{ item }"
          >
            <v-row v-if="item.adding" class="px-3"></v-row>
            <v-row v-else-if="item.editable" class="px-3" :class="item.error.length > 0 ? 'mb-1' : 'mb-0'">
              <loading-autocomplete
                  v-model="item.visitor"
                  @input="changeEditableState(item)"
                  :url="visitorsSelector"
                  :error="item.error.length > 0"
                  :error-messages="item.error"
                  :no-size-parameter="true"
                  :return-object="true"
                  :load-fios="false"
                  search-parameter="fio"
              ></loading-autocomplete>
            </v-row>
            <v-row v-else class="px-3">
              {{ item.visitor.text }}
            </v-row>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-row no-gutters justify="space-around" align="center" v-show="item.adding">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      @click="addEmptyInnerItem"
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon>
                      add_circle_outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Добавить пустое поле</span>
              </v-tooltip>
            </v-row>
            <v-row no-gutters justify="space-around" align="center" v-show="!item.adding">
              <v-tooltip bottom >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      @click="deleteEmptyInnerItem(item)"
                      v-bind="attrs"
                      v-on="on"
                  >
                    delete
                  </v-icon>
                </template>
                <span>Удалить поле</span>
              </v-tooltip>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" class="text-h6" style="white-space: nowrap; text-align: start;">
        Внешние пользователи
      </v-col>
      <v-col cols="12">
        <v-data-table
            :items="outer_items"
            :headers="outer_headers"
            disable-pagination
            hide-default-footer
            class="elevation-2"
        >
          <template v-slot:item.email="{ item }">
            <v-row class="px-3 mb-1" :class="item.error.length > 0 ? 'mb-1' : 'mb-0'">
              <v-text-field
                  v-show="!item.adding"
                  v-model="item.email"
                  :error="item.error.length > 0"
                  :error-messages="item.error"
              ></v-text-field>
            </v-row>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-row no-gutters justify="space-around" align="center" v-show="item.adding">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      @click="addEmptyOuterEvent"
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon>
                      add_circle_outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Добавить пустое поле</span>
              </v-tooltip>
            </v-row>
            <v-row no-gutters justify="space-around" align="center" v-show="!item.adding">
              <v-tooltip bottom >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      @click="deleteEmptyOuterItem(item)"
                      v-bind="attrs"
                      v-on="on"
                  >
                    delete
                  </v-icon>
                </template>
                <span>Удалить поле</span>
              </v-tooltip>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-alert
            v-if="hasError('event')"
            color="red"
            class="elevation-2"
            type="error"
        >{{ String(getError('event')) }}</v-alert>
      </v-col>
    </v-row>
  </scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import ScrollCenterDialog from "@/modules/templates/ScrollCenterDialog.vue";
import {mapActions} from "vuex";
import selectors from "@/urls/selectors";
import LoadingAutocomplete from "@/modules/core/components/LoadingAutocomplete.vue";

export default {
  name: "AdminInvitesEditDialog",
  props: {
    opened: Boolean,
  },
  mixins: [FormErrorsMixin],
  components: {
    ScrollCenterDialog,
    LoadingAutocomplete,
  },
  data() {
    return {
      save_loading: false,
      items: [],
      outer_items: [],
      empty_outer_item: {
        id: null,
        email: '',
        editable: false,
        adding: true,
        error: ''
      },
      empty_item: {
        id: null,
        visitor: null,
        editable: false,
        adding: true,
        error: '',
      },
      visitors: [],
      email_visitors: [],
      headers: [
        {text: 'ФИО', value: 'fio', sortable: false, width: '85%', align: 'center'},
        {text: 'Действия', value: 'actions', sortable: false, width: '15%', align: 'center'},
      ],
      outer_headers: [
        {text: 'E-mail', value: 'email', sortable: false, width: '85%', align: 'center'},
        {text: 'Действия', value: 'actions', sortable: false, width: '15%', align: 'center'},
      ],
    }
  },
  computed: {
    visitorsSelector() {
      return selectors.SELECTORS.VISITORS.ALL()
    },
    visitorsItems() {
      return this.items
    }
  },
  methods: {
    ...mapActions({
      createInvite: 'admin/createInvites',
    }),
    close() {
      this.$emit('close', false)
    },
    getVisitors() {
      this.visitors = [];

      for (let i = 0; i < this.items.length - 1; i++) {
        if (!this.items[i].editable) {
          this.visitors.push(this.items[i].visitor.value)
        }
      }
    },
    getEmailVisitors() {
      this.email_visitors = [];

      for (let i = 0; i < this.outer_items.length - 1; i++) {
        if (this.outer_items[i].email.length > 0) {
          this.email_visitors.push(this.outer_items[i].email)
        }
      }
    },
    save() {
      this.save_loading = true;

      this.getVisitors()
      this.getEmailVisitors()

      this.createInviteFunction()
    },
    changeEditableState(item) {
      item.editable = !item.editable
    },
    addEmptyOuterEvent() {
      this.outer_items.push(Object.assign({}, this.empty_outer_item))
      this.outer_items.at(-1).index = this.items.length - 1;
      this.outer_items.at(-2).adding = false;
      this.outer_items.at(-2).editable = true;
    },
    deleteEmptyOuterItem(item) {
      const index = this.outer_items.indexOf(item)
      this.outer_items.splice(index, 1)

      this.outer_items.forEach((item, index) => {
        item.id = index
      })
    },
    addEmptyInnerItem() {
      this.items.push(Object.assign({}, this.empty_item))
      this.items.at(-1).index = this.items.length - 1;
      this.items.at(-2).adding = false;
      this.items.at(-2).editable = true;
    },
    deleteEmptyInnerItem(item) {
      const index = this.items.indexOf(item)
      this.items.splice(index, 1)

      this.items.forEach((item, index) => {
        item.id = index
      })
    },
    createInviteFunction() {
      this.createInvite({
        data: {
          visitors: this.visitors,
          email_visitors: this.email_visitors,
        },
        event_id: this.$route.params.idEvent,
        finalizer: () => {
          this.save_loading = false;
          this.close()
        },
        catcher: (val) => {
          this.mapCreationErrors(val)
          this.save_loading = false;
        }
      })
    },
    mapCreationErrors(errors) {
      let emails = errors.email_visitors
      for (let index in emails) {
        for (let error of emails[index]) {
          this.outer_items[Number(index)].error += error + ' '
        }
      }
    },
    createForm() {
      for (let i = 0; i < 2; i++) {
        this.items.push(Object.assign({}, this.empty_item))
        this.items[i].id = i;

        this.outer_items.push(Object.assign({}, this.empty_outer_item))
        this.outer_items[i].id = i;
      }

      this.items[0].editable = true
      this.items[0].adding = false

      this.outer_items[0].editable = true
      this.outer_items[0].adding = false
    },
  },
  created() {
    this.createForm()
  }
}
</script>

<style scoped>

</style>
