<template>
  <v-container class="page-content pa-0" style="  width: 100%" >
    <div v-if="$route.name === names.EVENTS.INTERVALS.LIST">
      <v-toolbar
          class="removeBtnPaddingRight rounded-t-lg"
          color="#DADADA"
          flat
      >
        <!--        <v-text-field-->
        <!--            class="pb-1"-->
        <!--            single-line-->
        <!--            v-model="search.value" label="Поиск" hide-details @keydown.enter="searchData">-->
        <!--        </v-text-field>-->
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-data-table
          :headers="headers"
          :items="items"
          :loading="isLoading"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.size"
          :server-items-length="count"
          :options.sync="options"
          :footer-props="{'items-per-page-options':[10,50,100]}"
          class="elevation-1 rounded-b-lg rounded-t-0"
      >
        <template v-slot:item.start="{ item }">
          <span v-if="item.start!==null">{{ displayDate(item.start) }}</span>
        </template>
        <template v-slot:item.end="{ item }">
          <span v-if="item.end!==null">{{ displayDate(item.end) }}</span>
        </template>
        <template v-slot:item.is_exclusive="{ item }">
          <v-icon
              :color="checkFlag(item.is_exclusive).color"
          >
            {{checkFlag(item.is_exclusive).icon}}
          </v-icon>
        </template>
        <template v-slot:item.slots="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  class="mr-2"
                  @click="getListIntervals(item.id)"
                  v-bind="attrs"
                  v-on="on"
              >
                view_list
              </v-icon>
            </template>
            <span>Список слотов</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-btn
              color="primary"
              @click="updateData"
          >
            Обновить
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <router-view v-else></router-view>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import {displayDate} from "@/helper";
import viewer from "@/urls/roles/viewer";
import names_core from "@/modules/core/routers/names";
import names from "@/modules/viewer/routers/names";

export default {
  name: "ViewerEventsIntervalsList",
  components: {},
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  data() {
    return {
      page_title: "Список интервалов",
      headers: [
        {text: 'Дата начала', value: 'start', sortable: false, align: 'start', width: '25%'},
        {text: 'Дата конца', value: 'end', sortable: false, align: 'start', width: '25%'},
        {text: 'Исключающий интервал', value: 'is_exclusive', sortable: false, align: 'center', width: '25%'},
        {text: 'Cлоты интервала', value: 'slots', sortable: false, align: 'center', width: '25%'},
      ],
    }
  },
  computed: {
    ...mapGetters({
      items: 'viewer/getIntervalsList',
      count: 'viewer/getIntervalsCount',
      isLoading: 'viewer/isLoadingIntervals'
    }),
    names: () => names,
  },
  methods: {
    ...mapActions({
      loadIntervalsList: 'viewer/loadIntervalsList',
      loadLightDetail: 'viewer/loadEventsLightDetail',
      changeEventDetailLoadingState: 'leftmenu/changeEventDetailLoadingState'
    }),
    displayDate: displayDate,
    getListIntervals(id) {
      this.$router.push({name: names.EVENTS.SLOTS.LIST, params: {}, query: {idInterval: id}})
    },
    updateData(){
      // if (this.search.value) {
      //   this.search.value = ''
      // }
      this.loadData()
    },
    loadData(url = undefined) {
      this.loadIntervalsList({
        url: url,
        event_id: this.$route.params.idEvent,
        urlAppend: (url) => {
          return this.addPageParameter(this.addSizeParameter(url));
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
          this.changeEventDetailLoadingState({loading_state: false})
        }
      })
    },
    checkFlag(flag) {
      if (flag) return {icon: "check_circle", color: "green"}
      else return {icon: "not_interested", color: "gray"}
    },
    updatePageTitle() {
      this.loadLightDetail({
        id: this.$route.params.idEvent,
        finalizer: (data) => {
          this.page_title = this.page_title + ': ' + data.name
        }
      })
    },
  },
  created() {
    if (viewer.VIEWER.ALLOWED()){
      if(this.$route.name === names.EVENTS.INTERVALS.LIST){
        this.updatePageTitle()
        this.loadData()
      }
    } else{
      this.$router.push({name: names_core.MAIN})
    }
  },
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0;
}
</style>
