<template>
  <div>
    <slot name="btn"></slot>
    <v-divider class="bold"></v-divider>
  </div>
</template>

<script>
export default {
  props: {
    edit: Boolean,
    data: Object,
  },
  name: "SpacerBlock",
}
</script>

<style scoped>

</style>
