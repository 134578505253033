<template>
  <v-carousel v-if="!edit" hide-delimiters>
    <v-carousel-item
      v-for="(item, i) in data.files"
      :key="i"
      style="background: #BBBBBB"
    >
      <v-img
        :gradient=" !item.src ?
                      '41deg, rgba(29,59,242,1) 0%, rgba(197,113,233,1) 50%, rgba(197,113,233,1) 78%, rgba(186,234,254,1) 100%'
                      : ''"
        :src="item.src"
        :aspect-ratio="1"
        contain
        max-height="500px"
        class="ma-0"
      ></v-img>
    </v-carousel-item>
  </v-carousel>
  <div v-else>
    <v-card-text class="text-start pa-0 my-4 ml-4 text-subtitle-1 text-sm-h6 font-weight-bold" v-if="data.all_files.length > 0">Загруженные изображения</v-card-text>
    <v-row class="mb-4 mx-1 pa-0">
      <file-chip
          v-for="(item, i) in data.all_files"
          :key="i"
          :i="i"
          :data="data"
          @moveFileUp="moveFileUp"
          @moveFileDown="moveFileDown"
          @deleteOneFile="deleteOneFile"
      ></file-chip>
    </v-row>
    <v-card-text class="text-start pa-0 my-4 ml-4 text-subtitle-1 text-sm-h6 font-weight-bold">Загрузите изображения</v-card-text>
    <v-file-input
        v-model="data.new_files"
        accept="image/png, image/jpeg, image/jpg"
        placeholder="Загрузите изображения..."
        outlined
        multiple
        show-size
        :error="data.errors.file.length > 0"
        :error-messages="data.errors.file"
        @change="$emit('loadFilesFromInput', data.id)"
    ></v-file-input>
  </div>
</template>

<script>


import FileChip from "@/modules/templates/description_blocks/blocks/blocks_components/FileChip.vue";

export default {
  props: {
    edit: Boolean,
    data: Object,
  },
  name: "SliderBlock",
  components: {FileChip},
  methods: {
    moveFileUp(id, file_index) {
      this.$emit('moveFileUp', id, file_index)
    },
    moveFileDown(id, file_index) {
      this.$emit('moveFileDown', id, file_index)
    },
    deleteOneFile(id, file_index) {
      this.$emit('deleteOneFile', id, file_index)
    },
  }
}
</script>

<style scoped>
</style>
