<template>
  <v-dialog
      v-model="open"
      :max-width="maxWidth"
      persistent
      scrollable
      transition="dialog-top-transition"
  >
    <v-card  class="rounded-lg">
      <v-toolbar flat tile rounded="0" width="100%" :color="topColor">
        <slot name="title">
          <span :class="$vuetify.breakpoint.mdAndUp ? 'text-h5 pl-6' : 'text-h6 pl-5'">
            {{ title }}
          </span>
        </slot>
        <v-spacer v-if="spacedTitle"></v-spacer>
        <v-btn
            v-show="closed"
            fab
            icon
            small
            @click="closeDialog()"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <slot name="text">
          <span v-html="text"></span>
        </slot>
      </v-card-text>
      <v-card-actions>
        <slot name="actions">
          <v-spacer/>
          <v-btn
              v-if="closable"
              :color="closeColor"
              variant="text"
              class="rounded-lg"
              :loading="loading"
              @click="closeDialog()"
          >
            {{ closeText }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PopUpAlert",
  props: {
    value: Boolean,
    title: String,
    text: String,
    scrolled: {
      default: true,
      type: Boolean
    },
    closed: {
      default: false,
      type: Boolean
    },
    spacedTitle: {
      default: true,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    closable: {
      default: true,
      type: Boolean,
    },
    closeText: {
      default: 'Закрыть',
      type: String,
    },
    closeColor: {
      default: 'grey-darken-2',
      type: String,
    },
    topColor: {
      default: 'red-darken-2',
      type: String
    },
    maxWidth: {
      default: '500px',
      type: String,
    },
    contentHeight: {
      default: undefined,
      type: String
    }
  },
  data () {
    return {
      open: false,
    }
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        this.open = this.value
      }
    },
    open: {
      immediate: true,
      handler () {
        this.$emit('input', this.open)
      }
    }
  },
  methods: {
    closeDialog () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.scrolled-style::-webkit-scrollbar {
  width: 5px;
}

.scrolled-style::-webkit-scrollbar-track {
}

.scrolled-style::-webkit-scrollbar-thumb {
  background: gray;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
</style>
