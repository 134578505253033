<template>
  <div>
    <v-alert
        v-if="selectorError"
        color="red"
        class="elevation-2"
        type="error"
    > {{ String(selectorError) }}</v-alert>
    <v-card-title class="text-left px-0 pt-0 pb-4 pl-1">
      Варианты выбора:
    </v-card-title>
    <draggable
      :list="items"
    >
      <transition-group>
        <v-row no-gutters class="pa-0" v-for="(item, i) in items" :key="i + 'item'">
          <v-col cols="1">
            <v-icon class="mt-4 mr-2">
              drag_indicator
            </v-icon>
          </v-col>
          <v-col cols="9" sm="10" class="pr-md-1">
            <v-text-field
                class="pa-0"
                v-model="items[i]"
                :label="'Вариант ' + (i + 1)"
                @keydown.enter="i === items.length - 1 ? addNewChoice() : ()=>{}"
                outlined
                autofocus
            >
            </v-text-field>
          </v-col>
          <v-col cols="2" sm="1">
            <v-btn
                :disabled="items.length <= 2"
                icon
                @click="removeChoice(i)"
                class="pr-0 mt-2"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </transition-group>
    </draggable>
    <v-row no-gutters>
      <v-spacer></v-spacer>
      <v-btn
          text
          @click="addNewChoice"
          class="mr-2 pr-1"
      >
        Добавить вариант&emsp;
        <v-icon>add</v-icon>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "SelectorComponent",
  props: {
    value: {
      type: Array,
      default: () => {
        return []
      }
    },
    selectorError: Array
  },
  components: {
    draggable
  },
  data() {
    return {
      items: ['', '']
    }
  },
  watch: {},
  computed: {},
  methods: {
    removeChoice(index) {
      this.items.splice(index, 1)
    },
    addNewChoice() {
      this.items.push("")
      this.$emit('updateSelector', this.items)
    },
  },
  created() {
    this.items = this.value
  },
}
</script>

<style scoped>
.card-body {
  min-height: 50px;
}
</style>
