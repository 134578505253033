import {
    ACCOUNTS_INSTANCE,
    EVENTS_INSTANCE,
} from "@/helper/consts";

import Breadcrumbs from "@/modules/organiser/Breadcrumbs";
import names from "@/modules/organiser/routers/names";

import Events from "@/modules/organiser/events/Events";
import EventsDetail from "@/modules/organiser/events/pages/BreadcrumbsEvent"
import EventsDetailPreview from "@/modules/organiser/events/pages/preview/Base"
import EventsDetailIntervals from "@/modules/organiser/events/pages/intervals/List"
import EventsDetailSlots from "@/modules/organiser/events/pages/slots/List"
import EventsDetailSlotsDetail from "@/modules/organiser/events/pages/slots/Detail"
import EventsDetailVisits from "@/modules/organiser/events/pages/visits/List"
import EventsDetailVisitsDetail from "@/modules/organiser/events/pages/visits/Detail"
import EventsDetailInvites from "@/modules/organiser/events/pages/invites/List"
import EventsDetailRoles from "@/modules/organiser/events/pages/roles/List"

import FindVisits from "@/modules/organiser/visits/FindVisits"

export default ([
    {
        path: '/organiser',
        redirect: '/organiser/events',
        name: names.MAIN,
        component: Breadcrumbs,
        meta: {
            breadcrumb: 'Организатор',
            requiredAuth: true,
            instances: [
                ACCOUNTS_INSTANCE,
                EVENTS_INSTANCE,
            ]
        },
        children: [
            {
                path: 'events',
                name: names.EVENTS.LIST,
                component: Events,
                meta: {
                    breadcrumb: 'События',
                    requiredAuth: true,
                    instances: [
                        EVENTS_INSTANCE,
                        ACCOUNTS_INSTANCE
                    ]
                },
                children: [
                    {
                        path: ':idEvent',
                        name: names.EVENTS.DETAIL,
                        component: EventsDetail,
                        meta: {
                            breadcrumb: 'Детальное представление',
                            requiredAuth: true,
                            instances: [
                                EVENTS_INSTANCE,
                                ACCOUNTS_INSTANCE
                            ]
                        },
                        children: [
                            {
                                path: 'preview',
                                name: names.EVENTS.PREVIEW.MAIN,
                                component: EventsDetailPreview,
                                meta: {
                                    breadcrumb: 'Предпросмотр',
                                    requiredAuth: true,
                                    instances: [
                                        EVENTS_INSTANCE,
                                        ACCOUNTS_INSTANCE
                                    ]
                                },
                            },
                            {
                                path: 'intervals',
                                name: names.EVENTS.INTERVALS.LIST,
                                component: EventsDetailIntervals,
                                meta: {
                                    breadcrumb: 'Интервалы',
                                    requiredAuth: true,
                                    instances: [
                                        EVENTS_INSTANCE,
                                        ACCOUNTS_INSTANCE
                                    ]
                                },
                            },
                            {
                                path: 'slots',
                                name: names.EVENTS.SLOTS.LIST,
                                component: EventsDetailSlots,
                                meta: {
                                    breadcrumb: 'Слоты',
                                    requiredAuth: true,
                                    instances: [
                                        EVENTS_INSTANCE,
                                        ACCOUNTS_INSTANCE
                                    ]
                                },
                                children: [
                                    {
                                        path: ':idSlot',
                                        name: names.EVENTS.SLOTS.DETAIL,
                                        component: EventsDetailSlotsDetail,
                                        meta: {
                                            breadcrumb: 'Детальное представление',
                                            requiredAuth: true,
                                            instances: [
                                                EVENTS_INSTANCE,
                                                ACCOUNTS_INSTANCE
                                            ]
                                        },
                                    }
                                ]
                            },
                            {
                                path: 'visits',
                                name: names.EVENTS.VISITS.LIST,
                                component: EventsDetailVisits,
                                meta: {
                                    breadcrumb: 'Посетители',
                                    requiredAuth: true,
                                    instances: [
                                        EVENTS_INSTANCE,
                                        ACCOUNTS_INSTANCE
                                    ]
                                },
                                children: [
                                    {
                                        path: ':idVisit',
                                        name: names.EVENTS.VISITS.DETAIL,
                                        component: EventsDetailVisitsDetail,
                                        meta: {
                                            breadcrumb: 'Детальное представление',
                                            requiredAuth: true,
                                            instances: [
                                                EVENTS_INSTANCE,
                                                ACCOUNTS_INSTANCE
                                            ]
                                        },
                                    }
                                ]
                            },
                            {
                                path: 'invites',
                                name: names.EVENTS.INVITES.LIST,
                                component: EventsDetailInvites,
                                meta: {
                                    breadcrumb: 'Приглашения',
                                    requiredAuth: true,
                                    instances: [
                                        EVENTS_INSTANCE,
                                        ACCOUNTS_INSTANCE
                                    ]
                                },
                            },
                            {
                                path: 'roles',
                                name: names.EVENTS.ROLES.LIST,
                                component: EventsDetailRoles,
                                meta: {
                                    breadcrumb: 'Роли',
                                    requiredAuth: true,
                                    instances: [
                                        EVENTS_INSTANCE,
                                        ACCOUNTS_INSTANCE
                                    ]
                                },
                            },
                        ]
                    },
                ]
            },
            {
                path: 'find_visits',
                name: names.FIND_VISITS.LIST,
                component: FindVisits,
                meta: {
                    breadcrumb: 'Найти посещение',
                    requiredAuth: true,
                    instances: [
                        EVENTS_INSTANCE,
                        ACCOUNTS_INSTANCE
                    ]
                },
            }
        ]
    }
])
