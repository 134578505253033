<template>
  <v-container class="page-content pa-0" style="  width: 100%" >
    <div v-if="$route.name === names.EVENTS.VISITS.LIST">
      <v-toolbar
          class="removeBtnPaddingRight rounded-t-lg"
          color="#DADADA"
          flat
      >
        <v-text-field
            v-model="search.value"
            class="dark-primary my-1"
            clearable
            dense
            outlined
            label="Поиск"
            hide-details
            @keydown.enter="searchData"
            @click:clear="updateData"
        ></v-text-field>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-data-table
          :headers="headers"
          :items="items"
          :loading="isLoading"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.size"
          :server-items-length="count"
          :options.sync="options"
          :footer-props="{'items-per-page-options':[10,50,100]}"
          class="elevation-1 rounded-b-lg"
      >
        <template v-slot:item.visitor.fio="{ item }">
          <span style="text-decoration: underline; color: #065aad; cursor: pointer" @click="getDetailVisit(item.id)">{{item.visitor.fio}}</span>
        </template>
        <template v-slot:item.event.start="{ item }">
          <span v-if="item.event.start!==null">{{ displayDate(item.event.start) }}</span>
        </template>
        <template v-slot:item.event.end="{ item }">
          <span v-if="item.event.end!==null">{{ displayDate(item.event.end) }}</span>
        </template>
        <template v-slot:item.have_records="{ item }">
          <v-icon :color="checkFlag(item.have_records).color">
            {{ checkFlag(item.have_records).icon }}
          </v-icon>
        </template>
        <template v-slot:item.is_end="{ item }">
          <v-icon :color="checkFlag(item.is_end).color">
            {{ checkFlag(item.is_end).icon }}
          </v-icon>
        </template>
        <template v-slot:item.is_enter="{ item }">
          <v-icon :color="checkFlag(item.is_enter).color">
            {{ checkFlag(item.is_enter).icon }}
          </v-icon>
        </template>
        <template v-slot:item.is_cancel="{ item }">
          <v-icon :color="checkCancelFlag(item.is_cancel).color">
            {{ checkFlag(item.is_cancel).icon }}
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn
              color="primary"
              @click="updateData"
          >
            Обновить
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <router-view v-else></router-view>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import {displayDate} from "@/helper";
import viewer from "@/urls/roles/viewer";
import names_core from "@/modules/core/routers/names";
import names from "@/modules/viewer/routers/names";

export default {
  name: "ViewerEventsVisitsList",
  components: {},
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  data() {
    return {
      page_title: "Список посещений",
      search: {
        value: '',
        parameter: 'search',
      },
      headers: [
        {text: 'Посетитель', value: 'visitor.fio', sortable: false, align: 'start',  width: '28%'},
        {text: 'Дата начала события', value: 'event.start', sortable: false, align: 'center', width: '12%'},
        {text: 'Дата конца события', value: 'event.end', sortable: false, align: 'center', width: '12%'},
        {text: 'Есть записи', value: 'have_records', sortable: false, align: 'center', width: '12%'},
        {text: 'Событие завершено', value: 'is_end', sortable: false, align: 'center', width: '12%'},
        {text: 'Посещено', value: 'is_enter', sortable: false, align: 'center', width: '12%'},
        {text: 'Отменено', value: 'is_cancel', sortable: false, align: 'center', width: '12%'},
      ],
    }
  },
  computed: {
    ...mapGetters({
      items: 'viewer/getVisitsList',
      count: 'viewer/getVisitsCount',
      isLoading: 'viewer/isLoadingVisits'
    }),
    names: () => names,
  },
  watch: {
  },
  methods: {
    ...mapActions({
      loadVisitsList: 'viewer/loadVisitsList',
      loadLightDetail: 'viewer/loadEventsLightDetail',
      changeEventDetailLoadingState: 'leftmenu/changeEventDetailLoadingState'
    }),
    displayDate: displayDate,
    getDetailVisit(id) {
      this.$router.push({name: names.EVENTS.VISITS.DETAIL, params: {idVisit: id}})
    },
    updateData() {
      if (this.search.value) {
        this.search.value = ''
      }
      this.loadData()
    },
    checkFlag(flag) {
      if (flag) return {icon: "check_circle", color: "green"}
      else return {icon: "not_interested", color: "gray"}
    },
    checkCancelFlag(flag) {
      if (flag) return {icon: "check_circle", color: "yellow darken-3"}
      else return {icon: "not_interested", color: "grey"}
    },
    loadData(url = undefined) {
      this.loadVisitsList({
        url: url,
        urlAppend: (url) => {
          return this.addPageParameter(
              this.addSizeParameter(
                  this.addGetParameter(url, 'event', this.$route.params.idEvent)
              )
          )
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
          this.changeEventDetailLoadingState({loading_state: false})
        }
      })
    },
    updatePageTitle() {
      this.loadLightDetail({
        id: this.$route.params.idEvent,
        finalizer: (data) => {
          this.page_title = this.page_title + ': ' + data.name
        }
      })
    },
  },
  created() {
    if (viewer.VIEWER.ALLOWED()){
      if(this.$route.name === names.EVENTS.VISITS.LIST){
        this.updatePageTitle()
        this.loadData()
      }
    } else{
      this.$router.push({name: names_core.MAIN})
    }
  },
  beforeRouteUpdate(to, from, next) {
    next()
    if(this.$route.name === names.EVENTS.VISITS.LIST){
      // Принудительное обновление компонента
      this.page_title = this.page_title + ' '
      this.loadData()
    }
  },
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0px;
}
</style>
