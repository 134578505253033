import {sendGetRequest} from "@/helper/requests";
import visitor from "@/urls/roles/visitor"


const state = {
    fields: {
        currentUrl: null,
        items: [],
        loading: false,
        count: 0,
        error: null,
    }
}

const getters = {
    getFieldsList: state => state.fields.items,
    getFieldsCount: state => state.fields.count,
    getFieldsCurrentUrl: state => state.fields.currentUrl,
    isLoadingFields: state => state.fields.loading,
    saveFieldsError: state => state.fields.error,
}

const actions = {
    loadFieldsList: ({commit}, {event_id, url, urlAppend, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) {
            url = visitor.VISITOR.EVENTS.FIELDS.LIST(event_id)
            if (urlAppend) url = urlAppend(url)
        }

        commit('SET_FIELDS_LOADING');
        sendGetRequest(
            url, (data, url) => {
                commit('SET_FIELDS_LIST', data);
                commit('SET_FIELDS_COUNT', data.length);
                commit('SET_FIELDS_LOADING_END');
                finalizer(data, url);
            }, (data, url) => {
                commit('SET_FIELDS_LOADING_END');
                commit('SAVE_FIELDS_ERROR', data);
                catcher(data, url);
            },
        )

    },
}

const mutations = {
    SET_FIELDS_LIST: (state, value) => {
        state.fields.items = value
    },
    SET_FIELDS_COUNT: (state, value) => {
        state.fields.count = value
    },
    SET_FIELDS_CURRENT_URL: (state, value) => {
        state.fields.currentUrl = value
    },
    SET_FIELDS_LOADING: (state) => {
        state.fields.loading = true
    },
    SET_FIELDS_LOADING_END: (state) => {
        state.fields.loading = false
    },
    SAVE_FIELDS_ERROR: (state, error) => {
        state.fields.error = error
    },
}

export default {
    namespaced: false,
    state,
    mutations,
    actions,
    getters,
}
