<template>
  <v-container class="page-content pa-0" style="  width: 100%" >
    <div v-if="$route.name === names.EVENTS.VISITS.LIST">
      <v-toolbar
          class="removeBtnPaddingRight rounded-t-lg"
          color="#DADADA"
          flat
      >
        <v-text-field
            v-model="search.value"
            class="dark-primary my-1"
            clearable
            dense
            outlined
            label="Поиск"
            hide-details
            @keydown.enter="searchData"
            @click:clear="updateData"
        ></v-text-field>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-data-table
          :headers="headers"
          :items="items"
          :loading="isLoading"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.size"
          :server-items-length="count"
          :options.sync="options"
          :footer-props="{'items-per-page-options':[10,50,100]}"
          class="elevation-1 rounded-b-lg"
      >
        <template v-slot:item.visitor.fio="{ item }">
          <span style="text-decoration: underline; color: #065aad; cursor: pointer" @click="getDetailVisit(item.id)">{{item.visitor.fio}}</span>
        </template>
        <template v-slot:item.event.start="{ item }">
          <span v-if="item.event.start!==null">{{ displayDate(item.event.start) }}</span>
        </template>
        <template v-slot:item.event.end="{ item }">
          <span v-if="item.event.end!==null">{{ displayDate(item.event.end) }}</span>
        </template>
        <template v-slot:item.have_records="{ item }">
          <v-icon :color="checkFlag(item.have_records).color">
            {{ checkFlag(item.have_records).icon }}
          </v-icon>
        </template>
        <template v-slot:item.is_end="{ item }">
          <v-icon :color="checkFlag(item.is_end).color">
            {{ checkFlag(item.is_end).icon }}
          </v-icon>
        </template>
        <template v-slot:item.is_enter="{ item }">
          <v-icon :color="checkFlag(item.is_enter).color">
            {{ checkFlag(item.is_enter).icon }}
          </v-icon>
        </template>
        <template v-slot:item.is_cancel="{ item }">
          <v-icon :color="checkCancelFlag(item.is_cancel).color">
            {{ checkFlag(item.is_cancel).icon }}
          </v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom v-if="!item.is_cancel && !item.is_enter && light_detail.allow_update && !(light_detail.is_end && !light_detail.is_draft) && !item.has_entered_records">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  class="mr-2"
                  @click="cancelVisit(item)"
                  v-bind="attrs"
                  v-on="on"
              >
                cancel
              </v-icon>
            </template>
            <span>Отменить</span>
          </v-tooltip>
          <v-tooltip bottom v-if="!item.is_cancel && !item.is_enter && light_detail.allow_update && !(light_detail.is_end && !light_detail.is_draft)">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  class="mr-2"
                  @click="enterVisit(item)"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
              >
                add_task
              </v-icon>
            </template>
            <span>Зафиксировать посещение мероприятия</span>
          </v-tooltip>
          <v-tooltip bottom v-if="!item.is_cancel && item.is_enter && !(light_detail.is_end && !light_detail.is_draft) && !item.has_entered_records && light_detail.allow_update">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  class="mr-2"
                  @click="backVisit(item)"
                  v-bind="attrs"
                  v-on="on"
              >
                undo
              </v-icon>
            </template>
            <span>Отменить фиксацию посещения мероприятия</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-btn
              color="primary"
              @click="updateData"
          >
            Обновить
          </v-btn>
        </template>
      </v-data-table>
      <ApplyDialog
          v-if="dialog_cancel"
          :opened="dialog_cancel"
          :get-text="() => `Вы уверены, что хотите отменить посещение ?`"
          @close="closeCancelDialog"
      ></ApplyDialog>
      <ApplyDialog
          v-if="dialog_back"
          :opened="dialog_back"
          :get-text="() => `Вы уверены, что хотите отменить фиксацию посещения ?`"
          @close="closeBackDialog"
      ></ApplyDialog>
      <ApplyDialog
          v-if="dialog_enter"
          :opened="dialog_enter"
          :get-text="() => `Вы уверены, что хотите зафиксировать посещение  мероприятия ?`"
          @close="closeEnterDialog"
      ></ApplyDialog>
    </div>
    <router-view v-else></router-view>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import {displayDate} from "@/helper";
import organiser from "@/urls/roles/organiser";
import names_core from "@/modules/core/routers/names";
import names from "@/modules/organiser/routers/names";
import ApplyDialog from "@/modules/core/components/ApplyDialog";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";

export default {
  name: "OrganiserEventsVisitsList",
  components: {ApplyDialog},
  mixins: [TitledPageMixin, PaginatedDataMapperMixin, FormErrorsMixin],
  data() {
    return {
      page_title: "Список посетителей",
      dialog_cancel: false,
      dialog_enter: false,
      edit_status: undefined,
      visit_data: undefined,
      search: {
        value: '',
        parameter: 'search',
      },
      headers: [
        {text: 'Посетитель', value: 'visitor.fio', sortable: false, align: 'start',  width: '28%'},
        {text: 'Дата начала события', value: 'event.start', sortable: false, align: 'center', width: '12%'},
        {text: 'Дата конца события', value: 'event.end', sortable: false, align: 'center', width: '10%'},
        {text: 'Есть записи', value: 'have_records', sortable: false, align: 'center', width: '10%'},
        {text: 'Событие завершено', value: 'is_end', sortable: false, align: 'center', width: '10%'},
        {text: 'Посещено', value: 'is_enter', sortable: false, align: 'center', width: '10%'},
        {text: 'Отменено', value: 'is_cancel', sortable: false, align: 'center', width: '10%'},
        {text: 'Действия', value: 'actions', sortable: false, align: 'center', width: '10%'},
      ],
      dialog_back: false
    }
  },
  computed: {
    ...mapGetters({
      items: 'organiser/getVisitsList',
      count: 'organiser/getVisitsCount',
      isLoading: 'organiser/isLoadingVisits',
      light_detail: 'organiser/getEventsLightDetail',
    }),
    names: () => names,
  },
  watch: {
  },
  methods: {
    ...mapActions({
      loadVisitsList: 'organiser/loadVisitsList',
      cancelVisits: 'organiser/cancelVisits',
      enterVisits: 'organiser/enterVisits',
      loadLightDetail: 'organiser/loadEventsLightDetail',
      changeEventDetailLoadingState: 'leftmenu/changeEventDetailLoadingState',
      backVisits: 'organiser/rollBackEnterVisits'
    }),
    displayDate: displayDate,
    getDetailVisit(id) {
      this.$router.push({name: names.EVENTS.VISITS.DETAIL, params: {idVisit: id}})
    },
    updateData(){
      if (this.search.value) {
        this.search.value = ''
      }
      this.loadData()
    },
    backVisit(item) {
      this.dialog_back = true
      this.visit_data = item
    },
    closeBackDialog(visit) {
      if (visit) {
        this.backVisits({
          id: this.visit_data.id,
          finalizer: (val) => {
            this.visit_data.is_enter = false
            this.dialog_back = false
            this.visit_data = undefined
            this.loadData()
          },
        })
      } else {
        this.dialog_back = false
      }
    },
    enterVisit(item) {
      this.dialog_enter = true
      this.visit_data = item
    },
    closeEnterDialog(visit) {
      if (visit) {
        this.enterVisits({
          id: this.visit_data.id,
          data: {},
          finalizer: (val) => {
            this.visit_data.is_enter = true
            this.visit_data = undefined
            this.loadData()
          },
        })
      }
      this.dialog_enter = false
    },
    checkFlag(flag) {
      if (flag) return {icon: "check_circle", color: "green"}
      else return {icon: "not_interested", color: "gray"}
    },
    checkCancelFlag(flag) {
      if (flag) return {icon: "check_circle", color: "yellow darken-3"}
      else return {icon: "not_interested", color: "grey"}
    },
    loadData(url = undefined) {
      this.loadVisitsList({
        url: url,
        urlAppend: (url) => {
          return this.addPageParameter(
              this.addSizeParameter(
                  this.addGetParameter(url, 'event', this.$route.params.idEvent)
              )
          )
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
          this.changeEventDetailLoadingState({loading_state: false})
        }
      })
    },
    cancelVisit(item) {
      this.dialog_cancel = true
      this.visit_data = item
    },
    closeCancelDialog(visit) {
      if (visit) {
        this.cancelVisits({
          id: this.visit_data.id,
          finalizer: (val) => {
            this.loadData()
          },
          catcher: (val) => {
            this.mapErrors(val)
          },
        })
      }
      this.dialog_cancel = false
      this.visit_data = undefined
    },
    updatePageTitle() {
      this.loadLightDetail({
        id: this.$route.params.idEvent,
        finalizer: (data) => {
          this.page_title = this.page_title + ': ' + data.name
        }
      })
    },
  },
  created() {
    if (organiser.ORGANISER.ALLOWED()){
      if(this.$route.name === names.EVENTS.VISITS.LIST){
        this.updatePageTitle()
        this.loadData()
      }
    } else{
      this.$router.push({name: names_core.MAIN})
    }
  },
  beforeRouteUpdate(to, from, next) {
    next()
    if(this.$route.name === names.EVENTS.VISITS.LIST){
      // Принудительное обновление компонента
      this.page_title = this.page_title + ' '
      this.loadData()
    }
  },
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0px;
}
</style>
