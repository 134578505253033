<template>
  <v-row class="ma-0 pa-0">
    <v-col class="ma-0 pa-0" v-if="row.cols_amount === 0">
      <v-card
        class="pa-5 ma-0"
      >
        <v-alert
          v-if="row.error"
          color="red"
          class="elevation-2"
          type="error"
        > {{ row.error }}</v-alert>
        <v-card-text v-else>
          В строке пока что отсутствуют блоки
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      v-for="(blockData, k) in row.cols"
      :key="k"
      class="ma-0 pa-0"
      :cols="getBlockCols(row, blockData)"
    >
      <div
        v-if="blockExists(blockData)"
        :class="getBlockPadding(blockData)"
        class="fill-height"
      >
        <v-card
          class="ma-0 fill-height"
          :class="getCardClass(blockData.type)"
        >
          <block-buttons-wrapper
            :edit-mode="editMode"
            :block-id="blockData.id"
            :block-type="blockData.type"
            :moving-left-allowed="blockData.data.col !== 0"
            :moving-right-allowed="blockData.data.col + blockData.data.colspan !== blockData.data.cols_amount"
            @extendBlock="extendBlock"
            @compressBlock="compressBlock"
            @deleteBlock="deleteBlock"
            @moveBlockLeft="moveBlockLeft"
            @moveBlockRight="moveBlockRight"
            @addEmptyEntry="addEmptyEntry"
          >
            <template v-slot:block>
              <component
                :is="getBlockComponent(blockData)"
                :data="blockData"
                :edit="editMode"
                :block-types="blockTypes"
                @chooseBlockType="chooseBlockType"
                @deleteEntry="deleteEntry"
                @moveEntryDown="moveEntryDown"
                @moveEntryUp="moveEntryUp"
                @deleteOneFile="deleteOneFile"
                @loadFilesFromInput="loadFilesFromInput"
                @moveFileUp="moveFileUp"
                @moveFileDown="moveFileDown"
              ></component>
            </template>
          </block-buttons-wrapper>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import TextBlock from "@/modules/templates/description_blocks/blocks/TextBlock.vue";
import SpacerBlock from "@/modules/templates/description_blocks/blocks/SpacerBlock.vue";
import ImageBlock from "@/modules/templates/description_blocks/blocks/ImageBlock.vue";
import SliderBlock from "@/modules/templates/description_blocks/blocks/SliderBlock.vue";
import EntriesSliderBlock from "@/modules/templates/description_blocks/blocks/EntriesSliderBlock.vue";
import FilesListBlock from "@/modules/templates/description_blocks/blocks/FilesListBlock.vue";
import EntriesListBlock from "@/modules/templates/description_blocks/blocks/EntriesListBlock.vue";
import NullBlock from "@/modules/templates/description_blocks/blocks/NullBlock.vue";
import HeaderBlock from "@/modules/templates/description_blocks/blocks/HeaderBlock.vue";
import EmptyBlock from "@/modules/templates/description_blocks/blocks/EmptyBlock.vue";
import BLOCK_COMPONENTS from "@/helper/block_components";
import BlockButtonsWrapper from "@/modules/templates/description_blocks/BlockButtonsWrapper.vue";

export default {
  props: {
    row: Object,
    editMode: Boolean,
    blockTypes: Array,
  },
  components: {
    BlockButtonsWrapper,
    EmptyBlock,
    TextBlock,
    SpacerBlock,
    ImageBlock,
    SliderBlock,
    EntriesSliderBlock,
    FilesListBlock,
    EntriesListBlock,
    NullBlock,
    HeaderBlock
  },
  name: "BlocksRow",
  data() {
    return {
      BLOCK_COMPONENTS: BLOCK_COMPONENTS,
    }
  },
  methods: {
    getBlockComponent(block) {
      if (block.type === 7 && !this.editMode && this.$vuetify.breakpoint.xsOnly) return BLOCK_COMPONENTS[5]
      return BLOCK_COMPONENTS[block.type]
    },
    blockExists(block) {
      return !!block
    },
    getBlockPadding(block) {
      if(!block) return '';
      return 'px-2 py-3';
    },
    getCardClass(type) {
      let class_string = '';
      class_string += (type === 1 || type === 2) ||  (type === 5 || type === 7) && !this.editMode ? 'elevation-0' : ''
      class_string += (type === 1 || type === 2) && !this.editMode ? ' px-0' : ' '
      class_string += (type !== 1 && type !== 2 && type !== 4 && type !== 3 && type !== 5 && type !== 7) || this.editMode ? ' pa-5' : ''
      class_string += type === 2 && !this.editMode ? ' pb-3' : ''
      class_string += type === 1 && !this.editMode ? ' pa-1' : ''
      return class_string
    },
    getBlockCols(row, block) {
      if (!this.blockExists(block)) return;

      let cols_amount = row.cols_amount;
      let colspan = block.data.colspan;
      let col_number = block.data.col

      if (this.$vuetify.breakpoint.lgAndUp) {
        return 12 / cols_amount * colspan;
      }
      else if (this.$vuetify.breakpoint.mdOnly) {
        if (cols_amount <= 3) {
          return 12 / cols_amount * colspan;
        }
        else if (cols_amount === 4) {
          if (colspan === 2) {
            if (col_number === 0 || col_number === 2) {
              if (col_number === 0 && this.blockExists(row.cols[2]) && row.cols[2].data.colspan === 2
                  || col_number === 2 && this.blockExists(row.cols[0]) && row.cols[0].data.colspan === 2) {
                return 6;
              }
              return 12;
            }
            return 4;

          }
          else if (colspan === 3) {
            return 6;
          }
          else if (colspan === 1) {
            if (this.blockExists(row.cols[1]) && row.cols[1].data.colspan === 2) {
              return 4
            }
            return 6
          }
          return 12
        }
      }
      else if (this.$vuetify.breakpoint.smOnly) {
        if (cols_amount <= 2) {
          return 12 / cols_amount * colspan;
        }
        else if (cols_amount === 4) {
          if (colspan === 2) {
            if (col_number === 0 || col_number === 2) {
              if (col_number === 0 && this.blockExists(row.cols[2]) && row.cols[2].data.colspan === 2
                  || col_number === 2 && this.blockExists(row.cols[0]) && row.cols[0].data.colspan === 2) {
                return 6;
              }
              return 12;
            }
            return 6;
          }
          else if (colspan === 3) {
            return 6;
          }
          else if (colspan === 1) {
            if (col_number === 3 && this.blockExists(row.cols[1]) && row.cols[1].data.colspan === 2) {
              return 12
            }
            return 6
          }
          return 12
        }
      }
      return 12
    },
    deleteBlock(id) {
      this.$emit('deleteBlock', id)
    },
    moveBlockLeft(id) {
      this.$emit('moveBlockLeft', id)
    },
    moveBlockRight(id) {
      this.$emit('moveBlockRight', id)
    },
    extendBlock(id) {
      this.$emit('extendBlock', id)
    },
    compressBlock(id) {
      this.$emit('compressBlock', id)
    },
    chooseBlockType(type, id) {
      this.$emit('chooseBlockType', type, id)
    },
    deleteOneFile(id, file_index) {
      this.$emit('deleteOneFile', id, file_index)
    },
    addEmptyEntry(id) {
      this.$emit('addEmptyEntry', id)
    },
    deleteEntry(id, entry_number) {
      this.$emit('deleteEntry', id, entry_number);
    },
    loadFilesFromInput(id) {
      this.$emit('loadFilesFromInput', id)
    },
    moveFileUp(id, file_index) {
      this.$emit('moveFileUp', id, file_index)
    },
    moveFileDown(id, file_index) {
      this.$emit('moveFileDown', id, file_index)
    },
    moveEntryUp(id, entry_number) {
      this.$emit('moveEntryUp', id, entry_number);
    },
    moveEntryDown(id, entry_number) {
      this.$emit('moveEntryDown', id, entry_number);
    },
  }
}
</script>

<style scoped>

</style>
