import helper from "../helper";

let getEventsUrl = helper.getEventsUrlWithParameters
let getEventsAllowed = helper.allowedEventsUrl

export default {
    getEventsUrl,
    getByRecievedUrlData: (url, gets = null) => getEventsUrl(
        url.basename,
        url.action,
        url.params,
        gets
    ),
    VISITOR: {
        ALLOWED: () => getEventsAllowed(
            'visitor|events|events-list',
            "list",
        ),
        EVENTS: {
            LIST:
                (gets = null) => getEventsUrl(
                    'visitor|events|events-list',
                    "list",
                    null,
                    gets
                ),
            DETAIL:
                (id, gets = null) => getEventsUrl(
                    'visitor|events|events-detail',
                    "retrieve",
                    {id: id},
                    gets
                ),
            LIGHT_DETAIL:
                (id, gets = null) => getEventsUrl(
                    'visitor|events|events-light-detail',
                    "light_detail",
                    {id: id},
                    gets
                ),
            IMAGE:
                (id, gets = null) => getEventsUrl(
                    'description|media|download',
                    "get",
                    {id: id},
                    gets
                ),
            VISITS: {
                LIST:
                    (gets = null) => getEventsUrl(
                        'visitor|visitors|visits-list',
                        "list",
                        null,
                        gets
                    ),
                DETAIL:
                    (id, gets = null) => getEventsUrl(
                        'visitor|visitors|visits-detail',
                        "retrieve",
                        {id: id},
                        gets
                    ),
                CANCEL:
                    (id, gets = null) => getEventsUrl(
                        'visitor|visitors|visits-cancel',
                        "cancel",
                        {id: id},
                        gets
                    ),
                CREATE:
                    (gets = null) => getEventsUrl(
                        'visitor|visitors|visits-list',
                        "create",
                        null,
                        gets
                    ),
            },
            RECORDS: {
                LIST:
                    (visit_id, gets = null) => getEventsUrl(
                        'visitor|visitors|records-list',
                        "list",
                        {visit_id: visit_id},
                        gets
                    ),
                CANCEL:
                    (visit_id, id, gets = null) => getEventsUrl(
                        'visitor|visitors|records-cancel',
                        "cancel",
                        {visit_id: visit_id, id: id},
                        gets
                    ),
                CREATE:
                    (visit_id, gets = null) => getEventsUrl(
                        'visitor|visitors|records-list',
                        "create",
                        {visit_id: visit_id},
                        gets
                    ),
            },
            BLOCKS: {
                LIST:
                    (event_id, gets = null) => getEventsUrl(
                        'visitor|description|blocks-list',
                        "list",
                        {event_id: event_id},
                        gets
                    ),
                DETAIL:
                    (event_id, gets = null) => getEventsUrl(
                        'visitor|description|blocks-detail',
                        "retrieve",
                        {event_id: event_id},
                        gets
                    ),
            },
            FIELDS: {
                LIST:
                    (event_id, gets = null) => getEventsUrl(
                        'visitor|events|fields-list',
                        "list",
                        {event_id: event_id},
                        gets
                    ),
            },
            INVITES: {
                ACCEPT:
                    (invite, gets = null) => getEventsUrl(
                        'visitor|roles|invite-accept',
                        "get",
                        {invite: invite},
                        gets
                    ),
            },
        },
        LOGIN:
            (gets = null) => getEventsUrl(
                'visitor|visitors|login',
                "post",
                null,
                gets
              ),
    },
}
