<template>
  <v-container class="page-content pa-0" style="  width: 100%" >
    <div v-if="$route.name === names.EVENTS.INVITES.LIST">
      <v-toolbar
          class="removeBtnPaddingRight rounded-t-lg"
          color="#DADADA"
          flat
      >
        <!--        <v-text-field-->
        <!--            class="pb-1"-->
        <!--            single-line-->
        <!--            v-model="search.value" label="Поиск" hide-details @keydown.enter="searchData">-->
        <!--        </v-text-field>-->
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-data-table
          :headers="headers"
          :items="items"
          :loading="isLoading"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.size"
          :server-items-length="count"
          :options.sync="options"
          :footer-props="{'items-per-page-options':[10,50,100]}"
          class="elevation-1 rounded-b-lg"
      >
        <template v-slot:item.visitor.email="{ item }">
          <span v-if="item.visitor!==null"> {{ item.visitor.email }}</span>
          <span v-else> {{ item.email }}</span>
        </template>
        <template v-slot:item.send="{ item }">
          <span v-if="item.send!==null">{{ displayDate(item.send) }}</span>
        </template>
        <template v-slot:item.accepted="{ item }">
          <span v-if="item.accepted!==null">{{ displayDate(item.accepted) }}</span>
        </template>
        <template v-slot:item.is_accept="{ item }">
          <v-icon
              :color="checkFlag(item.is_accept).color"
          >
            {{checkFlag(item.is_accept).icon}}
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn
              color="primary"
              @click="updateData"
          >
            Обновить
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <router-view v-else></router-view>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import {displayDate} from "@/helper";
import viewer from "@/urls/roles/viewer";
import names_core from "@/modules/core/routers/names";
import names from "@/modules/viewer/routers/names";

export default {
  name: "ViewerEventsInvitesList",
  components: {},
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  data() {
    return {
      page_title: "Список приглашений",
      headers: [
        {text: 'ФИО', value: 'visitor.fio', sortable: false, align: 'start',  width: '30%'},
        {text: 'Почта', value: 'visitor.email', sortable: false, align: 'start', width: '30%'},
        {text: 'Дата приглашения', value: 'send', sortable: false, align: 'center', width: '15%'},
        {text: 'Дата подтверждения получения', value: 'accepted', sortable: false, align: 'center', width: '15%'},
        {text: 'Получено', value: 'is_accept', sortable: false, align: 'center', width: '10%'},
      ],
    }
  },
  computed: {
    ...mapGetters({
      items: 'viewer/getInvitesList',
      count: 'viewer/getInvitesCount',
      isLoading: 'viewer/isLoadingInvites'
    }),
    names: () => names,
  },
  methods: {
    ...mapActions({
      loadInvitesList: 'viewer/loadInvitesList',
      loadLightDetail: 'viewer/loadEventsLightDetail',
      changeEventDetailLoadingState: 'leftmenu/changeEventDetailLoadingState'
    }),
    displayDate: displayDate,
    updateData(){
      // if (this.search.value) {
      //   this.search.value = ''
      // }
      this.loadData()
    },
    loadData(url = undefined) {
      this.loadInvitesList({
        url: url,
        event_id: this.$route.params.idEvent,
        urlAppend: (url) => {
          return this.addPageParameter(this.addSizeParameter(url));
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
          this.changeEventDetailLoadingState({loading_state: false})
        }
      })
    },
    checkFlag(flag) {
      if (flag) return {icon: "check_circle", color: "green"}
      else return {icon: "not_interested", color: "gray"}
    },
    updatePageTitle() {
      this.loadLightDetail({
        id: this.$route.params.idEvent,
        finalizer: (data) => {
          this.page_title = this.page_title + ': ' + data.name
        }
      })
    },
  },
  created() {
    if (viewer.VIEWER.ALLOWED()){
      if(this.$route.name === names.EVENTS.INVITES.LIST){
        this.updatePageTitle()
        this.loadData()
      }
    } else{
      this.$router.push({name: names_core.MAIN})
    }
  },
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0;
}
</style>
