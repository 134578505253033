<template>
  <tr
      v-if="$vuetify.breakpoint.smAndUp"
      class="table-row"
      :class="bgColorIndex === 0 ? 'element--white' : 'element--grey'"
  >
    <td class="table-col table-col__date">
      <div class="slot-date">
        {{ getSlotDates() }}
      </div>
    </td>
    <td class="table-col table-col__time">
      <div class="slot-date">
        {{ deleteSecondsFromTime(item.start) + '-' + deleteSecondsFromTime(item.end) }}
      </div>
    </td>
    <td class="table-col table-col__name">
      {{ getSeatsText() }}
    </td>
    <td class="table-col table-col__button">
      <div class="flex-container--row-center">
        <v-tooltip left max-width="320px" v-if="item.crossing_event_name">
          <template v-slot:activator="{ on, attrs }">
            <div
                v-bind="attrs"
                v-on="on"
                class="button button--disabled"
                :class="getButtonFontSize()"
            >
              Запись недоступна
            </div>
          </template>
          <span>Данное время пересекается с одной из существующих записей на событие {{ item.crossing_event_name }}</span>
        </v-tooltip>
        <v-tooltip left max-width="320px" v-else-if="!hasVisit">
          <template v-slot:activator="{ on, attrs }">
            <div
                v-bind="attrs"
                v-on="on"
                class="button button--disabled"
                :class="getButtonFontSize()"
            >
              Запись недоступна
            </div>
          </template>
          <span>Необходимо записаться на основное событие</span>
        </v-tooltip>
        <v-tooltip left max-width="320px" v-else-if="item.is_default">
          <template v-slot:activator="{ on, attrs }">
            <div
                v-bind="attrs"
                v-on="on"
                class="button button--disabled"
                :class="getButtonFontSize()"
            >
              Отмена невозможна
            </div>
          </template>
          <span>Данная запись назначена организатором события и не может быть отменена</span>
        </v-tooltip>
        <div
            v-else-if="item.record_ended"
            class="button button--disabled"
            :class="getButtonFontSize()"
        >
          Сеанс завершён
        </div>
        <div
            v-else-if="item.record && !item.is_enter"
            class="button button__cancel--active"
            :class="getButtonFontSize()"
            @click="$emit('cancelRecord', item)"
        >
          Отменить запись
        </div>
        <div
            v-else-if="item.record && item.is_enter"
            class="button button--disabled"
            :class="getButtonFontSize()"
        >
          Отменить запись
        </div>
        <div
            v-else-if="item.seats_left > 0"
            class="button button__record button__record--active"
            :class="getButtonFontSize()"
            @click="$emit('createRecord', item)"
        >
          Запись
        </div>
        <div
            v-else-if="item.seats_left <= 0"
            class="button button__record button--disabled"
            :class="getButtonFontSize()"
        >
          Запись
        </div>
      </div>
    </td>
  </tr>
  <div v-else :class="bgColorIndex === 0 ? 'element--white' : 'element--grey'" class="container">
    <div class="flex-space-between" v-if="!breakPoint500">
      <div class="info-block">
        <div class="text-row date-row" :class="getRowClass()">
          <div class="text date-text" :class="getTextFontSize()">
            Дата записи
          </div>
          <div class="text-value date-value" :class="getTextFontSize()">
            {{ getSlotDates() }}
          </div>
        </div>
        <div class="text-row time-row" :class="getRowClass()">
          <div class="text time-text" :class="getTextFontSize()">
            Время записи
          </div>
          <div class="text-value time-value" :class="getTextFontSize()">
            {{ deleteSecondsFromTime(item.start) + '-' + deleteSecondsFromTime(item.end) }}
          </div>
        </div>
        <div class="text-row seats-row" :class="getRowClass()">
          <div class="text seats-text" :class="getTextFontSize()">
            Количество мест
          </div>
          <div class="text-value seats-value" :class="getTextFontSize()">
            {{ getSeatsText() }}
          </div>
        </div>
      </div>
      <div class="cancel-block">
        <v-tooltip left max-width="320px" v-if="!hasVisit">
          <template v-slot:activator="{ on, attrs }">
            <div
                v-bind="attrs"
                v-on="on"
                class="button button--disabled"
            >
              Запись недоступна
            </div>
          </template>
          <span>Необходимо записаться на основное событие</span>
        </v-tooltip>
        <v-tooltip left max-width="320px" v-else-if="item.is_default">
          <template v-slot:activator="{ on, attrs }">
            <div
                v-bind="attrs"
                v-on="on"
                class="button button--disabled"
            >
              Отмена невозможна
            </div>
          </template>
          <span>Данная запись назначена организатором события и не может быть отменена</span>
        </v-tooltip>
        <div
            v-else-if="item.record_ended"
            class="button button--disabled"
        >
          Сеанс завершён
        </div>
        <div
            v-else-if="item.record && !item.is_enter"
            class="button button__cancel--active"
            @click="$emit('cancelRecord', item)"
        >
          Отменить запись
        </div>
        <div
            v-else-if="item.record && item.is_enter"
            class="button button--disabled"
        >
          Отменить запись
        </div>
        <div
            v-else-if="item.seats_left > 0"
            class="button button__record button__record--active"
            @click="$emit('createRecord', item)"
        >
          Запись
        </div>
        <div
            v-else-if="item.seats_left <= 0"
            class="button button__record button--disabled"
        >
          Запись
        </div>
      </div>
    </div>
    <div class="text-row date-row" :class="getRowClass()" v-if="breakPoint500">
      <div class="text date-text" :class="getTextFontSize()">
        Дата записи
      </div>
      <div class="text-value date-value" :class="getTextFontSize()">
        {{ getSlotDates() }}
      </div>
    </div>
    <div class="text-row time-row" :class="getRowClass()" v-if="breakPoint500">
      <div class="text time-text" :class="getTextFontSize()">
        Время записи
      </div>
      <div class="text-value time-value" :class="getTextFontSize()">
        {{ deleteSecondsFromTime(item.start) + '-' + deleteSecondsFromTime(item.end) }}
      </div>
    </div>
    <div class="text-row seats-row" :class="getRowClass()" v-if="breakPoint500">
      <div class="text seats-text" :class="getTextFontSize()">
        Количество мест
      </div>
      <div class="text-value seats-value" :class="getTextFontSize()">
        {{ getSeatsText() }}
      </div>
    </div>
    <div class="cancel-row" v-if="breakPoint500">
      <v-tooltip top max-width="320px" v-if="!hasVisit">
        <template v-slot:activator="{ on, attrs }">
          <div
              v-bind="attrs"
              v-on="on"
              class="button button--disabled"
          >
            Запись недоступна
          </div>
        </template>
        <span>Необходимо записаться на основное событие</span>
      </v-tooltip>
      <v-tooltip bottom max-width="320px" v-else-if="item.is_default">
        <template v-slot:activator="{ on, attrs }">
          <div
              v-bind="attrs"
              v-on="on"
              class="button button--disabled"
          >
            Отмена невозможна
          </div>
        </template>
        <span>Данная запись назначена организатором события и не может быть отменена</span>
      </v-tooltip>
      <div
          v-else-if="item.record_ended"
          class="button button--disabled"
      >
        Сеанс завершён
      </div>
      <div
          v-else-if="item.record && !item.is_enter"
          class="button button__cancel--active"
          @click="$emit('cancelRecord', item)"
      >
        Отменить запись
      </div>
      <div
          v-else-if="item.record && item.is_enter"
          class="button button--disabled"
      >
        Отменить запись
      </div>
      <div
          v-else-if="item.seats_left > 0"
          class="button button__record button__record--active"
          @click="$emit('createRecord', item)"
      >
        Запись
      </div>
      <div
          v-else-if="item.seats_left <= 0"
          class="button button__record button--disabled"
      >
        Запись
      </div>
    </div>
  </div>
</template>

<script>
import {displayDate} from "@/helper";

export default {
  name: "SingleSlot",
  props: {
    item: Object,
    bgColorIndex: Number,
    hasVisit: Boolean,
  },
  data() {
    return {
      page_width: window.screen.availWidth,
    }
  },
  computed: {
    breakPoint595() {
      return this.page_width < 595
    },
    breakPoint545() {
      return this.page_width < 545
    },
    breakPoint500() {
      return this.page_width < 500
    },
    breakPoint350() {
      return this.page_width < 350
    },
    breakPoint640() {
      return this.page_width < 640
    },
  },
  methods: {
    getSeatsText() {
      if (this.item.is_default) return 'Без ограничений';
      return this.item.seats_left + '/' + this.item.count;
    },
    resizeHandler(e) {
      this.page_width = e.target.screen.availWidth
    },
    getSlotDates() {
      if (this.item.interval_date) {
        return this.displayDate(this.item.interval_date.start) + '-' + this.displayDate(this.item.interval_date.end)
      } else {
        return this.displayDate(this.item.record_date)
      }
    },
    getButtonFontSize() {
      if (this.breakPoint640) {
        return ' font-size-14'
      }
      return ''
    },
    getTextFontSize() {
      if (this.breakPoint350) {
        return ' font-size-14'
      }
      if (this.breakPoint500) {
        return ' font-size-16'
      }
      if (this.breakPoint545) {
        return ' font-size-14'
      }
      if (this.breakPoint595) {
        return ' font-size-16'
      }
      return ""
    },
    getRowClass() {
      if (this.breakPoint500) {
        return ' width-100'
      }
      return ""
    },
    displayDate: displayDate,
    deleteSecondsFromTime(time_string) {
      let time_array = time_string.split(':');
      return time_array[0] + ':' + time_array[1];
    },
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
}
</script>

<style scoped lang="scss">
.table-col {
  padding: 10px 0;
  @media (min-width: 1713px) {
    padding: 15px 0;
  }
}

.table-col__date {
  width: 23%;
  padding: 0 2%;
}

.table-col__time {
  width: 23%;
}

.table-col__seats {
  width: 23%;
}

.table-col__button {
  width: 31%;
  padding: 0 2%;
}

.element--white {
  background-color: white;
}

.element--grey {
  background-color: #F6F6F6;
}

.event-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #1D71B8;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.slot-date {
  color: #676767;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.flex-container--row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  border-radius: 45px;
  width: 174px;
  border: 1px solid #1D71B8;
  color: #1D71B8;
  height: 40px;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-transform: none;
}

.button__record {
  padding: 0 30px;
}

.button__record--active {
  cursor: pointer;

  &:hover, &:active {
    background: #1D71B8;
    color: white;
  }
}

.button__cancel--active {
  border-radius: 44px;
  border-color: #D64141;
  color: #D64141;
  cursor: pointer;

  &:hover, &:active {
    color: white;
    background: #D64141;
  }
}

.button--disabled {
  color: white;
  border: 1px solid #CECECE;
  background: #CECECE;

  &:hover {
    color: white;
    background: #CECECE;
    cursor: default;
  }
}

.container {
  padding: 20px 26px;
}

.flex-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.text-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: calc(50vw - 26px * 2);
  min-width: calc(56vw - 26px * 2);
  color: #7C7C7C;
  font-family: 'MADE Evolve Sans normal', serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-right: 10px;
}

.cancel-row {
  margin-top: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.text, .text-value {
  font-size: 18px;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.width-100 {
  max-width: 100vw !important;
}

.date-row, .time-row {
  margin-bottom: 18px;
}

.text-value {
  font-weight: 400;
}

.info-block {

}

.cancel-block {
  max-width: calc(50vw - 26px * 2);
  min-width: calc(50vw - 26px * 2);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
</style>
