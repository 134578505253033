<template>
  <v-container class="page-content px-5" style="  width: 100%" >
    <div v-if="$route.name === names.EVENTS.LIST">
      <v-toolbar
          class="removeBtnPaddingRight rounded-t-lg"
          color="#DADADA"
          flat
      >
        <v-text-field
            v-model="search.value"
            class="dark-primary my-1"
            clearable
            dense
            outlined
            label="Поиск"
            hide-details
            @keydown.enter="searchData"
            @click:clear="clearSearchData"
        ></v-text-field>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-data-table
          :headers="headers"
          :items="items"
          :loading="isLoading"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.size"
          :server-items-length="count"
          :options.sync="options"
          :footer-props="{'items-per-page-options':[10,50,100]}"
          class="elevation-1 rounded-b-lg"
      >
        <template v-slot:item.name="{ item }">
          <span style="text-decoration: underline; color: #065aad; cursor: pointer" @click="getDetail(item.id)">{{item.name}}</span>
        </template>
        <template v-slot:item.record_start="{ item }">
          <span v-if="item.record_start!==null">{{ correctTime(item.record_start, true) }}</span>
        </template>
        <template v-slot:item.record_end="{ item }">
          <span v-if="item.record_end!==null">{{ correctTime(item.record_end, true) }}</span>
        </template>
        <template v-slot:item.start="{ item }">
          <span v-if="item.start!==null">{{ displayDate(item.start) }}</span>
        </template>
        <template v-slot:item.end="{ item }">
          <span v-if="item.end!==null">{{ displayDate(item.end) }}</span>
        </template>
        <template v-slot:no-data>
          <v-btn
              color="primary"
              @click="updateData"
          >
            Обновить
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <router-view v-else></router-view>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import names from '@/modules/operator/routers/names';
import {displayDate} from "@/helper";
import operator from "@/urls/roles/operator";
import names_core from "@/modules/core/routers/names";

export default {
  name: "OperatorEventsList",
  components: {},
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  data() {
    return {
      page_title: "Список событий",
      search: {
        parameter: 'name',
      },
      headers: [
        {text: 'Название', value: 'name', sortable: false, align: 'start', width: '40%'},
        {text: 'Дата начала регистрации', value: 'record_start', sortable: false, align: 'center', width: '15%'},
        {text: 'Дата конца регистрации', value: 'record_end', sortable: false, align: 'center', width: '15%'},
        {text: 'Дата начала события', value: 'start', sortable: false, align: 'center', width: '15%'},
        {text: 'Дата конца события', value: 'end', sortable: false, align: 'center', width: '15%'},
      ],
    }
  },
  computed: {
    ...mapGetters({
      items: 'operator/getEventsList',
      count: 'operator/getEventsCount',
      isLoading: 'operator/isLoadingEvents'
    }),
    names: () => names,
  },
  watch: {
  },
  methods: {
    ...mapActions({
      loadEventsList: 'operator/loadEventsList',
    }),
    displayDate: displayDate,
    correctTime (time, minute = false) {
      if(time!==null) {
        return this.displayDate(time, minute)
      }
    },
    getDetail(id) {
      this.$router.push({name: names.EVENTS.DETAIL, params: {idEvent: id}})
    },
    updateData(){
      if (this.search.value) {
        this.search.value = ''
      }
      this.loadData()
    },
    loadData(url = undefined) {
      this.loadEventsList({
        url: url,
        urlAppend: (url) => {
          let new_url = this.addPageParameter(this.addSizeParameter(url))
          if (this.search.value)
            new_url = this.addGetParameter(new_url, 'name', this.search.value)
          return new_url
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
        }
      })
    },
  },
  beforeRouteUpdate(to, from, next){
    next()
    if (operator.OPERATOR.ALLOWED() && this.$route.name === names.EVENTS.LIST){
      this.loadData()
    }
  },
  created() {
    if (operator.OPERATOR.ALLOWED()){
      if(this.$route.name === names.EVENTS.LIST){
        this.loadData()
      }
    } else{
      this.$router.push({name: names_core.MAIN})
    }
  },
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0;
}
</style>
