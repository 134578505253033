const accounts = String(process.env.VUE_APP_ACCOUNTS_PREFIX).replace('/', '');
const ACCOUNT_HOST = process.env.VUE_APP_API_HOST + process.env.VUE_APP_ACCOUNTS_PREFIX.replace('/', '');
const ORGANIZATION_HOST = process.env.VUE_APP_API_HOST + process.env.VUE_APP_ORGANIZATION_PREFIX.replace('/', '');
const EVENT_HOST = process.env.VUE_APP_API_HOST + process.env.VUE_APP_EVENTS_PREFIX.replace('/', '');

const ACCOUNTS_INSTANCE = String(process.env.VUE_APP_ACCOUNTS_PREFIX).replace('/', '');
const ORGANIZATION_INSTANCE = String(process.env.VUE_APP_ORGANIZATION_PREFIX).replace('/', '');
const EVENTS_INSTANCE = String(process.env.VUE_APP_EVENTS_PREFIX).replace('/', '');

export {
    ACCOUNT_HOST,
    ACCOUNTS_INSTANCE,
    ORGANIZATION_HOST,
    ORGANIZATION_INSTANCE,
    EVENT_HOST,
    EVENTS_INSTANCE
}
export default {
    accounts,
}
