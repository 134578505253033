<template>
  <div>
    <v-expansion-panels
        v-model="panel"
        multiple
    >
      <v-expansion-panel class="rounded-0">
        <v-expansion-panel-header class="text-h6 font-weight-bold">
          Список дополнительных полей при регистрации
          <template v-slot:actions>
            <v-icon color="primary">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-toolbar
              class="removeBtnPaddingRight rounded-t-lg"
              color="#DADADA"
              flat
          >
            <!--            <v-text-field-->
            <!--                class="pb-1"-->
            <!--                single-line-->
            <!--                v-model="search.value" label="Поиск" hide-details @keydown.enter="searchData">-->
            <!--            </v-text-field>-->
            <v-spacer></v-spacer>
            <v-toolbar-items v-if="$vuetify.breakpoint.smAndUp && isAdminOrOrganiser() && parentEventIsEditable">
              <v-btn
                  v-if="this[`countFields${this.role}`] > 1"
                  text
                  @click="changeOrder"
                  class="dark-primary mr-0 rounded-tr-lg"
              >
                Изменить порядок
              </v-btn>
              <v-btn
                  text
                  @click="newField"
                  class="dark-primary mr-0 rounded-tr-lg"
              >
                Добавить поле
              </v-btn>
            </v-toolbar-items>
            <v-btn
                v-if="$vuetify.breakpoint.xsOnly && isAdminOrOrganiser() && parentEventIsEditable && this[`countFields${this.role}`] > 1"
                icon
                class="dark-primary mr-n3"
                @click="changeOrder"
            >
              <v-icon>sync_alt</v-icon>
            </v-btn>
            <v-btn
                v-if="$vuetify.breakpoint.xsOnly && isAdminOrOrganiser() && parentEventIsEditable"
                icon
                class="dark-primary mr-0"
                @click="newField"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </v-toolbar>
          <v-data-table
              :headers="headers"
              :items="this[`itemsFields${this.role}`]"
              :loading="this[`isLoadingFields${this.role}`]"
              :page.sync="pagination.page"
              :items-per-page.sync="pagination.size"
              :server-items-length="this[`countFields${this.role}`]"
              :options.sync="options"
              :footer-props="{'items-per-page-options':[10,50,100]}"
              class="elevation-1 rounded-b-lg"
          >
            <template v-slot:item.name="{ item }">
              <span v-if="item.field_type === 'Флаг'">{{ item.settings.text }}</span>
              <span v-else-if="item.name">{{ item.name }}</span>
            </template>
            <template v-slot:item.help_text="{ item }">
              <span v-if="item.field_type === 'Флаг'">-</span>
              <span v-else-if="item.help_text">{{ item.help_text }}</span>
            </template>
            <template v-slot:item.is_required="{ item }">
              <v-icon
                  :color="checkFlag(item.is_required).color"
              >
                {{ checkFlag(item.is_required).icon }}
              </v-icon>
            </template>
            <template v-slot:item.default="{ item }">
              <span v-if="item.field_type === 'Дата'">
                {{ item.default ? displayDate(item.default) : '' }}
              </span>
              <span v-else-if="item.field_type === 'Дата время'">
                {{ item.default ? displayDate(item.default, true) : '' }}
              </span>
              <span v-else-if="item.field_type === 'Список строк'"
                    v-for="(obj, index) in item.default"
                    :key="index"
              >
                {{ obj }} <br/>
              </span>
              <v-tooltip v-else-if="item.field_type === 'Флаг' && item.file" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <a :href="getFile(item)" target="_blank">
                    <v-icon
                      class="dark-primary ml-1 mr-3"
                      v-bind="attrs"
                      v-on="on"
                    >
                      download
                    </v-icon>
                  </a>
                </template>
                <span>Скачать файл</span>
              </v-tooltip>
              <span v-else>
                {{ item.default }}
              </span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom v-if="canChange(item) && parentEventIsEditable">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      class="mr-2"
                      @click="editField(item.id)"
                      v-bind="attrs"
                      v-on="on"
                  >
                    edit
                  </v-icon>
                </template>
                <span>Редактировать</span>
              </v-tooltip>
              <v-tooltip bottom v-if="isAdminOrOrganiser(item) && parentEventIsEditable">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      @click="deleteField(item.id)"
                      v-bind="attrs"
                      v-on="on"
                  >
                    delete
                  </v-icon>
                </template>
                <span>Удалить</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data>
              <v-btn
                  color="primary"
                  @click="updateDataFields"
              >
                Обновить
              </v-btn>
            </template>
          </v-data-table>
          <FieldsOrderEditDialogAdmin
              v-if="dialog_change_order && isAdmin()"
              :opened="dialog_change_order"
              @close="closeChangeOrderDialog"
          ></FieldsOrderEditDialogAdmin>
          <FieldsOrderEditDialogOrganiser
              v-if="dialog_change_order && isOrganiser()"
              :opened="dialog_change_order"
              @close="closeChangeOrderDialog"
          ></FieldsOrderEditDialogOrganiser>
          <EditDialogAdmin
              v-if="dialog_add_edit && isAdmin()"
              :opened="dialog_add_edit"
              :edit="edit_status"
              :field-id="field_id"
              @close="closeEditDialog"
          ></EditDialogAdmin>
          <EditDialogOrganiser
              v-if="dialog_add_edit && isOrganiser()"
              :opened="dialog_add_edit"
              :edit="edit_status"
              :field-id="field_id"
              @close="closeEditDialog"
          ></EditDialogOrganiser>
          <ApplyDialog
              v-if="dialog_delete && isAdminOrOrganiser()"
              :opened="dialog_delete"
              :get-text="() => `Вы уверены, что хотите удалить поле?`"
              @close="closeDeleteDialog"
          ></ApplyDialog>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import EditDialogAdmin from "@/modules/admin/events/modals/FieldsEditDialog";
import EditDialogOrganiser from "@/modules/organiser/events/modals/FieldsEditDialog";
import ApplyDialog from "@/modules/core/components/ApplyDialog";
import {displayDate} from "@/helper";
import admin from "@/urls/roles/admin";
import organiser from "@/urls/roles/organiser";
import FieldsOrderEditDialogAdmin from "@/modules/admin/events/modals/FieldsOrderEditDialog.vue";
import FieldsOrderEditDialogOrganiser from "@/modules/organiser/events/modals/FieldsOrderEditDialog.vue";

export default {
  name: "EventDetailFieldsList",
  props: {
    role:{
      type: String,
      // default: 'Admin',
    },
    needReloadListFields: {
      type: Boolean,
    },
    organiserAllowUpdate:{
      type: Boolean,
    },
    parentEventIsEditable: {
      required: false,
      type: Boolean,
      default: true
    },
  },
  components: {
    FieldsOrderEditDialogAdmin,
    FieldsOrderEditDialogOrganiser,
    EditDialogAdmin,
    EditDialogOrganiser,
    ApplyDialog
  },
  mixins: [PaginatedDataMapperMixin],
  data() {
    return {
      dialog_add_edit: false,
      dialog_delete: false,
      dialog_change_order: false,
      edit_status: undefined,
      field_id: undefined,
      panel: [0, 1],
      // search: {
      //   parameter: 'name',
      // },
      headers: [
        {text: 'Название', value: 'name', sortable: false, align: 'start', width: '20%'},
        {text: 'Подсказка по заполнению', value: 'help_text', sortable: false, align: 'start', width: '30%'},
        {text: 'Тип поля', value: 'field_type', sortable: false, align: 'start', width: '15%'},
        {text: 'Обязательное', value: 'is_required', sortable: false, align: 'center', width: '10%'},
        {text: 'Значение по умолчанию', value: 'default', sortable: false, align: 'start', width: '15%'},
        {text: 'Действия', value: 'actions', sortable: false, align: 'center', width: '10%'},
      ],
    }
  },
  watch: {
    needReloadListFields(){
      if(this.needReloadListFields && !this.dialog_add_edit){
        this.loadData()
        this.$emit("notNeedReloadListFields", !this.needReloadListFields)
      }
    }
  },
  computed: {
    ...mapGetters({
      itemsFieldsAdmin: 'admin/getFieldsList',
      countFieldsAdmin: 'admin/getFieldsCount',
      isLoadingFieldsAdmin: 'admin/isLoadingFields',
      eventLightDetailAdmin: 'admin/getEventsLightDetail',
      itemsFieldsViewer: 'viewer/getFieldsList',
      countFieldsViewer: 'viewer/getFieldsCount',
      isLoadingFieldsViewer: 'viewer/isLoadingFields',
      itemsFieldsOrganiser: 'organiser/getFieldsList',
      countFieldsOrganiser: 'organiser/getFieldsCount',
      isLoadingFieldsOrganiser: 'organiser/isLoadingFields',
      eventLightDetailOrganiser: 'organiser/getEventsLightDetail',
    }),
  },
  methods: {
    ...mapActions({
      loadFieldsListAdmin: 'admin/loadFieldsList',
      deleteFieldsAdmin: 'admin/deleteFields',
      loadFieldsListViewer: 'viewer/loadFieldsList',
      loadFieldsListOrganiser: 'organiser/loadFieldsList',
      deleteFieldsOrganiser: 'organiser/deleteFields',
    }),
    displayDate: displayDate,
    updateDataFields(){
      // if (this.search.value) {
      //   this.search.value = ''
      // }
      this.loadData()
    },
    checkFlag(flag) {
      if (flag) return {icon: "check_circle", color: "green"}
      else return {icon: "not_interested", color: "gray"}
    },
    getFile(value){
      let urls = {
        basename: value.file.name,
        action: value.file.action,
        params: value.file.params,
      }
      if(this.isAdmin)
        return admin.getByRecievedUrlData(urls, value.file.get_params)
      else
        return organiser.getByRecievedUrlData(urls, value.file.get_params)
    },
    loadData(url = undefined) {
      this[`loadFieldsList${this.role}`]({
        url: url,
        event_id: this.$route.params.idEvent,
        urlAppend: (url) => {
          return this.addPageParameter(this.addSizeParameter(url));
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
        }
      })
    },
    changeOrder() {
      this.dialog_change_order = true;
    },
    closeChangeOrderDialog() {
      this.loadData()
      this.dialog_change_order = false
    },
    newField() {
      this.dialog_add_edit = true
      this.edit_status = false
      this.field_id = undefined
    },
    editField(id) {
      this.dialog_add_edit = true
      this.edit_status = true
      this.field_id = id
    },
    deleteField(item) {
      this.dialog_delete = true
      this.field_id = item
    },
    closeEditDialog(field) {
      this.loadData()
      this.dialog_add_edit = false
      this.field_id= undefined
    },
    closeDeleteDialog(field) {
      if (field) {
        this[`deleteFields${this.role}`]({
          event_id: this.$route.params.idEvent,
          id: this.field_id,
          finalizer: (val) => {
            this.loadData()
          },
          catcher: (val) => {
            this.mapErrors(val)
          },
        })
      }
      this.dialog_delete = false
      this.field_id = undefined
    },
    canChange(item){
      if(this.role==='Admin' || (this.role==='Organiser' && this.organiserAllowUpdate)){
        if(!this[`eventLightDetail${this.role}`].have_visits)
          return true
        else if(this[`eventLightDetail${this.role}`].have_visits && !item.is_required)
          return true
        else
          return false
      }
      else
        return false
    },
    isAdminOrOrganiser(){
      if(this.role==='Admin' || (this.role==='Organiser' && this.organiserAllowUpdate))
        return true
      else
        return false
    },
    isAdmin(){
      if(this.role==='Admin')
        return true
      else
        return false
    },
    isOrganiser(){
      if(this.role==='Organiser' && this.organiserAllowUpdate)
        return true
      else
        return false
    },
  },
  created() {
    this.loadData()
  },
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0px;
}
</style>
