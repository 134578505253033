import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify';
import ru from "vuetify/es5/locale/ru";
import en from "vuetify/es5/locale/en";
import CheckCircleTrue from "@/assets/icons/CheckCircleTrue.vue";
import CheckCircleFalse from "@/assets/icons/CheckCircleFalse.vue";
import CancelTrue from "@/assets/icons/CancelTrue.vue";
import CancelFalse from "@/assets/icons/CancelFalse.vue";
import Location from "@/assets/icons/Location.vue";
import ArrowBack from "@/assets/icons/ArrowBack.vue";
import ExpandedFalse from "@/assets/icons/ExpandedFalse.vue";
import TooltipQuestionMark from "@/assets/icons/TooltipQuestionMark.vue";


Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'md',
        values: { // добавление кастомных иконок
            check_circle_true: { // название иконки через #vuetify.icons.<>
                component: CheckCircleTrue, // компонент иконки
            },
            check_circle_false: {
                component: CheckCircleFalse,
            },
            cancel_true: {
                component: CancelTrue,
            },
            cancel_false: {
                component: CancelFalse,
            },
            location: {
                component: Location,
            },
            arrow_back: {
                component: ArrowBack,
            },
            expanded_false: {
                component: ExpandedFalse,
            },
            tooltip_question_mark: {
                component: TooltipQuestionMark,
            }
        },
    },
    lang: {
        locales: {ru, en},
        current: 'ru',
    }
});
