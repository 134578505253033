<template>
  <div>
    <slot name="btn"></slot>
    <v-card-title
        v-if="!edit"
        class="pa-0 ma-0 bold text-left"
        style="word-break: keep-all!important;"
        :class="data.data.data.header ? 'mb-5' : 'mb-0'"
    >
      {{ data.data.data.header }}
    </v-card-title>
    <v-text-field
        v-else
        outlined
        class="pa-0 ma-0 text-left"
        v-model="data.data.data.header"
    >
      <template v-slot:label>
        Заголовок
      </template>
    </v-text-field>
    <v-card-text
        v-if="!edit"
        class="pa-0 ma-0 text-left"
    >
      {{ data.data.data.text }}
    </v-card-text>
    <v-textarea
        v-else
        outlined
        rows="5"
        no-resize
        v-model="data.data.data.text"
        :error="data.errors.text.length > 0"
        :error-messages="data.errors.text"
    >
      <template v-slot:label>
        Текст<span class="red--text"> *</span>
      </template>
    </v-textarea>
  </div>
</template>

<script>

export default {
  props: {
    edit: {
      type: Boolean,
      default: true
    },
    data: Object,
  },
  name: "TextBlock",
}
</script>

<style scoped>

</style>
