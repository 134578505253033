import {sendPostRequest} from "@/helper/requests";
import visitor from "@/urls/roles/visitor";

const state = {
  visitor: {
    login: [],
    token: null,
    error: null,
  },
}

const getters = {
  getVisitorLogin: state => state.visitor.login,
  getVisitorToken: state => state.visitor.token,
}

const actions = {
  loginVisitorViaEvent: ({commit}, {token}) => {
    commit('SAVE_VISITOR_TOKEN', token)
  },
  loginVisitor:  ({commit}, {data, finalizer=()=>{}, catcher=()=>{}}) => {
    let url =  visitor.VISITOR.LOGIN()
    sendPostRequest(
      url,
      data,
      (data) => {
        commit(`SAVE_VISITOR_TOKEN`, data.EventToken);
        finalizer(data)
      },
      (data) => {
        commit(`SAVE_VISITOR_ERROR`, data);
        catcher(data);
      },
    )
  },
}

const mutations = {
  SET_VISITOR_LOGIN: (state, value) => {
    state.visitor.login = value
  },
  SAVE_VISITOR_TOKEN: (state, value) => {
    state.visitor.token = value
  },
  SAVE_VISITOR_ERROR: (state, error) => {
    state.visitor.error = error
  }
}

export default {
  namespaced: false,
  state,
  mutations,
  actions,
  getters
}
