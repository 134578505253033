<template>
  <div>
    <v-expansion-panels
        v-model="panel"
        multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="text-h6 font-weight-bold">
          Список дополнительных данных, заполненных посетителем при регистрации
          <template v-slot:actions>
            <v-icon color="primary">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-toolbar
              class="removeBtnPaddingRight rounded-t-lg"
              color="#DADADA"
              flat
          >
            <!--            <v-text-field-->
            <!--                class="pb-1"-->
            <!--                single-line-->
            <!--                v-model="search.value" label="Поиск" hide-details @keydown.enter="searchData">-->
            <!--            </v-text-field>-->
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-data-table
              :headers="headers"
              :items="this[`itemsData${this.role}`]"
              :loading="this[`isLoadingData${this.role}`]"
              :page.sync="pagination.page"
              :items-per-page.sync="pagination.size"
              :server-items-length="this[`countData${this.role}`]"
              :options.sync="options"
              :footer-props="{'items-per-page-options':[10,50,100]}"
              class="elevation-1 rounded-b-lg"
          >
            <template v-slot:item.value="{ item }">
              <span v-if="item.field.field_type === 1"> {{ displayDate(item.value, true) }} </span>
              <span v-else-if="item.field.field_type === 2"> {{ displayDate(item.value) }} </span>
              <span v-else-if="item.field.field_type === 4" v-html="item.value.join('<br/>')"></span>
              <v-icon
                  v-else-if="item.field.field_type === 8"
                  :color="checkFlag(item.value).color"
              >
                {{ checkFlag(item.value).icon }}
              </v-icon>
              <span v-else> {{ item.value}} </span>
            </template>
            <template v-slot:item.field.name="{ item }">
              {{ item.field.field_type !== 8 ? item.field.name : item.field.check_box_text }}
            </template>
            <template v-slot:item.is_required="{ item }">
              <v-icon
                  :color="checkFlag(item.field.is_required).color"
              >
                {{ checkFlag(item.field.is_required).icon }}
              </v-icon>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip
                  bottom
                  v-if="isAdminOrOrganiser()
                  && !(item.field.is_required && item.field.field_type === 8)
                  && !(lightDetail.is_end && !lightDetail.is_draft)
                  && (isOrganiser() ? lightDetail.allow_update : true)"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      class="mr-2"
                      @click="editData(item.id)"
                      v-bind="attrs"
                      v-on="on"
                  >
                    edit
                  </v-icon>
                </template>
                <span>Редактировать</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data>
              <v-btn
                  color="primary"
                  @click="updateDataData"
              >
                Обновить
              </v-btn>
            </template>
          </v-data-table>
          <EditDialogAdmin
              v-if="dialog_add_edit && isAdmin()"
              :opened="dialog_add_edit"
              :edit="edit_status"
              :data-id="data_id"
              @close="closeEditDialog"
          ></EditDialogAdmin>
          <EditDialogOrganiser
              v-if="dialog_add_edit && isOrganiser()"
              :opened="dialog_add_edit"
              :edit="edit_status"
              :data-id="data_id"
              @close="closeEditDialog"
          ></EditDialogOrganiser>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import EditDialogAdmin from "@/modules/admin/events/modals/DataEditDialog";
import EditDialogOrganiser from "@/modules/organiser/events/modals/DataEditDialog";
import {displayDate} from "@/helper";

export default {
  name: "VisitDetailDataList",
  props: {
    role:{
      type: String,
      // default: 'Admin',
    },
    lightDetail: Object,
  },
  components: {EditDialogAdmin, EditDialogOrganiser},
  mixins: [PaginatedDataMapperMixin],
  data() {
    return {
      dialog_add_edit: false,
      edit_status: undefined,
      data_id: undefined,
      panel: [0, 1],
      // search: {
      //   parameter: 'name',
      // },
      headers: [
        {text: 'Значение', value: 'value', sortable: false, align: 'start', width: '30%'},
        {text: 'Название поля', value: 'field.name', sortable: false, align: 'start', width: '30%'},
        {text: 'Обязательное', value: 'is_required', sortable: false, align: 'center', width: '10%'},
        {text: 'Действия', value: 'actions', sortable: false, align: 'center', width: '10%'},
      ],
    }
  },
  watch: {},
  computed: {
    ...mapGetters({
      itemsDataAdmin: 'admin/getDataList',
      countDataAdmin: 'admin/getDataCount',
      isLoadingDataAdmin: 'admin/isLoadingData',
      itemsDataViewer: 'viewer/getDataList',
      countDataViewer: 'viewer/getDataCount',
      isLoadingDataViewer: 'viewer/isLoadingData',
      itemsDataOrganiser: 'organiser/getDataList',
      countDataOrganiser: 'organiser/getDataCount',
      isLoadingDataOrganiser: 'organiser/isLoadingData',
    }),
  },
  methods: {
    ...mapActions({
      loadDataListAdmin: 'admin/loadDataList',
      loadDataListViewer: 'viewer/loadDataList',
      loadDataListOrganiser: 'organiser/loadDataList',
    }),
    displayDate: displayDate,
    updateDataData(){
      // if (this.search.value) {
      //   this.search.value = ''
      // }
      this.loadData()
    },
    checkFlag(flag) {
      if (flag) return {icon: "check_circle", color: "green"}
      else return {icon: "not_interested", color: "gray"}
    },
    loadData(url = undefined) {
      this[`loadDataList${this.role}`]({
        url: url,
        urlAppend: (url) => {
          return this.addPageParameter(this.addSizeParameter(url))
        },
        visit_id: this.$route.params.idVisit,
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
        }
      })
    },
    editData(id) {
      this.dialog_add_edit = true
      this.edit_status = true
      this.data_id = id
    },
    closeEditDialog(data) {
      this.loadData()
      this.dialog_add_edit = false
      this.data_id= undefined
    },
    isAdminOrOrganiser(){
      if(this.role==='Admin' || this.role==='Organiser')
        return true
      else
        return false
    },
    isAdmin(){
      if(this.role==='Admin')
        return true
      else
        return false
    },
    isOrganiser(){
      if(this.role==='Organiser')
        return true
      else
        return false
    },
  },
  created() {
    this.loadData()
  },
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0px;
}
</style>
