<template>
  <scroll-center-dialog v-model="opened" :loading="save_loading" @close="close" @apply="save" :title="formTitle"
                        :scrolled="false" width="800px" class="align-content-center">
    <div v-if="loading" class="px-3">
      <loading-autocomplete
          :outlined="true"
          v-if="!this.edit"
          placeholder="Введите ФИО и выберите из предложенных вариантов"
          :label="`Работник`"
          v-model="selected_user"
          :required="true"
          :return-object="true"
          search-parameter="fio"
          :load-fios="false"
          :url="userSelector"
          :error="hasError('user')"
          :errorMessages="getError('user')"
          class="mt-4 mb-0"
      >
      </loading-autocomplete>
      <div
          class="mt-0 pt-0 mb-5 pl-3"
          :class="edit ? 'mt-6' : 'mt-0'"
          v-if="user.user"
      >
        <v-row
            class="text-h6"
            style="white-space: nowrap; text-align: start;"
        >Глобальные роли</v-row>
        <v-checkbox
            v-model="user.is_admin"
            label="Администратор"
            :error="hasError('is_admin')"
            :error-messages="getError('is_admin')"
            class="pt-0 mt-5"
            hide-details
        ></v-checkbox>
        <v-checkbox
            v-model="user.is_full_viewer"
            label="Наблюдатель"
            :error="hasError('is_full_viewer')"
            :error-messages="getError('is_full_viewer')"
            class="pt-0 mt-3"
            hide-details
        ></v-checkbox>
      </div>
      <v-divider></v-divider>
      <edit-dialog-table
          v-if="user.user"
          :delete-all="delete_all"
          :edit="edit"
          :user="user"
          @changeDeleteAllState="changeParentDeleteAllState"
      ></edit-dialog-table>
    </div>
    <div class="action mt-8 mb-4 align-center" v-else>
      <semipolar-spinner
          class="d-inline-block"
          :animation-duration="2000"
          :size="115"
          color="#1935ff"
      />
    </div>
  </scroll-center-dialog>
</template>


<script>

import selectors from "@/urls/selectors";
import LoadingAutocomplete from "@/modules/core/components/LoadingAutocomplete";
import FormErrorsMixin from "@/mixins/FormErrorsMixin"
import {mapActions, mapGetters} from "vuex";
import ScrollCenterDialog from "@/modules/templates/ScrollCenterDialog";
import {SemipolarSpinner} from "epic-spinners";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import EditDialogTable from "@/modules/admin/roles/components/EditDialogTable.vue";

export default {
  name: "RolesEditDialog",
  props: {
    editingExpandedItem: Boolean,
    opened: Boolean,
    edit: Boolean,
    userId: Number,
  },
  mixins: [FormErrorsMixin, PaginatedDataMapperMixin],
  components: {
    EditDialogTable,
    ScrollCenterDialog,
    LoadingAutocomplete,
    SemipolarSpinner,
  },
  data() {
    return {
      loading: false,
      save_loading: false,
      delete_all: false,
      create: [],
      delete: [],
      update: [],
      search: {
        parameter: 'name',
      },
      selected_user: null,
      user_default: {
        fio: '',
        user: '',
        is_admin: false,
        is_full_viewer: false,
        events: [],
      },
      user: {},
      user_before_changes: null,
      events_headers: [
        {text: 'Событие', value: 'event.name', sortable: true, width: '45%', align: 'start'},
        {text: 'Организатор', value: 'is_organiser', sortable: false, width: '15%', align: 'center'},
        {text: 'Оператор', value: 'is_operator', sortable: false, width: '15%', align: 'center'},
        {text: 'Наблюдатель', value: 'is_viewer', sortable: false, width: '15%', align: 'center'},
        {text: '', value: 'actions', sortable: false, width: '10%', align: 'center'},
      ],
    }
  },
  computed: {
    ...mapGetters({
      rolesDetail: 'admin/getRolesDetail',
    }),
    formTitle() {
      if (!this.edit) {
        return 'Назначить пользователя'
      } else if (this.user) {
        return this.user.fio
      } else {
        return ''
      }
    },
    userSelector() {
      return selectors.SELECTORS.USERS.ALL()
    },
  },
  watch: {
    'user.is_admin': {
      handler(newVar) {
        if (newVar && this.user.is_full_viewer) {
          this.user.is_full_viewer = false;
        }
      }
    },
    'user.is_full_viewer': {
      handler(newVar) {
        if (newVar && this.user.is_admin) {
          this.user.is_admin = false;
        }
      }
    },
    selected_user() {
      this.user.user = this.selected_user.value;
      this.user.fio = this.selected_user.text;
    },
  },
  methods: {
    ...mapActions({
      createRoles: 'admin/createRoles',
      updateRoles: 'admin/changeRoles',
      loadRolesDetail: 'admin/loadRolesDetail',
    }),
    loadUserData(url = undefined) {
      if (this.userId === undefined) {
        // Создание копие объекта с большой вложенностью ( Object.assign не работает )
        this.user = JSON.parse( JSON.stringify(this.user_default));
        this.user_before_changes = JSON.parse( JSON.stringify(this.user));
        this.loading = true
      }
      else if (!this.editingExpandedItem) {
        this.loadRolesDetail({
          url: url,
          id: this.userId,
          finalizer: () => {
            this.user = JSON.parse( JSON.stringify(this.rolesDetail));
            this.user_before_changes = JSON.parse( JSON.stringify(this.user));
            this.loading = true;
          }
        })
      } else {
        this.user = JSON.parse( JSON.stringify(this.rolesDetail));
        this.user_before_changes = JSON.parse( JSON.stringify(this.user));
        this.loading = true;
      }
    },
    changeParentDeleteAllState(value) {
      this.delete_all = value;
    },
    close() {
      this.$emit('close', false)
    },
    updateDeletedEvents() {
      this.delete = [];

      for (let i = 0; i < this.user_before_changes.events.length; i++) {
        const current_event_id = this.user_before_changes.events[i].id;

        let object_found = false;
        for (let j = 0; j < this.user.events.length; j++) {
          if (this.user.events[j].id) {
            if (this.user.events[j].id === current_event_id) {
              object_found = true;
              break;
            }
          }
        }

        if (!object_found) {
          this.delete.push(current_event_id)
        }
      }
    },
    updateCreatedEvents() {
      this.create = [];

      for (let i = 0; i < this.user.events.length; i++) {
        if (this.user.events[i].new_event_id) {
          let tmp_obj = {
            event: this.user.events[i].event.id,
            is_viewer: this.user.events[i].is_viewer,
            is_organiser: this.user.events[i].is_organiser,
            is_operator: this.user.events[i].is_operator,
          };

          this.create.push(tmp_obj)
        }
      }
    },
    roleEventDataWasChanged(user_event_index, user_before_event_index) {
      const previous_event_data = this.user_before_changes.events[user_before_event_index];
      const current_event_data = this.user.events[user_event_index];

      return previous_event_data.is_viewer !== current_event_data.is_viewer ||
          previous_event_data.is_organiser !== current_event_data.is_organiser ||
          previous_event_data.is_operator !== current_event_data.is_operator;
    },
    updateChangedEvents() {
      this.update = [];

      for (let i = 0; i < this.user.events.length; i++) {
        if (!this.user.events[i].new_event_id) {
          const current_role_event_id = this.user.events[i].id;

          for (let j = 0; j < this.user_before_changes.events.length; j++) {
            if (current_role_event_id === this.user_before_changes.events[j].id) {
              if (this.roleEventDataWasChanged(i, j)) {
                let tmp_obj = {
                  role_event: this.user.events[i].id,
                  is_viewer: this.user.events[i].is_viewer,
                  is_organiser: this.user.events[i].is_organiser,
                  is_operator: this.user.events[i].is_operator,
                };

                this.update.push(tmp_obj)
              }

              break;
            }
          }
        }
      }
    },
    hasAutocompleteErrors() {
      let hasErrors = false;

      for (let i = 0; i < this.user.events.length; i++) {
        this.user.events[i].has_error = !!this.user.events[i].is_new;
        if (this.user.events[i].has_error) hasErrors = true;
      }

      return hasErrors;
    },

    save() {
      this.save_loading = true;
      this.search_data = '';

      if (this.hasAutocompleteErrors() && !this.delete_all) {
        this.save_loading = false;
        return;
      }

      if (!this.delete_all && this.edit) {
        this.updateDeletedEvents();
        this.updateCreatedEvents();
        this.updateChangedEvents();
      }

      if (!this.edit) {
        this.createRole();
      } else {
        this.changeRole();
      }
    },
    createRole() {
      const events = [];

      this.user.events.forEach((item) => {
        events.push({
          event: item.event.id,
          is_viewer: item.is_viewer,
          is_organiser: item.is_organiser,
          is_operator: item.is_operator,
        });
      });

      this.createRoles({
        data: {
          user: this.user.user,
          is_viewer: this.user.is_full_viewer,
          is_admin: this.user.is_admin,
          events: events,
        },

        finalizer: () => {
          this.save_loading = false;
          this.close();
        },
        catcher: (val) => {
          this.save_loading = false;
          this.mapErrors(val)
        }
      })
    },
    changeRole() {
      this.updateRoles({
        role_id: this.userId,
        data: {
          is_viewer: this.user.is_full_viewer,
          is_admin: this.user.is_admin,
          events: {
            create: this.create,
            update: this.update,
            delete: this.delete,
          },
          delete_all: this.delete_all,
        },
        finalizer: () => {
          this.save_loading = false;
          this.close();
        },
      })
    },
  },
  created() {
    this.loadUserData()
  },
}
</script>

<style scoped>

</style>
