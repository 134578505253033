import Vue from 'vue'
import App from './App'
import router from './router'
import vuetify from './plugins/vuetify';
import store from './store/index'
import PortalVue from 'portal-vue'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '../public/css/main.css'
import VueBreadcrumbs from 'vue-2-breadcrumbs'
import DatetimePicker from 'vuetify-datetime-picker'
import vueHeadful from 'vue-headful';
import VueCookie from 'vue-cookies';
import VueGtag from 'vue-gtag';
import {checkIsProductionServer,checkIsDevelopmentServer} from "../src/helper/index";
import VueMask from 'v-mask';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import VueYandexMetrika from 'vue-yandex-metrika'
import Vue2Editor from "vue2-editor";

Vue.use(VueMask);
Vue.use(VueCookie);
Vue.use(DatetimePicker);
Vue.use(PortalVue);
Vue.component(VueQrcode.name, VueQrcode);
Vue.use(Vue2Editor);

// if(checkIsProductionServer())
//     Vue.use(VueYandexMetrika, {
//         id: process.env.VUE_APP_YandexMetrika,
//         router: router,
//         env: 'production',
//         options:{
//             clickmap:true,
//             trackLinks:true,
//             accurateTrackBounce:true,
//             webvisor:true
//     }
//     })
    // Vue.use(VueGtag, {
    //     config: {
    //         id: process.env.VUE_APP_GA_ID,
    //         params: {
    //             send_page_view: true,
    //         }
    //     }
    // }, router)

Vue.component('vue-headful', vueHeadful);


Vue.use(VueBreadcrumbs, {
    mixins: [
        {
            methods: {
                getRoute: function f(crumb) {
                    return {name: crumb.name, params: this.$route.params};
                }
            }
        }
    ],
    template: `
      <nav class="breadcrumb text-left" v-if="$breadcrumbs.length" xmlns="http://www.w3.org/1999/html">
      <template v-for="(crumb, i) in $breadcrumbs">
        <v-chip
            outlined
            class="breadcrumb-item-chips mb-2 my-2"
            color="#032F59"
        >
          <router-link
              class="breadcrumb-item"
              :to="getRoute(crumb)"
              style="text-decoration: none; color: inherit"
          >
            {{ getBreadcrumb(crumb.meta.breadcrumb) }}
          </router-link>
        </v-chip>
        <span v-if="$breadcrumbs.length - 1 !== i"
              class="pa-0 ma-0"
        >
        <v-icon
            class="my-2 dark-primary"
        >
          chevron_right
        </v-icon>
      </span>
      </template>
      </nav>
    `
});

Vue.config.productionTip = false;

new Vue({
    vuetify,
    router,
    store,
    render: (h) => h(App)
}).$mount('#app');

//HOOOOOOOOKKKK to xp chrome detected
function getChromeVersion() {
    var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
    return raw ? parseInt(raw[2], 10) : false;
}

let chrome = getChromeVersion();
if (chrome && chrome <= 49) {
    window.onload = function () {
        let element = document.getElementById('base-content');
        element.style.paddingTop = '60px';
        element = document.getElementById("main-left-menu");
        element.style.marginTop = '60px';
        element.style.maxHeight = 'calc(100% - 60px)';
    }
}

