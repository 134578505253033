<template>
  <v-row class="justify-center" v-if="!editMode">
    <v-slide-group
      show-arrows
    >
      <v-slide-item
          v-for="(item, i) in data.data.data.texts"
          :key="i"
      >
        <v-card
            class="ma-2 elevation-3 pa-0"
            :width="getCardWidth"
        >
          <v-row class="pa-5 justify-start ma-0">
            <v-col cols="12" class="pa-0 ma-0">
              <v-row class="justify-center pa-0 ma-0 mb-4">
                <v-img
                    style="border-radius: 50%;"
                    :gradient=" !data.files[i].src ?
            '41deg, rgba(29,59,242,1) 0%, rgba(197,113,233,1) 50%, rgba(197,113,233,1) 78%, rgba(186,234,254,1) 100%'
            : ''"
                    :src="data.files[i].src"
                    :aspect-ratio="1"
                    max-height="150"
                    max-width="150"
                ></v-img>
              </v-row>
            </v-col>
            <v-col cols="12" class="pa-0 ma-0">
              <v-card-title
                  class="pa-0 ma-0 mb-4 text-center text-subtitle-1 text-md-h6 font-weight-bold"
                  style="word-break: keep-all!important;"
              >
                {{ data.data.data.headers[i] }}
              </v-card-title>
            </v-col>
            <v-col cols="12" class="pa-0 ma-0">
              <v-card-text
                  class="pa-0 ma-0 text-left text-subtitle-2 text-md-subtitle-1"
              >
                {{ data.data.data.texts[i] }}
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </v-row>
  <EmptyEntry
      v-else
      :data="data"
      :entry-index="entryIndex"
      @loadFilesFromInput="loadFilesFromInput"
      @deleteOneFile="deleteOneFile"
  ></EmptyEntry>
</template>

<script>
import EmptyEntry from "@/modules/templates/description_blocks/blocks/entries_blocks/EmptyEntry.vue";

export default {
  components: {EmptyEntry},
  props: {
    editMode: Boolean,
    data: Object,
    entryIndex: Number,
  },
  name: "EntriesSliderEntry",
  computed: {
    getCardWidth() {
      if (this.$vuetify.breakpoint.xsOnly) return 235;
      if (this.$vuetify.breakpoint.mdAndDown) return 300;
      return 450;
    }
  },
  methods: {
    loadFilesFromInput(id) {
      this.$emit('loadFilesFromInput', id);
    },
    deleteOneFile(id, file_index) {
      this.$emit('deleteOneFile', id, file_index)
    },
  }
}
</script>

<style scoped>

</style>
