<template>
  <div
    class="list-header"
    :class="mobileBreakpoint ? 'list-header--xs' : 'list-header--smAndUp'"
    v-if="hasTitle || hasSearch"
  >
    <div
        v-if="hasTitle"
        class="list-header__text"
        :class="mobileBreakpoint ? 'list-header__text--xs' : 'list-header__text--smAndUp'"
        >
      {{ pageTitle }}
    </div>
    <div v-else></div>
    <div
      v-if="hasSearch"
      class="list-header__search"
      :class="mobileBreakpoint ? 'list-header__search--xs' : 'list-header__search--smAndUp'"
    >
      <span
        class="list-header__search-icon"
        :class="mobileBreakpoint ? 'list-header__search-icon--xs' : 'list-header__search-icon--smAndUp'"
      >
        <v-icon
          v-if="!value"
          color="#1D71B8"
        >
          search
        </v-icon>
        <v-icon
            v-else
            class="icon-clear"
            color="#1D71B8"
            @click="clearSearch"
        >
          clear
        </v-icon>
      </span>
      <input
          v-model="value"
          @input="$emit('update:searchValue', value)"
          @keydown.enter="$emit('searchData')"
          placeholder="Поиск..."
          type="text"
          :class="mobileBreakpoint ? 'list-header__search-field--xs' : 'list-header__search-field--smAndUp'"
          :style="getInputWidth"
          class="list-header__search-field"
      />
    </div>
    <div v-else></div>
  </div>
</template>

<script>
export default {
  name: "VisitorPageHeader",
  props: {
    pageTitle: String,
    searchValue: String,
    hasSearch: Boolean,
    hasTitle: Boolean,
    muctrUser: Boolean,
    mobileBreakpoint: Boolean
  },
  data() {
    return {
      value: '',
    }
  },
  computed: {
    getInputWidth() {
      if (this.mobileBreakpoint) return 'width: 84vw;'
      if (!this.mobileBreakpoint && this.$vuetify.breakpoint.smOnly) return 'width: 250px;';
      return 'width: 340px;'
    }
  },
  methods: {
    clearSearch() {
      this.value = ''
      this.$emit('update:searchValue', this.value)
      this.$emit('searchData')
    }
  },
  created() {
    this.value = this.searchValue
  }
}
</script>

<style scoped lang="scss">
.list-header {
  display: flex;
}

.list-header--smAndUp {
  height: 75px;
  padding: 20px 0 20px 28px;
  justify-content: space-between;
  align-items: center;
}

.list-header--xs {
  flex-direction: column;
  align-items: flex-start;
  max-height: 150px;
  padding: 20px 8% ;
}

.list-header__text {
  color: #1D71B8;
  font-family: 'MADE Evolve Sans normal', serif;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.list-header__text--smAndUp  {
  font-size: 26px;
}

.list-header__text--xs {
  font-size: 20px;
  margin-bottom: 18px;
}

.list-header__search {

}

.list-header__search--smAndUp {
  margin-right: 30px;
}

.list-header__search--xs {
  margin-top: -24px;
}

.list-header__search-field {
  border-radius: 27px;
  border: 1px solid #D3D3D3;
  height: 38px;
  padding: 5px 15% 5px 18px;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;

  &:hover {
    border: 1px solid #1D71B8;
  }

  &:focus {
    outline: none;
    border: 1px solid #1D71B8;
  }

  &::placeholder {
    color: #969696;
  }
}

.list-header__search-field--smAndUp {

}

.list-header__search-field--xs {

}

.list-header__search-icon {

}

.list-header__search-icon--smAndUp {
  position: relative;
  top: 0;
  left: 88%;
}

.list-header__search-icon--xs {
  position: relative;
  left: 43%;
  top: 32px;
  @media (max-width: 400px) {
    left: 42%;
  }
}

.icon-clear {
  cursor: pointer;
}

.list-header__clear-icon {

}

.list-header__clear-icon--xs {
  position: relative;
  left: 43%;
  top: 32px;
  @media (max-width: 400px) {
    left: 42%;
  }
}

.list-header__clear-icon--smAndUp {
  position: relative;
  top: 0;
  left: 72%;
}

</style>
