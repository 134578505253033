import {sendGetRequest} from "@/helper/requests";
import visitor from "@/urls/roles/visitor"


const state = {
    invites: {
        loading: true,
    }
}

const getters = {

}

const actions = {
    acceptInvite: ({commit},{invite, url, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) {
            url = visitor.VISITOR.EVENTS.INVITES.ACCEPT(invite)
        }
        sendGetRequest(
            url, (data, url) => {
                commit('SET_ACCEPTING_END')
                finalizer(data, url);
            }, (data, url) => {
                commit('SET_ACCEPTING_END')
                catcher(data, url);
            },
        )

    },
}

const mutations = {
    SET_ACCEPTING_END: (state) => {
        state.invites.loading = false
    }
}

export default {
    namespaced: false,
    state,
    mutations,
    actions,
    getters,
}
