<template>
  <svg width="13" height="19" viewBox="0 0 13 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="location_on_FILL0_wght400_GRAD0_opsz48 1">
      <path id="Vector" d="M6.49916 9.30212C6.88062 9.30212 7.2066 9.16629 7.47708 8.89463C7.74757 8.62299 7.88281 8.29642 7.88281 7.91495C7.88281 7.53348 7.74698 7.20751 7.47532 6.93702C7.20367 6.66653 6.87711 6.53129 6.49563 6.53129C6.11417 6.53129 5.78819 6.66712 5.51771 6.93878C5.24722 7.21043 5.11198 7.53699 5.11198 7.91847C5.11198 8.29993 5.24781 8.62591 5.51947 8.89639C5.79112 9.16688 6.11768 9.30212 6.49916 9.30212ZM6.4974 15.8532C8.25226 14.2566 9.54861 12.8085 10.3865 11.5089C11.2243 10.2092 11.6432 9.06462 11.6432 8.07504C11.6432 6.52087 11.1465 5.2483 10.1531 4.25733C9.15965 3.26636 7.94109 2.77087 6.4974 2.77087C5.0537 2.77087 3.83514 3.26636 2.84172 4.25733C1.84828 5.2483 1.35156 6.52087 1.35156 8.07504C1.35156 9.06462 1.78038 10.2092 2.63802 11.5089C3.49566 12.8085 4.78212 14.2566 6.4974 15.8532ZM6.4974 17.4167C4.37309 15.6091 2.78646 13.9301 1.7375 12.3797C0.688542 10.8294 0.164062 9.39448 0.164062 8.07504C0.164062 6.09587 0.800694 4.51914 2.07396 3.34483C3.34722 2.17053 4.8217 1.58337 6.4974 1.58337C8.17309 1.58337 9.64757 2.17053 10.9208 3.34483C12.1941 4.51914 12.8307 6.09587 12.8307 8.07504C12.8307 9.39448 12.3062 10.8294 11.2573 12.3797C10.2083 13.9301 8.6217 15.6091 6.4974 17.4167Z" fill="#1D71B8"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "LocationIcon"
}
</script>

<style scoped>

</style>
