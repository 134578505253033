<template>
  <v-container class="page-content px-0 py-0" style="width: 100%">
    <div v-if="$route.name === names.EVENTS.PREVIEW.MAIN">
      <div v-if="blocks_loaded && event_detail_loaded && fields_loaded && regexp_types_loaded">
        <PreviewToolbar
            v-if="light_detail.allow_update && !(light_detail.is_end && !light_detail.is_draft)"
            @editBlocks="editBlocks"
            @addEmptyRow="addEmptyRow"
            @formRequestData="formRequestData"
            @cancelChanges="cancelChanges"
            :edit="edit"
            :errors-found="blocks_alert"
        ></PreviewToolbar>
        <div v-if="edit">
          <VisitorEventDetail
              class="detail"
              :item="detail"
              :preview="true"
              :light_detail="light_detail"
              @createVisit="() => {}"
              @cancelVisit="() => {}"
          ></VisitorEventDetail>
          <div class="px-5">
            <v-alert
                v-if="blocks_alert"
                color="red"
                class="elevation-2 mt-5 mx-16"
                type="error"
            >
              Не перезагружайте страницу, пока не сохраните блоки описания без ошибок, это может привести к необходимости полностью их переделать
            </v-alert>
          </div>
          <div class="buttons" v-if="edit && side_buttons" :style="blocks_alert ? 'height: 125px!important;' : ''">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    outlined
                    x-large
                    color="primary"
                    style="background: white;"
                    @click="addEmptyRow"
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon>add</v-icon>
                </v-btn>
              </template>
              <span>Добавить пустую строку</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    outlined
                    x-large
                    color="green"
                    style="background: white;"
                    @click="formRequestData"
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon>save</v-icon>
                </v-btn>
              </template>
              <span>Сохранить изменения</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-if="!blocks_alert"
                    icon
                    outlined
                    x-large
                    color="red"
                    style="background: white;"
                    @click="cancelChanges"
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </template>
              <span>Отменить изменения</span>
            </v-tooltip>
          </div>
          <div v-if="renderComponent" class="px-5 mx-16">
            <div v-for="(row, i) in rows" :key="i" class="pa-0" :class="edit ? 'mt-4' : 'mx-n2 mt-0'">
              <RowToolbar
                  @addEmptyBlock="addEmptyBlock(row)"
                  @deleteRow="deleteRow(row)"
                  @moveRowUp="moveRowUp(row)"
                  @moveRowDown="moveRowDown(row)"
                  v-if="edit"
              ></RowToolbar>
              <BlocksRow
                  :row="row"
                  :edit-mode="edit"
                  :block-types="block_types"
                  :class="edit && row.cols_amount > 0 ? 'elevation-2 px-2' : 'elevation-0 '"
                  @extendBlock="extendBlock"
                  @compressBlock="compressBlock"
                  @deleteBlock="deleteBlock"
                  @moveBlockLeft="moveBlockLeft"
                  @moveBlockRight="moveBlockRight"
                  @chooseBlockType="chooseBlockType"
                  @deleteOneFile="deleteOneFile"
                  @addEmptyEntry="addEmptyEntry"
                  @deleteEntry="deleteEntry"
                  @moveEntryDown="moveEntryDown"
                  @moveEntryUp="moveEntryUp"
                  @loadFilesFromInput="loadFilesFromInput"
                  @moveFileUp="moveFileUp"
                  @moveFileDown="moveFileDown"
              ></BlocksRow>
            </div>
          </div>
        </div>
        <div v-else>
          <VisitorEventPagePreview
              v-if="blocks_loaded"
              :detail="detail"
              :blocks="blocks"
              :light-detail="light_detail"
              :regex-types="regexp_types"
          ></VisitorEventPagePreview>
        </div>
      </div>
      <div class="action mt-8 mb-4 align-center" v-else>
        <semipolar-spinner
            class="d-inline-block"
            :animation-duration="2000"
            :size="115"
            color="#1935ff"
        />
      </div>
    </div>
    <PopUpAlert
        :text="pop_up_text"
        :closable="true"
        :value="pop_up_alert"
        v-if="pop_up_alert"
        @close="closePopUpAlert"
    ></PopUpAlert>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import names_core from "@/modules/core/routers/names";
import names from "@/modules/organiser/routers/names";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import {SemipolarSpinner} from "epic-spinners";
import BlocksRow from "@/modules/templates/description_blocks/BlocksRow.vue";
import PopUpAlert from "@/modules/templates/PopUpAlert.vue";
import PreviewToolbar from "@/modules/templates/description_blocks/PreviewToolbar.vue";
import RowToolbar from "@/modules/templates/description_blocks/RowToolbar.vue";
import organiser from "@/urls/roles/organiser";
import DescriptionBlocksMixin from "@/mixins/DescriptionBlocksMixin";
import VisitorEventPagePreview from "@/modules/templates/VisitorEventPagePreview.vue";
import VisitorEventDetail from "@/modules/templates/visitor/event_detail/VisitorEventDetail.vue";

export default {
  name: "OrganiserEventsPreview",
  mixins: [ TitledPageMixin, DescriptionBlocksMixin ],
  components: {
    VisitorEventDetail,
    VisitorEventPagePreview,
    RowToolbar,
    PreviewToolbar, PopUpAlert, BlocksRow, SemipolarSpinner },
  data() {
    return {
      page_title: 'Предпросмотр',
      event_detail_loaded: false,
      blocks_loaded: false,
      block_types_loaded: false,
      block_types: null,
      edit: false,
      renderComponent: true,
      pop_up_text: '',
      pop_up_alert: false,
      blocks_alert: false,
      blocks: [],
      detail: undefined,
      fields: undefined,
      fields_loaded: false,
      regexp_types: null,
      regexp_types_loaded: false,
    }
  },
  computed: {
    ...mapGetters({
      light_detail: 'organiser/getEventsLightDetail',
    }),
    names: () => names,
  },
  watch: {
    block_types_loaded() {
      if (this.block_types_loaded) {
        this.loadData();
      }
    },
    requests_left(val) {
      if (val === 0) {
        this.blocks_loaded = false;
        this.loadData(() => {
          this.edit = false;
        })
      }
    }
  },
  methods: {
    ...mapActions({
      loadBlocksList: 'organiser/loadBlocksList',
      loadEventDetail: 'organiser/loadEventsDetail',
      loadBlockTypeChoices: "choices/loadBlockTypeChoices",
      deleteBlocks: "organiser/deleteBlocks",
      createBlocks: "organiser/createBlocks",
      changeBlocks: "organiser/changeBlocks",
      loadLightDetail: 'organiser/loadEventsLightDetail',
      updatePreviewOpenedState: 'mainpage/updatePreviewOpenedState',
      loadFieldsList: "organiser/loadNonPaginatedFieldsList",
      changeEventDetailLoadingState: 'leftmenu/changeEventDetailLoadingState',
      loadRegexpTypeChoices: 'choices/loadRegexpTypeChoices',
    }),
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      })
    },
    cancelChanges() {
      this.edit = false;
      this.loadData();
    },
    editBlocks() {
      this.edit = !this.edit
    },
    closePopUpAlert() {
      this.pop_up_alert = false;
      this.pop_up_text = '';
    },
    deleteBlockFunction(id) {
      this.deleteBlocks({
        event_id: this.$route.params.idEvent,
        id: id,
        finalizer: () => {
          this.requests_left--;
        },
        catcher: () => {},
      })
    },
    createBlockFunction(data) {
      let block_id = data.block_id;
      delete data.block_id

      let form_data = new FormData();
      for (let key of Object.keys(data))
      {
        if (key === 'data' || key === 'files_create')
        {
          form_data.append(key, JSON.stringify(data[key]))
        }
        else
        {
          form_data.append(key, data[key])
        }
      }
      this.createBlocks({
        event_id: this.$route.params.idEvent,
        data: form_data,
        finalizer: () => {
          this.requests_left--;
        },
        catcher: (val) => {
          this.mapBackEndFilesErrors(block_id, val.files, form_data)
        },
      })
    },
    changeBlockFunction(data) {
      let block_id = data.id
      delete data.id;

      let form_data = new FormData();
      for (let key of Object.keys(data))
      {
        if (key === 'data' || key === 'files_create' || key === 'files_delete' || key === "index_change")
        {
          form_data.append(key, JSON.stringify(data[key]))
        }
        else
        {
          form_data.append(key, data[key])
        }
      }
      this.changeBlocks({
        event_id: this.$route.params.idEvent,
        id: block_id,
        data: form_data,
        finalizer: () => {
          this.requests_left--;
        },
        catcher: (val) => {
          this.mapBackEndFilesErrors(block_id, val.files, form_data)
        },
      })
    },
    loadChoices() {
      this.loadRegexpTypeChoices({
        finalizer: (val) => {
          this.regexp_types = val;
          this.regexp_types_loaded = true;
        }
      })
      this.loadBlockTypeChoices({
        finalizer: (val) => {
          this.block_types = val;
          this.block_types_loaded = true;
        }
      })
      this.loadEventDetail({
        url: undefined,
        id: this.$route.params.idEvent,
        finalizer: (data) => {
          this.detail = data
          if (this.fields_loaded) {
            this.detail.fields = this.fields
          }
          this.event_detail_loaded = true;
        }
      })
      this.loadFieldsList({
        url: undefined,
        event_id: this.$route.params.idEvent,
        finalizer: (data) => {
          this.fields = data
          if (this.event_detail_loaded) {
            this.detail.fields = this.fields
          }
          this.fields_loaded = true
        }
      })
    },
    loadData(finalizer) {
      this.loadBlocksList({
        url: undefined,
        event_id: this.$route.params.idEvent,
        finalizer: (data) => {
          this.blocks = data
          this.rebuildBlocksToRows();
          this.blocks_before_changes = structuredClone(this.blocks)
          this.blocks_loaded = true;
          this.changeEventDetailLoadingState({loading_state: false})
          finalizer()
        }
      })
    },
    getImage(image) {
      if (!image) return null;

      let urls = {
        basename: image.name,
        action: image.action,
        params: image.params,
      }

      return organiser.getByRecievedUrlData(urls, image.get_params)
    },
    updatePageTitle() {
      this.loadLightDetail({
        id: this.$route.params.idEvent,
        finalizer: (data) => {
          this.page_title = this.page_title + ': ' + data.name
        }
      })
    },
  },
  created() {
    if (organiser.ORGANISER.ALLOWED()) {
      if(this.$route.name === names.EVENTS.PREVIEW.MAIN){
        this.updatePageTitle()
        this.loadChoices()
        let preview_opened = (this.$route.name.indexOf('|Events|Detail|Preview|Main') !== -1)
        this.updatePreviewOpenedState({
          value: preview_opened
        })
      }
    } else {
      this.$router.push({name: names_core.MAIN})
    }
  },
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0;
}

.buttons {
  display: flex;
  flex-direction: column;
  height: 180px;
  justify-content: space-between;
  position: fixed;
  z-index: 5;
  padding: 5px;
  border-radius: 25px;
  left: calc(100% - 85px);
  top: 20vh;
}
</style>
