<template>
  <div>
    <v-toolbar
        class="removeBtnPaddingRight rounded-t-lg"
        color="#DADADA"
        flat
    >
      <slot name="btn"></slot>
    </v-toolbar>
    <v-card-subtitle class="mx-6 px-0 subtitle-2 text-md-subtitle-1 text-lg-h6" v-if="item.description">
      <v-row no-gutters class="text-left pt-1 pb-5">
        <v-col cols="6" md="2" class="font-weight-bold">Описание события:</v-col>
        <v-col cols="6" md="10" class="text-right text-md-left">{{ item.description }}</v-col>
      </v-row>
      <v-divider class="grey my-1"></v-divider>
    </v-card-subtitle>
    <v-card-text class="subtitle-2 text-md-subtitle-1 text-lg-h6">
      <v-row no-gutters class="text-left mx-2" v-if="item.parent">
        <v-col cols="5" md="3" class="font-weight-bold">Родительское событие:</v-col>
        <v-col cols="7" md="9" class="text-right text-md-left">
          <span style="text-decoration: underline; color: #065aad; cursor: pointer" @click="getParentEvent(item.parent.id)">
            {{ item.parent.name }}
          </span>
        </v-col>
      </v-row>
      <v-row no-gutters class="text-left mx-2">
        <v-col cols="7" md="3" class="font-weight-bold py-1">Дата начала события:</v-col>
        <v-col cols="5" md="3" class="text-right text-md-left py-1">{{ correctTime(item.start) }}</v-col>
        <v-col cols="7" md="3" class="font-weight-bold py-1">Дата конца события:</v-col>
        <v-col cols="5" md="3" class="text-right text-md-left py-1">{{ correctTime(item.end) }}</v-col>
      </v-row>
      <v-row no-gutters class="text-left mx-2" v-if="!item.parent">
        <v-col cols="7" md="3" class="font-weight-bold py-1">Дата начала регистрации:</v-col>
        <v-col cols="5" md="3" class="text-right text-md-left py-1">{{ correctTime(item.record_start, true) }}</v-col>
        <v-col cols="7" md="3" class="font-weight-bold py-1">Дата конца регистрации:</v-col>
        <v-col cols="5" md="3" class="text-right text-md-left py-1">{{ correctTime(item.record_end, true) }}</v-col>
      </v-row>
      <v-row no-gutters class="text-left mx-2" v-if="notVisitor()">
        <v-col cols="7" md="3" class="font-weight-bold py-1">{{ item.parent ? 'Корневое событие опубликовано:' : 'Опубликовано:' }}</v-col>
        <v-col cols="5" md="3" class="text-right text-md-left py-1">
          <v-icon
              :color="checkFlag(!item.is_draft).color"
          >
            {{checkFlag(!item.is_draft).icon}}
          </v-icon>
        </v-col>
        <v-col cols="7" md="3" class="font-weight-bold py-1" v-if="item.parent">Скрыто:</v-col>
        <v-col cols="5" md="3" class="text-right text-md-left py-1" v-if="item.parent">
          <v-icon
              :color="checkFlag(item.is_hidden).color"
          >
            {{checkFlag(item.is_hidden).icon}}
          </v-icon>
        </v-col>
        <v-col cols="7" md="3" class="font-weight-bold py-1" v-if="!item.parent">Необходимо подтверждение посещения:</v-col>
        <v-col cols="5" md="3" class="text-right text-md-left py-1" v-if="!item.parent">
          <v-icon
              :color="checkFlag(item.is_required_confirmation).color"
          >
            {{checkFlag(item.is_required_confirmation).icon}}
          </v-icon>
        </v-col>
      </v-row>

      <v-row no-gutters class="text-left mx-2" v-if="item.parent">
        <v-col cols="7" md="3" class="font-weight-bold py-1">Необходимо подтверждение посещения:</v-col>
        <v-col cols="5" md="3" class="text-right text-md-left py-1">
          <v-icon
              :color="checkFlag(item.is_required_confirmation).color"
          >
            {{checkFlag(item.is_required_confirmation).icon}}
          </v-icon>
        </v-col>
        <v-col cols="7" md="3" class="font-weight-bold py-1" v-if="item.parent && !item.is_hidden">Находится в скрытой области:</v-col>
        <v-col cols="5" md="3" class="text-right text-md-left py-1" v-if="item.parent && !item.is_hidden">
          <v-icon
              :color="checkFlag(item.is_hidden_branch).color"
          >
            {{checkFlag(item.is_hidden_branch).icon}}
          </v-icon>
        </v-col>
<!--        <v-col cols="7" md="3" class="font-weight-bold py-1">Необходимо предоставить согласие на передачу данных:</v-col>-->
<!--        <v-col cols="5" md="3" class="text-right text-md-left py-1">-->
<!--          <v-icon-->
<!--              :color="checkFlag(item.is_required_data_dissemination).color"-->
<!--          >-->
<!--            {{checkFlag(item.is_required_data_dissemination).icon}}-->
<!--          </v-icon>-->
<!--        </v-col>-->
      </v-row>
      <v-row no-gutters class="text-left mx-2">
        <v-col cols="7" md="3" class="font-weight-bold py-1">Место проведения:</v-col>
        <v-col cols="5" md="3" class="text-right text-md-left py-1">{{ item.location ? item.location : '-' }}</v-col>
      </v-row>
<!--      <v-divider class="grey my-2" v-if="!!item.roles && isGetList(item.roles)"></v-divider>-->
<!--      <v-row no-gutters class="text-center subtitle-1" v-if="!!item.roles && isGetList(item.roles)">-->
<!--        <v-col cols="12" class="font-weight-bold">Список пользователей</v-col>-->
<!--      </v-row>-->
<!--      <v-divider class="grey my-2" v-if="!!item.roles && isGetList(item.roles)"></v-divider>-->
<!--      <v-data-table-->
<!--          v-if="!!item.roles && isGetList(item.roles)"-->
<!--          :headers="headers_roles"-->
<!--          :items="item.roles"-->
<!--          :footer-props="{'items-per-page-options':[25,50,100]}"-->
<!--      >-->
<!--      </v-data-table>-->
<!--      <slot name="visitor"></slot>-->
    </v-card-text>
    <slot name="event-time-btn"></slot>
  </div>
</template>

<script>
import {displayDate} from "@/helper";

export default {
  name: "EventDetail",
  props: {
    item:{
      type: Object,
    },
    role:{
      type: String,
      // default: 'Admin',
    },
  },
  data() {
    return {
      headers_roles: [
        {text: 'ФИО', value: 'fio', sortable: false, align: 'start', width: '100%'},
      ],
    }
  },
  methods: {
    correctTime (time, minute = false) {
      if(time!==null){
        return displayDate(time, minute)
      }
    },
    checkFlag(flag) {
      if (flag) return {icon: "check_circle", color: "green"}
      else return {icon: "not_interested", color: "grey"}
    },
    getParentEvent(item) {
      this.$router.push({name: this.role + '|Events|Detail', params: {idEvent: item}})
    },
    notVisitor(){
      if(this.role !== 'Visitor')
        return true
      else
        return false
    }
    // isGetList(list){
    //   if (list.length !== 0)
    //     return true
    //   else
    //     return false
    // },
  },
  created() {
  }
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0;
}
</style>
