<template>
  <div class="pa-0 ma-0" id="visit-detail">
    <div class="header">
      <v-img
          class="header__image"
          :src="item.event && item.event.image ? getImage(item.event.image) : basic_event"
      >
      </v-img>
      <div class="header__text" :class="getHeaderFontClass()">
        {{ item.event.name.toUpperCase() }}
      </div>
      <div v-if="item.event.image" class="header__gradient"></div>
      <div class="header-back">
        <div class="button button__back" @click="returnParent">
          <div class="header-back__text" v-if="!breakPoint570">
            Назад
          </div>
          <v-icon
              size="15"
          >
            $vuetify.icons.arrow_back
          </v-icon>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="location">
        <v-icon
          class="location-icon"
          :size="20"
        >
          $vuetify.icons.location
        </v-icon>
        <div class="location-text">
          {{ item.event.location }}
        </div>
      </div>


      <div class="info-block" v-if="!breakPont900">
        <div class="info-column">
          <div class="info-row">
            <div class="date-text">
              Дата начала события:
            </div>
            <div class="date-value">
              {{displayDate(item.event.start) }}
            </div>
          </div>
        </div>
        <div class="info-column">
          <div class="info-row">
            <div class="date-text">
              Дата окончания события:
            </div>
            <div class="date-value">
              {{ displayDate(item.event.end) }}
            </div>
          </div>
        </div>
        <div class="info-column">
          <div class="info-row">
            <div class="status-text">
              Статус посещения:
            </div>
            <div class="status-value" :style="'color:' + getVisitStatusParameters().color + ';'">
              {{ getVisitStatusParameters().value }}
            </div>
          </div>
        </div>
        <div class="info-column">
          <div class="info-row">
            <div class="status-text">
              Статус события:
            </div>
            <div class="status-value" :style="'color:' + getEventStatusParameters().color + ';'">
              {{ getEventStatusParameters().value }}
            </div>
          </div>
        </div>
        <div class="info-row--1500" v-if="!breakPoint1500">
          <div class="info-row  info-row--buttons-1500">
            <div
                v-if="visitCanBeCancelled"
                class="button button__cancel--active"
                @click="$emit('cancelVisit', item)"
            >
              Отменить посещение
            </div>
            <div
                v-else
                class="button button__cancel--disabled"
            >
              Отменить посещение
            </div>
          </div>
          <div class="info-row">
            <div
                class="button button__detail"
                @click="getEventDetail"
            >
              Перейти к событию
            </div>
          </div>
        </div>
      </div>
      <div class="info-block" v-if="breakPont900">
        <div class="info-column">
          <div class="info-row">
            <div class="date-text">
              Дата начала события:
            </div>
            <div class="date-value">
              {{displayDate(item.event.start) }}
            </div>
          </div>
          <div class="info-row">
            <div class="date-text">
              Дата окончания события:
            </div>
            <div class="date-value">
              {{ displayDate(item.event.end) }}
            </div>
          </div>
        </div>
        <div class="info-column" v-if="!breakPoint450">
          <div class="info-row">
            <div class="status-text">
              Статус посещения:
            </div>
            <div class="status-value" :style="'color:' + getVisitStatusParameters().color + ';'">
              {{ getVisitStatusParameters().value }}
            </div>
          </div>
          <div class="info-row">
            <div class="status-text">
              Статус события:
            </div>
            <div class="status-value" :style="'color:' + getEventStatusParameters().color + ';'">
              {{ getEventStatusParameters().value }}
            </div>
          </div>
        </div>
        <div class="info-column" v-if="!breakPoint640">
          <div class="info-row">
            <div
                v-if="visitCanBeCancelled"
                class="button button__cancel--active"
                @click="$emit('cancelVisit', item)"
            >
              Отменить посещение
            </div>
            <div
                v-else
                class="button button__cancel--disabled"
            >
              Отменить посещение
            </div>
          </div>
          <div class="info-row">
            <div
                class="button button__detail"
                @click="getEventDetail"
            >
              Перейти к событию
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 info-column--buttons-475" v-if="!breakPoint450 && breakPoint640 || !breakPont900 && breakPoint1500">
        <div class="info-row">
          <div
              v-if="visitCanBeCancelled"
              class="button button__cancel--active"
              @click="$emit('cancelVisit', item)"
          >
            Отменить посещение
          </div>
          <div
              v-else
              class="button button__cancel--disabled"
          >
            Отменить посещение
          </div>
        </div>
        <div class="info-row info-row--buttons-xs info-row--buttons-475">
          <div
              class="button button__detail"
              @click="getEventDetail"
          >
            Перейти к событию
          </div>
        </div>
      </div>
      <div class="info-column" v-if="breakPoint450">
        <div class="info-row">
          <div class="status-text">
            Статус посещения:
          </div>
          <div class="status-value" :style="'color:' + getVisitStatusParameters().color + ';'">
            {{ getVisitStatusParameters().value }}
          </div>
        </div>
        <div class="info-row">
          <div class="status-text">
            Статус события:
          </div>
          <div class="status-value" :style="'color:' + getEventStatusParameters().color + ';'">
            {{ getEventStatusParameters().value }}
          </div>
        </div>
      </div>
      <div class="info-column mt-5 info-column--buttons-xs" v-if="breakPoint450">
        <div class="info-row">
          <div
              v-if="visitCanBeCancelled"
              class="button button__cancel--active"
              @click="$emit('cancelVisit', item)"
          >
            Отменить посещение
          </div>
          <div
              v-else
              class="button button__cancel--disabled"
          >
            Отменить посещение
          </div>
        </div>
        <div class="info-row info-row--buttons-xs">
          <div
              class="button button__detail"
              @click="getEventDetail"
          >
            Перейти к событию
          </div>
        </div>
      </div>
      <div class="description" v-if="item.event.description">
        {{ item.event.description }}
      </div>
    </div>
  </div>
</template>

<script>
import basic_event from "@/assets/basicEventImage.svg";
import visitor from "@/urls/roles/visitor";
import names from "@/modules/visitor/routers/names";
import {displayDate} from "@/helper";

export default {
  name: "VisitorVisitDetail",
  props: {
    item: Object,
  },
  data() {
    return {
      basic_event: basic_event,
      page_width: window.screen.availWidth,
    }
  },
  computed: {
    visitCanBeCancelled() {
      return !this.item.is_enter && !this.item.is_cancel && !(this.item.is_end && !this.item.is_draft) && !this.item.has_entered_records;
    },
    breakPoint1500() {
      return this.page_width <= 1500
    },
    breakPont900() {
      return this.page_width <= 900
    },
    breakPont710() {
      return this.page_width <= 710
    },
    breakPoint640() {
      return this.page_width <= 640
    },
    breakPoint570() {
      return this.page_width <= 569
    },
    breakPoint540() {
      return this.page_width <= 540
    },
    breakPoint475() {
      return this.page_width <= 475
    },
    breakPoint450() {
      return this.page_width <= 450
    },
    breakPoint400() {
      return this.page_width <= 400
    }
  },
  methods: {
    resizeHandler(e) {
      this.page_width = e.target.screen.availWidth
    },
    getHeaderFontClass() {
      if (this.breakPoint400) {
        return ' font-size-15'
      }
      if (this.breakPoint475) {
        return ' font-size-17'
      }
      if (this.breakPoint540) {
        return ' font-size-21'
      }
      if (this.breakPont710) {
        return ' font-size-24'
      }
      return ''
    },
    displayDate: displayDate,
    getImage(image) {
      if (!image) return null;
      let urls = {
        basename: image.name,
        action: image.action,
        params: image.params,
      }
      return visitor.getByRecievedUrlData(urls, image.get_params)
    },
    getVisitStatusParameters() {
      if (!this.item.is_enter && !this.item.is_cancel) return {value : 'Не посещено', color: '#1D71B8'}
      else if (this.item.is_enter) return {value : 'Посещено', color: '#1D71B8'}
      return {value : 'Отменено', color: '#D64141'}
    },
    getEventStatusParameters() {
      if (this.item.is_draft) return {value : 'Скрыто', color: 'grey'}
      else if (this.item.is_end) return {value : 'Завершено', color: 'grey'}
      else if (this.item.is_held) return {value : 'Проводится', color: '#1D71B8'}
      else if (!this.item.event.is_record_started) return {value : 'Ожидайте запись', color: 'grey'}
      else if (this.item.is_recording) return {value : 'Идёт запись', color: 'green'}
      return {value : 'Ожидайте событие', color: 'green'}
    },
    returnParent() {
      this.$router.push({name: names.VISITS.LIST})
    },
    getEventDetail() {
      this.$router.push({name: names.EVENTS.DETAIL, params: {idEvent: this.item.event.slug}})
    },
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
}
</script>

<style scoped lang="scss">
.font-size-24 {
  font-size: 24px!important;
}

.font-size-21 {
  font-size: 21px!important;
}

.font-size-17 {
  font-size: 17px!important;
  line-height: 140%!important;
}

.font-size-15 {
  -webkit-line-clamp:6!important;
  font-size: 15px!important;
  line-height: 140%!important;
}

.header{
  position: relative;
  height: 228px;
  background: #032F59;
}

.header__image {
  height: inherit;
}

.header__text {
  text-align: left;
  width: 60%;
  position: absolute;
  bottom: 27px;
  left: 24px;
  color: #FFF;
  font-family: 'Inter normal', serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  z-index: 1;
  display:-webkit-box;
  -webkit-line-clamp:5;
  -webkit-box-orient:vertical;
  overflow: hidden;
  @media (max-width: 400px) {
    width: 200px;
  }
}

.header__gradient {
  position: relative;
  bottom: 167px;
  height: 167px;
  width: inherit;
  background: linear-gradient(0, #1D71B8 0%, rgba(0, 0, 0, 0) 100%);
}

.header-back {
  position: absolute;
  top: 19px;
  left: calc(100% - 46px - 107px);
  @media (max-width: 570px) {
    left: calc(95% - 49px);
  }
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-back__text {
  color: #1D71B8;
  font-family: 'Inter normal', serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-right: 13px;
}

.main {
  padding: 0 44px 36px 24px;
  @media (max-width: 570px) {
    padding: 0 24px 36px 24px;
  }
  color: #7C7C7C;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
}

.location {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.location-icon {
  margin-right: 23px;
}

.location-text {
  @media (max-width: 400px) {
    font-size: 15px;
  }
}

.info-block {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.info-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.info-column--buttons-xs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.info-column--buttons-475 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.info-row {
  min-height: 50px;
  padding-bottom: 10px;
  max-width: 200px;
}

.info-row--1500 {
  display: flex;
  flex-direction: row;
}

.info-row--buttons-475 {
  margin-left: 4%;
}

.info-row--buttons-1500 {
  margin-right: 5%;
}

.line-break {
  width: 100%;
}

.date-value {
  color: #404040;
  font-size: 18px;
}

.status-value {
  font-size: 16px;
  text-transform: uppercase;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  border-radius: 45px;
  border: 1px solid #1D71B8;
  color: #1D71B8;
  height: 40px;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-transform: none;
}

.button__back {
  background: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  &:hover, &:active {
    background: white;
  }
}

.button__detail {
  min-width: 180px;
  cursor: pointer;
  &:hover, &:active {
    color: white;
    background: #1D71B8;
  }
}

.button__cancel--active {
  min-width: 180px;
  border-radius: 44px;
  border-color: #D64141;
  color: #D64141;
  cursor: pointer;

  &:hover, &:active {
    color: white;
    background: #D64141;
  }
}

.button__cancel--disabled {
  min-width: 180px;
  border-color: #BCBCBC;
  color: #BCBCBC;
}

.description {
  margin-top: 25px;
  font-weight: 400;
}


</style>
