<template>
  <scroll-center-dialog v-model="opened" :loading="save_loading" width="760" @close="close" @apply="save"
                        :title="'Изменить даты события'" :scrolled="false">
    <v-row class="pa-0 ma-0 mt-4">
      <v-col cols="12" sm="6" class="pa-0 ma-0">
        <v-text-field
            outlined
            type="date"
            v-model="event_start"
            :class="$vuetify.breakpoint.smAndUp ? 'mr-2' : ''"
            :error="hasError('start')"
            :errorMessages="getError('start')"
        >
          <template v-slot:label>
            Начало интервала<span class="red--text"> *</span>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" class="pa-0 ma-0">
        <v-text-field
            outlined
            type="date"
            v-model="event_end"
            :class="$vuetify.breakpoint.smAndUp ? 'ml-2' : ''"
            :error="hasError('end')"
            :errorMessages="getError('end')"
        >
          <template v-slot:label>
            Окончание интервала<span class="red--text"> *</span>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-alert
        color="red"
        class="elevation-2 mt-2"
        type="error"
    >
      Обращаем ваше внимание, что изменение дат проведения мероприятия приведёт к отправке уведомления всем его посетителям
    </v-alert>
  </scroll-center-dialog>
</template>

<script>
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import {mapActions} from "vuex";

export default {
  name: "ChangeEventDatesDialog",
  components: {ScrollCenterDialog},
  mixins: [FormErrorsMixin],
  props: {
    start: String,
    end: String,
    opened: Boolean,
  },
  data() {
    return {
      event_start: undefined,
      event_end: undefined,
      save_loading: false,
    }
  },
  methods: {
    ...mapActions({
       changeDates: 'admin/changeEventsDates',
    }),
    close() {
      this.$emit('close')
    },
    save() {
      this.changeDates({
        id: this.$route.params.idEvent,
        data: {
          start: this.event_start,
          end: this.event_end,
        },
        finalizer: () => {
          this.save_loading = false
          this.close()
        },
        catcher: (data) => {
          this.mapErrors(data)
          this.save_loading = false
        }
      })
    },
  },
  created() {
    this.event_end = this.end
    this.event_start = this.start
  },
}
</script>

<style scoped>

</style>
