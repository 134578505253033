<template>
  <component
      :is="previewOpened ? 'div' : 'v-container'"
      class="page-content"
      :class="previewOpened ? '' : ' px-5'"
      style="  width: 100%"
  >
    <div v-if="$route.name === names.EVENTS.LIST">
      <v-toolbar
          class="removeBtnPaddingRight rounded-t-lg"
          color="#DADADA"
          flat
      >
        <v-text-field
            v-model="search.value"
            class="dark-primary my-1"
            clearable
            dense
            outlined
            label="Поиск"
            hide-details
            @keydown.enter="searchData"
            @click:clear="updateData"
        ></v-text-field>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-data-table
          :headers="headers"
          :items="items"
          :loading="isLoading"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.size"
          :server-items-length="count"
          :options.sync="options"
          :footer-props="{'items-per-page-options':[10,50,100]}"
          class="elevation-1 rounded-b-lg"
      >
        <template v-slot:item.name="{ item }">
          <span style="text-decoration: underline; color: #065aad; cursor: pointer" @click="getDetail(item.id)">{{item.name}}</span>
        </template>
        <template v-slot:item.record_start="{ item }">
          <span v-if="item.record_start!==null">{{ correctTime(item.record_start, true) }}</span>
        </template>
        <template v-slot:item.record_end="{ item }">
          <span v-if="item.record_end!==null">{{ correctTime(item.record_end, true) }}</span>
        </template>
        <template v-slot:item.start="{ item }">
          <span v-if="item.start!==null">{{ displayDate(item.start) }}</span>
        </template>
        <template v-slot:item.end="{ item }">
          <span v-if="item.end!==null">{{ displayDate(item.end) }}</span>
        </template>
        <template v-slot:no-data>
          <v-btn
              color="primary"
              @click="updateData"
          >
            Обновить
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <router-view v-else></router-view>
  </component>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import names from '@/modules/viewer/routers/names';
import {displayDate} from "@/helper";
import viewer from "@/urls/roles/viewer";
import names_core from "@/modules/core/routers/names";

export default {
  name: "ViewerEventsList",
  components: {},
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  data() {
    return {
      page_title: "Список событий",
      search: {
        parameter: 'name',
      },
      headers: [
        {text: 'Название', value: 'name', sortable: false, align: 'start', width: '40%'},
        {text: 'Дата начала регистрации', value: 'record_start', sortable: false, align: 'center', width: '15%'},
        {text: 'Дата конца регистрации', value: 'record_end', sortable: false, align: 'center', width: '15%'},
        {text: 'Дата начала события', value: 'start', sortable: false, align: 'center', width: '15%'},
        {text: 'Дата конца события', value: 'end', sortable: false, align: 'center', width: '15%'},
      ],
    }
  },
  computed: {
    ...mapGetters({
      items: 'viewer/getEventsList',
      count: 'viewer/getEventsCount',
      isLoading: 'viewer/isLoadingEvents',
      previewOpened: 'mainpage/isPreviewOpened'
    }),
    names: () => names,
  },
  watch: {
    '$route': function(newVal) {
      let preview_opened = (newVal.name.indexOf('|Events|Detail|Preview|Main') !== -1)
      this.updatePreviewOpenedState({
        value: preview_opened
      })
    }
  },
  methods: {
    ...mapActions({
      loadEventsList: 'viewer/loadEventsList',
      updatePreviewOpenedState: 'mainpage/updatePreviewOpenedState',
    }),
    displayDate: displayDate,
    correctTime (time, minute = false) {
      if(time!==null) {
        return this.displayDate(time, minute)
      }
    },
    getDetail(id) {
      this.$router.push({name: names.EVENTS.DETAIL, params: {idEvent: id}})
    },
    updateData(){
      if (this.search.value) {
        this.search.value = ''
      }
      this.loadData()
    },
    loadData(url = undefined) {
      this.loadEventsList({
        url: url,
        urlAppend: (url) => {
          return this.addPageParameter(this.addSizeParameter(url));
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
        }
      })
    },
  },
  beforeRouteUpdate(to, from, next){
    next()
    if (viewer.VIEWER.ALLOWED() && this.$route.name === names.EVENTS.LIST){
      this.loadData()
    }
  },
  created() {
    if (viewer.VIEWER.ALLOWED()){
      if(this.$route.name === names.EVENTS.LIST){
        this.loadData()
      }
    } else{
      this.$router.push({name: names_core.MAIN})
    }
  },
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0;
}
</style>
