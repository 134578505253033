<template>
  <div>
    <slot name="btn"></slot>
    <v-card-title
        v-if="!edit"
        style="word-break: keep-all!important;"
        class="pa-0 ma-0 bold text-left text-h4"
    >
      {{ data.data.data.header }}
    </v-card-title>
    <v-text-field
        v-else
        outlined
        class="pa-0 ma-0 text-left"
        v-model="data.data.data.header"
        :error="data.errors.header.length > 0"
        :error-messages="data.errors.header"
    >
      <template v-slot:label>
        Заголовок<span class="red--text"> *</span>
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    edit: Boolean,
    data: Object,
  },
  name: "HeaderBlock",
}
</script>

<style scoped>

</style>
