import {sendPostRequest, sendPatchRequest, sendDeleteRequest, sendGetRequest, sendPutRequest} from "@/helper/requests";
import organiser from "@/urls/roles/organiser";
import {executeRequest} from "@/store/utils";

const state = {
    roles:{
        currentUrl: null,
        items: [],
        loading: false,
        personalDataLoading: false,
        count: 0,
        detail: [],
        error: null,
    }
}

const getters = {
    getRolesList: state => state.roles.items,
    getRolesCount: state => state.roles.count,
    getRolesCurrentUrl: state => state.roles.currentUrl,
    getRolesDetail: state => state.roles.detail,
    getPersonalEvents: state => state.roles.detail.events,
    isLoadingPersonalData: state => state.roles.personalDataLoading,
    isLoadingRoles: state => state.roles.loading,
    saveRolesError: state => state.roles.error,
}

const actions = {
    loadRolesList: ({commit, getters}, {url, event_id, urlAppend, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) {
            url = organiser.ORGANISER.EVENTS.ROLES.LIST(event_id)
            if (urlAppend) url = urlAppend(url)
        }
        if (!getters.isLoadingRoles){
            commit('SET_ROLES_LOADING');
            sendGetRequest(
                url, (data, url) => {
                    commit('SET_ROLES_LIST', data['results']);
                    commit('SET_ROLES_COUNT', data['count']);
                    commit('SET_ROLES_CURRENT_URL', url);
                    commit('SET_ROLES_LOADING_END');
                    finalizer(data, url);
                }, (data, url) => {
                    commit('SET_ROLES_LOADING_END');
                    commit('SAVE_ROLES_ERROR', data);
                    catcher(data, url);
                }
            )
        }
    },
    loadRolesDetail: ({commit}, {url, event_id, id, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) url = organiser.ORGANISER.EVENTS.ROLES.DETAIL(event_id, id)
        state.roles.detail = [];
        commit('SET_DETAIL_DATA_LOADING');
        sendGetRequest(
            url, (data, url) => {
                commit('SET_ROLES_DETAIL', data);
                commit('SET_DETAIL_DATA_LOADING_END');
                finalizer(data, url);
            }, (data, url) => {
                commit('SET_DETAIL_DATA_LOADING_END');
                commit('SAVE_ROLES_ERROR', data);
                catcher(data, url);
            }
        )
    },
    createRoles: ({dispatch, commit, getters}, {data, event_id, finalizer=()=>{}, catcher=()=>{}}) => {
        let url = organiser.ORGANISER.EVENTS.ROLES.CREATE(event_id)
        executeRequest('Roles', sendPostRequest, {url, data, finalizer, catcher}, {dispatch, commit, getters});
    },
    changeRoles: ({dispatch, commit, getters}, {data, event_id, id, finalizer,catcher}) => {
        let url = organiser.ORGANISER.EVENTS.ROLES.UPDATE(event_id, id)
        executeRequest('Roles', sendPatchRequest, {url, data, finalizer, catcher}, {dispatch, commit, getters});
    },
    deleteRoles: ({dispatch, commit, getters}, {event_id, id, finalizer, catcher}) => {
        let url = organiser.ORGANISER.EVENTS.ROLES.DELETE(event_id, id,)
        executeRequest('Roles', sendDeleteRequest, {url, finalizer, catcher}, {dispatch, commit, getters});
    },
}

const mutations = {
    SET_ROLES_LIST: (state, roles) => {
        state.roles.items = roles
    },
    SET_ROLES_DETAIL: (state, value) => {
        state.roles.detail = value
    },
    SET_ROLES_COUNT: (state, value) => {
        state.roles.count = value
    },
    SET_ROLES_CURRENT_URL: (state, value) => {
        state.roles.currentUrl = value
    },
    SET_ROLES_LOADING: (state) => {
        state.roles.loading = true
    },
    SET_ROLES_LOADING_END: (state) => {
        state.roles.loading = false
    },
    SET_DETAIL_DATA_LOADING: (state) => {
        state.roles.personalDataLoading = true
    },
    SET_DETAIL_DATA_LOADING_END: (state) => {
        state.roles.personalDataLoading = false
    },
    SAVE_ROLES_ERROR: (state, error) => {
        state.roles.error = error
    }
}


export default {
    namespaced: false,
    state,
    mutations,
    actions,
    getters
}
