<template>
  <div>
    <div class="header-label-text mt-1 ml-3">
      {{ name }}
      <span v-if="isRequired" class="red--text">*</span>
    </div>
    <v-select
        rows="1"
        class="py-0 my-0 ml-3"
        outlined
        :items="settings.list_value"
        v-model="value"
        :error="!!(errorMessage && errorMessage.length > 0)"
        :errorMessages="errorMessage"
        @change="$emit('update:inputValue', settings.list_value.indexOf(value))"
        clearable
    >
      <template v-slot:label>
      </template>
      <template v-slot:append-outer>
        <v-tooltip bottom max-width="320px" v-if="helpText">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                class="mr-5 ml-3"
                v-bind="attrs"
                v-on="on"
                color="primary"
            >
              help
            </v-icon>
          </template>
          <span v-html="helpText"></span>
        </v-tooltip>
        <div v-else style="width: 56px"></div>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: "VisitorFormSelector",
  props: {
    inputValue: Number,
    errorMessage: String,
    isRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    index: Number,
    name: String,
    helpText: String,
    settings: Object,
  },
  data() {
    return {
      value: undefined,
    }
  },
  created() {
    if (this.settings.list_value[this.inputValue]) this.value = this.settings.list_value[this.inputValue]
  }
}
</script>

<style scoped>
.header-label-text {
  text-align: left;
  color: #000;
  font-family: "MADE Evolve Sans normal", serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 10px;
  padding-right: 56px;
}
</style>
