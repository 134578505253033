<template>
  <div>
    <v-col class="pa-0 ma-0" cols="12">
      <v-select
          outlined
          :disabled="edit"
          label="Тип граничных условий"
          :items="choices.date_types"
          v-model="value.date_type"
          :error="dynamicBoundsError && dynamicBoundsError.length > 0"
          :errorMessages="dynamicBoundsError"
      >
      </v-select>
    </v-col>
    <template v-if="value.date_type === 'static'">
      <v-row class="pa-0 ma-0">
        <v-col class="pa-0 ma-0 mt-4" cols="12" sm="6">
          <v-text-field
              :class="$vuetify.breakpoint.smAndUp ? 'mr-2' : ''"
              outlined
              type="datetime-local"
              v-model="value.static_start"
              :error="staticStartError && staticStartError.length > 0"
              :errorMessages="staticStartError"
          >
            <template v-slot:label>
              Нижняя граница допустимых значений
            </template>
          </v-text-field>
        </v-col>
        <v-col class="pa-0 ma-0 mt-4" cols="12" sm="6">
          <v-text-field
              :class="$vuetify.breakpoint.smAndUp ? 'ml-2' : ''"
              outlined
              type="datetime-local"
              v-model="value.static_end"
              :error="staticEndError && staticEndError.length > 0"
              :errorMessages="staticEndError"
          >
            <template v-slot:label>
              Верхняя граница допустимых значений
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </template>
    <template v-else-if="value.date_type === 'dynamic'">
      <v-row class="pa-0 ma-0">
        <v-col class="pa-0 ma-0 mt-4" cols="12">
          <v-text-field
              :class="$vuetify.breakpoint.smAndUp ? 'mr-2' : ''"
              outlined
              type="datetime-local"
              v-model="value.dynamic_start "
              :error="dynamicStartError && dynamicStartError.length > 0"
              :errorMessages="dynamicStartError"
          >
            <template v-slot:label>
              Нижняя граница допустимых значений<span class="red--text"> *</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col class="pa-0 ma-0 mt-4" cols="12" sm="6">
          <v-text-field
              :class="$vuetify.breakpoint.smAndUp ? 'ml-2' : ''"
              outlined
              type="number"
              v-model="value.delta.days"
              :error="!value.delta.days && deltaError && deltaError.length > 0"
              :errorMessages="deltaError"
          >
            <template v-slot:label>
              Количество дней<span class="red--text"> *</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col class="pa-0 ma-0 mt-4" cols="12" sm="6">
          <v-text-field
              :class="$vuetify.breakpoint.smAndUp ? 'ml-2' : ''"
              outlined
              type="number"
              v-model="value.delta.hours"
              :error="!value.delta.hours && deltaError && deltaError.length > 0"
              :errorMessages="deltaError"
          >
            <template v-slot:label>
              Количество часов<span class="red--text"> *</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin"
export default {
  name: "DateWithBounds",
  props: {
    value: {
      required: true,
      type: Object,
      default: () => {return {}},
    },
    edit: Boolean,
    staticEndError: Array,
    staticStartError: Array,
    dynamicStartError: Array,
    deltaError: Array,
    dynamicBoundsError: Array,
  },
  mixins: [FormErrorsMixin],
  components: {},
  data(){
    return {
      choices: {
        date_types: [
          {value: 'static', text: 'Статические',},
          {value: 'dynamic', text: 'Динамические',},
        ]
      }
    }
  },
  watch: {},
  computed: {},
  methods: {},
  created() {}
}
</script>

<style scoped>

</style>
