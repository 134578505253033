<template>
  <scroll-center-dialog v-model="opened" :loading="save_loading" width="760" @close="close" @apply="save"
                        :title="form_title" :scrolled="false">
    <div v-if="loading && loading_choices">
      <v-col cols="12" class="py-0 px-3 mt-4">
        <v-select
            outlined
            disabled
            label="Тип поля информации о посетителе"
            :items="field_types"
            v-model="data.field.field_type"
        ></v-select>
      </v-col>
      <v-row class="ma-0 pa-0">
        <v-col cols="12" class="px-3 py-0 mt-2">
          <v-select
              v-if="data.field.field_type === 9"
              class="py-0 my-0"
              outlined
              :items="data.settings.list_value"
              v-model="data.value"
          ></v-select>
          <v-text-field
              v-else-if="data.field.field_type !== 8 && data.field.field_type !== 4"
              :type="getDataComponentType()"
              outlined
              :label="data.field.name"
              v-model="data.value"
              :error="hasError(data.field.name)"
              :errorMessages="getError(data.field.name)"
          ></v-text-field>
          <template v-else-if="data.field.field_type === 4">
            <v-card-title class="px-0 ma-0 pb-6 pt-0">{{ data.field.name + ':' }}</v-card-title>
            <template v-for="(val, i) in data.value">
              <v-text-field
                  :key="i"
                  outlined
                  label="Введите значение..."
                  v-model="data.value[i]"
                  :error="hasError(data.field.name)"
                  :errorMessages="getError(data.field.name)"
              ></v-text-field>
            </template>
          </template>
          <v-checkbox
              v-else-if="data.field.field_type === 8"
              class="py-0 my-0"
              :label="data.field.check_box_text"
              v-model="data.value"
              :error="hasError(data.field.name)"
              :errorMessages="getError(data.field.name)"
          ></v-checkbox>
        </v-col>
      </v-row>
    </div>
    <v-skeleton-loader
        v-else
        type="table-heading, list-item-three-line, image, list-item-two-line, table-tfoot"
    ></v-skeleton-loader>
  </scroll-center-dialog>
</template>

<script>
import {mapActions} from "vuex";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import ScrollCenterDialog from "@/modules/templates/ScrollCenterDialog.vue";

export default {
  name: "OrganiserDataEditDialog",
  props: {
    opened: Boolean,
    edit: Boolean,
    dataId: Number,
  },
  mixins: [FormErrorsMixin],
  components: {
    ScrollCenterDialog,
  },
  data() {
    return {
      loading: false,
      loading_choices: false,
      save_loading: false,
      field: 0,
      data: {
        value: '',
      },
      field_types: null
    }
  },
  computed: {
    form_title() {
      return !this.edit ? 'Добавить информацию' : 'Редактировать информацию'
    },
  },
  methods: {
    ...mapActions({
      changeData: 'organiser/changeData',
      loadFieldTypeChoices: 'choices/loadFieldTypeChoices',
      loadDataDetail: 'organiser/loadDataDetail',
    }),
    getDataComponentType() {
      if (this.data.field.field_type === 0) return 'text'
      if (this.data.field.field_type === 1) return 'datetime-local'
      if (this.data.field.field_type === 2) return 'date'
      if (this.data.field.field_type === 3) return 'time'
      if (this.data.field.field_type === 6) return 'number'

      return 'text'
    },
    changeDataFunction(data) {
      this.changeData({
        data: data,
        visit_id: this.$route.params.idVisit,
        id: this.dataId,
        finalizer: () => {
          this.save_loading = false;
          this.close();
        },
        catcher: (val) => {
          this.mapErrors(val)
          this.save_loading = false
        },
      })
    },
    close() {
      this.$emit('close', false)
    },
    save() {
      this.save_loading = true;
      let data = this.data
      if (this.edit) {
        if (this.data.field.field_type === 9) {
          data.value = data.settings.list_value.indexOf(data.value)
        }
        this.changeDataFunction(data)
      }
    },
    loadData() {
      this.loading_choices = false;
      this.loading = false;

      this.loadFieldTypeChoices({
        finalizer: (data) => {
          this.field_types = data
          this.loading_choices = true
        }
      })
      if (this.dataId) {
        this.loadDataDetail({
          visit_id: this.$route.params.idVisit,
          id: this.dataId,
          finalizer: (data) => {
            this.data = data
            this.loading = true;
          }
        })
      }
      else {
        this.loading = true;
      }
    },
  },
  created() {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
