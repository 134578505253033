<template>
  <v-container class="page-content px-5" style="  width: 100%">
    <div v-if="$route.name === names.VISITORS.LIST">
      <v-toolbar
          class="removeBtnPaddingRight rounded-t-lg"
          color="#DADADA"
          flat
      >
        <v-text-field
            v-model="search.value"
            class="dark-primary my-1"
            clearable
            dense
            outlined
            label="Поиск"
            hide-details
            @keydown.enter="searchData"
            @click:clear="updateData"
        ></v-text-field>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-data-table
          :headers="headers"
          :items="items"
          :loading="isLoading"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.size"
          :server-items-length="count"
          :options.sync="options"
          :footer-props="{'items-per-page-options':[10,50,100]}"
          class="elevation-1 rounded-b-lg rounded-t-0"
      >
        <template v-slot:item.fio="{ item }">
          <span
              @click="openDetail(item.id)"
              style="text-decoration: underline; color: #065aad; cursor: pointer"
          >
            {{ item.fio }}
          </span>
        </template>
        <template v-slot:no-data>
          <v-btn
              color="primary"
              @click="updateData"
          >
            Обновить
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <router-view v-else></router-view>
  </v-container>
</template>


<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import viewer from "@/urls/roles/viewer";
import names_core from "@/modules/core/routers/names";
import names from "@/modules/viewer/routers/names";

export default {
  name: "ViewerVisitorsList",
  components: {},
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  data() {
    return {
      page_title: "Список посетителей",
      search: {
        parameter: 'fio',
      },
      selectors: {
        model: [],
      },
      headers: [
        {text: 'ФИО', value: 'fio', sortable: false, align: 'start', width: '40%'},
        {text: 'Почта', value: 'email', sortable: false, align: 'start', width: '40%'},
      ],
    }
  },
  computed: {
    ...mapGetters({
      items: 'viewer/getVisitorsList',
      count: 'viewer/getVisitorsCount',
      isLoading: 'viewer/isLoadingVisitors'
    }),
    names: () => names,
  },
  methods: {
    ...mapActions({
      loadVisitorsList: 'viewer/loadVisitorsList',
    }),
    updateData(){
      if (this.search.value) {
        this.search.value = ''
      }
      this.loadData()
    },
    loadData(url = undefined) {
      this.loadVisitorsList({
        url: url,
        urlAppend: (url) => {
          return this.addPageParameter(this.addSizeParameter(url));
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
        }
      })
    },
    checkFlag(flag) {
      if (flag) return {icon: "check_circle", color: "green"}
      else return {icon: "not_interested", color: "gray"}
    },
    openDetail(id) {
      this.$router.push({name: names.VISITORS.DETAIL, params: {idVisitor: id}})
    },
  },
  created() {
    if (viewer.VIEWER.ALLOWED()){
      if(this.$route.name === names.VISITORS.LIST){
        this.loadData()
      }
    } else{
      this.$router.push({name: names_core.MAIN})
    }
  },
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0;
}
</style>
