<template>
  <div>
    <v-toolbar
        class="removeBtnPaddingRight rounded-t-lg"
        color="#DADADA"
        flat
    >
      <slot name="btn"></slot>
    </v-toolbar>
    <v-card-text>
      <v-row no-gutters class="text-left subtitle-1 py-md-1">
        <v-col cols="7" md="3" class="font-weight-bold">Тип слота:</v-col>
        <v-col cols="5" md="3" class="text-right text-md-left">{{ getSlotType(item).text }}</v-col>
        <v-col cols="7" md="3" class="font-weight-bold">Слот "По умолчанию":</v-col>
        <v-col cols="5" md="3" class="text-right text-md-left">
          <v-icon
              :color="checkFlag(item.is_default).color"
          >
            {{ checkFlag(item.is_default).icon }}
          </v-icon>
        </v-col>
      </v-row>
      <v-row no-gutters class="text-left subtitle-1 py-md-1">
        <v-col cols="7" md="3" class="font-weight-bold">Даты слота:</v-col>
        <v-col cols="5" md="3" class="text-right text-md-left" v-if="getSlotType(item).value === 0">{{ displayDate(item.date) }}</v-col>
        <v-col cols="5" md="3" class="text-right text-md-left" v-if="getSlotType(item).value === 1" v-html="getIntervalsList(item)"></v-col>
        <v-col cols="5" md="3" class="text-right text-md-left" v-if="getSlotType(item).value === 2">-</v-col>
        <v-col cols="7" md="3" class="font-weight-bold">Максимальное количество посетителей:</v-col>
        <v-col cols="5" md="3" class="text-right text-md-left" v-if="item.count">{{ item.count }}</v-col>
      </v-row>
      <v-row no-gutters class="text-left subtitle-1 py-md-1">
        <v-col cols="7" md="3" class="font-weight-bold">Начало времени слота:</v-col>
        <v-col cols="5" md="3" class="text-right text-md-left">{{ item.start }}</v-col>
        <v-col cols="7" md="3" class="font-weight-bold">Конец времени слота:</v-col>
        <v-col cols="5" md="3" class="text-right text-md-left">{{ item.end }}</v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import {displayDate} from "@/helper";

export default {
  name: "SlotDetail",
  props: {
    item:{
      type: Object,
    },
  },
  data() {
    return {
      slot_types: [
        {text: 'Обычный слот', value: 0},
        {text: 'Интервальный слот', value: 1},
        {text: 'Слот события', value: 2},
      ],
    }
  },
  methods:{
    displayDate: displayDate,
    getIntervalsList(item) {
      let string = ``;
      for (let i = 0; i < item.intervals.length; i++) {
        string += this.displayDate(item.intervals[i].start) + '-' + this.displayDate(item.intervals[i].end)
        if (i !== item.intervals.length - 1) {
          string += '<br/>'
        }
      }
      return string;
    },
    getSlotType(item) {
      if (item.date) {
        return this.slot_types[0]
      }

      if (item.intervals && item.intervals.length > 0) {
        return this.slot_types[1]
      }

      return this.slot_types[2]
    },
    checkFlag(flag) {
      if (flag) return {icon: "check_circle", color: "green"}
      else return {icon: "not_interested", color: "gray"}
    },
  },
  created() {
  }
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0px;
}
</style>
