<template>
  <td
      :colspan="outerHeadersLength"
      style="background-color: rgba(53,106,158,0.5)"
  >
    <v-container class="page-content px-0" fluid style="width: 100%">
      <v-toolbar
          class="removeBtnPaddingRight rounded-t-lg"
          color="#DADADA"
          dense
          flat
      >
        <v-subheader style="color: #065aad; font-size: 1.2rem" class="pl-0">Список мероприятий</v-subheader>
      </v-toolbar>
      <v-data-table
          :headers="events_headers"
          :items="personalItems"
          :loading="isLoadingDetailData"
          class="elevation-1 rounded-t-0 rounded-b-lg"
      >
        <template v-slot:item.event.name=" { item } ">
          <div style="display:-webkit-box;-webkit-line-clamp:1;-webkit-box-orient:vertical;overflow: hidden; word-break: break-all">
            {{ item.event.name }}
          </div>
        </template>
        <template v-slot:item.is_organiser="{ item }">
          <v-icon
              :color="checkFlag(item.is_organiser).color"
          >
            {{ checkFlag(item.is_organiser).icon }}
          </v-icon>
        </template>
        <template v-slot:item.is_operator="{ item }">
          <v-icon
              :color="checkFlag(item.is_operator).color"
          >
            {{ checkFlag(item.is_operator).icon }}
          </v-icon>
        </template>
        <template v-slot:no-data>
          Пользователь не участвует в каких-либо конкретных событиях
        </template>
      </v-data-table>
    </v-container>
  </td>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "InnerRolesDataTable",
  props: ['outerHeadersLength'],
  computed: {
    ...mapGetters({
      personalItems: 'admin/getPersonalEvents',
      isLoadingDetailData: 'admin/isLoadingPersonalData',
    }),
  },
  data() {
    return {
      events_headers: [
        {text: 'Событие', value: 'event.name', sortable: true, width: '45%', align: 'start'},
        {text: 'Организатор', value: 'is_organiser', sortable: false, width: '15%', align: 'center'},
        {text: 'Оператор', value: 'is_operator', sortable: false, width: '15%', align: 'center'},
        {text: '', value: 'actions', sortable: false, width: '10%', align: 'center'},
      ],
    }
  },
  methods: {
    checkFlag(flag) {
      if (flag) return {icon: "check_circle", color: "green"}
      else return {icon: "not_interested", color: "gray"}
    },
  }
}
</script>

<style scoped>

</style>
