import helper from "./helper";

let getEventsUrlWithParameters = helper.getEventsUrlWithParameters

export default {
    SELECTORS: {
        USERS: {
            ALL: (gets = null) => getEventsUrlWithParameters(
                "selectors|relations|users",
                'get',
                {},
                gets
            ),
        },
        EVENTS: {
            ALL: (gets = null) => getEventsUrlWithParameters(
                "admin|events|selectors|events",
                'get',
                {},
                gets
            ),
        },
        VISITORS: {
            ALL: (gets = null) => getEventsUrlWithParameters(
                "selectors|visitors|visitors",
                'get',
                {},
                gets
            ),
        },
        INTERVALS: {
            ALL: (gets = null) => getEventsUrlWithParameters(
                "selectors|events|intervals",
                'get',
                {},
                gets
            ),
        },
    },

}
