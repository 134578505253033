import {sendGetRequest} from "@/helper/requests";
import viewer from "@/urls/roles/viewer";

const state = {
    records:{
        currentUrl: null,
        items: [],
        loading: false,
        count: 0,
        detail: [],
        error: null,
    }
}

const getters = {
    getRecordsList: state => state.records.items,
    getRecordsCount: state => state.records.count,
    getRecordsCurrentUrl: state => state.records.currentUrl,
    getRecordsDetail: state => state.records.detail,
    isLoadingRecords: state => state.records.loading,
    saveRecordsError: state => state.records.error,
}

const actions = {
    loadRecordsList: ({commit, getters}, {url, urlAppend, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) {
            url = viewer.VIEWER.EVENTS.RECORDS.LIST()
            if (urlAppend) url = urlAppend(url)
        }
        if (!getters.isLoadingRecords){
            commit('SET_RECORDS_LOADING');
            sendGetRequest(
                url, (data, url) => {
                    commit('SET_RECORDS_LIST', data['results']);
                    commit('SET_RECORDS_COUNT', data['count']);
                    commit('SET_RECORDS_CURRENT_URL', url);
                    commit('SET_RECORDS_LOADING_END');
                    finalizer(data, url);
                }, (data, url) => {
                    commit('SET_RECORDS_LOADING_END');
                    commit('SAVE_RECORDS_ERROR', data);
                    catcher(data, url);
                }
            )
        }
    },
}

const mutations = {
    SET_RECORDS_LIST: (state, records) => {
        state.records.items = records
    },
    SET_RECORDS_DETAIL: (state, value) => {
        state.records.detail = value
    },
    SET_RECORDS_COUNT: (state, value) => {
        state.records.count = value
    },
    SET_RECORDS_CURRENT_URL: (state, value) => {
        state.records.currentUrl = value
    },
    SET_RECORDS_LOADING: (state) => {
        state.records.loading = true
    },
    SET_RECORDS_LOADING_END: (state) => {
        state.records.loading = false
    },
    SAVE_RECORDS_ERROR: (state, error) => {
        state.records.error = error
    }
}


export default {
    namespaced: false,
    state,
    mutations,
    actions,
    getters
}
