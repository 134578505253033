<template>
  <div>
    <v-card v-if="render_component" class="rounded-b-0">
      <EventDetail
          v-if="loading"
          :item="item"
          :role="role"
      >
        <template v-slot:btn>
          <v-btn
              class="dark-primary ml-0"
              icon
              small
              @click="returnParent"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-items v-if="$vuetify.breakpoint.smAndUp && item.allow_update">
            <v-btn
                v-if="eventIsEditable"
                text
                @click="editEvent(item)"
                class="dark-primary mr-0 rounded-0"
            >
              Редактировать событие
            </v-btn>
            <v-btn
                v-if="!item.parent && !!item.is_draft && !item.is_end"
                text
                @click="helpEventDialog('опубликовать')"
                class="dark-primary mr-0 rounded-tr-lg"
            >
              Опубликовать событие
            </v-btn>
            <v-btn
                v-if="!item.parent && !item.is_draft && !item.is_end"
                text
                @click="helpEventDialog('скрыть')"
                class="dark-primary mr-0 rounded-tr-lg"
            >
              Отменить публикацию
            </v-btn>
          </v-toolbar-items>
          <v-btn
              v-if="$vuetify.breakpoint.xsOnly && eventIsEditable && item.allow_update"
              icon
              class="dark-primary mr-0"
              @click="editEvent(item)"
          >
            <v-icon>edit</v-icon>
          </v-btn>
          <v-btn
              v-if="!item.parent && $vuetify.breakpoint.xsOnly && !!item.is_draft && !item.is_end && item.allow_update"
              icon
              class="dark-primary mr-2"
              @click="helpEventDialog('опубликовать')"
          >
            <v-icon>cloud_sync</v-icon>
          </v-btn>
          <v-btn
              v-if="!item.parent && $vuetify.breakpoint.xsOnly && !item.is_draft && !item.is_end && item.allow_update"
              icon
              class="dark-primary mr-0"
              @click="helpEventDialog('скрыть')"
          >
            <v-icon>cloud_off</v-icon>
          </v-btn>
        </template>
        <template v-slot:event-time-btn>
          <v-row v-if="eventIsEditable && item.allow_update && (item.is_have_records || item.is_have_visits)">
            <v-col cols="12" class="mb-5" :class="$vuetify.breakpoint.xsOnly ? 'px-2' : ''">
              <v-btn outlined color="#065aad" @click="changeDatesDialog">
                Изменить даты события
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </EventDetail>
    </v-card>
    <EventDetailEventsList
        v-if="this.loading"
        :role="role"
        :event-end="item.end"
        :event-start="item.start"
        :parent-event-is-editable="eventIsEditable"
        :organiser-allow-update="item.allow_update"
        :needReloadListEvents="need_reload_list_events"
        @notNeedReloadListEvents="notNeedReloadListEvents"
        @updateEventData="loadData"
    >
    </EventDetailEventsList>
    <EventDetailFieldsList
        v-if="!item.parent && this.loading"
        :role="role"
        :organiser-allow-update="item.allow_update"
        :parent-event-is-editable="eventIsEditable"
        :needReloadListFields="need_reload_list_fields"
        @notNeedReloadListFields="notNeedReloadListFields"
    >
    </EventDetailFieldsList>
    <div class="action mt-8 mb-4 align-center" v-if="!this.loading">
      <semipolar-spinner
          class="d-inline-block"
          :animation-duration="2000"
          :size="115"
          color="#1935ff"
      />
    </div>
    <EditDialog
        v-if="dialog_add_edit"
        :opened="dialog_add_edit"
        :edit="edit_status"
        :event-id="item.id"
        :root-event="!item.parent"
        @close="closeEditDialog"
    ></EditDialog>
    <ApplyDialog
        v-if="dialog_active"
        :opened="dialog_active"
        :get-text="() => dialog_text"
        @close="closeEventDialog"
    ></ApplyDialog>
    <HideEventMessageDialog
        :item="item"
        :opened="opened_hide_msg_dialog"
        @close="closeMessageDialog"
    ></HideEventMessageDialog>
    <PublishEventMessageDialog
        :opened="opened_publish_msg_dialog"
        @close="closeMessageDialog"
    ></PublishEventMessageDialog>
    <ChangeEventDatesDialog
        v-if="dialog_change_dates"
        :opened="dialog_change_dates"
        :start="item.start"
        :end="item.end"
        @close="closeChangeDatesDialog"
    ></ChangeEventDatesDialog>
  </div>
</template>

<script>
import TitledPageMixin from "@/mixins/TitledPageMixin";
import EditDialog from "@/modules/organiser/events/modals/EventsEditDialog";
import {mapActions, mapGetters} from "vuex";
import names from "@/modules/organiser/routers/names";
import EventDetail from "@/modules/templates/EventDetail";
import EventDetailEventsList from "@/modules/core/components/event/EventDetailEventsList";
import EventDetailFieldsList from "@/modules/core/components/event/EventDetailFieldsList";
import organiser from "@/urls/roles/organiser";
import names_core from "@/modules/core/routers/names";
import ApplyDialog from "@/modules/core/components/ApplyDialog.vue";
import HideEventMessageDialog from "@/modules/organiser/events/modals/HideEventMessageDialog.vue";
import PublishEventMessageDialog from "@/modules/organiser/events/modals/PublishEventMessageDialog.vue";
import ChangeEventDatesDialog from "@/modules/organiser/events/modals/ChangeEventDatesDialog.vue";
import {SemipolarSpinner} from "epic-spinners";

export default {
  name: "OrganiserEventsDetail",
  props:{
    needReload: {
      type: Boolean,
    }
  },
  mixins: [TitledPageMixin],
  components: {
    ChangeEventDatesDialog,
    EditDialog,
    EventDetail,
    EventDetailEventsList,
    EventDetailFieldsList,
    ApplyDialog,
    HideEventMessageDialog,
    PublishEventMessageDialog,
    SemipolarSpinner
  },
  data() {
    return {
      loading: false,
      page_title: "",
      default_page_title: 'Детальное представление события',
      dialog_add_edit: false,
      dialog_change_dates: false,
      edit_status: undefined,
      event_id: undefined,
      role: 'Organiser',
      need_reload_list_events: false,
      need_reload_list_fields: false,
      dialog_active: false,
      dialog_text: '',
      opened_hide_msg_dialog: false,
      opened_publish_msg_dialog: false,
      msg: '',
      render_component: true,
    }
  },
  watch: {
    needReload(){
      if(this.needReload) {
        this.updatePageTitle()
        this.loadData()
        this.need_reload_list_events = true
        this.need_reload_list_fields = true
        this.$emit("notNeedReload", !this.needReload)
      }
    }
  },
  computed: {
    ...mapGetters({
      item: 'organiser/getEventsDetail',
    }),
    names: () => names,
    eventIsEditable() {
      return !(this.item.is_end && !this.item.is_draft)
    },
  },
  methods: {
    ...mapActions({
      loadEventDetail: 'organiser/loadEventsDetail',
      publishEvents: 'organiser/publishEvents',
      unpublishEvents: 'organiser/unpublishEvents',
      loadLightDetail: 'organiser/loadEventsLightDetail',
      changeEventDetailLoadingState: 'leftmenu/changeEventDetailLoadingState'
    }),
    changeDatesDialog() {
      this.dialog_change_dates = true
    },
    closeChangeDatesDialog() {
      this.dialog_change_dates = false;
      this.loadData()
    },
    returnParent() {
      if (!this.item.parent) {
        this.$router.push({name: names.EVENTS.LIST})
      } else {
        this.$router.push({name: names.EVENTS.DETAIL, params: {idEvent: this.item.parent.id}})
      }
    },
    loadData(url=undefined) {
      this.loading = false;
      this.loadEventDetail({
        url: url,
        id: this.$route.params.idEvent,
        finalizer: (data) => {
          this.loading = true
          this.changeEventDetailLoadingState({loading_state: false})
          this.$emit("notNeedReload", false)
        }
      })
    },
    editEvent() {
      this.dialog_add_edit = true
      this.edit_status = true
      this.event_id = this.item.id
    },
    closeEditDialog() {
      this.loadData()
      this.dialog_add_edit = false
      this.edit_status = undefined
      this.event_id = undefined
    },
    forceButtonsRerender() {
      this.render_component = false;
      this.$nextTick(() => {
        this.render_component = true;
      })
    },
    notNeedReloadListEvents(){
      this.need_reload_list_events = false
    },
    notNeedReloadListFields(){
      this.need_reload_list_fields = false
    },
    helpEventDialog(action_string) {
      if (this.item.is_have_records || this.item.is_have_visits) {
        this.dialog_text = `Вы уверены, что хотите `+ action_string + ` событие? После этого потребуется составить текст письма для оповещения посетителей данного события.`;
      } else {
        this.dialog_text = `Вы уверены, что хотите `+ action_string + ` событие?`;
        if (action_string === 'опубликовать') {
          this.dialog_text += ' Оно станет видимым для пользователей системы.'
        }
      }
      this.dialog_active = true;
    },
    closeEventDialog(confirmed) {
      if (confirmed) {
        if (!this.item.is_draft) {
          if (this.item.is_have_records || this.item.is_have_visits) {
            this.opened_hide_msg_dialog = true;
          } else {
            this.hideEvent()
          }
        } else {
          if (this.item.is_have_records || this.item.is_have_visits) {
            this.opened_publish_msg_dialog = true;
          } else {
            this.publishEvent()
          }
        }
      }

      this.dialog_active = false;
    },
    closeMessageDialog(confirmed, msg) {
      if (confirmed) {
        this.msg = msg;
        if (this.item.is_draft) {
          this.publishEvent()
        } else {
          this.hideEvent()
        }
      }
      this.opened_publish_msg_dialog = false;
      this.opened_hide_msg_dialog = false;
    },
    hideEvent() {
      this.unpublishEvents({
        id: this.item.id,
        data: (this.item.is_have_records || this.item.is_have_visits) ? { msg: this.msg } : {},
        finalizer: () => {
          this.loadData()
          this.forceButtonsRerender()
        },
        catcher: () => {},
      })
    },
    publishEvent() {
      this.publishEvents({
        id: this.item.id,
        data: (this.item.is_have_records || this.item.is_have_visits) ? { msg: this.msg } : {},
        finalizer: () => {
          this.loadData()
          this.forceButtonsRerender()
        },
        catcher: () => {},
      })
    },
    updatePageTitle() {
      this.loadLightDetail({
        id: this.$route.params.idEvent,
        finalizer: (data) => {
          this.page_title = this.default_page_title + ': ' + data.name
        }
      })
    },
  },
  beforeRouteUpdate(to, from, next){
    next()
  },
  created () {
    if (organiser.ORGANISER.ALLOWED()){
      if(this.$route.name === names.EVENTS.DETAIL){
        this.updatePageTitle()
        this.loadData()
      }
    } else{
      this.$router.push({name: names_core.MAIN})
    }
  },
}
</script>

<style scoped>

</style>
