<template>
  <div>
    <v-col cols="12">
      <v-row
          class="mx-1 text-h6 font-weight-bold mt-2 mb-1"
          :class="($vuetify.breakpoint.smAndUp ? '' : ' justify-center')"
      >
        Посещения и записи
      </v-row>
    </v-col>
    <v-data-table
        class="mx-4"
        :item-class="getExpandedItemClass"
        :headers="headers"
        :items="visits"
        :loading="isLoadingVisits"
        :page.sync="pagination.page"
        :expanded.sync="expanded"
        show-expand
        single-expand
        :items-per-page.sync="pagination.size"
        :options.sync="options"
        :footer-props="{'items-per-page-options':[10,25,50]}"
    >
      <template v-slot:item.event.start="{ item }">
        {{ displayDate(item.event.start) }}
      </template>
      <template v-slot:item.event.end="{ item }">
        {{ displayDate(item.event.end) }}
      </template>
      <template v-slot:item.is_enter="{ item }">
        <v-icon
            :color="checkEnterFlag(item.is_enter).color"
        >
          {{ checkEnterFlag(item.is_enter).icon }}
        </v-icon>
      </template>
      <template v-slot:item.is_cancel="{ item }">
        <v-icon
            :color="checkCancelFlag(item.is_cancel).color"
        >
          {{ checkCancelFlag(item.is_cancel).icon }}
        </v-icon>
      </template>
      <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
        <v-btn
            v-if="item.have_records"
            icon
            @click="expand(!isExpanded)"
            :ref="`expand_button_${item.id}`">
          <v-icon v-if="isExpanded">
            expand_less
          </v-icon>
          <v-icon v-else>
            expand_more
          </v-icon>
        </v-btn>
        <!-- Пустой v-else, т.к. темплэйт не может быть пустым и Vue не отрабатывает условие v-if -->
        <div v-else></div>
      </template>
      <template v-slot:expanded-item>
        <RecordsList
            :outer-headers-length="headers.length"
        ></RecordsList>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import formErrorsMixin from "@/mixins/FormErrorsMixin";
import RecordsList from "@/modules/viewer/visitors/components/RecordsList";
import {displayDate} from "@/helper";

export default {
  name: "ViewerVisitorsVisitsList",
  components: {RecordsList},
  mixins: [PaginatedDataMapperMixin, formErrorsMixin],
  data() {
    return {
      headers: [
        {text: 'Событие', value: 'event.name', sortable: false, align: 'start', width: '40%'},
        {text: 'Дата начала', value: 'event.start', sortable: false, align: 'center', width: '20%'},
        {text: 'Дата окончания', value: 'event.end', sortable: false, align: 'center', width: '20%'},
        {text: 'Посещено', value: 'is_enter', sortable: false, align: 'center', width: '10%'},
        {text: 'Отменено', value: 'is_cancel', sortable: false, align: 'center', width: '10%'},
        {text: 'Действия', value: 'data-table-expand', sortable: false, align: 'center', width: '10%'},
      ],
      expanded: [],
      dialog_text: '',
    }
  },
  computed: {
    ...mapGetters({
      visits: 'viewer/getVisitsList',
      count: 'viewer/getVisitsCount',
      isLoadingVisits: 'viewer/isLoadingVisits'
    }),
  },
  watch: {
    expanded: function (newVal) {
      newVal.forEach(el => {
        if (el.id !== undefined) {
          this.loadRecordsData(el.id);
        }
      });
    },
  },
  methods: {
    displayDate: displayDate,
    ...mapActions({
      loadVisitsList: 'viewer/loadVisitsList',
      loadRecordsList: 'viewer/loadRecordsList',
    }),
    getExpandedItemClass(item) {
      if (this.expanded.length > 0 && this.expanded[0].id === item.id || this.expanded.length === 0) {
        return "white";
      }
      return "grey lighten-3";
    },
    checkEnterFlag(flag) {
      if (flag) return {icon: "check_circle", color: "green"}
      else return {icon: "not_interested", color: "grey"}
    },
    checkCancelFlag(flag) {
      if (flag) return {icon: "check_circle", color: "yellow darken-3"}
      else return {icon: "not_interested", color: "grey"}
    },
    loadRecordsData(id) {
      this.loadRecordsList({
        url: undefined,
        urlAppend: (url) => {
          return this.addPageParameter(
              this.addSizeParameter(
                  this.addGetParameter(url, 'visit', id)
              )
          )
        },
        // finalizer: (data, url) => {
        // this.setCurrentLoadUrl(url);
        // }
      })
    },
    loadData(url = undefined) {
      this.loadVisitsList({
        url: url,
        urlAppend: (url) => {
          return this.addPageParameter(
              this.addSizeParameter(
                  this.addGetParameter(url, 'visitor', this.$route.params.idVisitor)
              )
          )
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
        }
      })
    },
  },
  created() {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
