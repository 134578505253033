<template>
  <table v-if="$vuetify.breakpoint.lgAndUp">
    <thead>
      <tr class="table-row">
        <td class="table-col table-col__name">
          Название события
        </td>
        <td class="table-col table-col__detail">

        </td>
        <td class="table-col table-col__date">
          Дата начала события
        </td>
        <td class="table-col table-col__date">
          Дата конца события
        </td>
        <td class="table-col table-col__icon">
          Есть записи
        </td>
        <td class="table-col table-col__icon">
          Посещено
        </td>
        <td class="table-col table-col__icon">
          Отменено
        </td>
        <td class="table-col table-col__cancel">

        </td>
      </tr>
    </thead>
    <tbody>
      <SingleVisit
        v-for="(item, i) in items" :key="i"
        :item="item"
        :bg-color-index="getBackgroundColorIndex(i)"
        @cancelVisit="cancelVisitEmit"
      ></SingleVisit>
    </tbody>
  </table>
  <v-row v-else class="pa-0 ma-0">
    <v-col cols="12" sm="6" md="4" class="pa-0 ma-0" v-for="(item, i) in items" :key="i">
      <SingleVisit
        :item="item"
        :bg-color-index="getBackgroundColorIndex(i)"
        @cancelVisit="cancelVisitEmit"
      ></SingleVisit>
    </v-col>
  </v-row>
</template>

<script>
import SingleVisit from "@/modules/templates/visitor/visits_list/SingleVisit.vue";

export default {
  name: "VisitsTable",
  components: {SingleVisit},
  props: {
    items: Array,
  },
  methods: {
    cancelVisitEmit(item) {
      this.$emit("cancelVisit", item)
    },
    getBackgroundColorIndex(index) {
      // Функция страшной математики для шахматного порядка цветов на любом разрешении
      let cards_in_row = 1;

      if (this.$vuetify.breakpoint.sm) cards_in_row = 2
      else if (this.$vuetify.breakpoint.md) cards_in_row = 3
      else if (this.$vuetify.breakpoint.lgAndUp) cards_in_row = 1

      let row_number = Math.floor(index / cards_in_row)
      if (cards_in_row % 2 === 1 && row_number % 2 === 1) row_number++;
      return (index + row_number + 1) % 2
    },
  },
}
</script>

<style scoped lang="scss">
table {
  width: 100%;
  border-spacing: 0;
}
.table-row {
  width: 100%;
  padding: 25px 0;
  color: #7C7C7C;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.table-col {
  margin-right: 2%;
  padding: 10px 5px;
  @media (min-width: 1713px) {
    padding: 15px 8px;
  }
}
.table-col__name {
  text-align: left;
  width: 16%;
  padding-left: 2%;
  @media (min-width: 1454px) and (max-width: 1712px) {
    width: 21%;
  }
  @media (min-width: 1713px) {
    width: 27%;
  }
}

.table-col__detail {
  width: 11%;
  @media (min-width: 1454px) and (max-width: 1712px) {
    width: 9%;
  }
  @media (min-width: 1713px) {
    width: 8%;
  }
}

.table-col__date {
  width: 8%;
}

.table-col__icon {
  width: 7%;
}

.table-col__cancel {
  width: 21%;
  @media (min-width: 1454px) and (max-width: 1712px) {
    width: 16%;
  }
  @media (min-width: 1713px) {
    width: 13%;
  }
  padding-right: 2%;
}
</style>
