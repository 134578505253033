import { sendGetRequest } from "@/helper/requests";
import choices from "@/urls/choices";

const state = {
}

const getters = {

}

const actions = {
    loadAccessTypeChoices: ({commit}, {finalizer, catcher}) => {
        let url = choices.CHOICES.EVENTS.ACCESS_TYPE()
        sendGetRequest(
            url, finalizer, catcher
        )
    },
    loadFieldTypeChoices: ({commit}, {finalizer, catcher}) => {
        let url = choices.CHOICES.EVENTS.FIELD_TYPE()
        sendGetRequest(
            url, finalizer, catcher
        )
    },
    loadBlockTypeChoices: ({commit}, {finalizer, catcher}) => {
        let url = choices.CHOICES.EVENTS.BLOCK_TYPE()
        sendGetRequest(
            url, finalizer, catcher
        )
    },
    loadRegexpTypeChoices: ({commit}, {finalizer, catcher}) => {
        let url = choices.CHOICES.EVENTS.REGEXP_TYPES()
        sendGetRequest(
            url, finalizer, catcher
        )
    },
}

const mutations = {

}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}
