import {sendGetRequest} from "@/helper/requests";
import viewer from "@/urls/roles/viewer";
import intervals from "@/store/modules/viewer/events/intervals";
import slots from "@/store/modules/viewer/events/slots";
import visits from "@/store/modules/viewer/events/visits";
import invites from "@/store/modules/viewer/events/invites";
import blocks from "@/store/modules/viewer/events/blocks";
import fields from "@/store/modules/viewer/events/fields";
import data from "@/store/modules/viewer/events/data";
import records from "@/store/modules/viewer/events/records";

const state = {
    events:{
        currentUrl: null,
        items: [],
        loading: false,
        count: 0,
        detail: [],
        light_detail: [],
        error: null,
    }
}

const getters = {
    getEventsList: state => state.events.items,
    getEventsCount: state => state.events.count,
    getEventsCurrentUrl: state => state.events.currentUrl,
    getEventsDetail: state => state.events.detail,
    getEventsLightDetail: state => state.events.light_detail,
    isLoadingEvents: state => state.events.loading,
    saveEventsError: state => state.events.error,
}

const actions = {
    loadEventsList: ({commit, getters}, {url, urlAppend, is_parent, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) {
            url = viewer.VIEWER.EVENTS.LIST()
            if (urlAppend) url = urlAppend(url)
        }
        if (!getters.isLoadingEvents || (getters.getEventsCurrentUrl !== url && is_parent === false)){
            commit('SET_EVENTS_LOADING');
            sendGetRequest(
                url, (data, url) => {
                    commit('SET_EVENTS_LIST', data['results']);
                    commit('SET_EVENTS_COUNT', data['count']);
                    commit('SET_EVENTS_CURRENT_URL', url);
                    commit('SET_EVENTS_LOADING_END');
                    finalizer(data, url);
                }, (data, url) => {
                    commit('SET_EVENTS_LOADING_END');
                    commit('SAVE_EVENTS_ERROR', data);
                    catcher(data, url);
                }
            )
        }
    },
    loadEventsDetail: ({commit}, {url, id, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) url = viewer.VIEWER.EVENTS.DETAIL(id)
        sendGetRequest(
            url, (data, url) => {
                commit('SET_EVENTS_DETAIL', data);
                finalizer(data, url);
            }, (data, url) => {
                commit('SAVE_EVENTS_ERROR', data);
                catcher(data, url);
            }
        )
    },
    loadEventsLightDetail: ({commit}, {url, id, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) url = viewer.VIEWER.EVENTS.LIGHT_DETAIL(id)
        sendGetRequest(
            url, (data, url) => {
                commit('SET_EVENTS_LIGHT_DETAIL', data);
                finalizer(data, url);
            }, (data, url) => {
                commit('SAVE_EVENTS_ERROR', data);
                catcher(data, url);
            }
        )
    },
}

const mutations = {
    SET_EVENTS_LIST: (state, events) => {
        state.events.items = events
    },
    SET_EVENTS_DETAIL: (state, value) => {
        state.events.detail = value
    },
    SET_EVENTS_LIGHT_DETAIL: (state, value) => {
        state.events.light_detail = value
    },
    SET_EVENTS_COUNT: (state, value) => {
        state.events.count = value
    },
    SET_EVENTS_CURRENT_URL: (state, value) => {
        state.events.currentUrl = value
    },
    SET_EVENTS_LOADING: (state) => {
        state.events.loading = true
    },
    SET_EVENTS_LOADING_END: (state) => {
        state.events.loading = false
    },
    SAVE_EVENTS_ERROR: (state, error) => {
        state.events.error = error
    },
}

export default {
    namespaced: false,
    state,
    mutations,
    actions,
    getters,
    modules: {
        intervals,
        slots,
        visits,
        invites,
        blocks,
        fields,
        data,
        records
    }
}
