const ORGANISER= {
    BASE: 'Organiser',
    MAIN: undefined,
    EVENTS: {
        _BASE: undefined,
        LIST: undefined,
        DETAIL: undefined,
        PREVIEW: {
            _BASE: undefined,
            MAIN: undefined,
        },
        INTERVALS: {
            _BASE: undefined,
            LIST: undefined,
            DETAIL: undefined,
        },
        SLOTS: {
            _BASE: undefined,
            LIST: undefined,
            DETAIL: undefined,
        },
        VISITS: {
            _BASE: undefined,
            LIST: undefined,
            DETAIL: undefined,
        },
        INVITES: {
            _BASE: undefined,
            LIST: undefined,
            DETAIL: undefined,
        },
        BLOCKS: {
            _BASE: undefined,
            LIST: undefined,
            DETAIL: undefined,
        },
        ROLES: {
            _BASE: undefined,
            LIST: undefined,
            DETAIL: undefined,
        },
    },
    FIND_VISITS:{
        _BASE: undefined,
        LIST: undefined,
    }
};

ORGANISER.MAIN = `${ORGANISER.BASE}|Main`

ORGANISER.EVENTS._BASE = `${ORGANISER.BASE}|Events`
ORGANISER.EVENTS.LIST = `${ORGANISER.EVENTS._BASE}|List`
ORGANISER.EVENTS.DETAIL = `${ORGANISER.EVENTS._BASE}|Detail`

ORGANISER.EVENTS.PREVIEW._BASE = `${ORGANISER.EVENTS.DETAIL}|Preview`
ORGANISER.EVENTS.PREVIEW.MAIN = `${ORGANISER.EVENTS.PREVIEW._BASE}|Main`

ORGANISER.EVENTS.INTERVALS._BASE = `${ORGANISER.EVENTS.DETAIL}|Intervals`
ORGANISER.EVENTS.INTERVALS.LIST = `${ORGANISER.EVENTS.INTERVALS._BASE}|List`
ORGANISER.EVENTS.INTERVALS.DETAIL = `${ORGANISER.EVENTS.INTERVALS._BASE}|Detail`

ORGANISER.EVENTS.SLOTS._BASE = `${ORGANISER.EVENTS.DETAIL}|Slots`
ORGANISER.EVENTS.SLOTS.LIST = `${ORGANISER.EVENTS.SLOTS._BASE}|List`
ORGANISER.EVENTS.SLOTS.DETAIL = `${ORGANISER.EVENTS.SLOTS._BASE}|Detail`

ORGANISER.EVENTS.VISITS._BASE = `${ORGANISER.EVENTS.DETAIL}|Visits`
ORGANISER.EVENTS.VISITS.LIST = `${ORGANISER.EVENTS.VISITS._BASE}|List`
ORGANISER.EVENTS.VISITS.DETAIL = `${ORGANISER.EVENTS.VISITS._BASE}|Detail`

ORGANISER.EVENTS.INVITES._BASE = `${ORGANISER.EVENTS.DETAIL}|Invites`
ORGANISER.EVENTS.INVITES.LIST = `${ORGANISER.EVENTS.INVITES._BASE}|List`
ORGANISER.EVENTS.INVITES.DETAIL = `${ORGANISER.EVENTS.INVITES._BASE}|Detail`

ORGANISER.EVENTS.BLOCKS._BASE = `${ORGANISER.EVENTS.DETAIL}|Blocks`
ORGANISER.EVENTS.BLOCKS.LIST = `${ORGANISER.EVENTS.BLOCKS._BASE}|List`
ORGANISER.EVENTS.BLOCKS.DETAIL = `${ORGANISER.EVENTS.BLOCKS._BASE}|Detail`

ORGANISER.EVENTS.ROLES._BASE = `${ORGANISER.EVENTS.DETAIL}|Roles`
ORGANISER.EVENTS.ROLES.LIST = `${ORGANISER.EVENTS.ROLES._BASE}|List`
ORGANISER.EVENTS.ROLES.DETAIL = `${ORGANISER.EVENTS.ROLES._BASE}|Detail`

ORGANISER.FIND_VISITS._BASE = `${ORGANISER.BASE}|FindVisits`
ORGANISER.FIND_VISITS.LIST = `${ORGANISER.FIND_VISITS._BASE}|List`

export default {
    ...ORGANISER
}