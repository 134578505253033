const state = {
    enabled: false,
    show: false,
    items: [],
    instances: [],
    currentInstance: undefined,
    activeRouter: undefined,
    eventDetailLoading: false,
    needEventMenuReload: false,
};

const getters = {
    getLeftMenuShow: state => state.show,
    isEventDetailLoading: state => state.eventDetailLoading,
    needEventMenuReload: state => state.needEventMenuReload,
}

const actions = {
    changeEventDetailLoadingState: ({commit}, {loading_state}) => {
        commit('setEventDetailLoadingState', loading_state)
    },
    changeNeedEventMenuReloadState: ({commit}, {value}) => {
        commit('SET_EVENTS_NEED_MENU_RELOAD', value)
    },
}

const mutations = {
    setEventDetailLoadingState(state, loading_state) {
        state.eventDetailLoading = loading_state
    },
    enableLeftMenu(state) {
        state.enabled = true;
    },
    dissable(state) {
        state.enabled = false;
        state.show = false;
    },
    show(state) {
        state.show = true;
    },
    hide(state) {
        state.show = false;
    },
    toggleVisible(state) {
        state.show = !state.show;
    },
    replaceItems(state, items) {
        state.items = items
    },
    replaceInstances(state, instances) {
        state.instances = instances;
    },
    addItem(state, item) {
        state.items.push(item)
    },
    appendItems(state, items) {
        state.items.push(...items)
    },
    appendItem(state, item) {
        state.items.push(item)
    },
    selectInstance(state, name) {
        state.currentInstance = name
    },
    activeRouterChange(state, activeRouterName) {
        state.activeRouter = activeRouterName
    },
    SET_EVENTS_NEED_MENU_RELOAD: (state, value) => {
        state.needEventMenuReload = value
    },
};


export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}
