<template>
  <v-container class="page-content px-5" style="  width: 100%">
    <div v-if="$route.name === names.VISITORS.LIST">
      <v-toolbar
          class="removeBtnPaddingRight rounded-t-lg"
          color="#DADADA"
          flat
      >
        <v-text-field
            v-model="search.value"
            class="dark-primary my-1"
            clearable
            dense
            outlined
            label="Поиск"
            hide-details
            @keydown.enter="searchData"
            @click:clear="clearSearchData"
        ></v-text-field>
        <v-select
            label="Статус"
            class="dark-primary mr-1 ml-2"
            outlined
            dense
            hide-details
            v-model="pagination.filters.is_not_accounts_user"
            :items="status_choices"
        ></v-select>
        <v-spacer></v-spacer>
        <v-toolbar-items v-if="$vuetify.breakpoint.smAndUp">
          <v-btn
              text
              @click="addVisitor"
              class="dark-primary mr-0 rounded-tr-lg"
          >
            Создать посетителя
          </v-btn>
        </v-toolbar-items>
        <v-btn
            v-if="$vuetify.breakpoint.xsOnly"
            icon
            class="dark-primary mr-0"
            @click="addVisitor"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
          :headers="headers"
          :items="items"
          :loading="isLoading"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.size"
          :server-items-length="count"
          :options.sync="options"
          :footer-props="{'items-per-page-options':[10,50,100]}"
          class="elevation-1 rounded-b-lg rounded-t-0"
      >
        <template v-slot:item.fio="{ item }">
          <span
              @click="openDetail(item.id)"
              style="text-decoration: underline; color: #065aad; cursor: pointer"
          >
            {{ item.fio }}
          </span>
        </template>
        <template v-slot:item.muctr_user="{ item }">
          <v-icon
              :color="checkFlag(item.user).color"
          >
            {{ checkFlag(item.user).icon }}
          </v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom v-if="!item.user">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click="editVisitor(item.id)"
                v-bind="attrs"
                v-on="on"
                class="mr-2"
              >
                edit
              </v-icon>
            </template>
            <span>Редактировать посетителя</span>
          </v-tooltip>
          <v-tooltip bottom v-if="!item.user">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  @click="deleteVisitor(item.id)"
                  v-bind="attrs"
                  v-on="on"
              >
                delete
              </v-icon>
            </template>
            <span>Удалить посетителя</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-btn
              color="primary"
              @click="updateData"
          >
            Обновить
          </v-btn>
        </template>
      </v-data-table>
      <EditDialog
        v-if="dialog_add_edit"
        :opened="dialog_add_edit"
        :edit="edit_status"
        :visitor-id="visitor_id"
        @close="closeEditDialog"
      ></EditDialog>
      <ApplyDialog
          v-if="dialog_delete"
          :opened="dialog_delete"
          :get-text="() => `Вы уверены, что хотите удалить данного пользователя ?`"
          @close="closeDeleteDialog"
      ></ApplyDialog>
    </div>
    <router-view v-else></router-view>
  </v-container>
</template>


<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import admin from "@/urls/roles/admin";
import names_core from "@/modules/core/routers/names";
import names from "@/modules/admin/routers/names";
import EditDialog from "@/modules/admin/visitors/modals/VisitorsEditDialog.vue";
import ApplyDialog from "@/modules/core/components/ApplyDialog";

export default {
  name: "AdminVisitorsList",
  components: { EditDialog, ApplyDialog },
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  data() {
    return {
      page_title: "Список посетителей",
      search: {
        parameter: 'fio',
      },
      headers: [
        {text: 'ФИО', value: 'fio', sortable: false, align: 'start', width: '37%'},
        {text: 'Почта', value: 'email', sortable: false, align: 'start', width: '37%'},
        {text: 'Пользователь MUCTR', value: 'muctr_user', sortable: false, align: 'center', width: '16%'},
        {text: 'Действия', value: 'actions', sortable: false, align: 'center', width: '10%'}
      ],
      visitor_id: undefined,
      edit_status: undefined,
      dialog_add_edit: false,
      dialog_delete: false,
      status_choices: [
        {text: 'Все', value: null},
        {text: 'Пользователь MUCTR', value: 'false'},
        {text: 'Внешний пользователь', value: 'true'},
      ],
      pagination: {
        filters: {
          'is_not_accounts_user': null,
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      items: 'admin/getVisitorsList',
      count: 'admin/getVisitorsCount',
      isLoading: 'admin/isLoadingVisitors'
    }),
    names: () => names,
  },
  watch: {
  },
  methods: {
    ...mapActions({
      loadVisitorsList: 'admin/loadVisitorsList',
      deleteVisitors: 'admin/deleteVisitors',
    }),
    updateData(){
      if (this.search.value) {
        this.search.value = ''
        this.loadData()
      }
      this.pagination.filters.is_not_accounts_user = null
    },
    loadData(url = undefined) {
      this.loadVisitorsList({
        url: url,
        urlAppend: (url) => {
          let new_url = this.addPageParameter(this.addSizeParameter(url))
          if (this.search.value)
            new_url = this.addGetParameter(new_url, 'fio', this.search.value)
          if (this.pagination.filters.is_not_accounts_user)
            new_url = this.addGetParameter(new_url, 'is_not_accounts_user', this.pagination.filters.is_not_accounts_user)
          return new_url
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
        }
      })
    },
    checkFlag(flag) {
      if (flag) return {icon: "check_circle", color: "green"}
      else return {icon: "not_interested", color: "gray"}
    },
    openDetail(id) {
      this.$router.push({name: names.VISITORS.DETAIL, params: {idVisitor: id}})
    },
    addVisitor() {
      this.dialog_add_edit = true
      this.edit_status = false
    },
    editVisitor(id) {
      this.dialog_add_edit = true
      this.edit_status = true
      this.visitor_id = id
    },
    deleteVisitor(id) {
      this.dialog_delete = true
      this.visitor_id = id
    },
    closeEditDialog() {
      this.loadData()
      this.dialog_add_edit = false
      this.user_id = undefined
    },
    closeDeleteDialog(delete_visitor) {
      if (delete_visitor) {
        this.deleteVisitors({
          id: this.visitor_id,
          finalizer: () => {
            this.dialog_delete = false;
            this.loadData()
          },
          catcher: () => {}
        })
      } else {
        this.dialog_delete = false;
      }
    }
  },
  created() {
    if (admin.ADMIN.ALLOWED()){
      if(this.$route.name === names.VISITORS.LIST){
        this.loadData()
      }
    } else{
      this.$router.push({name: names_core.MAIN})
    }
  },
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0;
}
</style>
