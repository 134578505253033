import {sendPostRequest, sendPatchRequest, sendDeleteRequest, sendGetRequest, sendPutRequest} from "@/helper/requests";
import admin from "@/urls/roles/admin";
import {executeRequest} from "@/store/utils";

const state = {
    intervals:{
        currentUrl: null,
        items: [],
        loading: false,
        count: 0,
        detail: [],
        error: null,
    }
}

const getters = {
    getIntervalsList: state => state.intervals.items,
    getIntervalsCount: state => state.intervals.count,
    getIntervalsCurrentUrl: state => state.intervals.currentUrl,
    getIntervalsDetail: state => state.intervals.detail,
    isLoadingIntervals: state => state.intervals.loading,
    saveIntervalsError: state => state.intervals.error,
}

const actions = {
    loadIntervalsList: ({commit, getters}, {url, event_id, urlAppend, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) {
            url = admin.ADMIN.EVENTS.INTERVALS.LIST(event_id)
            if (urlAppend) url = urlAppend(url)
        }
        if (!getters.isLoadingIntervals){
            commit('SET_INTERVALS_LOADING');
            sendGetRequest(
                url, (data, url) => {
                    commit('SET_INTERVALS_LIST', data['results']);
                    commit('SET_INTERVALS_COUNT', data['count']);
                    commit('SET_INTERVALS_CURRENT_URL', url);
                    commit('SET_INTERVALS_LOADING_END');
                    finalizer(data, url);
                }, (data, url) => {
                    commit('SET_INTERVALS_LOADING_END');
                    commit('SAVE_INTERVALS_ERROR', data);
                    catcher(data, url);
                }
            )
        }
    },
    loadIntervalDetail: ({commit}, {url, event_id, id, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) url = admin.ADMIN.EVENTS.INTERVALS.DETAIL(event_id, id)
        sendGetRequest(
            url, (data, url) => {
                commit('SET_INTERVALS_DETAIL', data);
                finalizer(data, url);
            }, (data, url) => {
                commit('SAVE_INTERVALS_ERROR', data);
                catcher(data, url);
            }
        )
    },
    createIntervals: ({dispatch, commit, getters}, {data, event_id, finalizer=()=>{}, catcher=()=>{}}) => {
        let url = admin.ADMIN.EVENTS.INTERVALS.CREATE(event_id)
        executeRequest('Intervals', sendPostRequest, {url, data, finalizer, catcher}, {dispatch, commit, getters});
    },
    changeIntervals: ({dispatch, commit, getters}, {data, event_id, id, finalizer,catcher}) => {
        let url = admin.ADMIN.EVENTS.INTERVALS.UPDATE(event_id, id)
        executeRequest('Intervals', sendPatchRequest, {url, data, finalizer, catcher}, {dispatch, commit, getters});
    },
    deleteIntervals: ({dispatch, commit, getters}, {event_id, id, finalizer, catcher}) => {
        let url = admin.ADMIN.EVENTS.INTERVALS.DELETE(event_id, id)
        executeRequest('Intervals', sendDeleteRequest, {url, finalizer, catcher}, {dispatch, commit, getters});
    },
}

const mutations = {
    SET_INTERVALS_LIST: (state, intervals) => {
        state.intervals.items = intervals
    },
    SET_INTERVALS_DETAIL: (state, value) => {
        state.intervals.detail = value
    },
    SET_INTERVALS_COUNT: (state, value) => {
        state.intervals.count = value
    },
    SET_INTERVALS_CURRENT_URL: (state, value) => {
        state.intervals.currentUrl = value
    },
    SET_INTERVALS_LOADING: (state) => {
        state.intervals.loading = true
    },
    SET_INTERVALS_LOADING_END: (state) => {
        state.intervals.loading = false
    },
    SAVE_INTERVALS_ERROR: (state, error) => {
        state.intervals.error = error
    }
}

export default {
    namespaced: false,
    state,
    mutations,
    actions,
    getters
}
