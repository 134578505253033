export default {
    data() {
        return {
            empty_row: {
                cols: [],
                cols_amount: 0,
                id: undefined,
            },
            empty_block: {
                data: {
                    row: undefined,
                    col: undefined,
                    cols_amount: undefined,
                    colspan: 1,
                    data: {},
                },
                selected: 8,
                type: 9,
                id: undefined,
                files: [],
                new_files: [],
                all_files: [],
                files_delete: [],
            },
            new_row_id: 1,
            new_block_id: 1,
            empty_header_block_data: {
                text: '',
            },
            empty_text_block_data: {
                header: '',
                text: '',
            },
            blocks_before_changes: undefined,
            blocks_after_changes: undefined,
            empty_spacer_block_data: {},
            empty_image_block_data: {},
            empty_slider_block_data: {},
            empty_entries_slider_block_data: {
                texts: ['', ''],
                headers: ['', ''],
            },
            empty_files_list_block_data: {
                header: ''
            },
            empty_entries_list_block_data: {
                texts: ['', ''],
                headers: ['', ''],
            },
            empty_null_block_data: {
                text: '',
            },
            create: [],
            create_id: [],
            change: [],
            delete: [],
            requests_left: -1,
            empty_errors: {
                file: '',
                empty: '',
                text: '',
                header: '',
                files: [],
                texts: [],
                backend_files: [],
            },
            rows: [],
            rows_amount: 0,
            side_buttons: false,
        }
    },
    methods: {
        scrollTopSizeHandler(e) {
            if (!this.side_buttons && e.target.scrollTop >= 500) {
                this.side_buttons = true;
            } else if (e.target.scrollTop < 500 && this.side_buttons) {
                this.side_buttons = false;
            }
        },
        rowIsFull(row) {
            // Предикат проверки заполненности строки
            let cols_occupied = 0;
            for (let i = 0; i < row.cols.length; i++) {
                if (row.cols[i]) cols_occupied += row.cols[i].data.colspan;
                if (cols_occupied >= 4) return true
            }
            return false
        },
        rowHasFullSizeBlock(row) {
            // Предикат наличия полноразмерного блока
            for (let i = 0; i < row.cols.length; i++) {
                if (row.cols[i]) {
                    if (row.cols[i].type === 2 || row.cols[i].type === 1 || row.cols[i].type === 5 || row.cols[i].type === 7) return true;
                }
            }
            return false;
        },
        deleteEntry(block_id, entry_number) {
            // Функция удаления записи
            for (let i = 0; i < this.rows.length; i++) {
                for (let j = 0; j < this.rows[i].cols.length; j++) {
                    // находим наш блок
                    if (this.rows[i].cols[j] && this.rows[i].cols[j].id === block_id) {
                        // удаление текста и заголовка
                        this.rows[i].cols[j].data.data.texts.splice(entry_number, 1)
                        this.rows[i].cols[j].data.data.headers.splice(entry_number, 1)

                        // удаление файла
                        this.deleteOneFile(block_id, entry_number, true)

                        // перерендер страницы
                        this.forceRerender()
                        return;
                    }
                }
            }
        },
        addEmptyEntry(block_id) {
            // Функция добавления пустой записи
            for (let i = 0; i < this.rows.length; i++) {
                for (let j = 0; j < this.rows[i].cols.length; j++) {
                    // находим наш блок
                    if (this.rows[i].cols[j] && this.rows[i].cols[j].id === block_id) {
                        // добавление пустой записи
                        if (!this.rows[i].cols[j].data.data.texts) this.rows[i].cols[j].data.data.texts = []
                        if (!this.rows[i].cols[j].data.data.headers) this.rows[i].cols[j].data.data.headers = []
                        this.rows[i].cols[j].data.data.texts.push("")
                        this.rows[i].cols[j].data.data.headers.push("")
                        this.rows[i].cols[j].new_files.push(null)

                        // перерендер страницы
                        this.forceRerender()
                        return;
                    }
                }
            }
        },
        moveEntryDown(block_id, entry_number) {
            // Функция добавления пустой записи
            for (let i = 0; i < this.rows.length; i++) {
                for (let j = 0; j < this.rows[i].cols.length; j++) {
                    // находим наш блок
                    if (this.rows[i].cols[j] && this.rows[i].cols[j].id === block_id) {
                        // если уже внизу, то конец
                        if (this.rows[i].cols[j].data.data.texts.length - 1 === entry_number) return;

                        // меняем местами тексты
                        let tmp = this.rows[i].cols[j].data.data.texts[entry_number];
                        this.rows[i].cols[j].data.data.texts[entry_number] = this.rows[i].cols[j].data.data.texts[entry_number + 1]
                        this.rows[i].cols[j].data.data.texts[entry_number + 1] = tmp

                        // меняем местами хэдеры
                        tmp = this.rows[i].cols[j].data.data.headers[entry_number];
                        this.rows[i].cols[j].data.data.headers[entry_number] = this.rows[i].cols[j].data.data.headers[entry_number + 1]
                        this.rows[i].cols[j].data.data.headers[entry_number + 1] = tmp

                        // меняем местами файлы
                        tmp = this.rows[i].cols[j].all_files[entry_number];
                        this.rows[i].cols[j].all_files[entry_number] = this.rows[i].cols[j].all_files[entry_number + 1]
                        this.rows[i].cols[j].all_files[entry_number + 1] = tmp

                        // перерендер страницы
                        this.forceRerender()
                        return;
                    }
                }
            }
        },
        moveEntryUp(block_id, entry_number) {
            // Функция добавления пустой записи
            for (let i = 0; i < this.rows.length; i++) {
                for (let j = 0; j < this.rows[i].cols.length; j++) {
                    // находим наш блок
                    if (this.rows[i].cols[j] && this.rows[i].cols[j].id === block_id) {
                        // если уже наверху, то конец
                        if (entry_number === 0) return;

                        // меняем местами тексты
                        let tmp = this.rows[i].cols[j].data.data.texts[entry_number];
                        this.rows[i].cols[j].data.data.texts[entry_number] = this.rows[i].cols[j].data.data.texts[entry_number - 1]
                        this.rows[i].cols[j].data.data.texts[entry_number - 1] = tmp

                        // меняем местами хэдеры
                        tmp = this.rows[i].cols[j].data.data.headers[entry_number];
                        this.rows[i].cols[j].data.data.headers[entry_number] = this.rows[i].cols[j].data.data.headers[entry_number - 1]
                        this.rows[i].cols[j].data.data.headers[entry_number - 1] = tmp

                        // меняем местами файлы
                        tmp = this.rows[i].cols[j].all_files[entry_number];
                        this.rows[i].cols[j].all_files[entry_number] = this.rows[i].cols[j].all_files[entry_number - 1]
                        this.rows[i].cols[j].all_files[entry_number - 1] = tmp

                        // перерендер страницы
                        this.forceRerender()
                        return;
                    }
                }
            }
        },
        loadFilesFromInput(block_id) {
            // Функция подгрузки файлов из новых в общий массив файлов (старых и новых)
            for (let i = 0; i < this.rows.length; i++) {
                for (let j = 0; j < this.rows[i].cols.length; j++) {
                    // находим наш блок
                    if (this.rows[i].cols[j] && this.rows[i].cols[j].id === block_id) {
                        // если блок изображение, то у него file-input возвращает элемент, а не массив
                        if (this.rows[i].cols[j].type === 3) {
                            this.rows[i].cols[j].new_files = [this.rows[i].cols[j].new_files];
                        }

                        // Если в блоке "Изображение" уже был файл, то удаляем его
                        if (this.rows[i].cols[j].type === 3 && this.rows[i].cols[j].all_files[0]) {
                            this.deleteOneFile(block_id, 0);
                        }

                        // отдельное поведение для списков и слайдеров записей
                        if (this.rows[i].cols[j].type === 7 || this.rows[i].cols[j].type === 5) {
                            for (let k = 0; k < this.rows[i].cols[j].new_files.length; k++) {
                                // если i-ый файл новый существует, то с тем же индексом сохраняем в общий массив
                                if (this.rows[i].cols[j].new_files[k]) {
                                    // предварительно удаляем старый файл
                                    this.deleteOneFile(block_id, k)
                                    this.rows[i].cols[j].all_files[k] = structuredClone(this.rows[i].cols[j].new_files[k]);
                                    delete this.rows[i].cols[j].new_files[k];
                                }
                            }
                        }
                        else {
                            // если не слайдер/список записей, то просто переводим все файлы из новых в общий массив
                            while (this.rows[i].cols[j].new_files.length > 0) {
                                this.rows[i].cols[j].all_files.push(this.rows[i].cols[j].new_files.pop());
                            }
                        }

                        // перерендер страницы
                        this.forceRerender();
                        return;
                    }
                }
            }
        },
        moveFileUp(block_id, file_index) {
            // Функция движения файла вверх
            for (let i = 0; i < this.rows.length; i++) {
                for (let j = 0; j < this.rows[i].cols.length; j++) {
                    // находим наш блок
                    if (this.rows[i].cols[j] && this.rows[i].cols[j].id === block_id) {
                        // если файл уже наверху, то конец
                        if (file_index === 0) return;

                        // если нет, то меняем файлы местами
                        let tmp = this.rows[i].cols[j].all_files[file_index - 1];
                        this.rows[i].cols[j].all_files[file_index - 1] = this.rows[i].cols[j].all_files[file_index];
                        this.rows[i].cols[j].all_files[file_index] = tmp;

                        // двигаем ошибки файлов вместе с файлами
                        if (this.rows[i].cols[j].errors.backend_files[file_index]
                            && this.rows[i].cols[j].errors.backend_files[file_index].length > 0
                            || this.rows[i].cols[j].errors.backend_files[file_index - 1]
                            && this.rows[i].cols[j].errors.backend_files[file_index - 1].length > 0
                        ) {
                            tmp = this.rows[i].cols[j].errors.backend_files[file_index - 1]
                            this.rows[i].cols[j].errors.backend_files[file_index - 1] = this.rows[i].cols[j].errors.backend_files[file_index];
                            this.rows[i].cols[j].errors.backend_files[file_index] = tmp;
                        }

                        // перерендер всей страницы
                        this.forceRerender();
                        return;
                    }
                }
            }
        },
        moveFileDown(block_id, file_index) {
            // Функция движения файла вниз
            for (let i = 0; i < this.rows.length; i++) {
                for (let j = 0; j < this.rows[i].cols.length; j++) {
                    // находим наш блок
                    if (this.rows[i].cols[j] && this.rows[i].cols[j].id === block_id) {
                        // если файл уже внизу, то конец
                        if (file_index === this.rows[i].cols[j].all_files.length - 1) return;

                        // если нет, то меняем файлы местами
                        let tmp = this.rows[i].cols[j].all_files[file_index + 1];
                        this.rows[i].cols[j].all_files[file_index + 1] = this.rows[i].cols[j].all_files[file_index];
                        this.rows[i].cols[j].all_files[file_index] = tmp;

                        // двигаем ошибки файлов вместе с файлами
                        if (this.rows[i].cols[j].errors.backend_files[file_index]
                            && this.rows[i].cols[j].errors.backend_files[file_index].length > 0
                            || this.rows[i].cols[j].errors.backend_files[file_index + 1]
                            && this.rows[i].cols[j].errors.backend_files[file_index + 1].length > 0
                        ) {
                            tmp = this.rows[i].cols[j].errors.backend_files[file_index + 1]
                            this.rows[i].cols[j].errors.backend_files[file_index + 1] = this.rows[i].cols[j].errors.backend_files[file_index];
                            this.rows[i].cols[j].errors.backend_files[file_index] = tmp;
                        }

                        // перерендер всей страницы
                        this.forceRerender();
                        return;
                    }
                }
            }
        },
        deleteOneFile(block_id, file_index, deleting_entry) {
            // Функция удаления файла
            for (let i = 0; i < this.rows.length; i++) {
                for (let j = 0; j < this.rows[i].cols.length; j++) {
                    // находим наш блок
                    if (this.rows[i].cols[j] && this.rows[i].cols[j].id === block_id) {
                        // сохраняем файл
                        let file = this.rows[i].cols[j].all_files[file_index];

                        // если список/слайдер записей
                        if (this.rows[i].cols[j].type === 7 || this.rows[i].cols[j].type === 5) {
                            // если удаляем запись, то сплайсим
                            if (deleting_entry) {
                                this.rows[i].cols[j].all_files.splice(file_index, 1)
                                // подчистка ошибок, если есть
                                if (this.rows[i].cols[j].errors.backend_files[file_index]
                                    && this.rows[i].cols[j].errors.backend_files[file_index].length > 0) {
                                    this.rows[i].cols[j].errors.backend_files.splice(file_index, 1)
                                }
                            } else {
                                // в противном случае просто удаляем файл
                                this.rows[i].cols[j].all_files[file_index] = undefined
                                // подчистка ошибок, если есть
                                if (this.rows[i].cols[j].errors.backend_files[file_index]
                                    && this.rows[i].cols[j].errors.backend_files[file_index].length > 0) {
                                    this.rows[i].cols[j].errors.backend_files[file_index] = undefined
                                }
                            }
                        } else {
                            this.rows[i].cols[j].all_files.splice(file_index, 1)
                            // подчистка ошибок, если есть
                            if (this.rows[i].cols[j].errors.backend_files[file_index]
                                && this.rows[i].cols[j].errors.backend_files[file_index].length > 0) {
                                this.rows[i].cols[j].errors.backend_files.splice(file_index, 1)
                            }
                        }

                        // если файл уже существовал, то добавляем в удалённые файлы
                        if (file) {
                            if (file.id) {
                                let index = this.rows[i].cols[j].files.indexOf(file)
                                this.rows[i].cols[j].files_delete.push(this.rows[i].cols[j].files[index].id)
                                this.rows[i].cols[j].files.splice(index, 1)
                            }
                        }

                        // перерендер страницы
                        this.forceRerender()
                        return;
                    }
                }
            }
        },
        moveRowUp(row) {
            // Функция движения строки вверх
            let row_number = row.row_number;

            // если строка уже в самом верху - ничего не делаем
            if (row_number === 0) return;

            // меняем строки местами
            let current_row = structuredClone(this.rows[row_number]);
            this.rows[row_number] = structuredClone(this.rows[row_number - 1]);
            this.rows[row_number - 1] = current_row;
            this.rows[row_number].row_number = row_number
            this.rows[row_number - 1].row_number = row_number - 1

            // устанавливаем правильное значение row для строк, поменявшихся местами
            for (let i = row_number - 1; i <= row_number; i++) {
                for (let j = 0; j < this.rows[i].cols.length; j++) {
                    if (this.rows[i].cols[j]) {
                        this.rows[i].cols[j].data.row = i
                    }
                }
            }

            // перерендер страницы
            this.forceRerender()
        },
        moveRowDown(row) {
            // Функция движения строки вниз
            let row_number = row.row_number;

            // если строка уже в самом низу - ничего не делаем
            if (row_number === this.rows.length - 1) return;

            // меняем строки местами
            let current_row = structuredClone(this.rows[row_number]);
            this.rows[row_number] = structuredClone(this.rows[row_number + 1]);
            this.rows[row_number + 1] = current_row;
            this.rows[row_number].row_number = row_number
            this.rows[row_number + 1].row_number = row_number + 1

            // устанавливаем правильное значение row для строк, поменявшихся местами
            for (let i = row_number; i <= row_number + 1; i++) {
                for (let j = 0; j < this.rows[i].cols.length; j++) {
                    if (this.rows[i].cols[j]) {
                        this.rows[i].cols[j].data.row = i
                    }
                }
            }

            // перерендер страницы
            this.forceRerender()
        },
        moveBlockLeft(id) {
            // Функция движения блока влево в одной строке
            for (let i = 0; i < this.rows.length; i++) {
                for (let j = 0; j < this.rows[i].cols.length; j++) {
                    // находим наш блок
                    if (this.rows[i].cols[j] && this.rows[i].cols[j].id === id) {

                        // если блок уже максимально слева - ничего не делаем
                        if (j === 0) return;
                        let prev_block_index = -1;
                        let previous_block;

                        // находим следующий слева блок, сохраняем его, а на его место ставим наш блок
                        for (let k = j - 1; k >= 0; k--) {
                            if (this.rows[i].cols[k]) {
                                previous_block = structuredClone(this.rows[i].cols[k])
                                this.rows[i].cols[k] = structuredClone(this.rows[i].cols[j])
                                this.rows[i].cols[k].data.col = k;
                                delete this.rows[i].cols[j]
                                prev_block_index = k;
                                break;
                            }
                        }

                        // на всякий случай отработка, что такой блок не найден
                        if (prev_block_index === -1) return;

                        // ставим следующий блок слева на место нашего блока
                        this.rows[i].cols[prev_block_index + this.rows[i].cols[prev_block_index].data.colspan] = previous_block;
                        this.rows[i].cols[prev_block_index + this.rows[i].cols[prev_block_index].data.colspan].data.col
                            = prev_block_index + this.rows[i].cols[prev_block_index].data.colspan;

                        // перерендр всей страницы
                        this.forceRerender()
                        return;
                    }
                }
            }
        },
        moveBlockRight(id) {
            // Функция движения блока вправо в одной строке
            for (let i = 0; i < this.rows.length; i++) {
                for (let j = 0; j < this.rows[i].cols.length; j++) {
                    // находим наш блок
                    if (this.rows[i].cols[j] && this.rows[i].cols[j].id === id) {
                        // если блок уже максимально справа - ничего не делаем
                        if (j === this.rows[i].cols.length - 1) return;

                        // сохраняем найденный блок
                        let current_block = structuredClone(this.rows[i].cols[j]);
                        let new_index;
                        let block_not_found = true;

                        // ищем следующий блок справа и сохраняем его на позицию первого
                        for (let k = j + 1; k < this.rows[i].cols.length; k++) {
                            if (this.rows[i].cols[k]) {
                                this.rows[i].cols[j] = structuredClone(this.rows[i].cols[k])
                                this.rows[i].cols[j].data.col = j;
                                delete this.rows[i].cols[k];
                                new_index = k
                                block_not_found = false;
                                break;
                            }
                        }

                        // на всякий случай отработка, что блок справа не нашли
                        if (block_not_found) return;

                        // сдвигаем текущий блок на правую позицию
                        this.rows[i].cols[new_index] = current_block
                        this.rows[i].cols[new_index].data.col = new_index

                        // перерендер страницы
                        this.forceRerender()
                        return;
                    }
                }
            }
        },
        extendBlock(id) {
            // Функция расширения блока
            for (let i = 0; i < this.rows.length; i++) {
                for (let j = 0; j < this.rows[i].cols.length; j++) {
                    // находим наш блок
                    if (this.rows[i].cols[j] && this.rows[i].cols[j].id === id) {
                        // не расширяем, если строка уже полна
                        if (this.rowIsFull(this.rows[i])) {
                            this.pop_up_text = 'Невозможно иметь в строке больше 4 блочных единиц';
                            this.pop_up_alert = true;
                            return
                        }

                        // не расширяем, если блок единственный в строке
                        if (this.rows[i].cols_amount <= 1) return;

                        // двигаем все блоки справа от нашего на 1 ячейку вправо
                        for (let k = this.rows[i].cols.length - 1; k > j; k--) {
                            if (this.rows[i].cols[k]) {
                                this.rows[i].cols[k + 1] = structuredClone(this.rows[i].cols[k])
                                this.rows[i].cols[k + 1].data.col++;
                                delete this.rows[i].cols[k];
                            }
                        }

                        // увеличиваем размер нашего блока
                        this.rows[i].cols[j].data.colspan++;

                        // изменяем значение количества колонок во всех блоках строки и самой строке
                        for (let k = 0; k < this.rows[i].cols.length; k++) {
                            if (this.rows[i].cols[k]) {
                                this.rows[i].cols[k].data.cols_amount++;
                            }
                        }
                        this.rows[i].cols_amount++;

                        // перерендер всей страницы
                        this.forceRerender()
                        return;
                    }
                }
            }
        },
        compressBlock(id) {
            // Функция сужения блока
            for (let i = 0; i < this.rows.length; i++) {
                for (let j = 0; j < this.rows[i].cols.length; j++) {
                    // находим наш блок
                    if (this.rows[i].cols[j] && this.rows[i].cols[j].id === id) {
                        // не сужаем, если блок уже максимально маленький
                        if (this.rows[i].cols[j].data.colspan <= 1) {
                            this.pop_up_text = 'Блок уже имеет минимально возможный размер';
                            this.pop_up_alert = true;
                            return
                        }

                        // двигаем все блоки справа от нашего на 1 ячейку влево
                        for (let k = j + 1; k < this.rows[i].cols.length - 1; k++) {
                            if (this.rows[i].cols[k + 1]) {
                                this.rows[i].cols[k + 1].data.col--;
                                this.rows[i].cols[k] = structuredClone(this.rows[i].cols[k + 1])
                                delete this.rows[i].cols[k + 1];
                            }
                        }
                        this.rows[i].cols[j].data.colspan--;

                        // изменяем значение количества колонок во всех блоках строки и самой строке
                        for (let k = 0; k < this.rows[i].cols.length; k++) {
                            if (this.rows[i].cols[k]) {
                                this.rows[i].cols[k].data.cols_amount--;
                            }
                        }
                        this.rows[i].cols_amount--;

                        // перерендер страницы
                        this.forceRerender()
                        return;
                    }
                }
            }
        },
        deleteBlock(id) {
            // Функция удаления блока
            for (let i = 0; i < this.rows.length; i++) {
                for (let j = 0; j < this.rows[i].cols.length; j++) {
                    // находим наш блок
                    if (this.rows[i].cols[j] && this.rows[i].cols[j].id === id) {
                        let col_colspan = this.rows[i].cols[j].data.colspan
                        // изменяем у всех блоков и самой строки количество колонок в строке
                        for (let k = 0; k < this.rows[i].cols.length; k++) {
                            if (this.rows[i].cols[k]) {
                                this.rows[i].cols[k].data.cols_amount -= col_colspan;
                            }
                        }
                        this.rows[i].cols_amount -= col_colspan

                        // двигаем все блоки слева от удаляемого на ширину блока влево
                        for (let k = j + 1; k < this.rows[i].cols.length; k++) {
                            if (this.rows[i].cols[k]) this.rows[i].cols[k].data.col -= col_colspan;
                        }
                        this.rows[i].cols.splice(j, col_colspan);

                        // Перерендер страницы
                        this.forceRerender();
                        return;
                    }
                }
            }
        },
        deleteRow(row) {
            // Функция удаления строки
            let index = this.rows.indexOf(row);
            // двигаем все блоки ниже на одну строку вверх
            for (let i = index + 1; i < this.rows.length; i++) {
                this.rows[i].row_number--;
                for (let j = 0; j < this.rows[i].cols.length; j++) {
                    if (this.rows[i].cols[j]) {
                        this.rows[i].cols[j].data.row--;
                    }
                }
            }

            // удаляем строку
            if (index !== -1) {
                this.rows.splice(index, 1);
                this.rows_amount--;
            }

            // перерендер страницы
            this.forceRerender()
        },
        chooseBlockType(block_type_number, id) {
            // функция выбора определённого типа блока
            let block_types_data = [ 'empty_text_block_data', 'empty_header_block_data', 'empty_spacer_block_data',
                'empty_image_block_data', 'empty_slider_block_data', 'empty_entries_slider_block_data',
                'empty_files_list_block_data', 'empty_entries_list_block_data', 'empty_null_block_data']

            for (let i = 0; i < this.rows.length; i++) {
                for (let j = 0; j < this.rows[i].cols.length; j++) {
                    // находим наш блок
                    if (this.rows[i].cols[j] && this.rows[i].cols[j].id === id) {
                        // нельзя добавить блоки, занимающие всю строку, если там уже есть какие-то блоки
                        if (this.rows[i].cols_amount > 1 && (block_type_number === 2 || block_type_number === 1 || block_type_number === 5 || block_type_number === 7)) {
                            this.pop_up_text = 'Блоки "Заголовок", "Горизонтальный разделитель", "Список записей" и "Слайдер записей" могут быть добавлены только в пустую строку';
                            this.pop_up_alert = true;
                            return
                        }

                        // отработка того, что у пустого блока уже могла быть ошибка
                        this.rows[i].cols[j].errors.text = ''

                        // присваиваем полю data лока поля, в зависимости от типа блока
                        for (let k = 0; k < this.block_types.length; k++)
                        {
                            if (this.block_types[k].value === block_type_number) {
                                this.rows[i].cols[j].data.data = structuredClone(this[block_types_data[this.block_types[k].value]])
                                this.rows[i].cols[j].type = this.rows[i].cols[j].selected
                                break;
                            }
                        }

                        // перерендер страницы
                        this.forceRerender()
                        return;
                    }
                }
            }
        },
        addEmptyBlock(row) {
            // Функция добавления пустого блока
            // проверка наличия полноразмерного блока
            if (this.rowHasFullSizeBlock(row)) {
                this.pop_up_text = 'Невозможно добавить новый блок в строку, имеющую внутри блоки "Заголовок", "Горизонтальный разделитель", "Список записей" или "Слайдер записей"';
                this.pop_up_alert = true;
                return
            }

            // проверка полноты строки
            if (this.rowIsFull(row)) {
                this.pop_up_text = 'Невозможно иметь в строке больше 4 блочных единиц';
                this.pop_up_alert = true;
                return
            }

            // создаём новый пустой блок, даём ему id
            let new_empty_block = structuredClone(this.empty_block);
            new_empty_block.id = this.new_block_id + '_new';
            this.new_block_id++;

            // обновляем номер строки и количество блоков в строке
            new_empty_block.data.row = row.row_number
            row.cols_amount++;

            // обновляем положение по горизонтали и количество блоков в строке у всех блоков
            new_empty_block.data.col = row.cols_amount - 1
            row.cols[new_empty_block.data.col] = new_empty_block
            for (let i = 0; i < row.cols.length; i++) {
                if (row.cols[i]) row.cols[i].data.cols_amount = row.cols_amount
            }

            // добавляем пустой объект ошибок
            row.cols[new_empty_block.data.col].errors = structuredClone(this.empty_errors)

            // перерендер страницы
            this.forceRerender()
        },
        addEmptyRow() {
            // создаём пустую строку и задаём ей id и номер строки
            let new_empty_row = structuredClone(this.empty_row)
            new_empty_row.id = this.new_row_id + '_new';
            this.new_row_id++;
            new_empty_row.row_number = this.rows_amount;

            // копируем новую строку и увеличиваем количество строк
            this.rows[this.rows_amount] = new_empty_row;
            this.rows_amount++;

            // перерендер страницы
            this.forceRerender()
        },
        formRequestData() {
            // Функция формирования данных для запроса
            // проверка наличия ошибок блоков
            if (this.blocksHaveDataErrors()) {
                this.blocks_alert = true;
                return;
            }
            else {
                this.blocks_alert = false;
            }

            this.blocks_after_changes = []
            this.create = []
            this.change = []
            this.delete = []
            this.requests_left = -1;

            for (let i = 0; i < this.rows.length; i++) {
                for (let j = 0; j < this.rows[i].cols.length; j++) {
                    if (this.rows[i].cols[j]) {
                        this.blocks_after_changes.push(structuredClone(this.rows[i].cols[j]))
                    }
                }
            }

            // поиск удалёных блоков
            for (let i = 0; i < this.blocks_before_changes.length; i++) {
                let block_is_not_deleted = false;
                for (let j = 0; j < this.blocks_after_changes.length; j++) {
                    if (this.blocks_before_changes[i].id === this.blocks_after_changes[j].id) {
                        block_is_not_deleted = true;
                        break;
                    }
                }
                if (!block_is_not_deleted) {
                    this.delete.push(this.blocks_before_changes[i].id)
                    if (this.requests_left === -1) {
                        this.requests_left += 2;
                    }
                    else {
                        this.requests_left++;
                    }
                }
            }

            // поиск созданных блоков
            for (let j = 0; j < this.blocks_after_changes.length; j++) {

                if (typeof this.blocks_after_changes[j].id === 'string') {
                    let created_block = {}
                    created_block.files_create = [];
                    let files_counter = 1;
                    for (let k = 0; k < this.blocks_after_changes[j].all_files.length; k++) {
                        let tmp_file_object = {};
                        tmp_file_object.name = this.blocks_after_changes[j].all_files[k].name;
                        tmp_file_object.index = k;
                        created_block.files_create.push(tmp_file_object)

                        let key_name = 'file' + files_counter;
                        created_block[key_name] = this.blocks_after_changes[j].all_files[k];

                        files_counter++;
                    }
                    created_block.type = this.blocks_after_changes[j].type
                    created_block.data = this.blocks_after_changes[j].data
                    created_block.block_id = this.blocks_after_changes[j].id
                    this.create_id.push(this.blocks_after_changes[j].id)
                    this.create.push(created_block)
                    if (this.requests_left === -1) {
                        this.requests_left += 2;
                    }
                    else {
                        this.requests_left++;
                    }
                }
            }

            // поиск обновлённых блоков
            for (let j = 0; j < this.blocks_after_changes.length; j++) {
                if (this.create_id.includes(this.blocks_after_changes)) continue;
                for (let i = 0; i < this.blocks_before_changes.length; i++) {
                    if (this.blocks_before_changes[i].id === this.blocks_after_changes[j].id && typeof this.blocks_after_changes[j].id !== 'string') {
                        let block_changed = false;

                        let keys = Object.keys(this.blocks_before_changes[i].data)

                        for (let k = 0; k < keys.length; k++) {
                            if (keys[k] === 'data') continue

                            if (this.blocks_before_changes[i].data[keys[k]] !== this.blocks_after_changes[j].data[keys[k]]) {
                                block_changed = true;
                                break;
                            }
                        }

                        let data_keys = Object.keys(this.blocks_before_changes[i].data.data);

                        for (let k = 0; k < data_keys.length; k++) {
                            if (block_changed) break;

                            if (this.blocks_before_changes[i].data.data[data_keys[k]] !== this.blocks_after_changes[j].data.data[data_keys[k]]) {
                                block_changed = true;
                                break;
                            }
                        }

                        if (this.blocks_after_changes[j].all_files.length !== this.blocks_after_changes[j].files.length) block_changed = true;
                        if (this.blocks_after_changes[j].files_delete.length > 0 ) block_changed = true;


                        if (block_changed) {
                            let updated_block = {}
                            updated_block.files_delete = []
                            updated_block.files_create = []
                            updated_block.index_change = []
                            let files_counter = 1;
                            for (let k = 0; k < this.blocks_after_changes[j].all_files.length; k++) {
                                let file = this.blocks_after_changes[j].all_files[k];
                                if (file.id) {
                                    let index = this.blocks_after_changes[j].files.indexOf(file);
                                    if (k !== index) {
                                        let tmp_file_object = {};
                                        tmp_file_object.id = file.id;
                                        tmp_file_object.index = k;
                                        updated_block.index_change.push(tmp_file_object);
                                    }
                                } else {
                                    let tmp_file_object = {};
                                    tmp_file_object.name = file.name;
                                    tmp_file_object.index = k;
                                    updated_block.files_create.push(tmp_file_object)

                                    let key_name = 'file' + files_counter;
                                    updated_block[key_name] = file;

                                    files_counter++;
                                }
                            }
                            updated_block.files_delete = this.blocks_after_changes[j].files_delete
                            updated_block.type = this.blocks_after_changes[j].type
                            updated_block.data = this.blocks_after_changes[j].data
                            updated_block.id = this.blocks_after_changes[j].id
                            this.change.push(updated_block)

                            if (this.requests_left === -1) {
                                this.requests_left += 2;
                            }
                            else {
                                this.requests_left++;
                            }
                        }

                        break;
                    }
                }
            }

            // Выполняем все запросы
            this.executeRequests()
        },
        blocksHaveDataErrors() {
            for (let i = 0; i < this.rows.length; i++) {
                this.rows[i].error = ''
            }
            let have_errors = false;
            for (let i = 0; i < this.rows.length; i++) {
                let row_blocks_amount = 0;
                for (let j = 0; j < this.rows[i].cols.length; j++) {
                    if (this.rows[i].cols[j]) row_blocks_amount++
                }
                if (row_blocks_amount === 0) {
                    have_errors = true;
                    this.rows[i].error = 'Нельзя оставлять пустые строки. Необходимо либо наполнить её блоками, либо удалить.'
                }
            }
            for (let i = 0; i < this.rows.length; i++) {
                for (let j = 0; j < this.rows[i].cols.length; j++) {
                    if (this.rows[i].cols[j]) {
                        // удаление ошибок, связанных с бэком
                        this.rows[i].cols[j].errors.backend_files = []

                        this.rows[i].cols[j].errors
                        if (this.rows[i].cols[j].type === 0) {
                            this.rows[i].cols[j].errors.text = '';
                            if (!this.rows[i].cols[j].data.data.text) {
                                have_errors = true;
                                this.rows[i].cols[j].errors.text = 'Это поле является обязательным для заполнения';
                            }
                            continue;
                        }

                        if (this.rows[i].cols[j].type === 1) {
                            this.rows[i].cols[j].errors.header = '';
                            if (!this.rows[i].cols[j].data.data.header) {
                                have_errors = true;
                                this.rows[i].cols[j].errors.header = 'Это поле является обязательным для заполнения';
                            }
                            continue;
                        }

                        if (this.rows[i].cols[j].type === 2) {
                            continue;
                        }

                        if (this.rows[i].cols[j].type === 3) {
                            this.rows[i].cols[j].errors.file = '';
                            if (this.rows[i].cols[j].all_files.length === 0) {
                                have_errors = true;
                                this.rows[i].cols[j].errors.file = 'Необходимо добавить изображение';
                            }
                            if (this.filesSizeIsTooBig(this.rows[i].cols[j].all_files)) {
                                have_errors = true;
                                this.rows[i].cols[j].errors.file = 'Размер файлов одного блока не должен превышать 15 МБ';
                            }
                            continue;
                        }

                        if (this.rows[i].cols[j].type === 4) {
                            this.rows[i].cols[j].errors.file = '';
                            if (this.rows[i].cols[j].all_files.length === 0) {
                                have_errors = true;
                                this.rows[i].cols[j].errors.file = 'Необходимо добавить хотя бы одно изображение';
                            }
                            if (this.filesSizeIsTooBig(this.rows[i].cols[j].all_files)) {
                                have_errors = true;
                                this.rows[i].cols[j].errors.file = 'Размер файлов одного блока не должен превышать 15 МБ';
                            }
                            continue;
                        }

                        if (this.rows[i].cols[j].type === 5 || this.rows[i].cols[j].type === 7) {
                            this.rows[i].cols[j].errors.empty = '';
                            this.rows[i].cols[j].errors.texts = [];
                            this.rows[i].cols[j].errors.files = []

                            if (this.rows[i].cols[j].data.data.texts.length === 0) {
                                this.rows[i].cols[j].errors.empty = 'Нельзя оставлять список записей пустым';
                                have_errors = true;
                            }

                            for (let k = 0; k < this.rows[i].cols[j].data.data.texts.length; k++) {
                                if (!this.rows[i].cols[j].data.data.texts[k]) {
                                    this.rows[i].cols[j].errors.texts[k] = 'Это поле является обязательным для заполнения';
                                    have_errors = true;
                                }

                                if (!this.rows[i].cols[j].all_files[k]) {
                                    this.rows[i].cols[j].errors.files[k] = 'Необходимо добавить изображение';
                                    have_errors = true;
                                }
                            }
                            if (this.filesSizeIsTooBig(this.rows[i].cols[j].all_files)) {
                                have_errors = true;
                                this.rows[i].cols[j].errors.file = 'Размер файлов одного блока не должен превышать 15 МБ';
                            }
                            continue;
                        }

                        if (this.rows[i].cols[j].type === 6) {
                            this.rows[i].cols[j].errors.file = '';
                            if (this.rows[i].cols[j].all_files.length === 0) {
                                have_errors = true;
                                this.rows[i].cols[j].errors.file = 'Необходимо добавить хотя бы один файл';
                            }
                            if (this.filesSizeIsTooBig(this.rows[i].cols[j].all_files)) {
                                have_errors = true;
                                this.rows[i].cols[j].errors.file = 'Размер файлов одного блока не должен превышать 15 МБ';
                            }
                            continue;
                        }

                        if (this.rows[i].cols[j].type === 8) {
                            this.rows[i].cols[j].errors.text = '';
                            if (!window.document.getElementById('null_block' + this.rows[i].cols[j].id).textContent) {
                                have_errors = true;
                                this.rows[i].cols[j].errors.text = 'Это поле является обязательным для заполнения';
                            }
                            continue;
                        }

                        if (this.rows[i].cols[j].type === 9)
                        {
                            have_errors = true;
                            this.rows[i].cols[j].errors.empty = '';
                            this.rows[i].cols[j].errors.empty = 'Нельзя оставлять пустой блок';
                        }

                    }
                }
            }
            if (have_errors) {
                this.forceRerender()
            }
            return have_errors;
        },
        filesSizeIsTooBig(files) {
            let size_count = 0;
            for (let index in files) {
                size_count += files[index].size
            }
            return size_count / 1024 / 1024 >= 15;
        },
        executeRequests() {
            // Если ничего не изменилось, ничего не делаем
            if (this.delete.length === 0 && this.create.length === 0 && this.change.length === 0) this.edit = false;

            // проход по всем запросам удаления
            for (let id of this.delete) {
                this.deleteBlockFunction(id)
            }

            // проход по всем запросам создания
            for (let data of this.create) {
                this.createBlockFunction(data)
            }

            // проход по всем запросам  изменения
            for (let data of this.change) {
                this.changeBlockFunction(data)
            }
        },
        mapBackEndFilesErrors(id, errors, data) {
            // Функция перебора ошибок, приходящих с бэка о файлах создаваемых/редактируемых блоков блоков
            this.blocks_alert = true;
            for (let i = 0; i < this.rows.length; i++) {
                for (let j = 0; j < this.rows[i].cols.length; j++) {
                    // находим наш блок
                    if (this.rows[i].cols[j] && this.rows[i].cols[j].id === id) {
                        // цикл по номерам файла в списке
                        for (let index in errors) {
                            let file_index;
                            if (typeof id === 'string') {
                                file_index = Number(index)
                            } else {
                                file_index = data.files_create[Number(index)].index
                            }
                            this.rows[i].cols[j].errors.backend_files[file_index] = []
                            // цикл по ошибкам относительно файла
                            for (let error of errors[index]) {
                                this.rows[i].cols[j].errors.backend_files[file_index].push(error)
                            }
                        }
                        this.forceRerender()
                        return;
                    }
                }
            }
        },
        rebuildBlocksToRows() {
            this.rows = []
            this.rows_amount = 0;
            for (let i = 0; i < this.blocks.length; i++)
            {
                // берём положение блока на странице
                let row = this.blocks[i].data.row;
                let col = this.blocks[i].data.col;

                // изменяем количество строчек, если положение по строке выходит за границы
                if (row + 1 > this.rows_amount) this.rows_amount = row + 1;

                // если такой строки ещё не существует, то создаём её
                if (!this.rows[row]) {
                    this.rows[row] = {};
                    this.rows[row].error = '';
                }

                // если массива колонок строки ещё не существует, то добавляем его
                if (!this.rows[row].cols) {
                    this.rows[row].cols = [];
                }

                // добавляем строке необходимые свойства
                this.rows[row].cols[col] = structuredClone(this.blocks[i]);
                this.rows[row].cols[col].errors = structuredClone(this.empty_errors);

                this.rows[row].cols[col].new_files = [];
                this.rows[row].cols[col].all_files = [];
                for (let file of this.rows[row].cols[col].files) {
                    this.rows[row].cols[col].all_files.push(file)
                }
                this.rows[row].cols[col].files_delete = [];

                this.rows[row].id = i;
                this.rows[row].cols_amount = this.rows[row].cols[col].data.cols_amount;
                this.rows[row].row_number = this.rows[row].cols[col].data.row;
                // загружаем все файлы блока
                for (let file of this.rows[row].cols[col].files) {
                    file.src = this.getImage(file.file);
                }
            }

            for (let i = 0; i < this.rows.length; i++) {
                if (!this.rows[i]) {
                    this.rows[i] = this.empty_row
                }
            }
        },
    },
    created() {
        document.getElementById('app-scroll-container').addEventListener("scroll", this.scrollTopSizeHandler)
    },
    destroyed() {
        document.getElementById('app-scroll-container').removeEventListener("scroll", this.scrollTopSizeHandler);
    },
}
