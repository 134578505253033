<template>
  <div>
    <v-card class="rounded-b-0">
      <EventDetail
          v-if="loading"
          :item="item"
          :role="role"
      >
        <template v-slot:btn>
          <v-btn
              class="dark-primary ml-0"
              icon
              small
              @click="returnParent"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </template>
      </EventDetail>
    </v-card>
    <EventDetailEventsList
        v-if="this.loading"
        :role="role"
        :needReloadListEvents="need_reload_list_events"
        @notNeedReloadListEvents="notNeedReloadListEvents"
    >
    </EventDetailEventsList>
    <div class="action mt-8 mb-4 align-center" v-if="!this.loading">
      <semipolar-spinner
          class="d-inline-block"
          :animation-duration="2000"
          :size="115"
          color="#1935ff"
      />
    </div>
  </div>
</template>

<script>
import TitledPageMixin from "@/mixins/TitledPageMixin";
import {mapActions, mapGetters} from "vuex";
import names from "@/modules/operator/routers/names";
import EventDetail from "@/modules/templates/EventDetail";
import EventDetailEventsList from "@/modules/core/components/event/EventDetailEventsList";
import operator from "@/urls/roles/operator";
import names_core from "@/modules/core/routers/names";
import {SemipolarSpinner} from "epic-spinners";

export default {
  name: "OperatorEventsDetail",
  props:{
    needReload: {
      type: Boolean,
    }
  },
  mixins: [TitledPageMixin],
  components: {
    EventDetail,
    EventDetailEventsList,
    SemipolarSpinner
  },
  data() {
    return {
      loading: false,
      page_title: "",
      default_page_title: 'Детальное представление события',
      event_id: undefined,
      role: 'Operator',
      need_reload_list_events: false,
    }
  },
  watch: {
    needReload(){
      if(this.needReload){
        this.updatePageTitle()
        this.loadData()
        this.need_reload_list_events = true
        this.$emit("notNeedReload", !this.needReload)
      }
    }
  },
  computed: {
    ...mapGetters({
      item: 'operator/getEventsDetail',
    }),
    names: () => names,
  },
  methods: {
    ...mapActions({
      loadEventDetail: 'operator/loadEventsDetail',
      loadLightDetail: 'operator/loadEventsLightDetail',
      changeEventDetailLoadingState: 'leftmenu/changeEventDetailLoadingState'
    }),
    returnParent() {
      if (!this.item.parent) {
        this.$router.push({name: names.EVENTS.LIST})
      } else {
        this.$router.push({name: names.EVENTS.DETAIL, params: {idEvent: this.item.parent.id}})
      }
    },
    loadData(url=undefined){
      this.loading = false;
      this.loadEventDetail({
        url: url,
        id: this.$route.params.idEvent,
        finalizer: (data) => {
          this.loading = true
          this.changeEventDetailLoadingState({loading_state: false})
          this.$emit("notNeedReload", false)
        }
      })
    },
    notNeedReloadListEvents(){
      this.need_reload_list_events = false
    },
    updatePageTitle() {
      this.loadLightDetail({
        id: this.$route.params.idEvent,
        finalizer: (data) => {
          this.page_title = this.default_page_title + ': ' + data.name
        }
      })
    },
  },
  created () {
    if (operator.OPERATOR.ALLOWED()){
      if(this.$route.name === names.EVENTS.DETAIL){
        this.updatePageTitle()
        this.loadData()
      }
    } else{
      this.$router.push({name: names_core.MAIN})
    }
  },
}
</script>

<style scoped>

</style>
