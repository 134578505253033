import helper from "../helper";

let getEventsUrl = helper.getEventsUrlWithParameters
let getEventsAllowed = helper.allowedEventsUrl

export default {
    getEventsUrl,
    getByRecievedUrlData: (url, gets = null) => getEventsUrl(
        url.basename,
        url.action,
        url.params,
        gets
    ),
    ORGANISER: {
        ALLOWED: () => getEventsAllowed(
            'organiser|events|events-list',
            "list",
        ),
        EVENTS: {
            LIST:
                (gets = null) => getEventsUrl(
                    'organiser|events|events-list',
                    "list",
                    null,
                    gets
                ),
            DETAIL:
                (id, gets = null) => getEventsUrl(
                    'organiser|events|events-detail',
                    "retrieve",
                    {id: id},
                    gets
                ),
            LIGHT_DETAIL:
                (id, gets = null) => getEventsUrl(
                    'organiser|events|events-light-detail',
                    "light_detail",
                    {id: id},
                    gets
                ),
            CREATE:
                (gets = null) => getEventsUrl(
                    'organiser|events|events-list',
                    "create",
                    null,
                    gets
                ),
            UPDATE:
                (id, gets = null) => getEventsUrl(
                    'organiser|events|events-detail',
                    "partial_update",
                    {id: id},
                    gets
                ),
            DELETE:
                (id, gets = null) => getEventsUrl(
                    'organiser|events|events-detail',
                    "destroy",
                    {id: id},
                    gets
                ),
            PUBLISH:
                (id, gets = null) => getEventsUrl(
                    'organiser|events|events-publish',
                    "publish",
                    {id: id},
                    gets
                ),
            UNPUBLISH:
                (id, gets = null) => getEventsUrl(
                    'organiser|events|events-unpublish',
                    "unpublish",
                    {id: id},
                    gets
                ),
            CHANGE_DATE:
                (id, gets = null) => getEventsUrl(
                    'organiser|events|events-change-date',
                    "change_date",
                    {id: id},
                    gets
                ),
            INTERVALS: {
                LIST:
                    (event_id, gets = null) => getEventsUrl(
                        'organiser|events|intervals-list',
                        "list",
                        {event_id: event_id},
                        gets
                    ),
                DETAIL:
                    (event_id, id, gets = null) => getEventsUrl(
                        'organiser|events|intervals-detail',
                        "retrieve",
                        {event_id: event_id, id: id},
                        gets
                    ),
                CREATE:
                    (event_id, gets = null) => getEventsUrl(
                        'organiser|events|intervals-list',
                        "create",
                        {event_id: event_id},
                        gets
                    ),
                UPDATE:
                    (event_id, id, gets = null) => getEventsUrl(
                        'organiser|events|intervals-detail',
                        "partial_update",
                        {event_id: event_id, id: id},
                        gets
                    ),
                DELETE:
                    (event_id, id, gets = null) => getEventsUrl(
                        'organiser|events|intervals-detail',
                        "destroy",
                        {event_id: event_id, id: id},
                        gets
                    ),
            },
            SLOTS: {
                LIST:
                    (event_id, gets = null) => getEventsUrl(
                        'organiser|events|slots-list',
                        "list",
                        {event_id: event_id},
                        gets
                    ),
                DETAIL:
                    (event_id, id, gets = null) => getEventsUrl(
                        'organiser|events|slots-detail',
                        "retrieve",
                        {event_id: event_id, id: id},
                        gets
                    ),
                CREATE:
                    (event_id, gets = null) => getEventsUrl(
                        'organiser|events|slots-list',
                        "create",
                        {event_id: event_id},
                        gets
                    ),
                UPDATE:
                    (event_id, id, gets = null) => getEventsUrl(
                        'organiser|events|slots-detail',
                        "partial_update",
                        {event_id: event_id, id: id},
                        gets
                    ),
                DELETE:
                    (event_id, id, gets = null) => getEventsUrl(
                        'organiser|events|slots-detail',
                        "destroy",
                        {event_id: event_id, id: id},
                        gets
                    ),
            },
            VISITS: {
                LIST:
                    (gets = null) => getEventsUrl(
                        'organiser|visitors|visits-list',
                        "list",
                        null,
                        gets
                    ),
                DETAIL:
                    (id, gets = null) => getEventsUrl(
                        'organiser|visitors|visits-detail',
                        "retrieve",
                        {id: id},
                        gets
                    ),
                CANCEL:
                    (id, gets = null) => getEventsUrl(
                        'organiser|visitors|visits-cancel',
                        "cancel",
                        {id: id},
                        gets
                    ),
                ENTER:
                    (id, gets = null) => getEventsUrl(
                    'organiser|visitors|visits-enter',
                    "enter",
                    {id: id},
                    gets
                    ),
                ROLL_BACK_ENTER:
                    (id, gets = null) => getEventsUrl(
                        'organiser|visitors|visits-roll-back-enter',
                        "roll_back_enter",
                        {id: id},
                        gets
                    ),
            },
            INVITES: {
                LIST:
                    (event_id, gets = null) => getEventsUrl(
                        'organiser|roles|invites-list',
                        "list",
                        {event_id: event_id},
                        gets
                    ),
                DETAIL:
                    (event_id, id, gets = null) => getEventsUrl(
                        'organiser|roles|invites-detail',
                        "retrieve",
                        {event_id: event_id, id: id},
                        gets
                    ),
                CREATE:
                    (event_id, gets = null) => getEventsUrl(
                        'organiser|roles|invites-list',
                        "create",
                        {event_id: event_id},
                        gets
                    ),
                DELETE:
                    (event_id, id, gets = null) => getEventsUrl(
                        'organiser|roles|invites-detail',
                        "destroy",
                        {event_id: event_id, id: id},
                        gets
                    ),
            },
            BLOCKS: {
                LIST:
                    (event_id, gets = null) => getEventsUrl(
                        'organiser|description|blocks-list',
                        "list",
                        {event_id: event_id},
                        gets
                    ),
                DETAIL:
                    (event_id, id, gets = null) => getEventsUrl(
                        'organiser|description|blocks-detail',
                        "retrieve",
                        {event_id: event_id, id: id},
                        gets
                    ),
                CREATE:
                    (event_id, gets = null) => getEventsUrl(
                        'organiser|description|blocks-list',
                        "create",
                        {event_id: event_id},
                        gets
                    ),
                UPDATE:
                    (event_id, id, gets = null) => getEventsUrl(
                        'organiser|description|blocks-detail',
                        "partial_update",
                        {event_id: event_id, id: id},
                        gets
                    ),
                DELETE:
                    (event_id, id, gets = null) => getEventsUrl(
                        'organiser|description|blocks-detail',
                        "destroy",
                        {event_id: event_id, id: id},
                        gets
                    ),
            },
            FIELDS: {
                LIST:
                    (event_id, gets = null) => getEventsUrl(
                        'organiser|events|fields-list',
                        "list",
                        {event_id: event_id},
                        gets
                    ),
                NON_PAGINATED_LIST:
                    (event_id, gets = null) => getEventsUrl(
                        'organiser|events|fields-unpaginated',
                        "unpaginated",
                        {event_id: event_id},
                        gets
                    ),
                DETAIL:
                    (event_id, id, gets = null) => getEventsUrl(
                        'organiser|events|fields-detail',
                        "retrieve",
                        {event_id: event_id, id: id},
                        gets
                    ),
                CREATE:
                    (event_id, gets = null) => getEventsUrl(
                        'organiser|events|fields-list',
                        "create",
                        {event_id: event_id},
                        gets
                    ),
                UPDATE:
                    (event_id, id, gets = null) => getEventsUrl(
                        'organiser|events|fields-detail',
                        "partial_update",
                        {event_id: event_id, id: id},
                        gets
                    ),
                DELETE:
                    (event_id, id, gets = null) => getEventsUrl(
                        'organiser|events|fields-detail',
                        "destroy",
                        {event_id: event_id, id: id},
                        gets
                    ),
                CHANGE_INDEX:
                    (event_id, gets = null) => getEventsUrl(
                        'organiser|events|fields-change-index',
                        "change_index",
                        {event_id: event_id},
                        gets
                    ),
            },
            DATA: {
                LIST:
                    (visit_id, gets = null) => getEventsUrl(
                        'organiser|visitors|data-list',
                        "list",
                        {visit_id: visit_id},
                        gets
                    ),
                DETAIL:
                    (visit_id, id, gets = null) => getEventsUrl(
                        'organiser|visitors|data-detail',
                        "retrieve",
                        {visit_id: visit_id, id: id},
                        gets
                    ),
                CREATE:
                    (visit_id, gets = null) => getEventsUrl(
                        'organiser|visitors|data-list',
                        "create",
                        {visit_id: visit_id},
                        gets
                    ),
                UPDATE:
                    (visit_id, id, gets = null) => getEventsUrl(
                        'organiser|visitors|data-detail',
                        "partial_update",
                        {visit_id: visit_id, id: id},
                        gets
                    ),
                DELETE:
                    (visit_id, id, gets = null) => getEventsUrl(
                        'organiser|visitors|data-detail',
                        "destroy",
                        {visit_id: visit_id, id: id},
                        gets
                    ),
            },
            RECORDS: {
                LIST:
                    (gets = null) => getEventsUrl(
                        'organiser|visitors|records-list',
                        "list",
                        {},
                        gets
                    ),
                DETAIL:
                    (id, gets = null) => getEventsUrl(
                        'organiser|visitors|records-detail',
                        "retrieve",
                        {id: id},
                        gets
                    ),
                CANCEL:
                    (id, gets = null) => getEventsUrl(
                        'organiser|visitors|records-cancel',
                        "cancel",
                        {id: id},
                        gets
                    ),
                ENTER:
                    (id, gets = null) => getEventsUrl(
                        'organiser|visitors|records-enter',
                        "enter",
                        {id: id},
                        gets
                        ),
                ROLL_BACK_ENTER:
                    (id, gets = null) => getEventsUrl(
                        'organiser|visitors|records-roll-back-enter',
                        "roll_back_enter",
                        {id: id},
                        gets
                    ),
            },
            ROLES: {
                LIST:
                    (event_id, gets = null) => getEventsUrl(
                        'organiser|roles|roles-list',
                        "list",
                        {event_id: event_id},
                        gets
                    ),
                DETAIL:
                    (event_id, id, gets = null) => getEventsUrl(
                        'organiser|roles|roles-detail',
                        "retrieve",
                        {event_id: event_id, id: id},
                        gets
                    ),
                CREATE:
                    (event_id, gets = null) => getEventsUrl(
                        'organiser|roles|roles-list',
                        "create",
                        {event_id: event_id},
                        gets
                    ),
                UPDATE:
                    (event_id, id, gets = null) => getEventsUrl(
                        'organiser|roles|roles-detail',
                        "update",
                        {event_id: event_id, id: id},
                        gets
                    ),
                DELETE:
                    (event_id, id, gets = null) => getEventsUrl(
                        'organiser|roles|roles-detail',
                        "destroy",
                        {event_id: event_id, id: id},
                        gets
                    ),
            },
        },
    },
}
