<template>
  <div class="elevation-0">
    <v-col class="ma-0 pa-5" v-if="edit && !!data.errors.empty">
      <v-alert
        v-if="!!data.errors.empty"
        color="red"
        class="elevation-2"
        type="error"
      > {{ data.errors.empty }}</v-alert>
      <v-card-text v-else>
        В строке пока что отсутствуют блоки
      </v-card-text>
    </v-col>
    <div v-if="edit">
      <div
          v-for="(item, i) in data.data.data.texts"
          :key="i"
          class="elevation-3 pa-5"
          :class="i !== data.data.data.texts.length - 1 ? 'mb-5' : ''"
      >
        <EntryBlockButtonsWrapper
            :edit-mode="edit"
            :block-id="data.id"
            :entry-number="i"
            @deleteEntry="deleteEntry"
            @moveEntryUp="moveEntryUp"
            @moveEntryDown="moveEntryDown"
        >
          <template v-slot:entry>
            <EntriesSliderEntry
                :edit-mode="edit"
                :data="data"
                :entry-index="i"
                @loadFilesFromInput="loadFilesFromInput"
                @deleteOneFile="deleteOneFile"
            ></EntriesSliderEntry>
          </template>
        </EntryBlockButtonsWrapper>
      </div>
    </div>
    <div v-else>
      <EntriesSliderEntry
          :edit-mode="edit"
          :data="data"
          :entry-index="0"
          @loadFilesFromInput="loadFilesFromInput"
          @deleteOneFile="deleteOneFile"
      ></EntriesSliderEntry>
    </div>
  </div>
</template>

<script>
import EntryBlockButtonsWrapper from "@/modules/templates/description_blocks/blocks/entries_blocks/EntryBlockButtonsWrapper.vue";
import EntriesSliderEntry from "@/modules/templates/description_blocks/blocks/entries_blocks/EntriesSliderEntry.vue";

export default {
  components: {EntriesSliderEntry, EntryBlockButtonsWrapper},
  props: {
    edit: Boolean,
    data: Object,
  },
  name: "EntriesSliderBlock",
  methods: {
    deleteEntry(id, entry_number) {
      this.$emit('deleteEntry', id, entry_number);
    },
    moveEntryUp(id, entry_number) {
      this.$emit('moveEntryUp', id, entry_number);
    },
    moveEntryDown(id, entry_number) {
      this.$emit('moveEntryDown', id, entry_number);
    },
    loadFilesFromInput(id) {
      this.$emit('loadFilesFromInput', id);
    },
    deleteOneFile(id, file_index) {
      this.$emit('deleteOneFile', id, file_index)
    },
  }
}
</script>

<style scoped>

</style>
