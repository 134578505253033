<template>
  <v-container class="page-content pa-0" style="  width: 100%" >
    <div v-if="$route.name === names.EVENTS.INVITES.LIST">
      <v-toolbar
          class="removeBtnPaddingRight rounded-t-lg"
          color="#DADADA"
          flat
      >
        <v-spacer></v-spacer>
        <v-toolbar-items v-if="$vuetify.breakpoint.smAndUp && !(light_detail.is_end && !light_detail.is_draft)">
          <v-btn
              text
              @click="newInvite"
              class="dark-primary mr-0 rounded-tr-lg"
          >
            Добавить приглашение
          </v-btn>
        </v-toolbar-items>
        <v-btn
            v-if="$vuetify.breakpoint.xsOnly && !(light_detail.is_end && !light_detail.is_draft)"
            icon
            class="dark-primary mr-0"
            @click="newInvite"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
          :headers="headers"
          :items="items"
          :loading="isLoading"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.size"
          :server-items-length="count"
          :options.sync="options"
          :footer-props="{'items-per-page-options':[10,50,100]}"
          class="elevation-1 rounded-b-lg"
      >
        <template v-slot:item.visitor.email="{ item }">
          <span v-if="item.visitor!==null"> {{ item.visitor.email }}</span>
          <span v-else> {{ item.email }}</span>
        </template>
        <template v-slot:item.send="{ item }">
          <span v-if="item.send!==null">{{ displayDate(item.send) }}</span>
        </template>
        <template v-slot:item.accepted="{ item }">
          <span v-if="item.accepted!==null">{{ displayDate(item.accepted) }}</span>
        </template>
        <template v-slot:item.is_accept="{ item }">
          <v-icon
              :color="checkFlag(item.is_accept).color"
          >
            {{checkFlag(item.is_accept).icon}}
          </v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom v-if="!(light_detail.is_end && !light_detail.is_draft)">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  @click="deleteInvite(item.id)"
                  v-bind="attrs"
                  v-on="on"
              >
                delete
              </v-icon>
            </template>
            <span>Удалить</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-btn
              color="primary"
              @click="updateData"
          >
            Обновить
          </v-btn>
        </template>
      </v-data-table>
      <EditDialog
          v-if="dialog_add"
          :opened="dialog_add"
          @close="closeAddingDialog"
      >
      </EditDialog>
      <ApplyDialog
          v-if="dialog_delete"
          :opened="dialog_delete"
          :get-text="() => `Вы уверены, что хотите удалить ?`"
          @close="closeDeleteDialog"
      ></ApplyDialog>
    </div>
    <router-view v-else></router-view>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import {displayDate} from "@/helper";
import admin from "@/urls/roles/admin";
import names_core from "@/modules/core/routers/names";
import names from "@/modules/admin/routers/names";
import ApplyDialog from "@/modules/core/components/ApplyDialog";
import EditDialog from "@/modules/admin/events/modals/InvitesEditDialog";

export default {
  name: "AdminEventsInvitesList",
  components: {EditDialog, ApplyDialog},
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  data() {
    return {
      page_title: "Список приглашений",
      dialog_add: false,
      dialog_delete: false,
      invite_id: undefined,
      headers: [
        {text: 'ФИО', value: 'visitor.fio', sortable: false, align: 'start',  width: '23%'},
        {text: 'Отправитель', value: 'inviter', sortable: false, align: 'start',  width: '23%'},
        {text: 'Почта', value: 'visitor.email', sortable: false, align: 'start', width: '14%'},
        {text: 'Дата приглашения', value: 'send', sortable: false, align: 'center', width: '10%'},
        {text: 'Дата подтверждения получения', value: 'accepted', sortable: false, align: 'center', width: '10%'},
        {text: 'Получено', value: 'is_accept', sortable: false, align: 'center', width: '10%'},
        {text: 'Действия', value: 'actions', sortable: false, align: 'center', width: '10%'},
      ],
    }
  },
  computed: {
    ...mapGetters({
      items: 'admin/getInvitesList',
      count: 'admin/getInvitesCount',
      isLoading: 'admin/isLoadingInvites',
      light_detail: 'admin/getEventsLightDetail'
    }),
    names: () => names,
  },
  watch: {
  },
  methods: {
    ...mapActions({
      loadInvitesList: 'admin/loadInvitesList',
      deleteInvites: 'admin/deleteInvites',
      loadLightDetail: 'admin/loadEventsLightDetail',
      changeEventDetailLoadingState: 'leftmenu/changeEventDetailLoadingState'
    }),
    displayDate: displayDate,
    updateData(){
      // if (this.search.value) {
      //   this.search.value = ''
      // }
      this.loadData()
    },
    loadData(url = undefined) {
      this.loadInvitesList({
        url: url,
        event_id: this.$route.params.idEvent,
        urlAppend: (url) => {
          return this.addPageParameter(this.addSizeParameter(url));
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
          this.changeEventDetailLoadingState({loading_state: false})
        }
      })
    },
    newInvite() {
      this.dialog_add = true
    },
    deleteInvite(id) {
      this.dialog_delete = true
      this.invite_id = id
    },
    closeAddingDialog() {
      this.dialog_add = false;
      this.loadData();
    },
    closeDeleteDialog(invite) {
      if (invite) {
        this.deleteInvites({
          event_id: this.$route.params.idEvent,
          id: this.invite_id,
          finalizer: () => {
            this.loadData()
          },
          catcher: (val) => {
            this.mapErrors(val)
          },
        })
      }
      this.dialog_delete = false
      this.invite_id = undefined
    },
    checkFlag(flag) {
      if (flag) return {icon: "check_circle", color: "green"}
      else return {icon: "not_interested", color: "gray"}
    },
    updatePageTitle() {
      this.loadLightDetail({
        id: this.$route.params.idEvent,
        finalizer: (data) => {
          this.page_title = this.page_title + ': ' + data.name
        }
      })
    },
  },
  created() {
    if (admin.ADMIN.ALLOWED()){
      if(this.$route.name === names.EVENTS.INVITES.LIST){
        this.updatePageTitle()
        this.loadData()
      }
    } else{
      this.$router.push({name: names_core.MAIN})
    }
  },
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0;
}
</style>
