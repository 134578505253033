import { render, staticRenderFns } from "./EventDetailFieldsList.vue?vue&type=template&id=dc7f4640&scoped=true"
import script from "./EventDetailFieldsList.vue?vue&type=script&lang=js"
export * from "./EventDetailFieldsList.vue?vue&type=script&lang=js"
import style0 from "./EventDetailFieldsList.vue?vue&type=style&index=0&id=dc7f4640&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc7f4640",
  null
  
)

export default component.exports