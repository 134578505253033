<template>
  <div class="page-content" style="margin: 0px; width: 100%">
    <PageHeader></PageHeader>
    <Main
        v-if="$route.name === names.MAIN"
        :name_list='name_list'
    ></Main>
    <router-view v-else></router-view>
  </div>
</template>

<script>

import names from '@/modules/viewer/routers/names'
import Main from "./Main";
import {getInstMenu, getInstNameByRouterName} from "@/helper/instances";
import PageHeader from "../menus/PageHeader";
import {mapGetters} from "vuex";

export default {
  name: "ViewerBase",
  components: {Main, PageHeader},
  data(){
    return {
      left_menu_list: [
        {title: 'События', icon: 'calendar_month', included: true, router: {name:  names.EVENTS.LIST, params:{}}},
        {title: 'Посетители', icon: 'group', included: true, router: {name:  names.VISITORS.LIST, params:{}}},
      ],
      name_list: []
    }
  },
  computed: {
    names: () => names,
    ...mapGetters({
      isAuthenticated: 'user/isAuthenticate'
    })
  },
  methods: {
    loadLeftmenu() {
      // Исправление бага, связанного с заголовком при переходе из меню события
      if (this.$route.name.endsWith('|Events|List')) {
        this.$store.commit('mainpage/changeTitle', 'Список событий');
      } else {
        this.$store.commit('mainpage/changeTitle', 'Наблюдатель');
      }
      if (this.isAuthenticated) {
        this.$store.commit('leftmenu/replaceInstances', getInstMenu());
        this.$store.commit('leftmenu/selectInstance', getInstNameByRouterName(names.MAIN));
        this.$store.commit('leftmenu/replaceItems', this.getLeftMenu());
      } else {
        this.$store.commit('leftmenu/dissable');
      }
    },
    getLeftMenu() {
      let menu = []
      for (let el in this.left_menu_list){
        menu.push(this.left_menu_list[el])
      }
      return menu
    },
    makeNameList(){
      for (let el in this.left_menu_list){
        if(this.left_menu_list[el].title!=='Главная')
          this.name_list.push(this.left_menu_list[el])
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    next()
    if (!this.$route.name.includes(names.EVENTS.DETAIL)){
      this.loadLeftmenu()
    }
  },
  created() {
    if (this.isAuthenticated) {
      this.$store.commit('leftmenu/enableLeftMenu');
    }
    this.loadLeftmenu()
    this.makeNameList()
  }
}
</script>

<style scoped>

</style>
