import {
    ACCOUNTS_INSTANCE,
    EVENTS_INSTANCE,
} from "@/helper/consts";

import Breadcrumbs from "@/modules/visitor/Breadcrumbs";
import names from "@/modules/visitor/routers/names";
import Events from "@/modules/visitor/events/Events";
import EventsDetail from "@/modules/visitor/events/pages/BreadcrumbsEvent"
import Visits from "@/modules/visitor/visits/Visits";
import VisitsDetail from "@/modules/visitor/visits/pages/Detail"
import EventsDetailSlots from "@/modules/visitor/events/pages/slots/List"
import InviteAcceptComponent from "@/modules/visitor/invites/InviteAcceptComponent.vue";
import VisitViaQrRedirect from "@/modules/core/pages/VisitViaQrRedirect.vue";

export default ([
    {
        path: '/visitor',
        redirect: '/visitor/events',
        name: names.MAIN,
        component: Breadcrumbs,
        meta: {
            breadcrumb: 'Посетитель',
            requiredAuth: false,
            instances: [
                ACCOUNTS_INSTANCE,
                EVENTS_INSTANCE,
            ]
        },
        children: [
            {
                path: 'find_visits/:uid',
                name: names.VISITS.REDIRECT,
                component: VisitViaQrRedirect,
                meta: {
                    breadcrumb: 'Посещение по QR-коду',
                    requiredAuth: false,
                    instances: [
                        EVENTS_INSTANCE,
                        ACCOUNTS_INSTANCE
                    ]
                },
                children:[
                ]
            },
            {
                path: 'invite-accept/:uid',
                name: names.INVITES.ACCEPT,
                component: InviteAcceptComponent,
                meta: {
                    breadcrumb: 'Подтверждение приглашения',
                    requiredAuth: false,
                    instances: [
                        EVENTS_INSTANCE,
                        ACCOUNTS_INSTANCE
                    ]
                }
            },
            {
                path: 'events',
                name: names.EVENTS.LIST,
                component: Events,
                meta: {
                    breadcrumb: 'События',
                    requiredAuth: false,
                    instances: [
                        EVENTS_INSTANCE,
                        ACCOUNTS_INSTANCE
                    ]
                },
                children: [
                    {
                        path: ':idEvent',
                        name: names.EVENTS.DETAIL,
                        component: EventsDetail,
                        alias: 'aboba',
                        meta: {
                            breadcrumb: 'Детальное представление события',
                            requiredAuth: false,
                            instances: [
                                EVENTS_INSTANCE,
                                ACCOUNTS_INSTANCE
                            ]
                        },
                        children: [
                            {
                                path: 'slots',
                                name: names.EVENTS.SLOTS.LIST,
                                component: EventsDetailSlots,
                                meta: {
                                    breadcrumb: 'Слоты',
                                    requiredAuth: false,
                                    instances: [
                                        EVENTS_INSTANCE,
                                        ACCOUNTS_INSTANCE
                                    ]
                                },
                            }
                        ]
                    }
                ]
            },
            {
                path: 'visits',
                name: names.VISITS.LIST,
                component: Visits,
                meta: {
                    breadcrumb: 'Посещения',
                    requiredAuth: false,
                    instances: [
                        EVENTS_INSTANCE,
                        ACCOUNTS_INSTANCE
                    ]
                },
                children: [
                    {
                        path: ':idVisit',
                        name: names.VISITS.DETAIL,
                        component: VisitsDetail,
                        meta: {
                            breadcrumb: 'Детальное представление',
                            requiredAuth: false,
                            instances: [
                                EVENTS_INSTANCE,
                                ACCOUNTS_INSTANCE
                            ]
                        },
                    }
                ]
            },
        ]
    }
])
