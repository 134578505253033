<template>
  <v-container class="pa-0 ma-0" style="width: 100%">
    <div v-if="$route.name === names.VISITS.LIST">
      <div v-if="!loading_visits">
        <VisitorPageHeader
            :has-search="has_visits"
            :search-value="search.value"
            :has-title="!$cookies.get('userMuctr')"
            :page-title="'Мои посещения'"
            :muctr-user="!!$cookies.get('userMuctr')"
            :mobile-breakpoint="$vuetify.breakpoint.xsOnly"
            @update:searchValue="newVal => search.value = newVal"
            @searchData="searchData"
        ></VisitorPageHeader>
        <v-data-iterator
            v-if="has_visits"
            :items="items"
            :loading="isLoading"
            :page.sync="pagination.page"
            :items-per-page.sync="pagination.size"
            :server-items-length="count"
            :options.sync="options"
            :footer-props="{'items-per-page-options':[12,24,48], 'items-per-page-text': 'Посещений на странице'}"
        >
          <template v-slot:default="props">
            <VisitsTable
              :items="props.items"
              @cancelVisit="cancelVisit"
            ></VisitsTable>
          </template>
          <template v-slot:no-data>
            <EmptySearchComponent
                :text="'По результатам поиска ничего не найдено'"
                :muctr-user="!!$cookies.get('userMuctr')"
                :is-visits-list="true"
            ></EmptySearchComponent>
          </template>
        </v-data-iterator>
        <EmptyListComponent
            :text="'На данный момент у вас нет посещений'"
            :has-help-text="true"
            :muctr-user="!!$cookies.get('userMuctr')"
            v-else
        >
          <template v-slot:helpText>
            <div class="empty-text">
              Ознакомиться со списком доступных событий можно <span class="empty-text__link" @click="goToEventsList">здесь</span>
            </div>
          </template>
        </EmptyListComponent>
        <CustomApplyDialog
            v-if="dialog_cancel"
            :question-text="`Вы уверены, что хотите отменить своё посещение?`"
            :apply-text="'Да, отменить'"
            :cancel-text="'Нет, оставить'"
            :apply-color="'#D64141'"
            @close="closeCancelDialog"
        ></CustomApplyDialog>
      </div>
      <div class="action mt-8 mb-4 align-center" v-else>
        <semipolar-spinner
            class="d-inline-block"
            :animation-duration="2000"
            :size="115"
            color="#1935ff"
        />
      </div>
    </div>
    <router-view v-else></router-view>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import {displayDate} from "@/helper";
import names from "@/modules/visitor/routers/names";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import EmptySearchComponent from "@/modules/templates/visitor/core/EmptySearchComponent.vue";
import EmptyListComponent from "@/modules/templates/visitor/core/EmptyListComponent.vue";
import VisitorPageHeader from "@/modules/templates/visitor/core/VisitorPageHeader.vue";
import SemipolarSpinner from "epic-spinners/src/components/lib/SemipolarSpinner.vue";
import VisitsTable from "@/modules/templates/visitor/visits_list/VisitsTable.vue";
import CustomApplyDialog from "@/modules/templates/visitor/custom_forms/CustomApplyDialog.vue";

export default {
  name: "VisitorVisitsList",
  components: {
    CustomApplyDialog,
    VisitsTable, VisitorPageHeader, EmptyListComponent, EmptySearchComponent, SemipolarSpinner,},
  mixins: [TitledPageMixin, PaginatedDataMapperMixin, FormErrorsMixin],
  data() {
    return {
      page_title: "Мои посещения",
      dialog_cancel: false,
      search: {
        value: '',
        parameter: 'event_name'
      },
      pagination: {
        size: 12,
      },
      visit_data: undefined,
      headers: [
        {text: 'Название события', value: 'event.name', sortable: false, align: 'start',  width: '30%'},
        {text: 'Дата начала события', value: 'event.start', sortable: false, align: 'center', width: '10%'},
        {text: 'Дата конца события', value: 'event.end', sortable: false, align: 'center', width: '10%'},
        {text: 'Есть записи', value: 'have_records', sortable: false, align: 'center', width: '10%'},
        {text: 'Событие завершено', value: 'is_end', sortable: false, align: 'center', width: '10%'},
        {text: 'Посещено', value: 'is_enter', sortable: false, align: 'center', width: '10%'},
        {text: 'Отменено', value: 'is_cancel', sortable: false, align: 'center', width: '10%'},
        {text: 'Действия', value: 'actions', sortable: false, align: 'center', width: '10%'},
      ],
      has_visits: false,
      loading_visits: true,
    }
  },
  computed: {
    ...mapGetters({
      items: 'visitor/getVisitsList',
      count: 'visitor/getVisitsCount',
      isLoading: 'visitor/isLoadingVisits'
    }),
    names: () => names,
  },
  watch: {},
  methods: {
    ...mapActions({
      loadVisitsList: 'visitor/loadVisitsList',
      cancelVisits: 'visitor/cancelVisits',
    }),
    displayDate: displayDate,
    updateData() {
      if (this.search.value) {
        this.search.value = ''
      }
      this.loadData()
    },
    loadData(url = undefined) {
      this.loading_visits = true
      this.loadVisitsList({
        url: url,
        urlAppend: (url) => {
          return this.addPageParameter(this.addSizeParameter(url))
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
          if (data.count > 0) {
            this.has_visits = true
          }
          this.loading_visits = false
        }
      })
    },
    cancelVisit(item) {
      this.dialog_cancel = true
      this.visit_data = item
    },
    closeCancelDialog(visit) {
      if (visit) {
        this.cancelVisits({
          id: this.visit_data.id,
          finalizer: (val) => {
            this.loadData()
          },
          catcher: (val) => {
            this.mapErrors(val)
          },
        })
      }
      this.dialog_cancel = false
      this.visit_data = undefined
    },
    goToEventsList() {
      this.$router.push({name: names.EVENTS.LIST, params: {}})
    },
  },
  beforeRouteUpdate(to, from, next) {
    next()
    this.loadData()
  },
  created() {
    if (this.$cookies.get('userMuctr') || this.$cookies.get('visitor_token')) {
      this.loadData()
    }
    else {
      this.goToEventsList()
    }
  },
}
</script>

<style scoped lang="scss">
.empty-text {
  color: #1E1E1E;
}
.empty-text__link {
  color: #1D71B8;
  cursor: pointer;
}
</style>
