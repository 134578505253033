<template>
  <td
      :colspan="outerHeadersLength"
      v-if="$vuetify.breakpoint.smAndUp"
      style="background-color: rgba(53,106,158,0.5)"
  >
    <v-container class="page-content px-0" fluid style="width: 100%">
      <v-toolbar
          class="removeBtnPaddingRight rounded-t-lg"
          color="#DADADA"
          dense
          flat
      >
        <v-subheader style="color: #065aad; font-size: 1.2rem" class="pl-0">Список записей</v-subheader>
      </v-toolbar>
      <v-data-table
          :headers="records_headers"
          :items="records"
          :loading="isLoadingRecords"
          class="rounded-0 elevation-1"
      >
        <template v-slot:item.date="{ item }">
          <div v-if="item.date">
            {{ displayDate(item.date) }}
          </div>
          <div v-else-if="item.slot.intervals.length > 0">
            {{ displayDate(item.slot.intervals[0].start) + '-' + displayDate(item.slot.intervals[0].end) }}
          </div>
          <div v-else>
            {{ displayDate(item.slot.event.start) + '-' + displayDate(item.slot.event.end) }}
          </div>
        </template>
        <template v-slot:item.slot.start="{ item }">
          {{ deleteSecondsFromTime(item.slot.start) }}
        </template>
        <template v-slot:item.slot.end="{ item }">
          {{ deleteSecondsFromTime(item.slot.end) }}
        </template>
        <template v-slot:item.is_enter="{ item }">
          <v-icon
              :color="checkEnterFlag(item.is_enter).color"
          >
            {{ checkEnterFlag(item.is_enter).icon }}
          </v-icon>
        </template>
        <template v-slot:item.is_cancel="{ item }">
          <v-icon
              :color="checkCancelFlag(item.is_cancel).color"
          >
            {{ checkCancelFlag(item.is_cancel).icon }}
          </v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip v-if="!item.is_cancel && !item.is_enter && !visitCanceled && !isEnd && !item.slot.is_default" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  class="mr-2"
                  @click="cancelOuterDialog(item)"
                  v-bind="attrs"
                  v-on="on"
              >
                cancel
              </v-icon>
            </template>
            <span>Отменить запись</span>
          </v-tooltip>
          <v-tooltip v-if="!item.is_cancel && !item.is_enter && !visitCanceled && !isEnd" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  @click="enterOuterDialog(item)"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
              >
                add_task
              </v-icon>
            </template>
            <span>Зафиксировать посещение слота</span>
          </v-tooltip>
          <v-tooltip v-if="!item.is_cancel && item.is_enter && !visitCanceled && !isEnd" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  @click="backOuterDialog(item)"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
              >
                undo
              </v-icon>
            </template>
            <span>Отменить фиксацию посещения слота</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
  </td>
</template>

<script>
import {displayDate} from "@/helper";
import {mapGetters} from "vuex";

export default {
  name: "AdminVisitorsVisitsRecordsList",
  props: {
    outerHeadersLength: Number,
    visitCanceled: Boolean,
    isEnd: Boolean,
  },
  data() {
    return {
      records_headers: [
        {text: 'Дата', value: 'date', sortable: false, align: 'center', width: '18%'},
        {text: 'Время начала', value: 'slot.start', sortable: false, align: 'center', width: '18%'},
        {text: 'Время окончания', value: 'slot.end', sortable: false, align: 'center', width: '18%'},
        {text: 'Посещено', value: 'is_enter', sortable: false, align: 'center', width: '18%'},
        {text: 'Отменено', value: 'is_cancel', sortable: false, align: 'center', width: '18%'},
        {text: 'Действия', value: 'actions', sortable: false, align: 'center', width: '10%'},
      ],
    }
  },
  computed: {
    ...mapGetters({
      records: 'admin/getRecordsList',
      isLoadingRecords: 'admin/isLoadingRecords',
    }),
  },
  methods: {
    deleteSecondsFromTime(time_string) {
      let time_array = time_string.split(':');
      return time_array[0] + ':' + time_array[1];
    },
    displayDate: displayDate,
    checkEnterFlag(flag) {
      if (flag) return {icon: "check_circle", color: "green"}
      else return {icon: "not_interested", color: "grey"}
    },
    checkCancelFlag(flag) {
      if (flag) return {icon: "check_circle", color: "yellow darken-3"}
      else return {icon: "not_interested", color: "grey"}
    },
    cancelOuterDialog(item) {
      this.$emit("cancelDialog", item);
    },
    enterOuterDialog(item) {
      this.$emit("enterDialog", item);
    },
    backOuterDialog(item) {
      this.$emit("backDialog", item);
    },
  },
}
</script>

<style scoped>

</style>
