<template>
  <div
      class="mt-5"
  >
    <v-row
        class="text-h6 mb-3 ml-0"
        style="white-space: nowrap; text-align: start;"
    >События пользователя
    </v-row>
    <v-toolbar
        class="removeBtnPaddingRight rounded-t-lg"
        color="#DADADA"
        flat
    >
      <v-text-field
          v-model="search_data"
          class="dark-primary my-1"
          clearable
          dense
          outlined
          label="Поиск"
          hide-details>
      </v-text-field>
      <v-spacer class="px-1"></v-spacer>
      <v-toolbar-items v-if="$vuetify.breakpoint.smAndUp && edit">
        <v-btn
            text
            @click="changeDeleteAllState"
            :disabled="!user.events.length"
            class="dark-primary mr-0"
        >
          Удалить всё
          <v-checkbox
              v-model="deleteAll"
              :disabled="true"
              class="dark-primary mt-6 ml-2"
          ></v-checkbox>
        </v-btn>
      </v-toolbar-items>
      <v-btn
          v-if="$vuetify.breakpoint.xsOnly && edit"
          @click="changeDeleteAllState"
          :disabled="!user.events.length"
          class="dark-primary mr-0 elevation-0"
      >
        <v-icon>delete</v-icon>
        <v-checkbox
            v-model="deleteAll"
            :disabled="true"
            class="dark-primary mt-6 ml-2"
        ></v-checkbox>
      </v-btn>
      <v-toolbar-items v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn
            text
            @click="addNewEvent"
            :disabled="deleteAll"
            class="dark-primary mr-0 rounded-tr-lg"
        >
          Добавить событие
        </v-btn>
      </v-toolbar-items>
      <v-btn
          v-if="$vuetify.breakpoint.smAndDown"
          :disabled="deleteAll"
          icon
          class="dark-primary mr-0"
          @click="addNewEvent"
      >
        <v-icon>add</v-icon>
      </v-btn>
    </v-toolbar>
    <v-data-table
        :items="user.events"
        :headers="events_headers"
        :search="search_data"
        :custom-filter="filterEvents"
        disable-pagination
        hide-default-footer
        class="elevation-1 rounded-b-lg rounded-t-0"
    >
      <template
          v-slot:item.event.name="{ item }"
      >
        <v-row v-if="!item.is_new">
          <div
            style="display:-webkit-box;-webkit-line-clamp:1;-webkit-box-orient:vertical;overflow: hidden; word-break: break-all"
            class="ml-4"
          >
            {{ item.event.name }}
          </div>
        </v-row>
        <v-row v-else>
          <loading-autocomplete
              :disabled="deleteAll"
              placeholder="Введите название и выберите из предложенных вариантов"
              label="Событие"
              v-model="item.event.selected_event"
              :return-object="true"
              search-parameter="name"
              :no-size-parameter="true"
              :load-fios="false"
              @input="updateEventData(item)"
              :url="possibleEventsSelector"
              :error="isNotCompleted(item)"
              :errorMessages="getError('id')"
              class="ml-4 mr-2"
          >
          </loading-autocomplete>
        </v-row>
      </template>
      <template v-slot:item.is_organiser="{ item }">
        <v-row no-gutters justify="space-around" align="center">
          <v-checkbox
              :disabled="deleteAll || ( 'is_allow' in item && !item.is_allow)"
              v-model="item.is_organiser"
              @change="checkOrganiserInput(item)"
              @click.native.capture="checkOrganiserSingleInput($event, item)"
              label=""
              class="mr-n2"
              :error="hasError('is_organiser')"
              :error-messages="getError('is_organiser')"
          ></v-checkbox>
        </v-row>
      </template>
      <template v-slot:item.is_operator="{ item }">
        <v-row no-gutters justify="space-around" align="center">
          <v-checkbox
              :disabled="deleteAll || ( 'is_allow' in item && !item.is_allow)"
              v-model="item.is_operator"
              @change="checkOperatorInput(item)"
              @click.native.capture="checkOperatorSingleInput($event, item)"
              label=""
              class="mr-n2"
              :error="hasError('is_operator')"
              :error-messages="getError('is_operator')"
          ></v-checkbox>
        </v-row>
      </template>
<!--      <template v-slot:item.is_viewer="{ item }">-->
<!--        <v-row no-gutters justify="space-around" align="center">-->
<!--          <v-checkbox-->
<!--              :disabled="deleteAll || ( 'is_allow' in item && !item.is_allow) || true"-->
<!--              v-model="item.is_viewer"-->
<!--              @change="checkViewerInput(item)"-->
<!--              @click.native.capture="checkViewerSingleInput($event, item)"-->
<!--              label=""-->
<!--              class="mr-n2"-->
<!--              :error="hasError('is_viewer')"-->
<!--              :error-messages="getError('is_viewer')"-->
<!--          ></v-checkbox>-->
<!--        </v-row>-->
<!--      </template>-->
      <template v-slot:item.actions="{ item }">
        <v-row no-gutters justify="space-around" align="center">
          <v-tooltip bottom v-if="item.is_allow">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  :disabled="deleteAll"
                  class=""
                  @click="deleteEvent(item)"
                  v-bind="attrs"
                  v-on="on"
              >delete</v-icon>
            </template>
            <span>Добавить событие</span>
          </v-tooltip>
        </v-row>
      </template>
      <template v-slot:no-data>
        Пользователь не участвует в каких-либо конкретных событиях
      </template>
    </v-data-table>
  </div>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import selectors from "@/urls/selectors";
import LoadingAutocomplete from "@/modules/core/components/LoadingAutocomplete.vue";

export default {
  name: "EditDialogTable",
  props: {
    deleteAll: Boolean,
    user: Object,
    edit: Boolean,
  },
  mixins: [FormErrorsMixin, PaginatedDataMapperMixin],
  components: {LoadingAutocomplete},
  data() {
    return {
      events_headers: [
        {text: 'Событие', value: 'event.name', sortable: true, width: '45%', align: 'start'},
        {text: 'Организатор', value: 'is_organiser', sortable: false, width: '15%', align: 'center'},
        {text: 'Оператор', value: 'is_operator', sortable: false, width: '15%', align: 'center'},
        /*{text: 'Наблюдатель', value: 'is_viewer', sortable: false, width: '15%', align: 'center'},*/
        {text: '', value: 'actions', sortable: false, width: '10%', align: 'center'},
      ],
      default_event: {
        new_event_id: null,
        is_new: true,
        has_error: false,
        event: {
          name: '',
          id: null,
          selected_event: null,
        },
        is_organiser: true,
        is_operator: false,
        is_viewer: false,
      },
      next_event_id_number: 1,
      search_data: '',
    }
  },
  methods: {
    filterEvents(value, query, item) {
      return item.is_new ||
          item.event.name.toString().indexOf(query) !== -1;
    },
    possibleEventsSelector() {
      let get_params = {};
      let list_events = [];
      for (let i = 0; i < this.user.events.length; i++) {
        if (this.user.events[i].event.id) {
          list_events.push(this.user.events[i].event.id)
        }
      }
      if (list_events.length > 0) {
        get_params.list_events = `${JSON.stringify(list_events)}`;
      }
      if (this.edit) {
        get_params.role_id = this.user.id;
      }
      return selectors.SELECTORS.EVENTS.ALL(get_params)
    },
    checkOrganiserSingleInput(e, item) {
      if (!item.is_viewer && !item.is_operator) {
        e.stopPropagation()
      }
    },
    checkOperatorSingleInput(e, item) {
      if (!item.is_viewer && !item.is_organiser) {
        e.stopPropagation()
      }
    },
    checkViewerSingleInput(e, item) {
      if (!item.is_operator && !item.is_organiser) {
        e.stopPropagation()
      }
    },
    checkOrganiserInput(item) {
      if (item.is_organiser) {
        item.is_viewer = false;
        item.is_operator = false;
      }
    },
    checkOperatorInput(item) {
      if (item.is_operator) {
        item.is_viewer = false;
        item.is_organiser = false;
      }
    },
    checkViewerInput(item) {
      if (item.is_viewer) {
        item.is_organiser = false;
        item.is_operator = false;
      }
    },
    changeDeleteAllState() {
      this.$emit('changeDeleteAllState', !this.deleteAll)
    },
    isNotCompleted(item) {
      if (item.has_error === undefined) return false;
      return item.has_error;
    },
    addNewEvent() {
      this.default_event.new_event_id = this.next_event_id_number;
      this.next_event_id_number++;
      this.user.events.push(JSON.parse( JSON.stringify((this.default_event))))
    },
    updateEventData(item) {
      item.event.id = item.event.selected_event.value
      item.event.name = item.event.selected_event.text
      item.is_new = false;
    },
    deleteEvent(item) {
      const index = this.user.events.indexOf(item);
      this.user.events.splice(index, 1)
    },
  }
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0;
}
</style>
