<template>
  <scroll-center-dialog v-model="opened" @close="close" @apply="save" :title="form_title" :scrolled="false"
                        v-if="value.field_type!==undefined">
    <v-select
      class=" mt-4 mb-0"
      ref="field_type"
      outlined
      v-if="!edit"
      v-model="value.field_type"
      :items="choices.field_type"
      :loading="loading.all"
      :readonly="loading.all"
      item-text="text"
      item-value="value"
      persistent-hint
      hint="Выберите базовый тип поля, от него зависит какую информацию потребуется заполнить"
      :error="hasError('field_type')"
      :error-messages="getError('field_type')"
      @input="deleteError('field_type')"
    ></v-select>
    <template  v-for="(object, idx) in TYPE_FIELDS[value.field_type].fields">
      <component
        class=" mt-4 mb-0"
        :is="object.component"
        v-show="isShow(object) && !(object.model === 'regexp' && value.regexp_type !== 6)"
        :ref="object.model"
        :key="`field_${idx}`"
        :label="object.label"
        :hint="object.help_text"
        :persistent-hint="object.help_text !== undefined"
        :type="object.component==='v-text-field' ? object.type: null"
        :items="choices[object.items]"
        :item-text="object.item_text"
        :item-value="object.item_value"
        outlined
        :loading="loading[object.loading] || loading.all"
        :readonly="loading[object.loading] || loading.all"
        :error="object.model !== 'default' ? hasError(object.model) : hasError(value.name)"
        :error-messages="object.model !== 'default' ? getError(object.model) : getError(value.name)"
        @input="object.model !== 'default' ? deleteError(object.model) : deleteError(value.name)"
        v-model="value[object.model]"
        :edit="edit"
        @updateSelector="updateSelector"
        :static-start-error="getError('static_start')"
        :static-end-error="getError('static_end')"
        :dynamic-bounds-error="getError('detail')"
        :dynamic-start-error="getError('dynamic_start')"
        :delta-error="getError('delta')"
        :file-error="getError('file')"
        :file-name-error="getError('file_name')"
        :text-error="getError('text')"
        :sub-str-error="getError('sub_str')"
        :selector-error="getError('list_value')"
        :list-string-error="getError(value.name)"
      >
        <template v-slot:label>
          {{object.label}}<span v-if="object.required" class="red--text">*</span>:
        </template>
      </component>
    </template>
  </scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin"
import {mapActions, mapGetters} from "vuex";
import ScrollCenterDialog from "@/modules/templates/ScrollCenterDialog";
import TYPES from "@/helper/field_types";
import DateTimeWithBounds from "@/modules/templates/custom_fields/DateTimeWithBounds";
import DateWithBounds from "@/modules/templates/custom_fields/DateWithBounds";
import TimeWithBounds from "@/modules/templates/custom_fields/TimeWithBounds";
import ListString from "@/modules/templates/custom_fields/ListString";
import FlagFile from "@/modules/templates/custom_fields/FlagFile";
import Selector from "@/modules/templates/custom_fields/Selector.vue";

const FIELD_PARAMS = {
  FIELD_TYPE: 'field_type',
  NAME: 'name',
  HELP_TEXT: 'help_text',
  IS_REQUIRED: 'is_required',
  DEFAULT: 'default',
  LEN: 'len',
  DATE: 'date',
  DATE_TYPE: 'date_type',
  DELTA: 'delta',
  LIST_STRING: 'list_string',
  FILE_CREATE: 'file_create',
  FILE_EDIT: 'file_edit',
  FILE_OBJ: 'file_obj',
  FILE: 'file',
  SETTINGS: 'settings',
}

export default {
  name: "OrganiserFieldsEditDialog",
  props: {
    opened: Boolean,
    edit: Boolean,
    fieldId: Number,
  },
  mixins:[FormErrorsMixin],
  components: {
    ScrollCenterDialog,
    DateTimeWithBounds,
    DateWithBounds,
    TimeWithBounds,
    FlagFile,
    ListString,
    Selector
  },
  data() {
    const FIELDS = {
      NAME: {
        component: 'v-text-field',
        type: 'text',
        label: 'Наименование поля в системе',
        help_text: 'Этот текст отображается как "Наименование поля в системе"',
        required: true,
        model: 'name',
      },
      HELP_TEXT: {
        component: 'v-text-field',
        type: 'text',
        label: 'Подсказка по заполнению',
        help_text: 'Этот текст может содержать подсказку или пояснение по заполнению поля в пропуске и будет всплывающей подсказкой сбоку от поля',
        required: false,
        model: 'help_text',
      },
      IS_REQUIRED:{
        component: 'v-checkbox',
        label: "Обязательное поле",
        model: 'is_required',
      },
      DEFAULT: {
        component: 'v-text-field',
        type: 'text',
        label: 'Значение по умолчанию',
        help_text: 'Значение, которое устанавливается по умолчанию в поле',
        required: false,
        model: 'default',
      },
      REGEXP_TYPE: {
        component: 'v-select',
        label: 'Ограничения вводимого текста',
        help_text: 'Эти ограничения будут применяться к вводимой посетителем информации',
        required: true,
        model: 'regexp_type',
        items: 'regexp_type',
        item_text: 'text',
        item_value: 'value',
      },
      REGEXP: {
        component: 'v-text-field',
        type: 'text',
        label: 'Регулярное выражение',
        help_text: 'Пример корректного регулярного выражения: ^[a-zA-Z]*$',
        required: false,
        model: 'regexp',
      },
      LEN: {
        component: 'v-text-field',
        type: 'number',
        label: 'Длина текста',
        help_text: 'Максимальная длина текста для запрашиваемой информации (оставьте 0, если длина не имеет значения)',
        required: false,
        model: 'len',
      },
      DATE_TIME: {
        component: 'DateTimeWithBounds',
        model: 'date',
      },
      DATE: {
        component: 'DateWithBounds',
        model: 'date',
      },
      TIME: {
        component: 'TimeWithBounds',
        model: 'date',
      },
      LIST_STRING: {
        component: 'ListString',
        model: 'list_string',
      },
      TYPE: {
        component: 'v-autocomplete',
        label: 'Тип числа',
        help_text: 'Будет создано число выбранного типа',
        model: 'type',
        required: true,
        items: 'number_type',
        item_text: 'text',
        item_value: 'value',
      },
      MIN: {
        component: 'v-text-field',
        type: 'number',
        label: 'Минимальная граница',
        help_text: 'Минимальное допустимое значение для вводимого поля (оставьте пустым, если не хотите задавать минимальное допустимое число)',
        required: false,
        model: 'min',
      },
      MAX: {
        component: 'v-text-field',
        type: 'number',
        label: 'Максимальная граница',
        help_text: 'Максимальное допустимое значение для вводимого поля (оставьте пустым, если не хотите задавать максимальное допустимое число)',
        required: false,
        model: 'max',
      },
      FILE_OBJ: {
        component: 'FlagFile',
        model: 'file_obj',
      },
      SELECTOR: {
        component: 'Selector',
        model: 'list_value'
      }
    }
    const BASE_FIELDS = [FIELDS.NAME, FIELDS.HELP_TEXT, FIELDS.IS_REQUIRED]
    return {
      loading: {
        all: false,
        detail: false
      },
      choices: {
        field_type: [],
        number_type: [
          {value: 'int', text: 'Целочисленное',},
          {value: 'float', text: 'Дробное',},
        ],
        regexp_type: [],
      },
      value: {
        field_type: undefined,
        name: undefined,
        help_text: undefined,
        is_required: false,
        default: undefined,
        regexp: undefined,
        len: undefined,
        date: {
          date_type: 'static',
          static_start: undefined,
          static_end: undefined,
          dynamic_start: undefined,
          delta: {
            days: 0,
            hours: 0,
          }
        },
        list_string: {
          list: [{}],
          many: false,
        },
        type: undefined,
        min: undefined,
        max: undefined,
        file_obj: {
          text: undefined,
          sub_str: undefined,
          file_create: null,
          file_edit: null,
          file_name: undefined,
        },
        regexp_type: undefined,
        list_value: ['', ''],
      },
      TYPE_FIELDS: {
        [TYPES.TEXT]: { //тип = 0 Текст
          fields: [
            ...BASE_FIELDS,
            FIELDS.DEFAULT,
            FIELDS.REGEXP_TYPE,
            FIELDS.REGEXP,
            FIELDS.LEN,
          ]
        },
        [TYPES.DATE_TIME]: { //тип = 1 Дата и Время
          fields: [
            ...BASE_FIELDS,
            FIELDS.DATE_TIME,
          ]
        },
        [TYPES.DATE]: { //тип = 2 Дата
          fields: [
            ...BASE_FIELDS,
            FIELDS.DATE,
          ]
        },
        [TYPES.TIME]: { //тип 3 Время
          fields: [
            ...BASE_FIELDS,
            FIELDS.TIME,
          ]
        },
        [TYPES.LIST_STRING]: { //тип 4 Список строк
          fields: [
            ...BASE_FIELDS,
            FIELDS.LIST_STRING,
          ]
        },
        [TYPES.NUMBER]: { //тип = 6 Число
          fields: [
            ...BASE_FIELDS,
            FIELDS.DEFAULT,
            FIELDS.TYPE,
            FIELDS.MIN,
            FIELDS.MAX,
          ]
        },
        [TYPES.FLAG]: { //тип = 8 Флаг
          fields: [
            FIELDS.IS_REQUIRED,
            FIELDS.FILE_OBJ,
          ]
        },
        [TYPES.SELECTOR]: {
          ...BASE_FIELDS,
          fields: [
            ...BASE_FIELDS,
            FIELDS.SELECTOR
          ]
        }
      }
    }
  },
  watch: {
    'value.field_type'(){
      if(this.loading.detail){
        this.clearFields()
      } else{
        this.loading.detail = true
      }
    }
  },
  computed: {
    ...mapGetters({
      light_event_detail: 'organiser/getEventsLightDetail',
    }),
    form_title () {
      return this.edit === false ? 'Добавить поле сведений' : 'Редактировать поле сведений'
    },
  },
  methods: {
    ...mapActions({
      createFields: 'organiser/createFields',
      changeFields: 'organiser/changeFields',
      loadFieldsDetail: 'organiser/loadFieldsDetail',
      loadFieldTypeChoices: 'choices/loadFieldTypeChoices',
      loadRegexpTypeChoices: 'choices/loadRegexpTypeChoices',
    }),
    updateSelector(items) {
      this.value.list_value = items
    },
    close() {
      this.$emit('close', false)
    },
    isShow(object) {
      if(object.model !== 'is_required') {
        return true
      } else if (!this.light_event_detail.have_visits && object.model === 'is_required') {
        return true
      } else {
        return false
      }
    },
    clearFields(){
      this.value.default = undefined
      this.value.regexp_type = undefined
      this.value.regexp = undefined
      this.value.date.date_type = 'static'
      this.value.date.static_start = undefined
      this.value.date.static_end = undefined
      this.value.date.dynamic_start = undefined
      if(this.value.date.delta){
        this.value.date.delta.days = 0
        this.value.date.delta.hours = 0
      }
      this.value.list_string.list = [""]
      this.value.list_string.many = false
      this.value.type = undefined
      this.value.min = undefined
      this.value.max = undefined
      this.value.file_obj.text = undefined
      this.value.file_obj.sub_str = undefined
      this.value.file_obj.file_create = undefined
      this.value.file_obj.file_edit = undefined
      this.value.file_obj.file_name = undefined
      this.value.list_value = ['', '']
    },
    save() {
      this.loading.all = true
      let data = this.prepareData()
      if (data.field_type === 0 && data.settings.regexp_type && data.settings.regexp_type !== 6 && data.settings.regexp) {
        data.settings.regexp = '';
      }
      if (this.edit) {
        this.changeFields({
          event_id: this.$route.params.idEvent,
          id: this.fieldId,
          has_file: this.value.field_type === 8,
          data: data,
          finalizer: () => {
            this.loading.all = false
            this.$emit('close')
          },
          catcher: this.catchErrors
        })
      }
      else {
        this.createFields({
          event_id: this.$route.params.idEvent,
          has_file: this.value.field_type === 8,
          data: data,
          finalizer: () => {
            this.loading.all = false
            this.$emit('close')
          },
          catcher: this.catchErrors
        })
      }
    },
    prepareData: function () {
      // создаём пустой объект
      let data = {};
      data[FIELD_PARAMS.SETTINGS] = {}
      // проход по всем значениям, введённым в форме
      for (let el in this.value) {
        // запись свойств без отработки
        if (el !== FIELD_PARAMS.FIELD_TYPE && el !== FIELD_PARAMS.NAME && el !== FIELD_PARAMS.HELP_TEXT &&
            el !== FIELD_PARAMS.IS_REQUIRED && el !== FIELD_PARAMS.DEFAULT && el !== FIELD_PARAMS.DATE &&
            el !== FIELD_PARAMS.LEN && el !== FIELD_PARAMS.LIST_STRING && el !== FIELD_PARAMS.FILE_OBJ) {
          data.settings[el] = this.value[el]
        }
        // если это текст и свойство длина, то записываем число
        else if(el === FIELD_PARAMS.LEN && this.value.field_type === 0) {
          data.settings[el] = Number(this.value[el])
        }
        // если свойство дата и тип поля ( дата и время / дата / время )
        else if(el === FIELD_PARAMS.DATE && (this.value.field_type === 1 || this.value.field_type === 2 || this.value.field_type === 3)){
          // проходимся по двум полям (либо начало и конец для статического,
          // либо начало и длина промежутка для динамического)
          for (let el_i in this.value[el]){
            // запись свойств (под) без отработки
            if (el_i !== FIELD_PARAMS.DATE_TYPE && el_i !== FIELD_PARAMS.DELTA && this.value[el][el_i] !== undefined){
              data.settings[el_i] = this.value[el][el_i]
            }
            // если свойство (под) длина промежутка и динамическая и дата и время, то разбираем на дни и часы
            else if(el_i === FIELD_PARAMS.DELTA && this.value[el][FIELD_PARAMS.DATE_TYPE] ==='dynamic' && this.value.field_type === 1){
              this.value[el][el_i].days = Number(this.value[el][el_i].days)
              this.value[el][el_i].hours = Number(this.value[el][el_i].hours)
              data.settings[el_i] = this.value[el][el_i]
            }
            // если свойство (под) длина промежутка и динамическая и дата, то разбираем на дни
            else if(el_i === FIELD_PARAMS.DELTA && this.value[el][FIELD_PARAMS.DATE_TYPE] ==='dynamic' && this.value.field_type === 2){
              this.value[el][el_i].days = Number(this.value[el][el_i].days)
              delete this.value[el][el_i].hours
              data.settings[el_i] = this.value[el][el_i]
            }
            // если свойство (под) длина промежутка и динамическая и время, то разбираем на часы
            else if(el_i === FIELD_PARAMS.DELTA && this.value[el][FIELD_PARAMS.DATE_TYPE] ==='dynamic' && this.value.field_type === 3){
              this.value[el][el_i].hours = Number(this.value[el][el_i].hours)
              delete this.value[el][el_i].days
              data.settings[el_i] = this.value[el][el_i]
            }
          }
        }
        // список строк
        else if(el === FIELD_PARAMS.LIST_STRING && this.value.field_type === 4) {
          if(this.value.list_string.list.length > -1){
            data.settings.many = true
          }
        }
        // флаг
        else if(el === FIELD_PARAMS.FILE_OBJ && this.value.field_type === 8) {
          for (let el_i in this.value[el]) {
            if (el_i !== FIELD_PARAMS.FILE_CREATE) {
              data.settings[el_i] = this.value[el][el_i]
            } else if (this.value[el][el_i]) {
              data.file = this.value[el][el_i]
            }
          }
          if(this.value.list_string.list.length > -1){
            data.settings.many = true
          }
        }
            // если обычные свойства (не в settings)
        // FIELD_TYPE, NAME, HELP_TEXT, IS_REQUIRED, DEFAULT
        else if(el !== FIELD_PARAMS.LEN && el !== FIELD_PARAMS.DATE && el !== FIELD_PARAMS.LIST_STRING && el !== FIELD_PARAMS.FILE_OBJ) {
          data[el] = this.value[el]
        }
      }
      //если дата и время, дата или время, то default это дата начала
      if (this.value.field_type === 1 || this.value.field_type === 2 || this.value.field_type === 3){
        data.default = this.value.date.static_start ? this.value.date.static_start : this.value.date.dynamic_start
      }
      // если список строк, то default это сам список
      if(this.value.field_type === 4){
        data.default = this.value.list_string.list
      }
      // если флаг то формируем дату
      if(this.value.field_type === 8) {
        let form_data = new FormData();
        if (this.edit && data && data['settings'] && !data['settings']['file_edit'] && !data.file) {
          form_data.append('file', '')
        }
        for (let key of Object.keys(data))
        {
          if (key === FIELD_PARAMS.SETTINGS) {
            if (this.edit && data && data['settings'] && data['settings']['file_edit']) {
              delete data['settings']['file_edit']
            }
            let settings_keys = Object.keys(data[key])
            for (let i = 0; i < settings_keys.length; i++) {
              if (data[key][settings_keys[i]] === '') {
                delete data[key][settings_keys[i]];
              }
            }
            form_data.append(key, JSON.stringify(data[key]))
          } else if (key !== FIELD_PARAMS.DEFAULT){
            form_data.append(key, data[key])
          }
        }
        return form_data
      } else {
        return data
      }
    },
    catchErrors(errors) {
      this.loading.all = false;
      this.mapErrors(errors)
    },
    loadChoices() {
      this.loadFieldTypeChoices({
        finalizer: (data) => {
          this.choices.field_type = data
          if (!this.fieldId)
            this.value.field_type = data[0].value
        }
      })
      this.loadRegexpTypeChoices({
        finalizer: (data) => {
          this.choices.regexp_type = data
          if (!this.fieldId)
            this.value.regexp_type = data[0].value
        }
      })
    },
    loadField(url=undefined){
      this.loadFieldsDetail({
        url: url,
        event_id: this.$route.params.idEvent,
        id: this.fieldId,
        finalizer: (data) => {
          this.value.field_type = data.field_type
          this.value.name = data.name ? data.name : ''
          this.value.help_text = data.help_text
          this.value.is_required = data.is_required
          this.value.default = data.default
          this.value.regexp_type = data.settings.regexp_type
          this.value.regexp = data.settings.regexp
          this.value.len = data.settings.len
          this.value.date.date_type = data.settings.dynamic_start ? 'dynamic' : 'static'
          this.value.date.static_start = data.settings.static_start
          this.value.date.static_end = data.settings.static_end
          this.value.date.dynamic_start = data.settings.dynamic_start
          this.value.date.delta = data.settings.delta
          if (data.default) {
            this.value.list_string.list = data.default
          } else {
            this.value.list_string.list = [""]
          }
          this.value.list_string.many = data.settings.many
          this.value.type = data.settings.type
          this.value.min = data.settings.min
          this.value.max = data.settings.max
          this.value.file_obj.text = data.settings.text
          this.value.file_obj.sub_str = data.settings.sub_str
          this.value.file_obj.file_edit = data.file
          this.value.file_obj.file_name = data.settings.file_name
          this.value.list_value = data.settings.list_value
        }
      })
    },
  },
  created() {
    this.loadChoices()
    if(this.edit){
      this.loadField()
    }
  }
}
</script>

<style scoped>

</style>
