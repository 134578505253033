import {sendPatchRequest, sendGetRequest, sendPostRequest} from "@/helper/requests";
import visitor from "@/urls/roles/visitor";
import {executeRequest} from "@/store/utils";

const state = {
    records:{
        currentUrl: null,
        items: [],
        loading: false,
        count: 0,
        error: null,
    }
}

const getters = {
    getRecordsList: state => state.records.items,
    getRecordsCount: state => state.records.count,
    getRecordsCurrentUrl: state => state.records.currentUrl,
    isLoadingRecords: state => state.records.loading,
    saveRecordsError: state => state.records.error,
}

const actions = {
    loadRecordsList: ({commit, getters}, {url, visit_id, urlAppend, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) {
            url = visitor.VISITOR.EVENTS.RECORDS.LIST(visit_id)
            if (urlAppend) url = urlAppend(url)
        }
        if (!getters.isLoadingRecords){
            commit('SET_RECORDS_LOADING');
            let headers = {}
            if(window.$cookies.get("visitor_token")){
                headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'EventToken ' + window.$cookies.get("visitor_token")
                }
            }
            sendGetRequest(
                url, (data, url) => {
                    commit('SET_RECORDS_LIST', data['results']);
                    commit('SET_RECORDS_COUNT', data['count']);
                    commit('SET_RECORDS_CURRENT_URL', url);
                    commit('SET_RECORDS_LOADING_END');
                    finalizer(data, url);
                }, (data, url) => {
                    commit('SET_RECORDS_LOADING_END');
                    commit('SAVE_RECORDS_ERROR', data);
                    catcher(data, url);
                },
            undefined,
                headers
            )
        }
    },
    cancelRecords: ({commit}, {visit_id, id, finalizer=()=>{}, catcher=()=>{}}) => {
        let  url = visitor.VISITOR.EVENTS.RECORDS.CANCEL(visit_id, id)
        let headers = {}
        if(window.$cookies.get("visitor_token")){
            headers = {
                'Content-Type': 'application/json',
                'Authorization': 'EventToken ' + window.$cookies.get("visitor_token")
            }
        }
        sendPatchRequest(
            url,
            {},
            (data, url) => {
                finalizer(data, url);
            },
            (data, url) => {
                commit('SAVE_RECORDS_ERROR', data);
                catcher(data, url);
            },
            headers
        )
    },
    createRecords: ({dispatch, commit, getters}, {data, visit_id, finalizer=()=>{}, catcher=()=>{}}) => {
        let url = visitor.VISITOR.EVENTS.RECORDS.CREATE(visit_id)
        let headers = {}
        if(window.$cookies.get("visitor_token")){
            headers = {
                'Content-Type': 'application/json',
                'Authorization': 'EventToken ' + window.$cookies.get("visitor_token")
            }
        }
        executeRequest('Events', sendPostRequest, {url, data, finalizer, catcher, headers}, {dispatch, commit, getters});
    },
}

const mutations = {
    SET_RECORDS_LIST: (state, records) => {
        state.records.items = records
    },
    SET_RECORDS_COUNT: (state, value) => {
        state.records.count = value
    },
    SET_RECORDS_CURRENT_URL: (state, value) => {
        state.records.currentUrl = value
    },
    SET_RECORDS_LOADING: (state) => {
        state.records.loading = true
    },
    SET_RECORDS_LOADING_END: (state) => {
        state.records.loading = false
    },
    SAVE_RECORDS_ERROR: (state, error) => {
        state.records.error = error
    }
}


export default {
    namespaced: false,
    state,
    mutations,
    actions,
    getters
}
