<template>
  <div>
    <div class="header-label-text mt-1 ml-3">
      {{ name }}
      <span v-if="isRequired" class="red--text">*</span>
    </div>
    <v-textarea
        rows="1"
        type="text"
        auto-grow
        class="py-0 my-0 ml-3"
        outlined
        v-model="value"
        :error="!!(errorMessage && errorMessage.length > 0)"
        :errorMessages="errorMessage"
        @change="$emit('update:inputValue', value)"
    >
      <template v-slot:label>
      </template>
      <template v-slot:append-outer>
        <v-tooltip bottom max-width="320px"
           v-if="helpText
            || (settings.len && settings.len > 0)
            || settings.regexp_type && (settings.regexp_type !== 'Без ограничений' && settings.regexp_type !== 'Ввести своё регулярное выражение')"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                class="mr-5 ml-3"
                v-bind="attrs"
                v-on="on"
                color="primary"
            >
              help
            </v-icon>
          </template>
          <span v-html="getHelpText()"></span>
        </v-tooltip>
        <div v-else style="width: 56px"></div>
      </template>
    </v-textarea>
  </div>
</template>

<script>
export default {
  name: "VisitorFormTextField",
  props: {
    inputValue: String,
    errorMessage: String,
    isRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    index: Number,
    name: String,
    helpText: String,
    settings: Object,
  },
  data() {
    return {
      value: undefined,
    }
  },
  methods: {
    getHelpText() {
      let text = ''
      if (this.helpText) {
        text += this.helpText + '<br/>'
      }
      if (this.settings.len > 0) {
        if (this.helpText) {
          text += '<br/>'
        }
        text += 'Длина введённого текста не должна превышать ' + this.settings.len + ' символов'
      }
      if (this.settings.regexp_type && this.settings.regexp_type !== 'Без ограничений' && this.settings.regexp_type !== 'Ввести своё регулярное выражение') {
        if (this.helpText || this.settings.len > 0) {
          text += '<br/>'
        }

        text += (this.settings.regexp_type === 'Только латиница') ? 'В тексте должна быть ' : 'Текст должен быть '

        text += this.settings.regexp_type.toLowerCase()
      }

      return text
    },
  },
  created() {
    this.value = this.inputValue
  }
}
</script>

<style scoped>
.header-label-text {
  text-align: left;
  color: #000;
  font-family: "MADE Evolve Sans normal", serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 10px;
  padding-right: 56px;
}
</style>
