<template>
  <div>
    <div class="header-text">Список доступных записей</div>
    <template v-for="(slot_event, i) in slotEvents">
      <v-divider :key="i"></v-divider>
      <SingleSlotEvent
        :key="i + 'slot_event'"
        :slot-event="slot_event"
        :bg-color-index="(i + 1) % 2"
        :has-visit="hasVisit"
        @createRecord="(data) => $emit('createRecord', data)"
        @cancelRecord="(data) => $emit('cancelRecord', data)"
      ></SingleSlotEvent>
    </template>
    <v-divider></v-divider>
  </div>
</template>

<script>
import SingleSlotEvent from "@/modules/templates/visitor/event_detail/SingleSlotEvent.vue";

export default {
  name: "SlotEventsTable",
  components: {SingleSlotEvent},
  props: {
    slotEvents: Array,
    hasVisit: Boolean,
  }
}
</script>

<style scoped lang="scss">
.header-text {
  color: #484848;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding: 0 24px;
}
</style>
