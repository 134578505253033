<template>
  <scroll-center-dialog v-model="opened" :loading="save_loading" width="760" @close="close" @apply="save" :title="form_title" :scrolled="false">
    <div v-if="loading">
      <v-row class="pa-0 ma-0 mt-4">
        <v-col cols="12" class="py-0 px-2 ma-0" v-if="!edit">
          <v-select
            v-model="visitor_status"
            :items="visitor_status_choices"
            outlined
            label="Статус пользователя"
          ></v-select>
        </v-col>
        <v-col cols="12" class="py-0 px-2 ma-0" v-if="visitor_status === 0">
          <loading-autocomplete
              :outlined="true"
              v-if="!this.edit"
              placeholder="Введите ФИО и выберите из предложенных вариантов"
              :label="`Пользователь`"
              v-model="selected_user"
              :required="true"
              search-parameter="fio"
              :load-fios="false"
              :url="userSelector"
              :error="hasError('email')"
              :error-messages="getError('email')"
              class="mt-4 mb-0"
          ></loading-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0 px-2 ma-0" v-if="visitor_status === 1 || edit">
          <v-text-field
            v-model="visitor.lastname"
            outlined
            label="Фамилия"
            :error="hasError('lastname')"
            :error-messages="getError('lastname')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="py-0 px-2 ma-0" v-if="visitor_status === 1 || edit">
          <v-text-field
            v-model="visitor.firstname"
            outlined
            label="Имя"
            :error="hasError('firstname')"
            :error-messages="getError('firstname')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="py-0 px-2 ma-0" v-if="visitor_status === 1 || edit">
          <v-text-field
            v-model="visitor.midname"
            outlined
            label="Отчество"
            :error="hasError('midname')"
            :error-messages="getError('midname')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="py-0 px-2 ma-0" v-if="visitor_status === 1 || edit">
          <v-text-field
            v-model="visitor.email"
            outlined
            label="e-mail"
            :error="hasError('email')"
            :error-messages="getError('email')"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
  </scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import ScrollCenterDialog from "@/modules/templates/ScrollCenterDialog.vue";
import {mapActions, mapGetters} from "vuex";
import selectors from "@/urls/selectors";
import LoadingAutocomplete from "@/modules/core/components/LoadingAutocomplete";

export default {
  name: "AdminVisitorsEditDialog",
  props: {
    opened: Boolean,
    edit: Boolean,
    visitorId: Number,
  },
  mixins: [FormErrorsMixin],
  components: {
    ScrollCenterDialog,
    LoadingAutocomplete
  },
  data() {
    return {
      loading: false,
      save_loading: false,
      visitor: {
        email: '',
        firstname: '',
        lastname: '',
        midname: '',
        is_data_access: false,
      },
      selected_user: undefined,
      visitor_status: 0,
      visitor_status_choices: [
        {text: 'Пользователь MUCTR', value: 0},
        {text: 'Внешний пользователь', value: 1}
      ],
    }
  },
  computed: {
    ...mapGetters({
      item: 'admin/getVisitorsDetail'
    }),
    form_title() {
      return !this.edit ? 'Добавить посетителя' : this.item.fio
    },
    userSelector() {
      return selectors.SELECTORS.USERS.ALL()
    },
  },
  methods: {
    ...mapActions({
      loadVisitorDetail: 'admin/loadVisitorsDetail',
      changeVisitor: 'admin/changeVisitors',
      createVisitor: 'admin/createVisitors'
    }),
    close() {
      this.$emit('close', false)
    },
    save() {
      if (this.edit) {
        this.changeVisitorFunction()
      } else {
        if (this.visitor_status === 0) {
          this.prepareMuctrUserData()
        }
        this.createVisitorFunction()
      }
    },
    prepareMuctrUserData() {
      delete this.visitor.email
      delete this.visitor.firstname
      delete this.visitor.lastname
      delete this.visitor.midname
      this.visitor.user = this.selected_user
    },
    changeVisitorFunction() {
      this.save_loading = true;
      this.changeVisitor({
        id: this.visitorId,
        data: this.visitor,
        finalizer: () => {
          this.close()
        },
        catcher: (val) => {
          this.save_loading = false;
          this.mapErrors(val)
        }
      })
    },
    createVisitorFunction() {
      this.save_loading = true;
      this.createVisitor({
        data: this.visitor,
        finalizer: () => {
          this.close()
        },
        catcher: (val) => {
          this.save_loading = false;
          this.mapErrors(val)
        }
      })
    },
    loadData() {
      if (this.edit) {
        this.loadVisitorDetail({
          id: this.visitorId,
          finalizer: () => {
            this.visitor = Object.assign({}, this.item)
            delete this.visitor.fio;
            delete this.visitor.is_active;
            delete this.visitor.id;
            if (this.edit) {
              delete this.visitor.is_data_access
            }
            this.loading = true;
          }
        })
      } else {
        this.loading = true;
      }
    }
  },
  created() {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
