import {
    sendPostRequest, sendPatchRequest, sendDeleteRequest, sendGetRequest
} from "@/helper/requests";
import admin from "@/urls/roles/admin";
import selectors from "@/urls/selectors";

const state = {
    turns: [],
}

const getters = {

}

const mutations = {

}

const actions = {
    // loadWindowSelector: ({commit}, {url, finalizer, catcher}) => {
    //     if (!url) url = selectors.SELECTORS.EQUEUE.WINDOWS()
    //     sendGetRequest(
    //         url, finalizer, catcher
    //     )
    // },
    // loadUserSelector: ({commit}, {url, finalizer, catcher}) => {
    //     if (!url) url = selectors.SELECTORS.EQUEUE.ROLE()
    //     sendGetRequest(
    //         url, finalizer, catcher
    //     )
    // },
    // loadTurnSelector: ({commit}, {finals, finalizer, catcher}={}) => {
    //     let url = selectors.SELECTORS.EQUEUE.TURN({finals: finals})
    //     sendGetRequest(
    //         url, (data, url) => {
    //             commit('SET_TURN_LIST', data);
    //             if(finalizer) finalizer(data, url)
    //         }, catcher
    //     )
    // },
    loadIntervalsSelector: ({commit}, {finals, finalizer, catcher}={}) => {
        let url = selectors.SELECTORS.INTERVALS.ALL()
        sendGetRequest(
            url, (data, url) => {
                commit('SET_TURN_LIST', data);
                if(finalizer) finalizer(data, url)
            }, catcher
        )
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}
