<template>
  <div>
    <v-expansion-panels
        v-model="panel"
        multiple
    >
      <v-expansion-panel class="rounded-0">
        <v-expansion-panel-header class="text-h6 font-weight-bold">
          Список вложенных событий
          <template v-slot:actions>
            <v-icon color="primary">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-toolbar
              class="removeBtnPaddingRight rounded-t-lg"
              color="#DADADA"
              flat
          >
            <v-text-field
                v-model="search.value"
                class="dark-primary my-1"
                clearable
                dense
                outlined
                label="Поиск"
                hide-details
                @keydown.enter="searchData"
                @click:clear="updateDataEvents"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-toolbar-items v-if="$vuetify.breakpoint.smAndUp && isAdminOrOrganiser() && parentEventIsEditable">
              <v-btn
                  text
                  @click="newNestedEvent"
                  class="dark-primary mr-0 rounded-tr-lg"
              >
                Добавить событие
              </v-btn>
            </v-toolbar-items>
            <v-btn
                v-if="$vuetify.breakpoint.xsOnly && isAdminOrOrganiser() && parentEventIsEditable"
                icon
                class="dark-primary mr-0"
                @click="newNestedEvent"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="this[`itemsEvents${this.role}`]"
                :loading="this[`isLoadingEvents${this.role}`]"
                :page.sync="pagination.page"
                :items-per-page.sync="pagination.size"
                :server-items-length="this[`countEvents${this.role}`]"
                :options.sync="options"
                :footer-props="{'items-per-page-options':[10,50,100]}"
                class="elevation-1 rounded-b-lg"
            >
              <template v-slot:item.name="{ item }">
                <span style="text-decoration: underline; color: #065aad; cursor: pointer" @click="getDetailEvent(item.id)">{{item.name}}</span>
              </template>
              <template v-slot:item.record_start="{ item }">
                <span v-if="item.record_start!==null">{{ displayDate(item.record_start) }}</span>
              </template>
              <template v-slot:item.record_end="{ item }">
                <span v-if="item.record_end!==null">{{ displayDate(item.record_end) }}</span>
              </template>
              <template v-slot:item.start="{ item }">
                <span v-if="item.start!==null">{{ displayDate(item.start) }}</span>
              </template>
              <template v-slot:item.end="{ item }">
                <span v-if="item.end!==null">{{ displayDate(item.end) }}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom v-if="isAdminOrOrganiser() && parentEventIsEditable">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        class="mr-2"
                        @click="editEvent(item.id)"
                        v-bind="attrs"
                        v-on="on"
                    >
                      edit
                    </v-icon>
                  </template>
                  <span>Редактировать</span>
                </v-tooltip>
                <v-tooltip bottom v-if="isAdminOrOrganiser() && parentEventIsEditable">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        @click="deleteEvent(item.id)"
                        v-bind="attrs"
                        v-on="on"
                    >
                      delete
                    </v-icon>
                  </template>
                  <span>Удалить</span>
                </v-tooltip>
              </template>
              <template v-slot:no-data>
                <v-btn
                    color="primary"
                    @click="updateDataEvents"
                >
                  Обновить
                </v-btn>
              </template>
            </v-data-table>
          <EditDialogAdmin
              v-if="dialog_add_edit && isAdmin()"
              :opened="dialog_add_edit"
              :edit="edit_status"
              :event-end="eventEnd"
              :event-start="eventStart"
              :event-id="event_id"
              :root-event="false"
              @close="closeEditDialog"
          ></EditDialogAdmin>
            <EditDialogOrganiser
              v-if="dialog_add_edit && isOrganiser()"
              :opened="dialog_add_edit"
              :edit="edit_status"
              :event-end="eventEnd"
              :event-start="eventStart"
              :event-id="event_id"
              :root-event="false"
              @close="closeEditDialog"
          ></EditDialogOrganiser>
          <ApplyDialog
              v-if="dialog_delete && isAdminOrOrganiser()"
              :opened="dialog_delete"
              :get-text="() => `Вы уверены, что хотите удалить ?`"
              @close="closeDeleteDialog"
          ></ApplyDialog>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import EditDialogAdmin from "@/modules/admin/events/modals/EventsEditDialog";
import EditDialogOrganiser from "@/modules/organiser/events/modals/EventsEditDialog";
import ApplyDialog from "@/modules/core/components/ApplyDialog";
import {displayDate} from "@/helper";

export default {
  name: "EventDetailEventsList",
  props: {
    role:{
      type: String,
      // default: 'Admin',
    },
    needReloadListEvents: {
      type: Boolean,
    },
    organiserAllowUpdate:{
      type: Boolean,
    },
    eventStart: {
      required: false,
      type: String,
      default: ''
    },
    eventEnd: {
      required: false,
      type: String,
      default: ''
    },
    parentEventIsEditable: {
      required: false,
      type: Boolean,
      default: true
    },
  },
  components: {
    EditDialogAdmin,
    EditDialogOrganiser,
    ApplyDialog
  },
  mixins: [PaginatedDataMapperMixin],
  data() {
    return {
      dialog_add_edit: false,
      dialog_delete: false,
      edit_status: undefined,
      event_id: undefined,
      panel: [0, 1],
      search: {
        parameter: 'name',
      },
      headers: [
        {text: 'Название', value: 'name', sortable: false, align: 'start', width: '40%'},
        {text: 'Дата начала события', value: 'start', sortable: false, align: 'center', width: '25%'},
        {text: 'Дата конца события', value: 'end', sortable: false, align: 'center', width: '25%'},
      ],
    }
  },
  watch: {
    needReloadListEvents(){
      if(this.needReloadListEvents  && !this.dialog_add_edit){
        this.loadData()
        this.$emit("notNeedReloadListEvents", !this.needReloadListEvents)
      }
    }
  },
  computed: {
    ...mapGetters({
      itemsEventsAdmin: 'admin/getEventsList',
      countEventsAdmin: 'admin/getEventsCount',
      isLoadingEventsAdmin: 'admin/isLoadingEvents',
      itemsEventsViewer: 'viewer/getEventsList',
      countEventsViewer: 'viewer/getEventsCount',
      isLoadingEventsViewer: 'viewer/isLoadingEvents',
      itemsEventsOrganiser: 'organiser/getEventsList',
      countEventsOrganiser: 'organiser/getEventsCount',
      isLoadingEventsOrganiser: 'organiser/isLoadingEvents',
      itemsEventsOperator: 'operator/getEventsList',
      countEventsOperator: 'operator/getEventsCount',
      isLoadingEventsOperator: 'operator/isLoadingEvents',
    }),
  },
  methods: {
    ...mapActions({
      loadEventsListAdmin: 'admin/loadEventsList',
      deleteEventsAdmin: 'admin/deleteEvents',
      loadEventsListViewer: 'viewer/loadEventsList',
      loadEventsListOrganiser: 'organiser/loadEventsList',
      deleteEventsOrganiser: 'organiser/deleteEvents',
      loadEventsListOperator: 'operator/loadEventsList',
      changeEventMenuReloadState: 'leftmenu/changeNeedEventMenuReloadState',
    }),
    displayDate: displayDate,
    getDetailEvent(id) {
      this.$router.push({name: this.role + '|Events|Detail', params: {idEvent: id}})
    },
    updateDataEvents(){
      if (this.search.value) {
        this.search.value = ''
      }
      this.clearSearchData()
    },
    loadData(url = undefined) {
      this[`loadEventsList${this.role}`]({
        url: url,
        urlAppend: (url) => {
          let new_url = this.addPageParameter(this.addSizeParameter(url))
          if (this.search.value)
            new_url = this.addGetParameter(new_url, 'name', this.search.value)
          new_url = this.addGetParameter(new_url, 'parent_event', this.$route.params.idEvent)
          return new_url
        },
        is_parent: false,
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
        }
      })
    },
    newNestedEvent() {
      this.dialog_add_edit = true
      this.edit_status = false
      this.event_id = undefined
    },
    editEvent(id) {
      this.dialog_add_edit = true
      this.edit_status = true
      this.event_id = id
    },
    deleteEvent(id) {
      this.dialog_delete = true
      this.event_id = id
    },
    closeEditDialog(event) {
      this.dialog_add_edit = false
      this.event_id = undefined
      this.changeEventMenuReloadState({value: true})
    },
    closeDeleteDialog(event) {
      if (event) {
        this[`deleteEvents${this.role}`]({
          id: this.event_id,
          finalizer: (val) => {
            this.changeEventMenuReloadState({value: true})
          },
          catcher: (val) => {
            this.mapErrors(val)
          },
        })
      }
      this.dialog_delete = false
      this.event_data = undefined
    },
    isAdminOrOrganiser(){
      if(this.role==='Admin' || (this.role==='Organiser' && this.organiserAllowUpdate))
        return true
      else
        return false
    },
    isAdmin(){
      if(this.role==='Admin')
        return true
      else
        return false
    },
    isOrganiser(){
      if(this.role==='Organiser' && this.organiserAllowUpdate)
        return true
      else
        return false
    },
  },
  created() {
    this.loadData()
    if (this.isAdminOrOrganiser()) {
      this.headers.push({text: 'Действия', value: 'actions', sortable: false, align: 'center', width: '10%'})
    }
  },
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0;
}
</style>
