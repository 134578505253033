<template>
  <v-container class="page-content px-5" style="  width: 100%" >
    <div v-if="$route.name === names.FIND_VISITS.LIST">
      <v-toolbar
        class="removeBtnPaddingRight rounded-t-lg"
        color="#DADADA"
        flat
      >
        <v-text-field
          v-model="pagination.filters.search"
          class="dark-primary my-1 mr-1"
          clearable
          dense
          outlined
          :disabled="isLoading"
          label="ФИО/Почта"
          hide-details
          @keydown.enter="loadDataWithFilters"
        ></v-text-field>
        <v-text-field
          v-model="pagination.filters.event_name"
          class="dark-primary my-1 ml-1"
          clearable
          dense
          outlined
          :disabled="isLoading"
          label="Событие"
          hide-details
          @keydown.enter="loadDataWithFilters"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          icon
          color="black"
          class="mr-0"
          @click="show = !show"
        >
          <v-icon>{{ show ? 'expand_less' : 'expand_more' }}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-slide-y-transition>
        <v-toolbar
          flat
          color="#DADADA"
          v-if="show"
          elevation="0"
          :height="extensionHeight"
        >
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                  dense
                  class="dark-primary my-1"
                  :class="$vuetify.breakpoint.smAndUp ? ' mr-1' : ''"
                  v-model="start"
                  type="date"
                  outlined
                  label="После"
                  clearable
                  @keydown.enter="updateDateFilters('start')"
                  @keydown.tab="updateDateFilters('start')"
                  @blur="updateDateFilters('start')"
                  @input="updateDateFilters('start')"
                  @click:clear="updateClearedDateFilters"
                  :disabled="isLoading"
                  hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                  dense
                  class="dark-primary my-1"
                  v-model="end"
                  type="date"
                  outlined
                  label="До"
                  clearable
                  @keydown.enter="updateDateFilters('end')"
                  @keydown.tab="updateDateFilters('end')"
                  @blur="updateDateFilters('end')"
                  @input="updateDateFilters('end')"
                  @click:clear="updateClearedDateFilters"
                  :disabled="isLoading"
                  hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                label="Статус"
                class="dark-primary my-1"
                :class="$vuetify.breakpoint.mdAndUp ? ' ml-1' : ''"
                outlined
                dense
                hide-details
                :disabled="isLoading"
                v-model="selected_status"
                :items="status_choices"
                @change="changeStatusFilters"
              ></v-select>
            </v-col>
          </v-row>
        </v-toolbar>
      </v-slide-y-transition>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="isLoading"
        :page.sync="pagination.page"
        :items-per-page.sync="pagination.size"
        :server-items-length="count"
        :options.sync="options"
        :footer-props="{'items-per-page-options':[10,50,100]}"
        class="elevation-1 rounded-b-lg"
      >
        <!--        <template v-slot:item.visitor.fio="{ item }">-->
        <!--          <span style="text-decoration: underline; color: #065aad; cursor: pointer" @click="getDetailVisit(item.id)">{{item.visitor.fio}}</span>-->
        <!--        </template>-->
        <template v-slot:item.event.start="{ item }">
          <span v-if="item.event.start!==null">{{ displayDate(item.event.start) }}</span>
        </template>
        <template v-slot:item.event.end="{ item }">
          <span v-if="item.event.end!==null">{{ displayDate(item.event.end) }}</span>
        </template>
        <template v-slot:item.have_records="{ item }">
          <v-icon :color="checkFlag(item.have_records).color">
            {{ checkFlag(item.have_records).icon }}
          </v-icon>
        </template>
        <template v-slot:item.is_end="{ item }">
          <v-icon :color="checkFlag(item.is_end).color">
            {{ checkFlag(item.is_end).icon }}
          </v-icon>
        </template>
        <template v-slot:item.is_enter="{ item }">
          <v-icon :color="checkFlag(item.is_enter).color">
            {{ checkFlag(item.is_enter).icon }}
          </v-icon>
        </template>
        <template v-slot:item.is_cancel="{ item }">
          <v-icon :color="checkCancelFlag(item.is_cancel).color">
            {{ checkFlag(item.is_cancel).icon }}
          </v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom v-if="!item.is_cancel && !item.is_end && !item.is_enter && !item.has_entered_records">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  class="mr-2"
                  @click="cancelVisit(item)"
                  v-bind="attrs"
                  v-on="on"
              >
                cancel
              </v-icon>
            </template>
            <span>Отменить посещение</span>
          </v-tooltip>
          <v-tooltip bottom v-if="!item.is_cancel && !item.is_end && item.is_enter && !item.has_entered_records">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  class="mr-2"
                  @click="backVisit(item)"
                  v-bind="attrs"
                  v-on="on"
              >
                undo
              </v-icon>
            </template>
            <span>Отменить фиксацию посещения</span>
          </v-tooltip>
          <v-tooltip bottom v-if="!item.is_cancel && !item.is_end && !item.is_enter">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click="enterVisit(item)"
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                add_task
              </v-icon>
            </template>
            <span>Зафиксировать посещение мероприятия</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-btn
              color="primary"
              @click="updateData"
          >
            Обновить
          </v-btn>
        </template>
      </v-data-table>
      <ApplyDialog
        v-if="dialog_enter"
        :opened="dialog_enter"
        :get-text="() => `Вы уверены, что хотите зафиксировать посещение мероприятия ?`"
        @close="closeEnterDialog"
      ></ApplyDialog>
      <ApplyDialog
          v-if="dialog_back"
          :opened="dialog_back"
          :get-text="() => `Вы уверены, что хотите отменить фиксацию посещения мероприятия ?`"
          @close="closeBackDialog"
      ></ApplyDialog>
      <ApplyDialog
          v-if="dialog_cancel"
          :opened="dialog_cancel"
          :get-text="() => `Вы уверены, что хотите отменить посещение ?`"
          @close="closeCancelDialog"
      ></ApplyDialog>
    </div>
    <router-view v-else></router-view>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import {displayDate} from "@/helper";
import admin from "@/urls/roles/admin";
import names_core from "@/modules/core/routers/names";
import names from "@/modules/admin/routers/names";
import ApplyDialog from "@/modules/core/components/ApplyDialog.vue";
export default {
  name: "AdminFindVisitsList",
  components: {ApplyDialog},
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  data() {
    return {
      page_title: "Поиск посещений",
      dialog_enter: false,
      dialog_cancel: false,
      visit_data: undefined,
      headers: [
        {text: 'Посетитель', value: 'visitor.fio', sortable: false, align: 'start', width: '15%'},
        {text: 'Событие', value: 'event.name', sortable: false, align: 'start', width: '15%'},
        {text: 'Дата начала события', value: 'event.start', sortable: false, align: 'center', width: '10%'},
        {text: 'Дата конца события', value: 'event.end', sortable: false, align: 'center', width: '10%'},
        {text: 'Есть записи', value: 'have_records', sortable: false, align: 'center', width: '10%'},
        {text: 'Событие завершено', value: 'is_end', sortable: false, align: 'center', width: '10%'},
        {text: 'Посещено', value: 'is_enter', sortable: false, align: 'center', width: '10%'},
        {text: 'Отменено', value: 'is_cancel', sortable: false, align: 'center', width: '10%'},
        {text: 'Действия', value: 'actions', sortable: false, align: 'center', width: '10%'},
      ],
      show: false,
      selected_status: 0,
      status_choices: [
        {text: 'Все', value: 0},
        {text: 'Активно', value: 1},
        {text: 'Посещено', value: 2},
        {text: 'Отменено', value: 3},
      ],
      start: undefined,
      end: undefined,
      timeout_set: false,
      tmp_filters_object: {},
      timeout_time_ms: 1000,
      timeout_id: undefined,
      disable_mixin_filters_watcher: true,
      dialog_back: false
    }
  },
  computed: {
    ...mapGetters({
      items: 'admin/getVisitsList',
      count: 'admin/getVisitsCount',
      isLoading: 'admin/isLoadingVisits'
    }),
    names: () => names,
    extensionHeight() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return 170
      } else if (this.$vuetify.breakpoint.smOnly) {
        return 120
      } else {
        return 0
      }
    },
  },
  watch: {
    'pagination.filters': {
      deep: true,
      handler: function (nVal, oVal) {
        // переменная timeout_set отвечает за текущую активную работу таймера
        if (!this.timeout_set) {
          // при первом изменении значений фильтров меняем булево значение
          this.timeout_set = true;
          // сохраняем текущее значение фильтров
          this.saveCurrentFiltersState();
          // запускаем отложенную функцию
          this.timeout_id = setTimeout(this.filtersTimeoutFunction, this.timeout_time_ms)
        }
      }
    },
  },
  methods: {
    ...mapActions({
      loadVisitsList: 'admin/loadVisitsList',
      enterVisits: 'admin/enterVisits',
      cancelVisits: 'admin/cancelVisits',
      backVisits: 'admin/rollBackEnterVisits'
    }),
    displayDate: displayDate,
    saveCurrentFiltersState() {
      this.tmp_filters_object = Object.assign({}, this.pagination.filters)
    },
    filtersTimeoutFunction() {
      // если фильтры изменились за время таймаута, то
      if (this.filtersWereChanged()) {
        // сохраняем новое текущее значение фильтров
        this.saveCurrentFiltersState()
        // запускаем таймаут заново
        this.timeout_id = setTimeout(this.filtersTimeoutFunction, this.timeout_time_ms)
      }
      // если фильтры не менялись, то загружаем даннные
      else this.loadDataWithFilters()
    },
    filtersWereChanged() {
      // функция проверки изменения размера
      if (Object.keys(this.pagination.filters).length !== Object.keys(this.tmp_filters_object).length) return true;
      for (let key of Object.keys(this.pagination.filters)) {
        let previous_keys = Object.keys(this.tmp_filters_object)
        let index = previous_keys.indexOf(key)
        if (index === -1) return true
        if (this.pagination.filters[key] !== this.tmp_filters_object[previous_keys[index]]) return true;
      }
      return false;
    },
    loadDataWithFilters() {
      // завершаем активное состояние таймаутов и подгружаем данные
      this.timeout_set = false;
      clearTimeout(this.timeout_id)
      this.cleanPaginationPageData();
      this.setCurrentLoadUrl(
          this.addFiltersParameter(
              this.getCurrentLoadUrl()
          )
      )
    },
    // getDetailVisit(id) {
    //   this.$router.push({name: names.EVENTS.VISITS.DETAIL, params: {idVisit: id}})
    // },
    backVisit(item) {
      this.dialog_back = true
      this.visit_data = item
    },
    closeBackDialog(visit) {
      if (visit) {
        this.backVisits({
          id: this.visit_data.id,
          finalizer: (val) => {
            this.visit_data.is_enter = false
            this.dialog_back = false
            this.visit_data = undefined
            this.loadData()
          },
        })
      } else {
        this.dialog_back = false
      }
    },
    cancelVisit(item) {
      this.dialog_cancel = true
      this.visit_data = item
    },
    closeCancelDialog(visit) {
      if (visit) {
        this.cancelVisits({
          id: this.visit_data.id,
          finalizer: () => {
            this.visit_data = undefined
            this.loadData()
          },
          catcher: (val) => {
            this.mapErrors(val)
          },
        })
      }
      this.dialog_cancel = false
    },
    changeStatusFilters() {
      if (this.pagination.filters.is_cancel) {
        delete this.pagination.filters.is_cancel
      }
      if (this.pagination.filters.is_enter) {
        delete this.pagination.filters.is_enter
      }
      if (this.pagination.filters.is_end) {
        delete this.pagination.filters.is_end
      }
      if (this.selected_status === 1) {
        this.pagination.filters.is_enter = false
        this.pagination.filters.is_cancel = false
        this.pagination.filters.is_end = false
      }
      else if (this.selected_status === 2) {
        this.pagination.filters.is_enter = true
      }
      else if (this.selected_status === 3) {
        this.pagination.filters.is_cancel = true
      }
      this.loadDataWithFilters()
    },
    updateDateFilters(field) {
      // если введёный год меньше 999, то игнорируем
      if (this[field] && this[field][0] === '0') return

      if (this.pagination.filters[field] !== this[field]) {
        this.pagination.filters[field] = this[field]
        this.loadData()
      }
    },
    updateClearedDateFilters() {
      this.pagination.filters.start = this.start
      this.pagination.filters.end = this.end
      if (!this.pagination.filters.start) {
        delete this.pagination.filters.start
      }

      if (!this.pagination.filters.end) {
        delete this.pagination.filters.end
      }
    },
    checkFlag(flag) {
      if (flag) return {icon: "check_circle", color: "green"}
      else return {icon: "not_interested", color: "gray"}
    },
    checkCancelFlag(flag) {
      if (flag) return {icon: "check_circle", color: "yellow darken-3"}
      else return {icon: "not_interested", color: "grey"}
    },
    loadData(url = undefined) {
      this.loadVisitsList({
        url: url,
        urlAppend: (url) => {
          return this.addPageParameter(
            this.addSizeParameter(
              this.addFiltersParameter(url)
            )
          )
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
        }
      })
    },
    updateData() {
      this.pagination.filters = {}
      this.selected_status = 0
      this.start = undefined
      this.end = undefined
      this.loadData()
    },
    enterVisit(item) {
      this.dialog_enter = true
      this.visit_data = item
    },
    closeEnterDialog(visit) {
      if (visit) {
        this.enterVisits({
          id: this.visit_data.id,
          finalizer: (val) => {
            this.visit_data.is_enter = true
            this.dialog_enter = false
            this.visit_data = undefined
            this.loadData()
          },
        })
      } else {
        this.dialog_enter = false
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    clearTimeout(this.timeout_id)
    next()
  },
  created() {
    if (admin.ADMIN.ALLOWED()){
      if(this.$route.name === names.FIND_VISITS.LIST){
        this.loadData()
      }
    } else{
      this.$router.push({name: names_core.MAIN})
    }
  }
}
</script>

<style scoped>

</style>
