<template>
  <scroll-center-dialog v-model="opened" width="760" @close="close" @apply="apply" :scrolled="false">
    <v-textarea
      class="mt-7 mx-3"
      rows="8"
      label="Введите сообщение"
      no-resize
      outlined
      v-model="msg"
      :error="error.length > 0"
      :error-messages="error"
    ></v-textarea>
  </scroll-center-dialog>
</template>

<script>
import formErrorsMixin from "@/mixins/FormErrorsMixin";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";

export default {
  name: "HideEventMessageDialog",
  components: {ScrollCenterDialog},
  props: {
    opened: Boolean,
  },
  mixins: [formErrorsMixin],
  data() {
    return {
      error: '',
      msg: '',
    }
  },
  methods: {
    apply() {
      if (this.msg.length === 0) {
        this.error = 'Это поле является обязательным для заполнения'
      } else {
        this.$emit('close', true, this.msg)
      }
    },
    close() {
      this.error = ''
      this.$emit('close', false, '')
    }
  }
}
</script>

<style scoped>

</style>
