<template>
  <v-container class="pa-0 ma-0" style="width: 100%">
    <div v-if="$route.name === names.EVENTS.LIST">
      <div v-if="!loading_events">
        <VisitorPageHeader
          :has-search="has_events"
          :search-value="search.value"
          :has-title="!$cookies.get('userMuctr')"
          :page-title="'События'"
          :muctr-user="!!$cookies.get('userMuctr')"
          :mobile-breakpoint="$vuetify.breakpoint.xsOnly"
          @update:searchValue="newVal => search.value = newVal"
          @searchData="searchData"
        ></VisitorPageHeader>
        <v-data-iterator
          v-if="has_events"
          :items="events"
          :loading="loading_events"
          :hide-default-footer="true"
          :items-per-page="events.length"
        >
          <template v-slot:default="props">
            <v-row class="pa-0 ma-0">
              <v-col cols="12" md="6" lg="4" xl="3" class="pa-0 ma-0" v-for="(item, i) in props.items" :key="i">
                <EventBriefPreview
                  :item="item"
                  :index="i"
                  :bg-color-index="getBackgroundColorIndex(i)"
                  @createVisit="newVisit"
                  @cancelVisit="startCancelVisitDialog"
                  :block-width="block_width"
                  @updateWidth="width => block_width = width"
                ></EventBriefPreview>
              </v-col>
            </v-row>
          </template>
          <template v-slot:no-data>
            <EmptySearchComponent
              :text="'По результатам поиска ничего не найдено'"
              :muctr-user="!!$cookies.get('userMuctr')"
              :is-events-list="true"
            ></EmptySearchComponent>
          </template>
        </v-data-iterator>
        <EmptyListComponent
          v-else
          :text="'На данный момент у нас нет запланированных событий'"
          :has-help-text="false"
          :muctr-user="!!$cookies.get('userMuctr')"
          :is-events-list="true"
        ></EmptyListComponent>
      </div>
      <div class="action mt-8 mb-4 align-center" v-else>
        <semipolar-spinner
          class="d-inline-block"
          :animation-duration="2000"
          :size="115"
          color="#1935ff"
        />
      </div>
      <div class="action mt-8 mb-4 align-center" v-if="loading_more_events && !loading_events">
        <semipolar-spinner
            class="d-inline-block"
            :animation-duration="2000"
            :size="115"
            color="#1935ff"
        />
      </div>
      <EditDialog
          v-if="dialog_add_edit"
          :event-id="event_id"
          :opened="dialog_add_edit"
          :edit="edit_status"
          :visit-id="visit_id"
          :has-data-fields="has_data_fields"
          :is-required-data-dissemination="data_dissemination_required"
          :need-data-access-confirmation="need_data_access_confirmation"
          @close="closeDialog"
      ></EditDialog>
      <CustomApplyDialog
          v-if="dialog_cancel"
          :question-text="`Вы уверены, что хотите отменить своё посещение?`"
          :apply-text="'Да, отменить'"
          :cancel-text="'Нет, оставить'"
          :apply-color="'#D64141'"
          @close="closeCancelDialog"
      ></CustomApplyDialog>
      <CustomApplyDialog
          v-if="light_dialog_add_edit"
          :question-text="`Вы уверены, что хотите записаться на событие?`"
          @close="closeLightDialog"
      ></CustomApplyDialog>
    </div>
    <router-view v-else></router-view>
  </v-container>
</template>

<script>
import names from "@/modules/visitor/routers/names";
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import EventBriefPreview from "@/modules/visitor/components/EventBriefPreview.vue";
import EditDialog from "@/modules/visitor/events/modals/VisitEditDialog";
import SemipolarSpinner from "epic-spinners/src/components/lib/SemipolarSpinner.vue";
import VisitorPageHeader from "@/modules/templates/visitor/core/VisitorPageHeader.vue";
import EmptyListComponent from "@/modules/templates/visitor/core/EmptyListComponent.vue";
import EmptySearchComponent from "@/modules/templates/visitor/core/EmptySearchComponent.vue";
import CustomApplyDialog from "@/modules/templates/visitor/custom_forms/CustomApplyDialog.vue";

export default {
  name: "VisitorEventsList",
  components: {
    CustomApplyDialog,
    EmptySearchComponent,
    EmptyListComponent,
    VisitorPageHeader,
    SemipolarSpinner,
    EventBriefPreview,
    EditDialog,
  },
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  data() {
    return {
      page_title: "Список событий",
      search: {
        value: '',
        parameter: 'name',
      },
      pagination: {
        size: 12,
        page: 1,
      },
      dialog_add_edit: false,
      light_dialog_add_edit: false,
      edit_status: undefined,
      visit_id: undefined,
      event_id: undefined,
      has_data_fields: undefined,
      loading_events: false,
      has_events: false,
      data_dissemination_required: false,
      need_data_access_confirmation: false,
      dialog_cancel: false,
      block_width: undefined,
      loading_more_events: false,
      events: [],
      events_list: true,
      search_value_changed: false,
    }
  },
  watch: {
    needReload: function(newVal) {
      if (newVal) {
        this.changeNeedReloadSate({value: false})
        this.updateData()
      }
    }
  },
  computed: {
    ...mapGetters({
      items: 'visitor/getEventsList',
      isLoading: 'visitor/isLoadingEvents',
      count: 'visitor/getEventsCount',
      visitor_token: 'visitor/getVisitorToken',
      needReload: 'visitor/isNeedReload',
    }),
    userIsAuthenticated() {
      return this.$cookies.get('userMuctr') || this.$cookies.get('visitor_token') || this.visitor_token;
    },
    names: () => names,
  },
  methods: {
    ...mapActions({
      loadEventsList: 'visitor/loadEventsList',
      cancelVisits: 'visitor/cancelVisits',
      createVisit: 'visitor/createVisits',
      changeNeedReloadSate: 'visitor/changeNeedReloadState',
    }),
    scrollHandler(e) {
      if (e.target.scrollHeight - e.target.scrollTop - document.body.scrollHeight <= 0
          && (!this.loading_more_events && this.count !== this.events.length || this.search_value_changed)
      ) {
        this.pagination.page++;
        this.addMoreData()
      }
    },
    newVisit(item, need_data_access_confirmation) {
      this.need_data_access_confirmation = need_data_access_confirmation
      this.event_id = item.id
      this.has_data_fields = item.have_data_field
      this.edit_status = false
      this.visit_id = undefined
      this.data_dissemination_required = item.is_required_data_dissemination
      if (!!this.userIsAuthenticated && !this.has_data_fields && !this.data_dissemination_required && !this.need_data_access_confirmation) {
        this.light_dialog_add_edit = true;
      } else {
        this.dialog_add_edit = true;
      }
    },
    closeDialog() {
      this.dialog_add_edit = false
      this.visit_id = undefined
      this.updateData()
    },
    closeLightDialog(create_accepted) {
      if (create_accepted) {
        this.createVisit({
          data: {
            event: this.event_id,
            is_data_dissemination: false,
            visitor_data: [],
          },
          finalizer: () => {
            this.updateData()
            this.light_dialog_add_edit = false;
          }
        })
      } else {
        this.light_dialog_add_edit = false;
      }
    },
    startCancelVisitDialog(visit_id) {
      this.visit_id = visit_id
      this.dialog_cancel = true
    },
    closeCancelDialog(delete_accepted) {
      if (delete_accepted) {
        this.cancelVisits({
          id: this.visit_id,
          finalizer: () => {
            this.updateData()
            this.dialog_cancel = false;
          }
        })
      } else {
        this.dialog_cancel = false;
      }
    },
    getBackgroundColorIndex(index) {
      // Функция страшной математики для шахматного порядка цветов на любом разрешении
      let cards_in_row = 1;

      if (this.$vuetify.breakpoint.md) cards_in_row = 2
      else if (this.$vuetify.breakpoint.lg) cards_in_row = 3
      else if (this.$vuetify.breakpoint.xl) cards_in_row = 4

      let row_number = Math.floor(index / cards_in_row)
      if (cards_in_row % 2 === 1 && row_number % 2 === 1) row_number++;
      return (index + row_number + 1) % 2
    },
    searchData() {
      this.search_value_changed = true;
      this.loadData()
    },
    updateData() {
      if (this.search.value) {
        this.search.value = ''
      }
      this.loadData()
    },
    loadData(url=undefined) {
      this.loading_events = true;
      this.pagination.page = 1;
      this.loadEventsList({
        url: url,
        urlAppend: (url) => {
          let new_url = this.addPageParameter(this.addSizeParameter(url))
          if (this.search.value)
            new_url = this.addGetParameter(new_url, 'name', this.search.value)
          return new_url
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
          if (data.count > 0) {
            this.has_events = true
          }
          this.events = data.results;

          this.dialog_add_edit = false;
          this.search_value_changed = false;
          this.loading_events = false;
        }
      })
    },
    addMoreData(url=undefined) {
      this.loading_more_events = true;
      this.loadEventsList({
        url: url,
        urlAppend: (url) => {
          let new_url = this.addPageParameter(this.addSizeParameter(url))
          if (this.search.value)
            new_url = this.addGetParameter(new_url, 'name', this.search.value)
          return new_url
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
          for (let el of data.results) {
            this.events.push(el)
          }
          this.loading_more_events = false;
        }
      })
    },
  },
  beforeRouteUpdate(to, from, next) {
    next()
    this.loadData()
  },
  created() {
    document.getElementById('app-scroll-container').addEventListener("scroll", this.scrollHandler)
    this.loadData()
  },
  destroyed() {
    document.getElementById('app-scroll-container').removeEventListener("scroll", this.scrollHandler);
  },
}
</script>

<style scoped lang="scss">

</style>
