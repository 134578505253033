<template>
  <div class="container mb-5 pb-5">
    <div class="header">
      <div class="header__text pb-5 pl-2" style="padding-right: 56px;">
        {{ name }}<span v-if="isRequired" class="red--text">*</span>
      </div>
      <div class="header__tooltip">
        <v-tooltip bottom max-width="320px" v-if="helpText">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                color="primary"
            >
              help
            </v-icon>
          </template>
          <span>{{ helpText }}</span>
        </v-tooltip>
      </div>
    </div>
    <div class="main">
      <v-row v-for="(value, i) in values" :key="i" class="main-row">
        <v-col class="main-row__text-field pa-0 ma-0 mt-4 ml-4">
          <v-textarea
              rows="1"
              type="text"
              label="Введите текст..."
              class="py-0 my-0"
              outlined
              v-model="values[i]"
              :error="stringHasError(i)"
              :error-messages="getStringError(i)"
              @change="$emit('update:inputValue', values)"
          >
            <template v-slot:append-outer>
              <v-tooltip bottom max-width="320px">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      class="mr-5 ml-3"
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      :disabled="values.length === 1 && isRequired"
                      @click="deleteString(i)"
                  >
                    delete
                  </v-icon>
                </template>
                <span>Удалить строку</span>
              </v-tooltip>
            </template>
          </v-textarea>
        </v-col>
      </v-row>
    </div>
    <v-row class="footer justify-center">
      <v-btn
        class="footer__append my-2"
        :color="'#1D71B8'"
        text
        @click="appendEmptyString"
      >
        Добавить строку
      </v-btn>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "VisitorFormStringsListField",
  props: {
    inputValue: Array,
    errorMessage: String,
    isRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    name: String,
    helpText: String,
  },
  data() {
    return {
      values: undefined,
    }
  },
  methods: {
    appendEmptyString() {
      this.values.push('')
      this.$emit('update:inputValue', this.values)
    },
    stringHasError(i) {
      return this.values[i].length === 0 && this.errorMessage.length > 0
    },
    getStringError(i) {
      if (this.stringHasError(i)) return this.errorMessage
      return ''
    },
    deleteString(i) {
      this.values.splice(i, 1)
      this.$emit('update:inputValue', this.values)
    }
  },
  created() {
    this.values = this.inputValue
    this.errors = this.errorMessage
  }
}
</script>

<style scoped lang="scss">
.container {
  background: #F6F6F6;
  color: #000;
  font-family: "MADE Evolve Sans normal", serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.header__text {
  text-align: left;
}
.header__tooltip {
  text-align: right;
}
</style>
