<template>
  <div>
    <div class="header">
      <v-img
          class="header__image"
          :src="item.image ? getImage(item.image) : basic_event"
      >
      </v-img>
      <div class="header__text" :class="getHeaderFontClass()">
        {{ item.name.toUpperCase() }}
      </div>
      <div v-if="item.image" class="header__gradient"></div>
      <div class="header-back">
        <div class="button button__back" @click="returnBack">
          <div class="header-back__text" v-if="!mobileCancelButtonBreakpoint">
            Назад
          </div>
          <v-icon
              size="15"
          >
            $vuetify.icons.arrow_back
          </v-icon>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="location">
        <v-icon
            class="location-icon"
            :size="20"
        >
          $vuetify.icons.location
        </v-icon>
        <div class="location-text">
          {{ item.location ? item.location : '-' }}
        </div>
      </div>
      <div class="info-row">
        <div class="date date__start">
          <div class="date-text">
            Дата начала события:
          </div>
          <div class="date-value">
            {{($vuetify.breakpoint.lgAndUp ? '&ensp;' : '') + displayDate(item.start) }}
          </div>
        </div>
        <div class="line-break" v-if="eventEndShiftNeeded"></div>
        <div class="date date__end">
          <div class="date-text">
            Дата окончания события:
          </div>
          <div class="date-value">
            {{ ($vuetify.breakpoint.lgAndUp ? '&ensp;' : '') + displayDate(item.end) }}
          </div>
        </div>
        <div class="line-break" v-if="statusShiftNeeded"></div>
        <div class="status">
          <div class="status-text">
            Статус:
          </div>
          <div class="status-value" :style="'color:' + getStatusParameters().color + ';'">
            {{ ($vuetify.breakpoint.lgAndUp ? '&ensp;' : '') + getStatusParameters().value }}
          </div>
        </div>
        <div class="line-break" v-if="cancelButtonShiftNeeded"></div>
        <div class="cancel" v-if="!mobileCancelButtonBreakpoint && !item.parent">
          <div
              v-if="!this.light_detail.visit || preview"
              @click="(item.can_visit || preview) && !$route.name.includes('Viewer') ? $emit('createVisit', item) : () => {}"
              :class="(item.can_visit || preview) && !$route.name.includes('Viewer') ? 'button__record--active' : 'button--disabled'"
              class="button button__record"
          >
            Записаться
          </div>
          <div
              v-else
              @click="visitorCanCancelVisit && !light_detail.has_entered_records && !$route.name.includes('Viewer') ? $emit('cancelVisit', light_detail.visit) : () => {}"
              :class="visitorCanCancelVisit && !light_detail.has_entered_records && !$route.name.includes('Viewer') ? 'button__cancel--active' : 'button--disabled'"
              class="button"
          >
            Отменить посещение
          </div>
        </div>
      </div>
      <div class="cancel--xs" v-if="mobileCancelButtonBreakpoint && !item.parent">
        <div
            v-if="!this.light_detail.visit || preview"
            @click="item.can_visit || preview ? $emit('createVisit', item) : () => {}"
            :class="item.can_visit || preview ? 'button__record--active' : 'button--disabled'"
            class="button button__record"
        >
          Записаться
        </div>
        <div
            v-else
            @click="visitorCanCancelVisit && !light_detail.has_entered_records ? $emit('cancelVisit', light_detail.visit) : () => {}"
            :class="visitorCanCancelVisit && !light_detail.has_entered_records ? 'button__cancel--active' : 'button--disabled'"
            class="button"
        >
          Отменить посещение
        </div>

      </div>
      <div class="description">
        {{ item.description }}
      </div>
    </div>
  </div>
</template>

<script>
import basic_event from "@/assets/basicEventImage.svg";
import {displayDate} from "@/helper";
import visitor from "@/urls/roles/visitor";
import names from "@/modules/visitor/routers/names";

export default {
  name: "VisitorEventDetail",
  props: {
    item: Object,
    light_detail: Object,
    preview: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      basic_event: basic_event,
      page_width: window.screen.availWidth,
    }
  },
  watch: {
    '$route.params': function() {
      this.$emit('reloadEventData')
    }
  },
  computed: {
    visitorCanCancelVisit() {
      return this.light_detail.visit && !this.light_detail.is_end && !this.light_detail.is_enter
    },
    cancelButtonShiftNeeded() {
      return this.page_width < 800 && this.page_width > 569
    },
    eventEndShiftNeeded() {
      return this.page_width <= 440
    },
    statusShiftNeeded() {
      return this.page_width <= 570
    },
    mobileCancelButtonBreakpoint() {
      return this.page_width <= 570
    },
    breakPont710() {
      return this.page_width <= 710
    },
    breakPoint540() {
      return this.page_width <= 540
    },
    breakPoint475() {
      return this.page_width <= 475
    },
    breakPoint400() {
      return this.page_width <= 400
    }
  },
  methods: {
    resizeHandler(e) {
      this.page_width = e.target.screen.availWidth
    },
    getHeaderFontClass() {
      if (this.breakPoint400) {
        return ' font-size-15'
      }
      if (this.breakPoint475) {
        return ' font-size-17'
      }
      if (this.breakPoint540) {
        return ' font-size-21'
      }
      if (this.breakPont710) {
        return ' font-size-24'
      }
      return ''
    },
    displayDate: displayDate,
    getImage(image) {
      if (!image) return null;
      let urls = {
        basename: image.name,
        action: image.action,
        params: image.params,
      }
      return visitor.getByRecievedUrlData(urls, image.get_params)
    },
    getStatusParameters() {
      if (!this.light_detail.is_enter && !this.light_detail.is_cancel) return {value : 'Не посещено', color: '#1D71B8'}
      else if (this.light_detail.is_enter) return {value : 'Посещено', color: '#1D71B8'}
      return {value : 'Отменено', color: '#D64141'}
    },
    returnBack() {
      if (this.preview) return

      if (!this.item.parent) {
        this.$router.push({name: names.EVENTS.LIST})
      } else {
        this.$router.push({name: names.EVENTS.DETAIL, params: {idEvent: this.item.parent.slug}})
      }
    },
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  },
  beforeRouteUpdate(to, from, next) {
    next()
    this.$emit('reloadEventData')
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
}
</script>

<style scoped lang="scss">
.font-size-24 {
  font-size: 24px!important;
}

.font-size-21 {
  font-size: 21px!important;
}

.font-size-17 {
  font-size: 17px!important;
  line-height: 140%!important;
}

.font-size-15 {
  -webkit-line-clamp:6!important;
  font-size: 15px!important;
  line-height: 140%!important;
}

.header{
  position: relative;
  height: 228px;
  background: #032F59;
}
.header__image {
  height: inherit;
}
.header__text {
  text-align: left;
  width: 60%;
  position: absolute;
  bottom: 27px;
  left: 24px;
  color: #FFF;
  font-family: 'Inter normal', serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  z-index: 1;
  display:-webkit-box;
  -webkit-line-clamp:5;
  -webkit-box-orient:vertical;
  overflow: hidden;
  @media (max-width: 400px) {
    font-size: 24px;
    width: 200px;
    -webkit-line-clamp:4;
  }
}
.header__gradient {
  position: relative;
  bottom: 167px;
  height: 167px;
  width: inherit;
  background: linear-gradient(0, #1D71B8 0%, rgba(0, 0, 0, 0) 100%);
}

.header-back {
  position: absolute;
  top: 19px;
  left: calc(100% - 46px - 107px);
  @media (max-width: 570px) {
    left: calc(95% - 49px);
  }
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-back__text {
  color: #1D71B8;
  font-family: 'Inter normal', serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-right: 13px;
}

.main {
  padding: 0 44px 0 24px;
  @media (max-width: 570px) {
    padding: 0 24px 36px 24px;
  }
  color: #7C7C7C;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
}

.location {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.location-icon {
  margin-right: 23px;
}

.location-text {
  @media (max-width: 400px) {
    font-size: 15px;
  }
}

.info-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
  @media (max-width: 570px) {
    margin-top: 18px;
  }
}

.line-break {
  width: 100%;
}

.date, .status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1263px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.date__start {

}

.date__end {
  @media (max-width: 440px) {
    margin-top: 18px;
  }
}

.status {
  @media (max-width: 570px) {
    margin-top: 18px;
  }
}

.date-text, .status-text {

}

.date-value {
  color: #404040;
  font-size: 18px;
  @media (min-width: 1264px) and (max-width: 1500px) {
    font-size: 16px;
  }
}

.status-value {
  font-size: 16px;
  text-transform: uppercase;
  @media (min-width: 1264px) and (max-width: 1500px) {
    font-size: 14px;
  }
}

.cancel {
  @media (max-width: 800px) {
    margin-top: 18px;
  }
}

.cancel--xs {
  margin-top: 18px;
  display: flex;
  justify-content: center;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  border-radius: 45px;
  border: 1px solid #1D71B8;
  color: #1D71B8;
  height: 40px;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-transform: none;
}

.button__back {
  background: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  &:hover, &:active {
    background: white;
  }
}

.button__record {
  padding: 0 30px;
}

.button__record--active {
  cursor: pointer;
  color: #FFF;
  background: #1D71B8;

  &:hover, &:active {
    background: #135B97;
  }
}

.button__cancel--active {
  border-radius: 44px;
  border-color: #D64141;
  color: #D64141;
  cursor: pointer;

  &:hover, &:active {
    color: white;
    background: #D64141;
  }
}

.button--disabled {
  color: white;
  border: 1px solid #CECECE;
  background: #CECECE;
  &:hover {
    color: white;
    background: #CECECE;
    cursor: default;
  }
}

.description {
  margin-top: 36px;
  font-weight: 400;
}


</style>
