<template>
  <div class="page-content" style="margin: 0px; width: 100%">
    <PageHeader v-if="isAuthenticated"></PageHeader>
    <Main
        v-if="$route.name === names.MAIN"
        :name_list='name_list'
    ></Main>
    <router-view v-else></router-view>
    <WelcomeDialogWindow
        v-if="welcome_dialog"
        :opened="welcome_dialog"
        @close="closeWelcomeDialog"
    ></WelcomeDialogWindow>
  </div>
</template>

<script>

import names from '@/modules/visitor/routers/names'
import Main from "./Main";
import {getInstMenu, getInstNameByRouterName} from "@/helper/instances";
import PageHeader from "../menus/PageHeader";
import {mapGetters} from "vuex";
import admin from "@/urls/roles/admin";
import viewer from "@/urls/roles/viewer";
import organiser from "@/urls/roles/organiser";
import operator from "@/urls/roles/operator";
import visitor from "@/urls/roles/visitor";
import visitor_names from "@/modules/visitor/routers/names";
import WelcomeDialogWindow from "@/modules/core/components/WelcomeDialogWindow.vue";

export default {
  name: "VisitorBase",
  components: {WelcomeDialogWindow, Main, PageHeader},
  data(){
    return {
      left_menu_list: [
        {title: 'События', icon: 'calendar_month', included: true, router: {name:  names.EVENTS.LIST, params:{}}},
        {title: 'Мои посещения', icon: 'fact_check', included: true, router: {name:  names.VISITS.LIST, params:{}}},
      ],
      name_list: [],
      welcome_dialog: false,
    }
  },
  computed: {
    names: () => names,
    ...mapGetters({
      isAuthenticated: 'user/isAuthenticate'
    })
  },
  methods: {
    loadLeftmenu() {
      // Исправление бага, связанного с заголовком при переходе из меню события
      if (!this.$route || !this.$route.name) return;
      if (this.$route.name.endsWith('|Events|List')) {
        this.$store.commit('mainpage/changeTitle', 'Список событий');
      } else {
        this.$store.commit('mainpage/changeTitle', 'Посетитель');
      }
      this.$store.commit('leftmenu/replaceInstances', getInstMenu());
      this.$store.commit('leftmenu/selectInstance', getInstNameByRouterName(names.MAIN));
      this.$store.commit('leftmenu/replaceItems', this.getLeftMenu());
    },
    getLeftMenu() {
      let menu = []
      for (let el in this.left_menu_list) {
        menu.push(this.left_menu_list[el])
      }
      return menu
    },
    makeNameList(){
      for (let el in this.left_menu_list){
        if(this.left_menu_list[el].title!=='Главная')
          this.name_list.push(this.left_menu_list[el])
      }
    },
    checkAuthenticate(finalizer=()=>{}){
      if(this.isAuthenticated) {
        let is_admin = admin.ADMIN.ALLOWED()
        let is_viewer = viewer.VIEWER.ALLOWED()
        let is_organiser = organiser.ORGANISER.ALLOWED()
        let is_operator = operator.OPERATOR.ALLOWED()
        let is_visitor = visitor.VISITOR.ALLOWED()
        if (is_admin || is_viewer || is_organiser || is_operator || is_visitor) {
          finalizer()
        }
      } else{
        if(this.$route.name === visitor_names.MAIN) {
          this.$router.push({name: visitor_names.EVENTS.LIST})
        }
      }
    },
    updateWelcomeDialogStatus() {
      if (this.$cookies.get('welcome-dialog-token')) return;

      if (!this.$cookies.get('userMuctr') && !this.$cookies.get('visitor_token')) {
        this.$cookies.set('welcome-dialog-token', true, '1d')
      }
      else if (this.$cookies.get('userMuctr')) {
        this.$cookies.set('welcome-dialog-token', true)
      }
      else if (this.$cookies.get('visitor_token')) {
        this.$cookies.set('welcome-dialog-token', true, '30d')
      }

      this.welcome_dialog = true
    },
    closeWelcomeDialog() {
      this.welcome_dialog = false;
    },
  },
  beforeRouteUpdate(to, from, next) {
    next()
      if (!this.$route.name.includes(names.EVENTS.DETAIL)){
        this.loadLeftmenu()
      }
  },
  created() {
    // if(this.$route.name === visitor_names.MAIN){
      this.checkAuthenticate(
        ()=>{
          this.$store.commit('leftmenu/enableLeftMenu');
          this.loadLeftmenu()
          this.makeNameList()
        }
      )
    if (this.$cookies.get('userMuctr')) {
      window.$cookies.remove("visitor_token",null);
    }
    if (this.$cookies.get('visitor_token')) {
      window.$cookies.remove("userMuctr",null);
    }

    this.updateWelcomeDialogStatus()
    // }
  }
}
</script>

<style scoped>

</style>
