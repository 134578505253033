import {sendGetRequest} from "@/helper/requests";
import viewer from "@/urls/roles/viewer";

const state = {
    fields:{
        currentUrl: null,
        items: [],
        non_paginated_items: [],
        loading: false,
        count: 0,
        detail: [],
        error: null,
    }
}

const getters = {
    getFieldsList: state => state.fields.items,
    getFieldsCount: state => state.fields.count,
    getFieldsCurrentUrl: state => state.fields.currentUrl,
    getFieldsDetail: state => state.fields.detail,
    isLoadingFields: state => state.fields.loading,
    saveFieldsError: state => state.fields.error,
}

const actions = {
    loadFieldsList: ({commit, getters}, {url, event_id, urlAppend, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) {
            url = viewer.VIEWER.EVENTS.FIELDS.LIST(event_id)
            if (urlAppend) url = urlAppend(url)
        }
        if (!getters.isLoadingFields || getters.getFieldsCurrentUrl !== url){
            commit('SET_FIELDS_LOADING');
            sendGetRequest(
                url, (data, url) => {
                    commit('SET_FIELDS_LIST', data['results']);
                    commit('SET_FIELDS_COUNT', data['count']);
                    commit('SET_FIELDS_CURRENT_URL', url);
                    commit('SET_FIELDS_LOADING_END');
                    finalizer(data, url);
                }, (data, url) => {
                    commit('SET_FIELDS_LOADING_END');
                    commit('SAVE_FIELDS_ERROR', data);
                    catcher(data, url);
                }
            )
        }
    },
    loadNonPaginatedFieldsList: ({commit}, {url, event_id, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) {
            url = viewer.VIEWER.EVENTS.FIELDS.NON_PAGINATED_LIST(event_id)
        }
        sendGetRequest(
            url, (data, url) => {
                commit('SET_NON_PAGINATED_FIELDS_LIST', data);
                finalizer(data, url);
            }, (data, url) => {
                commit('SAVE_FIELDS_ERROR', data);
                catcher(data, url);
            }
        )
    },
    loadFieldsDetail: ({commit}, {url, event_id, id, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) url = viewer.VIEWER.EVENTS.FIELDS.DETAIL(event_id, id)
        sendGetRequest(
            url, (data, url) => {
                commit('SET_FIELDS_DETAIL', data);
                finalizer(data, url);
            }, (data, url) => {
                commit('SAVE_FIELDS_ERROR', data);
                catcher(data, url);
            }
        )
    },
}

const mutations = {
    SET_FIELDS_LIST: (state, fields) => {
        state.fields.items = fields
    },
    SET_NON_PAGINATED_FIELDS_LIST: (state, fields) => {
        state.fields.non_paginated_items = fields
    },
    SET_FIELDS_DETAIL: (state, value) => {
        state.fields.detail = value
    },
    SET_FIELDS_COUNT: (state, value) => {
        state.fields.count = value
    },
    SET_FIELDS_CURRENT_URL: (state, value) => {
        state.fields.currentUrl = value
    },
    SET_FIELDS_LOADING: (state) => {
        state.fields.loading = true
    },
    SET_FIELDS_LOADING_END: (state) => {
        state.fields.loading = false
    },
    SAVE_FIELDS_ERROR: (state, error) => {
        state.fields.error = error
    }
}

export default {
    namespaced: false,
    state,
    mutations,
    actions,
    getters
}
