import { render, staticRenderFns } from "./SliderBlock.vue?vue&type=template&id=40876706&scoped=true"
import script from "./SliderBlock.vue?vue&type=script&lang=js"
export * from "./SliderBlock.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40876706",
  null
  
)

export default component.exports