import admin from "@/urls/roles/admin";
import viewer from "@/urls/roles/viewer";
import organiser from "@/urls/roles/organiser";
import operator from "@/urls/roles/operator";
import visitor from "@/urls/roles/visitor";

import admin_names from "@/modules/admin/routers/names";
import viewer_names from "@/modules/viewer/routers/names";
import organiser_names from "@/modules/organiser/routers/names";
import operator_names from "@/modules/operator/routers/names";
import visitor_names from "@/modules/visitor/routers/names";

export function getInstMenu() {
    /**
     * Функция которая возвращает нижнее боковое меню инстанса
     * @return {Array} - списко всех подмодулей инстанса, отображаемых в левом нижнем меню
     */
    let menu = []
    if (admin.ADMIN.ALLOWED())
        menu.push({title: 'Администратор', icon: 'admin_panel_settings', router: admin_names.MAIN, name: admin_names.MAIN})
    if (viewer.VIEWER.ALLOWED())
        menu.push({title: 'Наблюдатель', icon: 'visibility', router: viewer_names.MAIN, name: viewer_names.MAIN})
    if (organiser.ORGANISER.ALLOWED())
        menu.push({title: 'Организатор', icon: 'supervisor_account', router: organiser_names.MAIN, name: organiser_names.MAIN})
    if (operator.OPERATOR.ALLOWED())
        menu.push({title: 'Оператор', icon: 'miscellaneous_services', router: operator_names.MAIN, name: operator_names.MAIN})
    if (visitor.VISITOR.ALLOWED())
        menu.push({title: 'Посетитель', icon: 'account_circle', router: visitor_names.MAIN, name: visitor_names.MAIN})
    return menu
}

export function getInstNameByRouterName(router) {
    /**
     * Функция которая возвращает нижнее боковое меню инстанса
     * @return {Array} - списко всех подмодулей инстанса, отображаемых в левом нижнем меню
     */
    return getInstMenu().find(el => {
        return router === el.router
    }).name
}
