<template>
    <v-card class="mx-0 rounded-0 elevation-0 card" :id="!index ? 'event-block' : ''">
      <div
          :class="bgColorIndex === 0 ? 'card__container card__container--white' : 'card__container card__container--grey'"
      >
        <div class="content">
          <div class="event-header">
            <v-img
                class="event-header__image"
                :src="item.image ? getImage(item.image) : basic_event"
            >
            </v-img>
            <div class="event-header__text" :class="getHeaderFontClass()">
              {{ item.name.toUpperCase() }}
            </div>
            <div v-if="item.image" class="event-header__gradient"></div>
          </div>
          <div class="event-body">
            <div class="event-body__date">
              {{ displayDate(item.start) + ' - ' + displayDate(item.end) }}
            </div>
            <div class="event-body__divider"></div>
            <div class="event-body__text">
              {{ item.description }}
            </div>
            <div class="event-body__record-date">
              <div class="event-body__record-date--bold" v-if="!item.is_record_started">
                Регистрация будет доступна:
              </div>
              <div class="event-body__record-date--bold" v-if="item.can_visit">
                Регистрация доступна:
              </div>
              <div class="event-body__record-date--normal" v-if="item.can_visit || !item.is_record_started">
                {{ correctTime(item.record_start, true) + ' - ' + correctTime(item.record_end, true) }}
              </div>
              <div v-if="!item.can_visit && item.is_record_started" class="event-body__record-date--disabled">
                Регистрация завершена
              </div>
            </div>
          </div>
          <div class="event-buttons" v-if="light_detail" :class="getButtonsClass()">
            <div
              class="event-buttons__button event-buttons__button--detail"
              :class="getDetailButtonClass()"
              @click="getDetail(item.slug)"
            >
              Подробнее
            </div>
            <div
              v-if="!this.light_detail.visit"
              @click="item.can_visit ? $emit('createVisit', item, !light_detail.is_data_access_confirmation) : () => {}"
              :class="!item.can_visit ? 'button-disabled' : 'event-buttons__button--record--animation'"
              class="event-buttons__button event-buttons__button--record"
            >
              Записаться
            </div>
            <div
                v-else
                @click="visitorCanCancelVisit && !light_detail.has_entered_records ? $emit('cancelVisit', light_detail.visit) : () => {}"
                :class="visitorCanCancelVisit && !light_detail.has_entered_records ? 'event-buttons__button--cancel--animation' : 'button-disabled'"
                class="event-buttons__button event-buttons__button--cancel"
            >
              Отменить посещение
            </div>
          </div>
        </div>
      </div>
    </v-card>
</template>

<script>
import visitor from "@/urls/roles/visitor";
import {displayDate} from "@/helper";
import names from "@/modules/visitor/routers/names";
import basic_event from "@/assets/basicEventImage.svg";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "EventBriefPreview",
  props: {
    item: Object,
    bgColorIndex: Number,
    index: Number,
    blockWidth: Number,
  },
  data() {
    return {
      basic_event: basic_event,
      light_detail: undefined,
    }
  },
  computed: {
    visitorCanCancelVisit() {
      return this.light_detail.visit && !this.light_detail.is_end && !this.light_detail.is_enter
    },
    breakPoint530() {
      return this.blockWidth < 530;
    },
    breakPoint460() {
      return this.blockWidth < 460
    },
    breakPoint415() {
      return this.blockWidth < 415
    },
    breakPoint325() {
      return this.blockWidth < 325
    },
    ...mapGetters({
      leftMenuOpened: 'leftmenu/getLeftMenuShow',
    })
  },
  methods: {
    ...mapActions({
      loadLightDetail: 'visitor/loadEventsLightDetail',
    }),
    getHeaderFontClass() {
      if (this.leftMenuOpened && this.$vuetify.breakpoint.lgAndUp) {
        return ' font-size-18'
      }
      if (this.breakPoint325) {
        return ' font-size-18'
      }
      if (this.breakPoint415) {
        return ' font-size-21'
      }
      if (this.breakPoint530) {
        return ' font-size-24'
      }
      return ''
    },
    getButtonsClass(){
      if (this.breakPoint460) {
        return ' buttons-column'
      }
      return ''
    },
    getDetailButtonClass() {
      if (this.breakPoint460) {
        return ' no-margin'
      }
      return ''
    },
    resizeHandler() {
      if (!this.index) {
        this.$emit('updateWidth', document.getElementById('event-block').offsetWidth);
      }
    },
    getImage(image) {
      if (!image) return null;
      let urls = {
        basename: image.name,
        action: image.action,
        params: image.params,
      }
      return visitor.getByRecievedUrlData(urls, image.get_params)
    },
    displayDate: displayDate,
    correctTime (time, minute = false) {
      if(time!==null){
        return this.displayDate(time, minute)
      }
    },
    getDetail(id) {
      this.$router.push({name: names.EVENTS.DETAIL, params: {idEvent: id}})
    },
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
    this.loadLightDetail({
      id: this.item.id,
      finalizer: (data) => {
        this.light_detail = data
        this.$nextTick(() => {
          if (!this.index) {
            this.resizeHandler()
          }
        })
      }
    })
  },
  destroyed() {
    if (!this.index) {
      window.removeEventListener("resize", this.resizeHandler);
    }
  },
}
</script>

<style scoped lang="scss">
.font-size-24 {
  font-size: 24px!important;
}

.font-size-21 {
  font-size: 21px!important;
}

.font-size-18 {
  font-size: 18px!important;
}

.buttons-column {
  flex-direction: column!important;
  align-items: center;
  height: 70px;
  justify-content: space-between;
}

.no-margin {
  margin: 0!important;
}

.card {
  min-height: 562px;
  text-align: left;
  @media (max-width: 415px) {
    min-height: 494px;
  }
}
.card__container {
  min-height: 562px;
  padding: 35px 22px;

  @media (max-width: 415px) {
    padding: 0 0 20px;
    min-height: 494px;
  }
}
.card__container--white {
  background-color: white;
}
.card__container--grey {
  background-color: #F6F6F6;
}
.event-header{
  position: relative;
  height: 186px;
}
.event-header__image {
  height: inherit;
}
.event-header__text {
  width: 90%;
  position: absolute;
  bottom: 10px;
  left: 18px;
  color: #FFF;
  font-family: 'Inter normal', serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  z-index: 1;
  display:-webkit-box;
  -webkit-line-clamp:5;
  -webkit-box-orient:vertical;
  overflow: hidden;
  @media (max-width: 415px) {
    font-size: 24px;
    width: 85%;
  }
}
.event-header__gradient {
  position: relative;
  bottom: 167px;
  height: 167px;
  width: inherit;
  background: linear-gradient(0, #1D71B8 0%, rgba(0, 0, 0, 0) 100%);
}
.event-body {
  padding: 20px 0 17px 0;
}
.event-body__date {
  height: 24px;
  color: #000;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  @media (max-width: 415px) {
    font-size: 16px;
    margin-left: 18px;
  }
}
.event-body__divider {
  height: 2px;
  margin: 6px 0 0 0;
  background: #CECECE;
  @media (max-width: 415px) {
    margin: 6px 18px 0 18px;
  }
}
.event-body__text {
  height: 112px;
  color: #545454;
  font-family: 'Inter normal', serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin: 16px 0 17px 0;
  display:-webkit-box;
  -webkit-line-clamp:4;
  -webkit-box-orient:vertical;
  overflow: hidden;
  @media (max-width: 415px) {
    margin: 16px 18px 17px 18px;
  }
}
.event-body__record-date {
  display: flex;
  font-family: 'Inter normal', serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  @media (max-width: 480px) {
    flex-direction: column;
  }
  @media (max-width: 415px) {
    margin: 0 18px;
  }
  @media (min-width: 1264px) and (max-width: 1691px) {
    height: 56px;
    flex-direction: column;
  }
}
.event-body__record-date--bold {
  margin-right: 10px;
}
.event-body__record-date--normal {
  font-weight: 400;
}
.event-body__record-date--disabled {
  color: #CECECE;
}
.event-buttons {
  display: flex;
  flex-direction: row;
}
.event-buttons__button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  border-radius: 45px;
  border: 1px solid #1D71B8;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-transform: none;
  width: 192px;
  @media (max-width: 415px) {
    font-size: 13px;
    padding: 0 14px;
  }
  @media (min-width: 1264px) and (max-width: 1691px) {
    padding: 0 14px;
  }
}
.event-buttons__button--detail {
  cursor: pointer;
  margin-right: 32px;
  background: #FFF;
  &:hover {
    background: #BBDEFB;
  }
}
.event-buttons__button--record {
  color: #FFF;
  background: #1D71B8;
}

.event-buttons__button--cancel {
  border: 1px solid #D64141;
  color: #D64141;
}
.event-buttons__button--record--animation {
  cursor: pointer;
  &:hover {
    background: #135B97;
  }
}
.event-buttons__button--cancel--animation {
  cursor: pointer;
  &:hover {
    background: #D64141;
    color: white;
  }
}
.button-disabled {
  color: white;
  border: 1px solid #CECECE;
  background: #CECECE;
  &:hover {
    color: white;
    background: #CECECE;
    cursor: default;
  }
}
</style>
