import {sendGetRequest} from "@/helper/requests";
import viewer from "@/urls/roles/viewer";

const state = {
    data:{
        currentUrl: null,
        items: [],
        loading: false,
        count: 0,
        detail: [],
        error: null,
    }
}

const getters = {
    getDataList: state => state.data.items,
    getDataCount: state => state.data.count,
    getDataCurrentUrl: state => state.data.currentUrl,
    getDataDetail: state => state.data.detail,
    isLoadingData: state => state.data.loading,
    saveDataError: state => state.data.error,
}

const actions = {
    loadDataList: ({commit, getters}, {url, visit_id, urlAppend, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) {
            url = viewer.VIEWER.EVENTS.DATA.LIST(visit_id)
            if (urlAppend) url = urlAppend(url)
        }
        if (!getters.isLoadingData || getters.getDataCurrentUrl !== url){
            commit('SET_DATA_LOADING');
            sendGetRequest(
                url, (data, url) => {
                    commit('SET_DATA_LIST', data['results']);
                    commit('SET_DATA_COUNT', data['count']);
                    commit('SET_DATA_CURRENT_URL', url);
                    commit('SET_DATA_LOADING_END');
                    finalizer(data, url);
                }, (data, url) => {
                    commit('SET_DATA_LOADING_END');
                    commit('SAVE_DATA_ERROR', data);
                    catcher(data, url);
                }
            )
        }
    },
    loadDataDetail: ({commit}, {url, visit_id, id, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) url = viewer.VIEWER.EVENTS.DATA.DETAIL(visit_id, id)
        sendGetRequest(
            url, (data, url) => {
                commit('SET_DATA_DETAIL', data);
                finalizer(data, url);
            }, (data, url) => {
                commit('SAVE_DATA_ERROR', data);
                catcher(data, url);
            }
        )
    },
}

const mutations = {
    SET_DATA_LIST: (state, data) => {
        state.data.items = data
    },
    SET_DATA_DETAIL: (state, value) => {
        state.data.detail = value
    },
    SET_DATA_COUNT: (state, value) => {
        state.data.count = value
    },
    SET_DATA_CURRENT_URL: (state, value) => {
        state.data.currentUrl = value
    },
    SET_DATA_LOADING: (state) => {
        state.data.loading = true
    },
    SET_DATA_LOADING_END: (state) => {
        state.data.loading = false
    },
    SAVE_DATA_ERROR: (state, error) => {
        state.data.error = error
    }
}

export default {
    namespaced: false,
    state,
    mutations,
    actions,
    getters
}
