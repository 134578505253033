const state = {
    // enabled: false,
    mainInstances: [
        {
            title: 'Управление аккаунтом',
            router: 'LoadPortal',
        },
    ],
    secondInstances: [
        {
            name: 'muctr',
            title: 'Офицальный сайт',
            enabled: false,
            url:'https://muctr.ru/'
        },
        {
            name: 'mail',
            title: 'Корпоративная почта',
            enabled: false,
            url:'https://post.muctr.ru/'
        },
        {
            name: 'paymuctr',
            title: 'Оплата услуг университета',
            enabled: false,
            url:'https://pay.muctr.ru/'
        },
        {
            name: 'eios',
            title: 'Электронная информационно-образовательная среда',
            enabled: false,
            url:'https://eios.muctr.ru/'
        },
        {
            name: 'queue',
            title: 'Очередь на мат. помощь',
            enabled: false,
            url:'https://queue.muctr.ru/'
        },
        {
            name: 'moodle',
            title: 'Учебный портал',
            enabled: false,
            url:'https://moodle.muctr.ru'
        },
        {
            name: 'teams',
            title: 'Microsoft Teams',
            enabled: false,
            url:'https://teams.microsoft.com'
        },
        {
            name: 'allServices',
            title: 'Все сервисы',
            enabled: false,
            url: process.env.VUE_APP_PORTAL_HOST + 'tools',
        },
    ],
    userActions: [
        {
            title: 'Профиль',
            route: 'LoadPortal',
        },
    ],
    visitorNotLoginInstances: [
        {
            title: 'События',
            router: 'Visitor|Events|List'
        },
        {
            title: 'Вход',
            eventName: 'login'
        },
    ],
    visitorLoginInstances: [
        {
            title: 'События',
            router: 'Visitor|Events|List'
        },
        {
            title: 'Мои посещения',
            router: 'Visitor|Visits|List'
        },
        {
            title: 'Выход',
            eventName: 'logout'
        },
    ],
    visitorLoginXsInnerMenuInstances: [
        {
            title: 'События',
            router: 'Visitor|Events|List'
        },
        {
            title: 'Мои посещения',
            router: 'Visitor|Visits|List'
        },
    ],
    visitorLoginXsInstances: [
        {
            title: 'Выход',
            eventName: 'logout'
        },
    ],

    title: '',
    userAvatar: null,
    is_page_loading: false,
};

const actions = {
}

const mutations = {
    changeTitle(state, title) {
        state.title = title
    },
    changeAvatar(state, Avatar) {
        state.userAvatar = Avatar
    },
    setStartLoading(state) {
        state.is_page_loading = true
    },
    setEndRLoading(state) {
        state.is_page_loading = false
    },

};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
}
