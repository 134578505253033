<template>
  <div class="slot-event-container">
    <div :class="bgColorIndex === 0 ? 'element--white' : 'element--grey'" class="slot-event">
      <div class="text">
        <div class="slot-event__text">
          {{ slotEvent.name }}
        </div>
      </div>
      <div class="expand">
        <v-icon
          class="expanded-icon"
          :class="expanded ? 'expanded-icon--expanded' : 'expanded-icon--hidden'"
          @click="expanded = !expanded"
        >
          $vuetify.icons.expanded_false
        </v-icon>
      </div>
    </div>
    <div class="expanded-item" :class="expanded ? 'expanded-item--expanded' : 'expanded-item--hidden'">
      <SlotsTable
        :slots="slotEvent.data"
        :has-visit="hasVisit"
        @createRecord="(data) => $emit('createRecord', data)"
        @cancelRecord="(data) => $emit('cancelRecord', data)"
      ></SlotsTable>
    </div>
  </div>
</template>

<script>
import SlotsTable from "@/modules/templates/visitor/event_detail/SlotsTable.vue";

export default {
  name: "SingleSlotEvent",
  components: {SlotsTable},
  props: {
    slotEvent: Object,
    bgColorIndex: Number,
    hasVisit: Boolean,
  },
  data() {
    return {
      expanded: false,
    }
  },
}
</script>

<style scoped lang="scss">
.slot-event {
  height: 90px;
  color: #1D71B8;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 134%;
  text-align: left;
  padding: 21px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 500px) {
    font-size: 16px;
  }
}

.text {
  max-width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.slot-event__text {
  display:-webkit-box;
  -webkit-line-clamp:2;
  -webkit-box-orient:vertical;
  overflow: hidden;
}

.expand {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.expanded-icon--expanded {
  transform: rotate(180deg);
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
}


.expanded-item {

}

.expanded-item--expanded {
  max-height: 100%;
  transition: max-height 0s ease-in;
}

.expanded-item--hidden {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0s ease-out;
}

.element--white {
  background-color: white;
}

.element--grey {
  background-color: #F6F6F6;
}

</style>
