function capitalize(value) {
    return `${value.charAt(0).toUpperCase()}${value.slice(1)}`
}

export function executeRequest(prefix, request, {url, data, finalizer, catcher, headers}, {getters, dispatch, commit}){
    const LOADING_END = `SET_${prefix.toUpperCase()}_LOADING_END`
    const SAVE_ERROR = `SAVE_${prefix.toUpperCase()}_ERROR`
    commit(`SET_${prefix.toUpperCase()}_LOADING`);
    let ifinalizer = (data) => {
        dispatch(`load${capitalize(prefix)}List`, {url: getters.getCurrentUrl});
        commit(LOADING_END);
        finalizer(data);
    }
    let icatcher = (data) => {
        commit(LOADING_END);
        commit(SAVE_ERROR, data);
        catcher(data);
    }

    if (headers) {
        if(data) request(url, data, ifinalizer, icatcher, headers)
        else request(url, ifinalizer, icatcher, headers)
    } else {
        if(data) request(url, data, ifinalizer, icatcher)
        else request(url, ifinalizer, icatcher)
    }
}
