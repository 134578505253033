import {
    ORGANIZATION_HOST,
    ORGANIZATION_INSTANCE,
    ACCOUNT_HOST,
    ACCOUNTS_INSTANCE,
    EVENT_HOST,
    EVENTS_INSTANCE,

} from "@/helper/consts";
import {checkUrlAllowedWithParameters, getUrlAllowed, getUrlWithParameters, makeMediaHostUrl} from "@/helper/urls";


export default {
    getAccountsUrlWithParameters: function (name, method, parameters, gets) {
        return getUrlWithParameters(
            ACCOUNT_HOST, ACCOUNTS_INSTANCE, name, method, parameters, gets
        );
    },
    getEventsUrlWithParameters: function (name, method, parameters, gets) {
        return getUrlWithParameters(
            EVENT_HOST, EVENTS_INSTANCE, name, method, parameters, gets
        );
    },
    getWorkerUrlWithParameters: function (name, method, parameters, gets) {
        return getUrlWithParameters(
            ORGANIZATION_HOST, ORGANIZATION_INSTANCE, name, method, parameters, gets
        );
    },
    allowedEventsUrl(name, method) {
        return getUrlAllowed(EVENTS_INSTANCE, name, method)
    },
    allowedEventsUrlByParameters(name, method, parameters) {
        return checkUrlAllowedWithParameters(EVENT_HOST, EVENTS_INSTANCE, name, method, parameters)
    },
}
