<template>
  <scroll-center-dialog v-model="opened" :loading="save_loading" width="760" @close="close" @apply="save"
                        :title="'Изменить порядок полей при регистрации'" :scrolled="false">
    <div v-if="!loading_fields" class="px-3">
      <draggable
          :list="fields"
      >
        <transition-group>
          <v-card class="pa-0 ma-0" v-for="(item, i) in fields" :key="i + 'item'">
            <v-row class="px-0 py-4 mx-0 my-4">
              <v-col cols="1" class="pa-0 ma-0">
                <v-icon class="pa-0 ma-0">
                  drag_indicator
                </v-icon>
              </v-col>
              <v-col cols="10" class="pa-0 ma-0">
                {{ item.text }}
              </v-col>
            </v-row>
          </v-card>
        </transition-group>
      </draggable>
    </div>
    <v-skeleton-loader
        v-else
        type="table-heading, list-item-three-line, image, list-item-two-line, table-tfoot"
    ></v-skeleton-loader>
  </scroll-center-dialog>
</template>

<script>
import {mapActions} from "vuex";
import ScrollCenterDialog from "@/modules/core/components/ScrollCenterDialog.vue";
import draggable from "vuedraggable";

export default {
  name: "FieldsOrderEditDialogAdmin",
  components: {ScrollCenterDialog, draggable},
  props: {
    opened: Boolean,
  },
  data() {
    return {
      save_loading: false,
      loading_fields: true,
      fields: [],
      fields_data: {},
    }
  },
  methods: {
    ...mapActions({
      loadFields: 'admin/loadNonPaginatedFieldsList',
      changeFieldsIndexes: 'admin/changeFieldsIndexes',
    }),
    prepareDataAfterLoading(data) {
      this.fields = []
      for (let el of data) {
        let tmp_obj = {}
        tmp_obj.id = el.id
        if (el.field_type !== 8) {
          tmp_obj.text = el.name
        } else {
          tmp_obj.text = el.settings.text
        }

        this.fields.push(tmp_obj)
      }
    },
    prepareDataBeforeSaving() {
      this.fields_data = {}
      for (let i = 0; i < this.fields.length; i++) {
        this.fields_data[this.fields[i].id] = i
      }
    },
    save() {
      this.prepareDataBeforeSaving();
      this.changeFieldsIndexes({
        event_id: this.$route.params.idEvent,
        data: {
          index_changes: this.fields_data
        },
        finalizer: () => {
          this.close()
        }
      })
    },
    close() {
      this.$emit('close', false)
    },
    loadData() {
      this.loadFields({
        event_id: this.$route.params.idEvent,
        finalizer: (data) => {
          this.prepareDataAfterLoading(data)
          this.loading_fields = false;
        }
      })
    },
  },
  created() {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
