<template>
  <div>
    <v-card
      class="pa-4 mb-2 rounded-lg"
      elevation="0"
      color="grey lighten-3"
    >
      <v-card-title class="text-left subtitle-1 px-0 pt-0 pb-2">
        Значение по умолчанию:
      </v-card-title>
      <template
        v-for="(item, i) in value.list"
      >
        <v-row no-gutters class="pa-0"
               :key="i+'string'"
               :index="i"
        >
          <v-col cols="10" md="11" class="pr-md-1">
            <v-text-field
              class="pa-0"
              v-model="value.list[i]"
              outlined
              :error="!!(value.list[i].length === 0 && listStringError)"
              :error-messages="value.list[i].length === 0 ? listStringError : null"
            >
              <template v-slot:label>
                Текст
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="2" md="1">
            <v-btn
              :disabled="value.list.length <= 1"
              icon
              @click="removeLine(i)"
              class="pr-0 mt-2"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-card>
    <v-row no-gutters>
      <v-spacer></v-spacer>
      <v-btn
        text
        @click="addNewString"
        class="pr-0"
      >
        Добавить строку
        <v-icon>add</v-icon>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin"
export default {
  name: "ListString",
  props: {
    value: {
      type: Object,
      default: () => {return {}},
    },
    listStringError: Array,
  },
  mixins: [FormErrorsMixin],
  components: {},
  data(){
    return {
    }
  },
  watch: {},
  computed: {},
  methods: {
    // Удаление одной из строк
    removeLine(index) {
      this.value.list.splice(index, 1)
    },
    //Добавление еще одной строки
    addNewString() {
      this.value.list.push("")
    },
  },
  created() {}
}
</script>

<style scoped>

</style>
