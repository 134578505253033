const VISITOR = {
    BASE: 'Visitor',
    MAIN: undefined,
    EVENTS: {
        _BASE: undefined,
        LIST: undefined,
        DETAIL: undefined,
        SLOTS: {
            _BASE: undefined,
            LIST: undefined,
            DETAIL: undefined,
        },
    },
    VISITS: {
        _BASE: undefined,
        LIST: undefined,
        DETAIL: undefined,
        REDIRECT: undefined,
    },
    INVITES: {
        _BASE: undefined,
        ACCEPT: undefined,
    }

};

VISITOR.MAIN = `${VISITOR.BASE}|Main`

VISITOR.EVENTS._BASE = `${VISITOR.BASE}|Events`
VISITOR.EVENTS.LIST = `${VISITOR.EVENTS._BASE}|List`
VISITOR.EVENTS.DETAIL = `${VISITOR.EVENTS._BASE}|Detail`

VISITOR.EVENTS.SLOTS._BASE = `${VISITOR.EVENTS.DETAIL}|Slots`
VISITOR.EVENTS.SLOTS.LIST = `${VISITOR.EVENTS.SLOTS._BASE}|List`
VISITOR.EVENTS.SLOTS.DETAIL = `${VISITOR.EVENTS.SLOTS._BASE}|Detail`

VISITOR.VISITS._BASE = `${VISITOR.BASE}|Visits`
VISITOR.VISITS.LIST = `${VISITOR.VISITS._BASE}|List`
VISITOR.VISITS.DETAIL = `${VISITOR.VISITS._BASE}|Detail`
VISITOR.VISITS.REDIRECT = `${VISITOR.VISITS._BASE}|Redirect`

VISITOR.INVITES._BASE = `${VISITOR.BASE}|Visits`
VISITOR.INVITES.ACCEPT = `${VISITOR.INVITES._BASE}|ACCEPT`
export default {
    ...VISITOR
}
