<template>
  <v-col cols="12" class="pa-0 ma-0">
    <v-toolbar
        class="removeBtnPaddingRight rounded-t-lg"
        color="#DADADA"
        flat
    >
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              class="dark-primary mx-1"
              @click="$emit('moveRowUp')"
              v-bind="attrs"
              v-on="on"
          >
            arrow_upward
          </v-icon>
        </template>
        <span>Сдвинуть строку вверх</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              class="dark-primary mx-1"
              @click="$emit('moveRowDown')"
              v-bind="attrs"
              v-on="on"
          >
            arrow_downward
          </v-icon>
        </template>
        <span>Сдвинуть строку вниз</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              class="dark-primary mx-1"
              @click="$emit('addEmptyBlock')"
              v-bind="attrs"
              v-on="on"
          >
            add
          </v-icon>
        </template>
        <span>Добавить блок</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              class="dark-primary ml-1 mr-3"
              @click="$emit('deleteRow')"
              v-bind="attrs"
              v-on="on"
          >
            delete
          </v-icon>
        </template>
        <span>Удалить строку</span>
      </v-tooltip>
    </v-toolbar>
  </v-col>
</template>

<script>
export default {
  name: "RowToolbar",
  props: {
    rowNumber: Number,
  }
}
</script>

<style scoped>

</style>
