const STRING_VISITOR_FIELD_COMPONENTS = {
    "Текстовое" : "VisitorFormTextField",
    "Дата время" : "VisitorFormDateTimeField",
    "Дата" : "VisitorFormDateField",
    "Время" : "VisitorFormTimeField",
    "Список строк" : "VisitorFormStringsListField",
    "Пустое место 1" : "",
    "Число" : "VisitorFormNumberField",
    "Пустое место 2" : "",
    "Флаг" : "VisitorFormFlagField",
    "Селектор": "VisitorFormSelector"
};

export default STRING_VISITOR_FIELD_COMPONENTS
