<template>
  <v-row class="ma-0 pa-0 align-center" v-if="!editMode">
    <v-col
      class="ma-0 pa-0"
      cols="4" md="3" lg="2"
    >
      <v-row class="pa-0 mx-5 my-4 justify-center">
        <v-img
          style="border-radius: 50%;"
          :gradient=" !data.files[entryIndex].src ?
                '41deg, rgba(29,59,242,1) 0%, rgba(197,113,233,1) 50%, rgba(197,113,233,1) 78%, rgba(186,234,254,1) 100%'
                : ''"
          :src="data.files[entryIndex].src"
          :aspect-ratio="1"
          :max-height="getImageSize"
          :max-width="getImageSize"
        ></v-img>
      </v-row>
    </v-col>
    <v-col cols="8" md="9" lg="10">
      <v-row no-gutters>
        <v-card-title
            class="pa-0 ma-0 mb-2 text-left text-subtitle-1 text-md-h6 font-weight-bold"
            style="word-break: keep-all!important;"
        >
          {{ data.data.data.headers[entryIndex] }}
        </v-card-title>
      </v-row>
      <v-row no-gutters>
        <v-card-text
          class="pa-0 ma-0 text-left text-subtitle-2 text-md-subtitle-1"
        >
          {{ data.data.data.texts[entryIndex] }}
        </v-card-text>
      </v-row>
    </v-col>
  </v-row>
  <EmptyEntry
    v-else
    :data="data"
    :entry-index="entryIndex"
    @loadFilesFromInput="loadFilesFromInput"
    @deleteOneFile="deleteOneFile"
  ></EmptyEntry>
</template>

<script>
import EmptyEntry from "@/modules/templates/description_blocks/blocks/entries_blocks/EmptyEntry.vue";

export default {
  components: {EmptyEntry},
  props: {
    editMode: Boolean,
    data: Object,
    entryIndex: Number,
  },
  name: "EntriesListEntry",
  computed: {
    getImageSize() {
      return '150px'
    },
  },
  methods: {
    loadFilesFromInput(id) {
      this.$emit('loadFilesFromInput', id);
    },
    deleteOneFile(id, file_index) {
      this.$emit('deleteOneFile', id, file_index)
    },
  }
}
</script>

<style scoped>

</style>
