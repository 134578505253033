<template>
  <div>
    <v-card>
      <SlotDetail
          v-if="loading"
          :item="item"
      >
        <template v-slot:btn>
          <v-btn
              class="dark-primary ml-0"
              icon
              small
              @click="returnParent"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-items v-if="$vuetify.breakpoint.smAndUp && !(light_detail.is_end && !light_detail.is_draft) && !(item.is_default && item.have_visits)">
            <v-btn
                text
                @click="editSlot(item.id)"
                class="dark-primary mr-0 rounded-tr-lg"
            >
              Редактировать слот
            </v-btn>
          </v-toolbar-items>
          <v-btn
              v-if="$vuetify.breakpoint.xsOnly && !(light_detail.is_end && !light_detail.is_draft) && !(item.is_default && item.have_visits)"
              icon
              class="dark-primary mr-0"
              @click="editSlot(item.id)"
          >
            <v-icon>edit</v-icon>
          </v-btn>
          <v-toolbar-items v-if="$vuetify.breakpoint.smAndUp && !(light_detail.is_end && !light_detail.is_draft)">
            <v-btn
                text
                @click="deleteSlot(item.id)"
                class="dark-primary mr-0 rounded-tr-lg"
            >
              Удалить слот
            </v-btn>
          </v-toolbar-items>
          <v-btn
              v-if="$vuetify.breakpoint.xsOnly && !(light_detail.is_end && !light_detail.is_draft)"
              icon
              class="dark-primary mr-0"
              @click="deleteSlot(item.id)"
          >
            <v-icon>delete</v-icon>
          </v-btn>
        </template>
      </SlotDetail>
      <EventDetailRecordsList
          :role="role"
          :record-type="record_type"
      >
      </EventDetailRecordsList>
      <EditDialog
          v-if="dialog_add_edit"
          :opened="dialog_add_edit"
          :edit="edit_status"
          :slot-id="item.id"
          :have-visits="light_detail.have_visits"
          @close="closeEditDialog"
      ></EditDialog>
      <ApplyDialog
          v-if="dialog_delete"
          :opened="dialog_delete"
          :get-text="() => `Вы уверены, что хотите удалить данный слот?`"
          @close="closeDeleteDialog"
      ></ApplyDialog>
    </v-card>
    <v-skeleton-loader
        v-if="!this.loading"
        type="table-heading, list-item-three-line, image, list-item-two-line, table-tfoot"
    ></v-skeleton-loader>
  </div>
</template>

<script>
import TitledPageMixin from "@/mixins/TitledPageMixin";
import EditDialog from "@/modules/admin/events/modals/SlotsEditDialog";
import ApplyDialog from "@/modules/core/components/ApplyDialog.vue";
import {mapActions, mapGetters} from "vuex";
import names from "@/modules/admin/routers/names";
import SlotDetail from "@/modules/templates/SlotDetail";
import admin from "@/urls/roles/admin";
import names_core from "@/modules/core/routers/names";
import EventDetailRecordsList from "@/modules/core/components/event/EventDetailRecordsList";

export default {
  name: "AdminEventsSlotsDetail",
  mixins: [TitledPageMixin],
  components: {
    EditDialog,
    SlotDetail,
    ApplyDialog,
    EventDetailRecordsList,
  },
  data() {
    return {
      loading: false,
      page_title: "Детальное представление слота",
      dialog_add_edit: false,
      edit_status: undefined,
      slot_id: undefined,
      dialog_delete: false,
      role: 'Admin',
      record_type: 'slot'
    }
  },
  watch: {},
  computed: {
    ...mapGetters({
      item: 'admin/getSlotsDetail',
      light_detail: 'admin/getEventsLightDetail',
    }),
    names: () => names,
  },
  methods: {
    ...mapActions({
      loadSlotDetail: 'admin/loadSlotsDetail',
      deleteSlots: 'admin/deleteSlots',
      loadLightDetail: 'admin/loadEventsLightDetail',
      changeEventDetailLoadingState: 'leftmenu/changeEventDetailLoadingState'
    }),
    returnParent() {
      this.$router.push({name: names.EVENTS.SLOTS.LIST})
    },
    loadData(url=undefined){
      this.loadSlotDetail({
        url: url,
        event_id: this.$route.params.idEvent,
        id: this.$route.params.idSlot,
        finalizer: () => {
          this.loading = true
          this.changeEventDetailLoadingState({loading_state: false})
        }
      })
    },
    editSlot(id) {
      this.dialog_add_edit = true
      this.edit_status = true
      this.slot_id = id
    },
    closeEditDialog() {
      this.loadData()
      this.dialog_add_edit = false
      this.edit_status = undefined
      this.slot_id = undefined
    },
    deleteSlot(id) {
      this.dialog_delete = true
      this.slot_id = id
    },
    closeDeleteDialog(slot) {
      if (slot) {
        this.deleteSlots({
          event_id: this.$route.params.idEvent,
          id: this.$route.params.idSlot,
          finalizer: () => {
            this.dialog_delete = false
            this.slot_id = undefined
            this.$router.push({name: names.EVENTS.SLOTS.LIST, params: {}})
          },
        })
      }
      else {
        this.dialog_delete = false
        this.slot_id = undefined
      }
    },
    updatePageTitle() {
      this.loadLightDetail({
        id: this.$route.params.idEvent,
        finalizer: (data) => {
          this.page_title = this.page_title + ': ' + data.name
        }
      })
    },
  },
  created () {
    if (admin.ADMIN.ALLOWED()){
      if(this.$route.name === names.EVENTS.SLOTS.DETAIL){
        this.updatePageTitle()
        this.loadData(undefined)
      }
    } else{
      this.$router.push({name: names_core.MAIN})
    }
  },
}
</script>

<style scoped>

</style>
