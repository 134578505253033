<template>
  <div
    class="no-events-container "
    :class="getContainerClasses"
  >
    <div :class="$vuetify.breakpoint.xsOnly ? 'no-events-container__text--xs' : 'no-events-container__text--smAndUp'">
      {{ text }}
    </div>
    <div
      class="help-text"
      :class="$vuetify.breakpoint.xsOnly ? 'help-text--xs' : 'help-text--smAndUp'"
      v-if="hasHelpText"
    >
      <slot name="helpText"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyListComponent",
  props: {
    text: String,
    hasHelpText: Boolean,
    muctrUser: Boolean,
    isEventsList: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  computed: {
    getContainerClasses() {
      let class_str = '';
      class_str += this.muctrUser ? 'no-events-container--muctr-user' : 'no-events-container--outer-user'
      class_str += this.isEventsList ? ' no-events-container--muctr-user--events' : ''
      return class_str
    }
  }
}
</script>

<style scoped lang="scss">
.no-events-container {
  padding: 5vh 5vw 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #969696;
  font-family: 'Inter normal', serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.no-events-container__text--xs {
  font-size: 26px;
}

.no-events-container__text--smAndUp {
  font-size: 36px;
}

.no-events-container--muctr-user {
  // 60px верхнее меню
  // 75px (600px - 79px) шапка сайта с поиском и заголовком
  // 131px (302px - 179px) шапка с бредкрамсом у списка событий
  height: calc(100vh - 60px - 75px - 131px);
  @media (max-width: 600px) {
    height: calc(100vh - 60px - 79px - 131px);
  }

}
.no-events-container--muctr-user--events {
  @media (max-width: 302px) {
    height: calc(100vh - 60px - 79px - 179px);
  }
}

.no-events-container--outer-user {
  // 60px верхнее меню
  // 75px (600px - 121px) шапка сайта с поиском и заголовком
  height: calc(100vh - 60px - 75px);
  @media (max-width: 600px) {
    height: calc(100vh - 60px - 121px);
  }
}

.help-text {
  margin-top: 23px;
}

.help-text--xs {
  font-size: 18px;
}

.help-text--smAndUp {
  font-size: 24px;
}
</style>
