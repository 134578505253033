<template>
  <div class="page-detail">
    <VisitorPageHeader
        :has-search="false"
        :has-title="!$cookies.get('userMuctr')"
        :page-title="'Моё посещение'"
        :muctr-user="!!$cookies.get('userMuctr')"
        :mobile-breakpoint="$vuetify.breakpoint.xsOnly"
    ></VisitorPageHeader>
    <div v-if="!loading_detail && !loading_records && !loadingDetail && !loadingRecords && item">
      <VisitorVisitDetail
          class="detail"
          :item="item"
          @cancelVisit="cancelVisit"
      ></VisitorVisitDetail>
      <v-data-iterator
          v-if="have_records"
          :items="records"
          :loading="loading_records"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.size"
          :server-items-length="count"
          :options.sync="options"
          :footer-props="{'items-per-page-options':[12,24,48], 'items-per-page-text': 'Записей на странице'}"
      >
        <template v-slot:default>
          <RecordsTable
              :items="records"
              class="records"
              :event-is-draft="item.is_draft"
              :event-is-end="item.is_end"
              :visit-is-cancelled="item.is_cancel"
              @cancelRecord="cancelRecord"
          ></RecordsTable>
        </template>
        <template v-slot:no-data>
          По результатам поиска ничего не найдено
        </template>
      </v-data-iterator>
      <div v-else class="no-records">
        У вас нет записей на это событие
        <div class="no-records__help-text" v-if="showHelpText()">
          Ознакомиться со списком доступных записей можно <span class="no-records__help-text--link" @click="getEventDetail">здесь</span>
        </div>
      </div>
      <CustomApplyDialog
          v-if="dialog_cancel"
          :question-text="`Вы уверены, что хотите отменить своё посещение?`"
          :apply-text="'Да, отменить'"
          :cancel-text="'Нет, оставить'"
          :apply-color="'#D64141'"
          @close="closeCancelDialog"
      ></CustomApplyDialog>
      <CustomApplyDialog
          v-if="dialog_cancel_record"
          :question-text="`Вы уверены, что хотите отменить данную запись?`"
          :apply-text="'Да, отменить'"
          :cancel-text="'Нет, оставить'"
          :apply-color="'#D64141'"
          @close="closeCancelRecordDialog"
      ></CustomApplyDialog>
    </div>
    <div class="action mt-8 mb-4 align-center" v-else>
      <semipolar-spinner
          class="d-inline-block"
          :animation-duration="2000"
          :size="115"
          color="#1935ff"
      />
    </div>
  </div>
</template>

<script>
import TitledPageMixin from "@/mixins/TitledPageMixin";
import {mapActions, mapGetters} from "vuex";
import names from "@/modules/visitor/routers/names";
import CustomApplyDialog from "@/modules/templates/visitor/custom_forms/CustomApplyDialog.vue";
import VisitorVisitDetail from "@/modules/templates/visitor/visit_detail/VisitorVisitDetail.vue";
import RecordsTable from "@/modules/templates/visitor/visit_detail/RecordsTable.vue";
import VisitorPageHeader from "@/modules/templates/visitor/core/VisitorPageHeader.vue";
import SemipolarSpinner from "epic-spinners/src/components/lib/SemipolarSpinner.vue";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";

export default {
  name: "VisitorEventsVisitsDetail",
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  components: {
    VisitorPageHeader,
    CustomApplyDialog,
    VisitorVisitDetail,
    RecordsTable,
    SemipolarSpinner,
  },
  data() {
    return {
      loading_detail: true,
      loading_records: true,
      pagination: {
        size: 12,
      },
      page_title: "Детальное представление посещения",
      dialog_cancel: false,
      dialog_cancel_record: false,
      record_id: undefined,
      item: undefined,
      records: undefined,
      have_records: false,
    }
  },
  watch: {},
  computed: {
    ...mapGetters({
      count: 'visitor/getRecordsCount',
      loadingDetail: 'visitor/isLoadingVisitDetail',
      loadingRecords: 'visitor/isLoadingRecords',
    }),
    names: () => names,
  },
  methods: {
    ...mapActions({
      loadVisitDetail: 'visitor/loadVisitsDetail',
      cancelVisits: 'visitor/cancelVisits',
      loadRecords: 'visitor/loadRecordsList',
      cancelRecords: 'visitor/cancelRecords',
    }),
    loadDetail(url=undefined){
      this.loading_detail = true
      this.loadVisitDetail({
        url: url,
        id: this.$route.params.idVisit,
        finalizer: (data) => {
          this.item = data
          this.loading_detail = false
        }
      })
    },
    loadData(url=undefined) {
      this.loading_records = true
      this.loadRecords({
        url: url,
        urlAppend: (url) => {
          return this.addPageParameter(this.addSizeParameter(url))
        },
        visit_id: this.$route.params.idVisit,
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
          this.records = data.results
          if (this.records.length > 0) {
            this.have_records = true;
          }
          this.loading_records = false
        }
      })
    },
    showHelpText() {
      if (this.item.is_draft || !this.item.event.have_slots) {
        return false;
      }

      return !(this.item.is_end && !this.item.is_draft);
    },
    cancelRecord(item) {
      this.record_id = item.id
      this.dialog_cancel_record = true
    },
    closeCancelRecordDialog(record) {
      if (record) {
        this.cancelRecords({
          visit_id: this.$route.params.idVisit,
          id: this.record_id,
          finalizer: () => {
            this.loadData()
          },
          catcher: () => {

          },
        })
      }
      this.dialog_cancel_record = false
    },
    cancelVisit() {
      this.dialog_cancel = true
    },
    closeCancelDialog(visit) {
      if (visit) {
        this.cancelVisits({
          id: this.item.id,
          finalizer: () => {
            this.loadDetail()
            this.loadData()
          },
          catcher: () => {

          },
        })
      }
      this.dialog_cancel = false
    },
  },
  created () {
    this.loadDetail()
    this.loadData()
  },
}
</script>

<style scoped lang="scss">
.page-detail {

}

.no-records {
  color: #7C7C7C;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 32px;
  text-transform: uppercase;
}

.no-records__help-text {
  color: #1E1E1E;
  text-transform: none;
  margin-top: 5px;
}

.no-records__help-text--link {
  color: #1D71B8;
  cursor: pointer;
}
</style>
