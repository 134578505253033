import {sendGetRequest} from "@/helper/requests";
import visitor from "@/urls/roles/visitor"

import slots from "@/store/modules/visitor/events/slots";
import visits from "@/store/modules/visitor/events/visits";
import records from "@/store/modules/visitor/events/records";
import login from "@/store/modules/visitor/events/login";
import blocks from "@/store/modules/visitor/events/blocks";
import fields from "@/store/modules/visitor/events/fields";
import invites from "@/store/modules/visitor/events/invites";

const state = {
    events:{
        currentUrl: null,
        items: [],
        loading: false,
        count: 0,
        detail: [],
        light_detail: [],
        loading_light: false,
        error: null,
        need_reload: false,
    }
}

const getters = {
    getEventsList: state => state.events.items,
    getEventsCount: state => state.events.count,
    getEventsCurrentUrl: state => state.events.currentUrl,
    getEventsDetail: state => state.events.detail,
    getEventsLightDetail: state => state.events.light_detail,
    isLoadingEvents: state => state.events.loading,
    saveEventsError: state => state.events.error,
    isLoadingLightDetail: state => state.events.loading_light,
    isNeedReload: state => state.events.need_reload
}

const actions = {
    changeNeedReloadState: ({commit}, {value}) => {
        commit('SET_NEED_RELOAD', value);
    },
    loadEventsList: ({commit, getters}, {url, urlAppend, is_parent, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) {
            url = visitor.VISITOR.EVENTS.LIST()
            if (urlAppend) url = urlAppend(url)
        }
        if (!getters.isLoadingEvents || (getters.getEventsCurrentUrl !== url && is_parent === false)){
            commit('SET_EVENTS_LOADING');
            sendGetRequest(
                url, (data, url) => {
                    commit('SET_EVENTS_LIST', data['results']);
                    commit('SET_EVENTS_COUNT', data['count']);
                    commit('SET_EVENTS_CURRENT_URL', url);
                    commit('SET_EVENTS_LOADING_END');
                    finalizer(data, url);
                }, (data, url) => {
                    commit('SET_EVENTS_LOADING_END');
                    commit('SAVE_EVENTS_ERROR', data);
                    catcher(data, url);
                },
            )
        }
    },
    loadEventsDetail: ({commit}, {url, id, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) url = visitor.VISITOR.EVENTS.DETAIL(id)
        let headers = {}
        if(window.$cookies.get("visitor_token")){
            headers = {
                'Content-Type': 'application/json',
                'Authorization': 'EventToken ' + window.$cookies.get("visitor_token")
            }
        }
        sendGetRequest(
            url, (data, url) => {
                commit('SET_EVENTS_DETAIL', data);
                finalizer(data, url);
            }, (data, url) => {
                commit('SAVE_EVENTS_ERROR', data);
                catcher(data, url);
            },
            undefined,
            headers
        )
    },
    loadEventsLightDetail: ({commit}, {url, id, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) url = visitor.VISITOR.EVENTS.LIGHT_DETAIL(id)
        let headers = {}
        let token = window.$cookies.get("visitor_token")
        if(token) {
            headers = {
                'Content-Type': 'application/json',
                'Authorization': 'EventToken ' + token
            }
        }
        commit('SET_LIGHT_LOADING')
        sendGetRequest(
            url, (data, url) => {
                commit('SET_EVENTS_LIGHT_DETAIL', data);
                commit('SET_LIGHT_LOADING_END')
                finalizer(data, url);
            }, (data, url) => {
                commit('SET_LIGHT_LOADING_END')
                commit('SAVE_EVENTS_ERROR', data);
                catcher(data, url);
            },
            undefined,
            headers
        )
    },
}

const mutations = {
    SET_EVENTS_LIST: (state, events) => {
        state.events.items = events
    },
    SET_EVENTS_DETAIL: (state, value) => {
        state.events.detail = value
    },
    SET_EVENTS_LIGHT_DETAIL: (state, value) => {
        state.events.light_detail = value
    },
    SET_EVENTS_COUNT: (state, value) => {
        state.events.count = value
    },
    SET_EVENTS_CURRENT_URL: (state, value) => {
        state.events.currentUrl = value
    },
    SET_EVENTS_LOADING: (state) => {
        state.events.loading = true
    },
    SET_EVENTS_LOADING_END: (state) => {
        state.events.loading = false
    },
    SAVE_EVENTS_ERROR: (state, error) => {
        state.events.error = error
    },
    SET_LIGHT_LOADING: (state) => {
        state.events.loading_light = true
    },
    SET_LIGHT_LOADING_END: (state) => {
        state.events.loading_light = false
    },
    SET_NEED_RELOAD: (state, value) => {
        state.events.need_reload = value
    },
}

export default {
    namespaced: false,
    state,
    mutations,
    actions,
    getters,
    modules: {
        slots,
        visits,
        records,
        login,
        blocks,
        fields,
        invites
    }
}
