<template>
  <div>
    <v-card>
      <SlotDetail
          v-if="loading"
          :item="item"
      >
        <template v-slot:btn>
          <v-btn
              class="dark-primary ml-0"
              icon
              small
              @click="returnParent"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </template>
      </SlotDetail>
      <EventDetailRecordsList
          :role="role"
          :record-type="record_type"
      >
      </EventDetailRecordsList>
    </v-card>
    <v-skeleton-loader
        v-if="!this.loading"
        type="table-heading, list-item-three-line, image, list-item-two-line, table-tfoot"
    ></v-skeleton-loader>
  </div>
</template>

<script>
import TitledPageMixin from "@/mixins/TitledPageMixin";
import {mapActions, mapGetters} from "vuex";
import names from "@/modules/viewer/routers/names";
import SlotDetail from "@/modules/templates/SlotDetail";
import viewer from "@/urls/roles/viewer";
import names_core from "@/modules/core/routers/names";
import EventDetailRecordsList from "@/modules/core/components/event/EventDetailRecordsList";

export default {
  name: "ViewerEventsSlotsDetail",
  mixins: [TitledPageMixin],
  components: {
    SlotDetail,
    EventDetailRecordsList,
  },
  data() {
    return {
      loading: false,
      page_title: "Детальное представление слота",
      role: 'Viewer',
      record_type: 'slot'
    }
  },
  computed: {
    ...mapGetters({
      item: 'viewer/getSlotsDetail',
    }),
    names: () => names,
  },
  methods: {
    ...mapActions({
      loadSlotDetail: 'viewer/loadSlotsDetail',
      loadLightDetail: 'viewer/loadEventsLightDetail',
      changeEventDetailLoadingState: 'leftmenu/changeEventDetailLoadingState'
    }),
    returnParent() {
      this.$router.push({name: names.EVENTS.SLOTS.LIST})
    },
    loadData(url=undefined){
      this.loadSlotDetail({
        url: url,
        event_id: this.$route.params.idEvent,
        id: this.$route.params.idSlot,
        finalizer: () => {
          this.loading = true
          this.changeEventDetailLoadingState({loading_state: false})
        }
      })
    },
    updatePageTitle() {
      this.loadLightDetail({
        id: this.$route.params.idEvent,
        finalizer: (data) => {
          this.page_title = this.page_title + ': ' + data.name
        }
      })
    },
  },
  created () {
    if (viewer.VIEWER.ALLOWED()){
      if(this.$route.name === names.EVENTS.SLOTS.DETAIL){
        this.updatePageTitle()
        this.loadData()
      }
    } else{
      this.$router.push({name: names_core.MAIN})
    }
  },
}
</script>

<style scoped>

</style>
