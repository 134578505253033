<template>
  <div class="action mt-8 mb-4 align-center" v-if="loading">
    <semipolar-spinner
        class="d-inline-block"
        :animation-duration="2000"
        :size="115"
        color="#1935ff"
    />
  </div>
  <div
      v-else-if="not_found || duplication_error"
      class="error-container"
  >
    {{ not_found ? 'Такого приглашения не существует' : this.duplication_error }}
    <div
        class="help-text"
    >
      <span class="help-text--link" @click="getEventsList" v-if="not_found">перейти к списку событий</span>
      <span class="help-text--link" @click="getEventDetail" v-else>перейти к событию</span>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {SemipolarSpinner} from "epic-spinners";
import names from "@/modules/visitor/routers/names";

export default {
  name: "InviteAcceptComponent",
  components: { SemipolarSpinner },
  data() {
    return {
      loading: true,
      duplication_error: '',
      not_found: false,
      event_slug: undefined,
    }
  },
  methods: {
    ...mapActions({
      acceptInvite: 'visitor/acceptInvite',
    }),
    getEventsList() {
      this.$router.push({name: names.EVENTS.LIST})
    },
    getEventDetail() {
      this.$router.push({name: names.EVENTS.DETAIL, params: {idEvent: this.event_slug}})
    },
  },
  created() {
    this.acceptInvite({
      invite: this.$route.params.uid,
      finalizer: (data) => {
        this.event_slug = data.slug
        this.getEventDetail()
      },
      catcher: (data) => {
        if (data['is_accept']) {
          this.duplication_error = data['is_accept']
        }
        if (data.slug) {
          this.event_slug = data.slug
        } else {
          this.not_found = true;
        }
        this.loading = false
      }
    })
  }
}
</script>

<style scoped lang="scss">
.error-container {
  color: #7C7C7C;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 20vh;
  text-transform: uppercase;
  padding: 0 8vw;
}

.help-text {
  color: #1D71B8;
  text-transform: none;
  text-decoration: underline;
  margin-top: 5px;
  font-size: 15px;
}

.help-text--link {
  color: #1D71B8;
  cursor: pointer;
}
</style>
