<template>
  <div>
    <v-toolbar
        class="removeBtnPaddingRight rounded-t-lg"
        color="#DADADA"
        flat
    >
      <slot name="btn"></slot>
    </v-toolbar>
    <v-card-text class="subtitle-2 text-md-subtitle-1">
      <v-row no-gutters class="text-left">
        <v-col cols="5" sm="4" md="3" class="font-weight-bold py-1">Название события:</v-col>
        <v-col cols="7" sm="8" md="9" class="text-right text-md-left py-1">{{ item.event.name }}</v-col>
      </v-row>
      <v-row no-gutters class="text-left" v-if="!is_operator">
        <v-col cols="5" sm="4" md="3" class="font-weight-bold py-1">Дата начала события:</v-col>
        <v-col cols="7" sm="8" md="3" class="text-right text-md-left py-1">{{ displayDate(item.event.start) }}</v-col>
        <v-col cols="5" sm="4" md="3" class="font-weight-bold py-1">Дата конца события:</v-col>
        <v-col cols="7" sm="8" md="3" class="text-right text-md-left py-1">{{ displayDate(item.event.end) }}</v-col>
      </v-row>
      <v-row no-gutters class="text-left" v-if="!is_visitor">
        <v-col cols="5" sm="4" md="3" class="font-weight-bold py-1">ФИО:</v-col>
        <v-col cols="7" sm="8" md="3" class="text-right text-md-left py-1">{{ item.visitor.fio }}</v-col>
        <v-col cols="5" sm="4" md="3" class="font-weight-bold py-1" v-if="!is_operator">e-mail:</v-col>
        <v-col cols="7" sm="8" md="3" class="text-right text-md-left py-1" v-if="!is_operator">{{ item.visitor.email }}</v-col>
      </v-row>
      <v-row no-gutters class="text-left" >
        <v-col cols="7" sm="4" md="3" class="font-weight-bold py-1" v-if="!is_operator">Есть записи:</v-col>
        <v-col cols="5" sm="8" md="3" class="text-right text-md-left py-1" v-if="!is_operator">
          <v-icon
              :color="checkFlag(item.have_records).color"
          >
            {{checkFlag(item.have_records).icon}}
          </v-icon>
        </v-col>
        <v-col cols="7" sm="4" md="3" class="font-weight-bold py-1">Событие завершено:</v-col>
        <v-col cols="5" sm="8" md="3" class="text-right text-md-left py-1">
          <v-icon
              :color="checkFlag(item.is_end).color"
          >
            {{checkFlag(item.is_end).icon}}
          </v-icon>
        </v-col>
      </v-row>
      <v-row no-gutters class="text-left">
        <v-col cols="7" sm="4" md="3" class="font-weight-bold py-1" v-if="!is_operator">Дата входа на событие:</v-col>
        <v-col cols="5" sm="8" md="3" class="text-right text-md-left py-1"
               :class="correctTime(item.entered) ? '' : 'pr-1 mr-0'"
               v-if="!is_operator"
        >{{ correctTime(item.entered) ? correctTime(item.entered) : 'Не посещено' }}</v-col>
        <v-col cols="7" sm="4" md="3" class="font-weight-bold py-1">Событие посещено:</v-col>
        <v-col cols="5" sm="8" md="3" class="text-right text-md-left py-1">
          <v-icon
              :color="checkFlag(item.is_enter).color"
          >
            {{checkFlag(item.is_enter).icon}}
          </v-icon>
        </v-col>
      </v-row>
      <v-row no-gutters class="text-left">
        <v-col cols="7" sm="4" md="3" class="font-weight-bold py-1" v-if="!is_operator">Дата и время отмены:</v-col>
        <v-col cols="5" sm="8" md="3" class="text-right text-md-left py-1" v-if="!is_operator">{{ correctTime(item.cancelled, true) }}</v-col>
        <v-col cols="7" sm="4" md="3" class="font-weight-bold py-1">Посещение отменено:</v-col>
        <v-col cols="5" sm="8" md="3" class="text-right text-md-left py-1">
          <v-icon
              :color="item.is_cancel? 'yellow darken-3' : 'grey'"
          >
            {{checkFlag(item.is_cancel).icon}}
          </v-icon>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import {displayDate} from "@/helper";

export default {
  name: "VisitDetail",
  props: {
    item:{
      type: Object,
    },
    is_operator:{
      type: Boolean,
      default: false,
    },
    is_visitor:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
    }
  },
  methods:{
    correctTime (time, minute = false) {
      if(time!==null){
        return displayDate(time, minute)
      }
    },
    displayDate: displayDate,
    checkFlag(flag) {
      if (flag) return {icon: "check_circle", color: "green"}
      else return {icon: "not_interested", color: "grey"}
    },
  },
  created() {
  }
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0px;
}
</style>
