const BLOCK_COMPONENTS = [
    "TextBlock",
    "HeaderBlock",
    "SpacerBlock",
    "ImageBlock",
    "SliderBlock",
    "EntriesSliderBlock",
    "FilesListBlock",
    "EntriesListBlock",
    "NullBlock",
    "EmptyBlock",
]

export default BLOCK_COMPONENTS;
