import roles from "@/store/modules/admin/roles";
import events from "@/store/modules/admin/events/index";
import visitors from "@/store/modules/admin/visitors";

export default {
  namespaced: true,
  modules: {
    roles,
    events,
    visitors
  }
}