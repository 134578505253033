import helper from "./helper";

let getEventsUrlWithParameters = helper.getEventsUrlWithParameters


export default {
    CHOICES: {
        EVENTS: {
            ACCESS_TYPE: (gets = null) => getEventsUrlWithParameters(
                "choices|events|access_type",
                'get',
                {},
                gets
            ),
            FIELD_TYPE: (gets = null) => getEventsUrlWithParameters(
                "choices|events|field_type",
                'get',
                {},
                gets
            ),
            BLOCK_TYPE: (gets = null) => getEventsUrlWithParameters(
                "choices|description|block_type",
                'get',
                {},
                gets
            ),
            REGEXP_TYPES: (gets = null) => getEventsUrlWithParameters(
                    "choices|events|regex_type",
                    'get',
                    {},
                    gets
            ),
        }
    }
}
