import Vue from 'vue'
import Router from 'vue-router'
import PageNotFound from "@/modules/core/pages/PageNotFound";
import coreRouter from "@/modules/core/routers/routers";
import adminRouter from "@/modules/admin/routers/routers";
import viewerRouter from "@/modules/viewer/routers/routers";
import organiserRouter from "@/modules/organiser/routers/routers";
import operatorRouter from "@/modules/operator/routers/routers";
import visitorRouter from "@/modules/visitor/routers/routers";
import LoadPortal from "@/modules/core/pages/LoadPortal";

import {authRequiredHook, instanceRequiredHook} from "@/router/hooks";


Vue.use(Router)

const baseRoutes = [
    {
        path: "*",
        component: PageNotFound
    },
    {
      path: '/exit',
      name: 'LoadPortal',
      component: LoadPortal,
      meta: {
        breadcrumb: 'Выход',
      }
    },
];
var allRoutes = [];

allRoutes = allRoutes.concat(
    baseRoutes,
    coreRouter,
    adminRouter,
    viewerRouter,
    organiserRouter,
    operatorRouter,
    visitorRouter
);

const router = new Router(
    {
        base: '',
        mode: 'history',
        routes: allRoutes,
    }
);
router.beforeEach(authRequiredHook);
router.beforeEach(instanceRequiredHook);
// router.beforeEach(toAuthRedirectHook);

export default router;
