<template>
  <div v-if="!isArchived && !isHidden && !notFound && !noAccess">
    <div v-if="event_detail_loaded && light_detail_loaded && slots_loaded">
      <VisitorEventDetail
          class="detail"
          v-if="event_detail_loaded && light_detail_loaded"
          :item="detail"
          :light_detail="light_detail"
          @createVisit="newVisit"
          @cancelVisit="cancelDialog"
          @reloadEventData="reloadEventData"
      ></VisitorEventDetail>
      <VisitorBlocksView
          class="blocks"
          v-if="event_detail_loaded && detail.blocks.length > 0"
          :blocks="detail.blocks"
      ></VisitorBlocksView>
      <NestedEventsTable
          class="nested-events"
          v-if="event_detail_loaded && detail.children.length > 0"
          :items="detail.children"
          @reloadEventData="reloadEventData"
      ></NestedEventsTable>
      <SlotEventsTable
          class="slots"
          v-if="event_detail_loaded && slots_loaded && slots.length > 0 && light_detail_loaded"
          :slot-events="slots"
          :has-visit="!!light_detail.visit"
          @createRecord="createRecordFunction"
          @cancelRecord="cancelRecordFunction"
      ></SlotEventsTable>
    </div>
    <div class="action mt-8 mb-4 align-center" v-else>
      <semipolar-spinner
          class="d-inline-block"
          :animation-duration="2000"
          :size="115"
          color="#1935ff"
      />
    </div>
    <EditDialog
        v-if="dialog_add_edit"
        :event-id="detail.id"
        :opened="dialog_add_edit"
        :edit="false"
        :visit-id="undefined"
        :has-data-fields="detail.have_data_field"
        :is-required-data-dissemination="detail.is_required_data_dissemination"
        :need-data-access-confirmation="!light_detail.is_data_access_confirmation"
        @close="closeDialog"
    ></EditDialog>
    <CustomApplyDialog
        v-if="dialog_cancel"
        :question-text="`Вы уверены, что хотите отменить своё посещение?`"
        :apply-text="'Да, отменить'"
        :cancel-text="'Нет, оставить'"
        :apply-color="'#D64141'"
        @close="closeCancelDialog"
    ></CustomApplyDialog>
    <CustomApplyDialog
        v-if="light_dialog_add_edit"
        :question-text="`Вы уверены, что хотите записаться на событие?`"
        @close="closeLightDialog"
    ></CustomApplyDialog>
    <CustomApplyDialog
        v-if="dialog_create_record"
        :question-text="`Вы уверены, что хотите записаться?`"
        @close="closeCreateRecordDialog"
    ></CustomApplyDialog>
    <CustomApplyDialog
        v-if="dialog_cancel_record"
        :question-text="`Вы уверены, что хотите отменить данную запись?`"
        :apply-text="'Да, отменить'"
        :cancel-text="'Нет, оставить'"
        :apply-color="'#D64141'"
        @close="closeCancelRecordDialog"
    ></CustomApplyDialog>
  </div>
  <div
      v-else-if="isHidden || isArchived || notFound || noAccess"
      class="error-container"
  >
    {{ getErrorText() }}
    <div
        class="help-text"
        :class="$vuetify.breakpoint.xsOnly ? 'help-text--xs' : 'help-text--smAndUp'"
    >
      <span class="help-text--link" @click="getEventsList">вернуться к списку событий</span>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import {SemipolarSpinner} from "epic-spinners";
import names from "@/modules/visitor/routers/names";
import EditDialog from "@/modules/visitor/events/modals/VisitEditDialog";
import {displayDate} from "@/helper";
import VisitorEventDetail from "@/modules/templates/visitor/event_detail/VisitorEventDetail.vue";
import CustomApplyDialog from "@/modules/templates/visitor/custom_forms/CustomApplyDialog.vue";
import NestedEventsTable from "@/modules/templates/visitor/event_detail/NestedEventsTable.vue";
import VisitorBlocksView from "@/modules/templates/visitor/description_blocks/VisitorBlocksView.vue";
import SlotEventsTable from "@/modules/templates/visitor/event_detail/SlotEventsTable.vue";

export default {
  name: "VisitorEventsDetail",
  props: {
    isArchived: Boolean,
    isHidden: Boolean,
    notFound: Boolean,
    noAccess: Boolean,
  },
  mixins: [TitledPageMixin],
  components: {
    SlotEventsTable,
    VisitorBlocksView,
    NestedEventsTable,
    CustomApplyDialog,
    VisitorEventDetail,
    SemipolarSpinner,
    EditDialog
  },
  data() {
    return {
      page_title: 'Детальное представление события',
      event_detail_loaded: false,
      light_detail_loaded: false,
      slots_loaded: false,
      light_dialog_add_edit: false,
      dialog_add_edit: false,
      dialog_cancel: false,
      dialog_create_record: false,
      dialog_cancel_record: false,
      record_slot: undefined,
      slots: [],
    }
  },
  computed: {
    ...mapGetters({
      blocks: 'visitor/getBlocksList',
      detail: 'visitor/getEventsDetail',
      light_detail: 'visitor/getEventsLightDetail',
      visitor_token: 'visitor/getVisitorToken',
    }),
    userIsAuthenticated() {
      return this.$cookies.get('userMuctr') || this.$cookies.get('visitor_token') || this.visitor_token;
    },
    names: () => names,
  },
  watch: {},
  methods: {
    displayDate: displayDate,
    ...mapActions({
      loadEventDetail: 'visitor/loadEventsDetail',
      loadLightDetail: 'visitor/loadEventsLightDetail',
      createVisit: 'visitor/createVisits',
      cancelVisit: 'visitor/cancelVisits',
      createRecord: 'visitor/createRecords',
      cancelRecord: 'visitor/cancelRecords'
    }),
    getEventsList() {
      this.$router.push({name: names.EVENTS.LIST})
    },
    getErrorText() {
      if (this.isArchived) return 'Данное событие уже закончилось';
      if (this.isHidden) return 'Данное событие скрыто';
      if (this.notFound) return 'Такого события не существует';
      if (this.noAccess) return 'Недостаточно прав для просмотра мероприятия';

      return '';
    },
    newVisit() {
      if (!!this.userIsAuthenticated && !this.detail.have_data_field && !this.detail.is_required_data_dissemination && this.light_detail.is_data_access_confirmation) {
        this.light_dialog_add_edit = true;
      } else {
        this.dialog_add_edit = true;
      }
    },
    closeDialog() {
      this.dialog_add_edit = false
      this.visit_id = undefined
      this.reloadEventData()
    },
    closeLightDialog(create_accepted) {
      if (create_accepted) {
        this.createVisit({
          data: {
            event: this.detail.id,
            is_data_dissemination: false,
            visitor_data: [],
          },
          finalizer: () => {
            this.reloadEventData()
            this.light_dialog_add_edit = false;
          }
        })
      } else {
        this.light_dialog_add_edit = false;
      }
    },
    cancelDialog() {
      this.dialog_cancel = true
    },
    closeCancelDialog(delete_accepted) {
      if (delete_accepted) {
        this.cancelVisit({
          id: this.light_detail.visit,
          finalizer: () => {
            this.reloadEventData()
            this.dialog_cancel = false;
          }
        })
      } else {
        this.dialog_cancel = false;
      }
    },
    createRecordFunction(item) {
      this.record_slot = item
      this.dialog_create_record = true;
    },
    closeCreateRecordDialog(created) {
      if (created) {
        this.createRecord({
          data: {
            date: this.record_slot.record_date,
            slot: this.record_slot.id,
            visit: this.light_detail.visit,
          },
          visit_id: this.light_detail.visit,
          finalizer: () => {
            this.dialog_create_record = false;
            this.reloadEventData()
          }
        })
      } else {
        this.dialog_create_record = false;
      }
    },
    cancelRecordFunction(item) {
      this.record_slot = item
      this.dialog_cancel_record = true;
    },
    closeCancelRecordDialog(cancelled) {
      if (cancelled) {
        this.cancelRecord({
          visit_id: this.light_detail.visit,
          id: this.record_slot.record,
          finalizer: () => {
            this.dialog_cancel_record = false;
            this.reloadEventData()
          }
        })
      } else {
        this.dialog_cancel_record = false;
      }
    },
    reloadEventData() {
      this.event_detail_loaded = false;
      this.light_detail_loaded = false;
      this.slots_loaded = false;
      this.slots = []
      this.loadEventDetailFunction()
      this.loadEventsLightDetailFunction()
    },
    loadEventDetailFunction() {
      this.event_detail_loaded = false;
      this.loadEventDetail({
        url: undefined,
        id: this.$route.params.idEvent,
        finalizer: (data) => {
          this.page_title = 'Событие: ' + data.name
          this.rebuildSlots()
          this.event_detail_loaded = true;
        }
      })
    },
    loadEventsLightDetailFunction() {
      this.light_detail_loaded = false
      this.loadLightDetail({
        id: this.$route.params.idEvent,
        finalizer: () => {
          this.light_detail_loaded = true;
        },
        catcher: (data) => {
        }
      })
    },
    slotIsNotCommon(slot) {
      return this.slotIsDefault(slot) && (this.slotIsInterval(slot) || this.slotIsEvent(slot))
    },
    slotIsDefault(slot) {
      return slot.is_default
    },
    slotIsInterval(slot) {
      return slot.intervals.length > 0
    },
    slotIsEvent(slot) {
      return slot.intervals.length === 0 && !slot.date
    },
    rebuildSlots() {
      this.slots_loaded = false;
      this.slots = []

      for (let i = 0; i < this.detail.slots.length; i++) {
        let slot_already_used = false;

        for (let k = 0; k < this.slots.length; k++) {
          if (this.detail.slots[i].event_name === this.slots[k].name) {
            slot_already_used = true;
            break;
          }
        }

        if (slot_already_used) continue;

        let tmp_obj = {}
        tmp_obj.name = this.detail.slots[i].event_name
        tmp_obj.data = []
        if (this.slotIsNotCommon(this.detail.slots[i])) {
          let tmp_slot = {}
          tmp_slot.id = this.detail.slots[i].id

          tmp_slot.interval_date = {}
          if (this.slotIsInterval(this.detail.slots[i])) {
            tmp_slot.interval_date.start = this.detail.slots[i].intervals[0].start
            tmp_slot.interval_date.end = this.detail.slots[i].intervals[0].end
          } else {
            tmp_slot.interval_date.start = this.detail.start
            tmp_slot.interval_date.end = this.detail.end
          }

          tmp_slot.date = this.detail.slots[i].date
          tmp_slot.intervals = this.detail.slots[i].intervals

          tmp_slot.start = this.detail.slots[i].start
          tmp_slot.end = this.detail.slots[i].end

          tmp_slot.is_default = this.detail.slots[i].is_default

          tmp_obj.data.push(tmp_slot)
        } else {
          for (let day of this.detail.slots[i].days) {
            let tmp_slot = {}
            tmp_slot.id = this.detail.slots[i].id

            tmp_slot.date = this.detail.slots[i].date
            tmp_slot.intervals = this.detail.slots[i].intervals

            tmp_slot.record_date = day.date
            tmp_slot.start = this.detail.slots[i].start
            tmp_slot.end = this.detail.slots[i].end

            tmp_slot.is_default = this.detail.slots[i].is_default
            tmp_slot.record = day.record
            tmp_slot.record_ended = day.record_ended
            tmp_slot.is_enter = day.is_enter

            tmp_slot.count = this.detail.slots[i].count
            tmp_slot.seats_left = day.seats_left

            tmp_slot.crossing_event_name = day.name

            tmp_obj.data.push(tmp_slot)
          }
        }

        for (let j = i + 1; j < this.detail.slots.length; j++) {
          if (tmp_obj.name === this.detail.slots[j].event_name) {
            if (this.slotIsNotCommon(this.detail.slots[j])) {
              let tmp_slot = {}
              tmp_slot.id = this.detail.slots[j].id

              tmp_slot.interval_date = {}
              if (this.slotIsInterval(this.detail.slots[j])) {
                tmp_slot.interval_date.start = this.detail.slots[j].intervals[0].start
                tmp_slot.interval_date.end = this.detail.slots[j].intervals[0].end
              } else {
                tmp_slot.interval_date.start = this.detail.start
                tmp_slot.interval_date.end = this.detail.end
              }

              tmp_slot.date = this.detail.slots[j].date
              tmp_slot.intervals = this.detail.slots[j].intervals

              tmp_slot.start = this.detail.slots[j].start
              tmp_slot.end = this.detail.slots[j].end

              tmp_slot.is_default = this.detail.slots[j].is_default

              tmp_obj.data.push(tmp_slot)
            } else {
              for (let day of this.detail.slots[j].days) {
                let tmp_slot = {}
                tmp_slot.id = this.detail.slots[j].id

                tmp_slot.date = this.detail.slots[j].date
                tmp_slot.intervals = this.detail.slots[j].intervals

                tmp_slot.record_date = day.date
                tmp_slot.start = this.detail.slots[j].start
                tmp_slot.end = this.detail.slots[j].end

                tmp_slot.is_default = this.detail.slots[j].is_default
                tmp_slot.record = day.record
                tmp_slot.record_ended = day.record_ended
                tmp_slot.is_enter = day.is_enter

                tmp_slot.count = this.detail.slots[j].count
                tmp_slot.seats_left = day.seats_left

                tmp_slot.crossing_event_name = day.name

                tmp_obj.data.push(tmp_slot)
              }
            }
          }
        }
        this.slots.push(tmp_obj)
      }

      // сортируем в хронологическом порядке, выносим
      for (let slot of this.slots) {
        slot.data.sort(this.slotSortIncreasingDateTime)
      }

      this.slots_loaded = true;
    },
    slotSortIncreasingDateTime(slot1, slot2) {
      if (!slot1.is_default && !slot2.is_default) {
        return new Date(slot1.record_date + 'T' + slot1.start) - new Date(slot2.record_date + 'T' + slot2.start)
      } else if (slot1.is_default && !slot2.is_default) {
        return -1
      } else if (!slot1.is_default && slot2.is_default) {
        return 1
      } else if (slot1.is_default && slot2.is_default) {
        let slot1_date = (slot1.interval_date ? slot1.interval_date.start + 'T' + slot1.start : slot1.record_date + 'T' + slot1.start)
        let slot2_date = (slot2.interval_date ? slot2.interval_date.start + 'T' + slot2.start : slot2.record_date + 'T' + slot2.start)
        return new Date(slot1_date) - new Date(slot2_date)
      } else {
        return 0
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next()
    if (!this.isArchived && !this.isHidden && !this.notFound && !this.noAccess) {
      this.loadEventDetailFunction()
      this.loadEventsLightDetailFunction()
    }
  },
  created() {
    if (!this.isArchived && !this.isHidden && !this.notFound && !this.noAccess) {
      this.loadEventDetailFunction()
      this.loadEventsLightDetailFunction()
    }
  }
}
</script>

<style scoped lang="scss">
.nested-events, .blocks, .detail {
  margin-bottom: 50px;
}

.slots {
  margin-bottom: 10px;
}

.error-container {
  color: #7C7C7C;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 20vh;
  text-transform: uppercase;
  padding: 0 8vw;
}

.help-text {
  color: #1D71B8;
  text-transform: none;
  text-decoration: underline;
  margin-top: 5px;
  font-size: 15px;
}

.help-text--link {
  color: #1D71B8;
  cursor: pointer;
}
</style>
