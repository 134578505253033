<template>
  <custom-scroll-center-dialog
    @close="close"
    @apply="save"
    :loading="save_loading"
    v-if="opened"
  >
    <div v-if="loading && loading_fields && !save_loading" class="form-text">
      <div v-if="!userIsAuthenticated || preview" class="ml-3">
        <v-text-field
            class="mt-4 mb-0"
            outlined
            v-model="visitor.email"
            :error="hasError('email')"
            :errorMessages="getError('email')"
        >
          <template v-slot:label>
            Email<span class="red--text"> *</span>
          </template>
        </v-text-field>
        <v-text-field
            class="mt-4 mb-0"
            outlined
            v-model="visitor.lastname"
            :error="hasError('lastname')"
            :errorMessages="getError('lastname')"
        >
          <template v-slot:label>
            Фамилия<span class="red--text"> *</span>
          </template>
        </v-text-field>
        <v-text-field
            class="mt-4 mb-0"
            outlined
            v-model="visitor.firstname"
            :error="hasError('firstname')"
            :errorMessages="getError('firstname')"
        >
          <template v-slot:label> Имя<span class="red--text"> *</span></template>
        </v-text-field>
        <v-text-field
            class="mt-4 mb-0"
            outlined
            v-model="visitor.midname"
            :error="hasError('midname')"
            :errorMessages="getError('midname')"
        >
          <template v-slot:label> Отчество</template>
        </v-text-field>
        <v-alert
            class="mb-8"
            type="info"
            color="primary"
        >
          Обязательно проверьте введённые e-mail и ФИО.<br/>Эти данные необходимы для последующего доступа к системе.
        </v-alert>
      </div>
      <VisitorFormFlagField
          v-if="!userIsAuthenticated || preview || needDataAccessConfirmation"
          text="Соглашаюсь с положением об обработке персональных данных"
          sub-str="положением об обработке персональных данных"
          class="mt-4 ml-3"
          :is-required="true"
          :file="'https://www.muctr.ru/sveden/document/?id=28304'"
          :error-message="(getError('is_data_access') ? String(getError('is_data_access')) : undefined)"
          :input-value="visitor.is_data_access"
          @update:inputValue="newVal => visitor.is_data_access = newVal"
          :margin-left="false"
          :no-padding="true"
      ></VisitorFormFlagField>
<!--      <div v-if="isRequiredDataDissemination" class="ml-3">-->
<!--        <v-checkbox-->
<!--            class="py-0 my-0 mt-4 mb-0"-->
<!--            :class="userIsAuthenticated ? ' mt-8 ' : ''"-->
<!--            v-model="visit.is_data_dissemination"-->
<!--            :error="hasError('is_data_dissemination')"-->
<!--            :errorMessages="getError('is_data_dissemination')"-->
<!--        >-->
<!--          <template v-slot:label>-->
<!--            Согласие на распространение персональных данных-->
<!--          </template>-->
<!--        </v-checkbox>-->
<!--      </div>-->
      <div v-if="hasDataFields || (this.previewFields && this.previewFields.length > 0)">
        <div v-for="(field, i) in fields" :key="i">
          <component
            v-if="renderComponent"
            class="mt-4 mb-0"
            :index="i"
            :is="VISITOR_FIELD_COMPONENTS[field.field_type] || STRING_VISITOR_FIELD_COMPONENTS[field.field_type]"
            :file="getFile(field.file)"
            :text="field.settings.text"
            :sub-str="field.settings.sub_str"
            :input-value="visit.visitor_data[i].value"
            :is-required="field.is_required"
            :error-message="field_errors[i]"
            :help-text="field.help_text"
            :name="field.name"
            :settings="field.settings"
            @update:inputValue="newVal => visit.visitor_data[i].value = newVal"
            @updateErrors="newVal => field_errors[i] = newVal"
          ></component>
        </div>
      </div>
    </div>
    <v-skeleton-loader
        v-else
        type="table-heading, list-item-three-line, image, list-item-two-line, table-tfoot"
    ></v-skeleton-loader>
  </custom-scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import { mapActions, mapGetters } from "vuex";
import VISITOR_FIELD_COMPONENTS from "@/helper/visitor_field_components"
import VisitorFormTextField from "@/modules/templates/visitor/custom_forms/VisitorFormTextField.vue";
import VisitorFormDateTimeField from "@/modules/templates/visitor/custom_forms/VisitorFormDateTimeField.vue";
import VisitorFormDateField from "@/modules/templates/visitor/custom_forms/VisitorFormDateField.vue";
import VisitorFormTimeField from "@/modules/templates/visitor/custom_forms/VisitorFormTimeField.vue";
import VisitorFormStringsListField from "@/modules/templates/visitor/custom_forms/VisitorFormStringsListField.vue";
import VisitorFormNumberField from "@/modules/templates/visitor/custom_forms/VisitorFormNumberField.vue";
import VisitorFormFlagField from "@/modules/templates/visitor/custom_forms/VisitorFormFlagField.vue";
import CustomScrollCenterDialog from "@/modules/templates/visitor/custom_forms/CustomScrollCenterDialog.vue";
import visitor from "@/urls/roles/visitor";
import DebugJsonComponent from "@/modules/core/components/DebugJsonComponent.vue";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import STRING_VISITOR_FIELD_COMPONENTS from "@/helper/visitor_string_field_components";
import VisitorFormSelector from "@/modules/templates/visitor/custom_forms/VisitorFormSelector.vue";

export default {
  name: "VisitorVisitEditDialog",
  props: {
    opened: Boolean,
    edit: Boolean,
    visitId: Number,
    hasDataFields: {
      required: false,
      default: false,
      type: Boolean,
    },
    previewFields: {
      required: false,
      default: undefined,
      type: Array,
    },
    preview: {
      required: false,
      type: Boolean,
      default: false,
    },
    eventId: Number,
    isRequiredDataDissemination: Boolean,
    needDataAccessConfirmation: {
      required: false,
      type: Boolean,
      default: false,
    }
  },
  mixins: [FormErrorsMixin],
  components: {
    DebugJsonComponent,
    CustomScrollCenterDialog,
    VisitorFormTextField,
    VisitorFormDateTimeField,
    VisitorFormDateField,
    VisitorFormTimeField,
    VisitorFormStringsListField,
    VisitorFormNumberField,
    VisitorFormFlagField,
    VisitorFormSelector
  },
  data() {
    return {
      loading: false,
      loading_fields: false,
      save_loading: false,
      form_title: "",
      help_title: "Заполните форму записи на событие",
      visit: {
        is_data_access: false,
        is_data_dissemination: false,
        visitor_data: [],
        event: this.eventId,
      },
      visitor: {
        email: "",
        firstname: "",
        midname: "",
        lastname: "",
        is_data_access: false,
      },
      VISITOR_FIELD_COMPONENTS: VISITOR_FIELD_COMPONENTS,
      STRING_VISITOR_FIELD_COMPONENTS: STRING_VISITOR_FIELD_COMPONENTS,
      fields: [],
      field_errors: [],
      renderComponent: true,
    };
  },
  computed: {
    userIsAuthenticated() {
      return this.$cookies.get('userMuctr') || this.$cookies.get('visitor_token');
    },
    ...mapGetters({
      getVisitDetail: "visitor/getVisitsDetail",
      event: 'visitor/getEventsDetail',
      getFieldList: "visitor/getFieldsList",
      store_visitor_token: 'visitor/getVisitorToken',
    }),
  },
  methods: {
    ...mapActions({
      createVisit: "visitor/createVisits",
      loadVisitDetail: "visitor/loadVisitsDetail",
      loadFieldList: "visitor/loadFieldsList",
      loginVisitorViaEvent: "visitor/loginVisitorViaEvent",
    }),
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      })
    },
    close() {
      this.$emit('close');
    },
    save() {
      this.save_loading = true;

      if (this.preview) {
        this.close()
        return
      }

      for (let i = 0; i < this.visit.visitor_data.length; i++) {
        if (this.visit.visitor_data[i].value === "" ||
            (this.visit.visitor_data[i].value && this.visit.visitor_data[i].value.constructor === Array && this.visit.visitor_data[i].value.length === 0) ||
            (this.fields[i].field_type === 9 && this.visit.visitor_data[i].value === -1)
        ) {
          this.visit.visitor_data[i].value = null
        }
      }

      if (this.userIsAuthenticated) {
        this.visit.is_data_access = this.visitor.is_data_access
        delete this.visit.visitor
      } else {
        this.visit.visitor = this.visitor
      }

      if (!this.needDataAccessConfirmation) {
        delete this.visit.is_data_access
      }

      this.removeDataFieldsErrors()

      this.createVisit({
        data: this.visit,
        finalizer: (data) => {
          if (data.token) {
            window.$cookies.set("visitor_token", data.token);
            this.loginVisitorViaEvent({
              token: data.token
            })
          }
          this.save_loading = false;
          this.close();
        },
        catcher: (val) => {
          this.mapErrors(val);
          this.mapFieldsErrors(val.visitor_data)
          this.forceRerender()
          for (let i = 0; i < this.fields.length; i++) {
            if (this.visit.visitor_data[i].value === null && this.fields[i].field_type === 4) this.visit.visitor_data[i].value = []
          }
          this.save_loading = false;
        },
      });
    },
    loadVisit(url = undefined) {
      this.loadVisitDetail({
        url: url,
        finalizer: () => {
          this.visit = this.item;
          this.loading = true;
        },
      });
    },
    removeDataFieldsErrors() {
      for (let i = 0; i < this.field_errors.length; i++) {
          this.field_errors[i] = ""
      }
    },
    mapFieldsErrors(errors) {
      if (!errors) return;
      let keys = Object.keys(errors)
      for (let i = 0; i < keys.length; i++) {
        this.field_errors[Number(keys[i])] = errors[keys[i]][0]
      }
    },
    loadFields() {
      this.loadFieldList ({
        event_id: this.eventId,
        finalizer: (data) => {
          this.loading_fields = true;
          this.fields = data
          this.prepareFieldsData()
        }
      })
    },
    prepareFieldsData() {
      for (let i = 0; i < this.fields.length; i++) {
        let tmp_obj = {}
        tmp_obj.field = this.fields[i].id
        tmp_obj.value = this.fields[i].default
        this.visit.visitor_data.push(tmp_obj)
        this.field_errors.push('');
      }
    },
    getFile(file_obj) {
      if (!file_obj) return null;
      let urls = {
        basename: file_obj.name,
        action: file_obj.action,
        params: file_obj.params,
      }

      return visitor.getByRecievedUrlData(urls, file_obj.get_params)
    },
    loadLightDetail() {

    },
  },
  created() {
    if (this.hasDataFields && !this.preview) {
      this.loadFields()
    } else {
      if (this.preview) {
        this.fields = this.previewFields
        this.prepareFieldsData()
      }
      this.loading_fields = true;
    }

    this.loadLightDetail()

    if (this.edit && !this.preview) {
      this.loadVisit();
    } else {
      this.loading = true;
    }
    this.opened = true
  },
};
</script>

<style scoped>
.form-text {
  font-family: "MADE Evolve Sans normal", serif;
  font-style: normal;
  font-weight: 400;
}
</style>
