<template>
  <v-autocomplete
      ref="input"
      clearable
      :outlined="outlined"
      :placeholder="placeholder"
      :error="error"
      :error-messages="errorMessages"
      :label="label"
      :item-text="itemText"
      :item-value="itemValue"
      :value="value"
      :disabled="disabled"
      :readonly="state.readonly || readonly || initiated"
      @input="emitInput($event)"
      :loading="state.loading || loading"
      :items="list"
      :search-input.sync="search"
      :filter="filter"
      :hint="hint"
      :persistent-hint="persistentHint"
      :return-object="returnObject"
      @keydown.enter.prevent="searchObjects(false)"
      @update:search-input="searchObjects()"
      @click:clear="clear"
  >
    <template v-slot:no-data>
      <slot name="no-data">
        <div class="px-2">
          Для поиска начните набирать текст, <br/> и либо остановитесь на 1 секунду, либо нажмите Enter
        </div>
      </slot>
    </template>
    <template v-slot:label>
      <slot name="label">
        {{ label }} <span v-if="required" class="red--text">*</span>
      </slot>
    </template>
    <template v-slot:prepend-item>
      <slot name="prepend-item"></slot>
    </template>
    <template v-slot:append-item>
      <slot name="append-item"></slot>
    </template>
  </v-autocomplete>
</template>

<script>
import {addGetParameters, sendGetRequest} from "@/helper/requests";
import {generateListUidToName} from "@/helper/uidToName";

export default {
  name: "LoadedAutocomplete",
  props: {
    error: {
      default: false,
    },
    errorMessages: {
      default: '',
    },
    outlined: {
      default: false,
    },
    value: {
      required: true,
    },
    label: {
      default: '',
    },
    hint: {
      required: false,
      hint: '',
    },
    disabled: {
      default: false,
    },
    persistentHint: {
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    itemText: {
      default: 'text',
    },
    itemValue: {
      default: 'value',
    },
    placeholder: {
      default: 'Начните вводить текст',
    },
    url: {
      required: true,
      type: [String, Function]
    },
    searchParameter: {
      required: true,
      type: String,
    },
    postLoader: {
      required: false,
      type: Function
    },
    required: {
      required: false,
      default: false,
      type: Boolean
    },
    loadFios: {
      required: false,
      type: Boolean,
      default: true
    },
    searchInput: {
      required: false,
      type: String,
    },
    returnObject: {
      required: false,
      type: Boolean,
    },
    noSizeParameter: {
      default: false,
    },
    staticEventIdParameter: {
      required: false,
      default: undefined
    }

  },
  data: function () {
    let list = [];
    if (this.returnObject){
      list.push(this.value)
    } else {
      if(this.value)
        list.push({
          [`${this.getText(this.value)}`]: this.getValue(this.value)
        })
    }
    return {
      state: {
        loading: false,
        readonly: false,
      },
      list: !!this.value ? list : [],
      search: !!this.searchInput ? this.searchInput: '',
      timeout: null,
      selected: !!this.value ? this.value: null,
      initiated: !!this.value,
      watch_change: false,
    }
  },
  watch: {
    searchInput(nV, oV){
      if (nV !== oV && this.search !== this.searchInput && !this.watch_change) {
        this.search = this.searchInput;
        this.searchObjects(false)
      } else {
        this.watch_change = false;
      }
    },
    search(nV, oV){
      if (nV !== oV && this.search !== this.searchInput) {
        this.watch_change = true;
        this.$emit('update:searchInput', this.search);
      }
    },
  },
  methods: {
    filter(item, queryText, itemText){
      return true
    },
    clear() {
      this.list = [];
      if(this.initiated) {
        this.emitInput(null)
        this.initiated = false;
      }
    },
    focus() {
      // this.$refs.input.focus();
      this.$refs.input.focus();
      this.$refs.input.isMenuActive = true;
    },
    validate() {
      return true
    },
    emitInput(event) {
      this.selected = event;
      this.$emit('input', event)
    },
    getText(element){
      if (typeof this.itemText === 'function') {
        return this.itemText(element)
      } else if (this.itemText) {
        return element[this.itemText]
      } else {
        return element.text
      }
    },
    getValue(element){
      if(this.returnObject)
        return element;
      else if(this.itemValue !== undefined)
        return element[this.itemValue];
      else
        return element.value;
    },
    searchObjects(timeout=true){
      if(this.list.findIndex(el => this.getValue(el) === this.selected) !== -1)
        return;
      if(this.timeout !== null)
        clearTimeout(this.timeout);
      if(this.list.length === 1 && this.getValue(this.list[0]) === this.value)
        return;
      if(!(this.list.length === 1 && this.getText(this.list[0]).includes(this.search))) {
        if (this.search) {
          if (timeout) {
            this.timeout = setTimeout(
                () => {
                  this.loadWhenSearch();
                },
                1000
            )
          } else{
            this.loadWhenSearch();
          }
        }
        if (this.list.length > 0 && this.search) {
          this.list = []
        }
      }
    },
    setLoading(){
      this.state.loading = true;
      this.state.readonly = true;
    },
    setNotLoading(){
      this.state.loading = false;
      this.state.readonly = false;
    },
    loadWhenSearch(){
      this.setLoading();
      let parameters = {};
      let url;

      if (!this.noSizeParameter) {
        parameters.size = 25;
      }
      if (this.staticEventIdParameter) {
        parameters.event_id = this.staticEventIdParameter
      }
      parameters[`${this.searchParameter}`] = this.search
      url = addGetParameters(
          typeof this.url === 'function' ? this.url() : this.url,
          parameters
      )

      sendGetRequest(url,
          data => {
            if(this.postLoader){
              this.postLoader(
                  data,
                  (vals) => {
                    this.list = vals
                    return this.list
                  },
                  () => {
                    this.setNotLoading();
                  }
              );
            } else{
              let vals = data;
              if(data.hasOwnProperty('results'))
                vals = data.results
              if (this.loadFios) {
                vals.forEach(el => {
                  this.$set(el, 'fio', '')
                  let text = el.text.split(', ');
                  this.$set(el, 'user', text[0])
                  if (text.length > 1)
                    this.$set(el, 'text', text[1])
                  else {
                    this.$set(el, 'text', '')
                  }
                })
              }
              this.list = vals;
              if (this.loadFios) {
                generateListUidToName(
                    this.list,
                    "user",
                    "fio",
                    () => {
                      this.list.forEach(el => {
                        if (el.text !== '')
                          this.$set(el, 'text', `${el.fio}, ${el.text}`)
                        else
                          this.$set(el, 'text', `${el.fio}`)
                      })
                      this.setNotLoading();
                    }
                )
              } else {
                this.setNotLoading();
              }
            }
          }
      );
    },
  },
}
</script>

<style scoped>

</style>
