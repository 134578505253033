<template>
  <tr
      v-if="$vuetify.breakpoint.lgAndUp"
      class="table-row"
      :class="bgColorIndex === 0 ? 'element--white' : 'element--grey'"
  >
    <td class="table-col table-col__name">
      <div class="event-name">
        {{ item.name }}
      </div>
    </td>
    <td class="table-col table-col__detail">
      <div class="flex-container--row-center">
        <div
            class="button button__detail"
            @click="getChild()"
        >
          Подробнее
        </div>
      </div>
    </td>
    <td class="table-col table-col__date">
      <div class="event-date">
        {{ displayDate(item.start) }}
      </div>
    </td>
    <td class="table-col table-col__date table-col__date--end">
      <div class="date">
        {{ displayDate(item.end) }}
      </div>
    </td>
  </tr>
  <div v-else :class="bgColorIndex === 0 ? 'element--white' : 'element--grey'" class="container">
    <div class="card__name">
      <div class="name" @click="getChild()">
        {{ item.name }}
      </div>
    </div>
    <div class="card__divider"></div>
    <v-row class="card__dates">
      <div class="date">
        <div class="date-title">
          Дата начала события
        </div>
        {{ displayDate(item.start) }}
      </div>
      <div class="date">
        <div class="date-title">
          Дата конца события
        </div>
        {{ displayDate(item.end) }}
      </div>
    </v-row>
  </div>
</template>

<script>
import {displayDate} from "@/helper";
import names from "@/modules/visitor/routers/names";

export default {
  name: "SingleNestedEvent",
  props: {
    item: Object,
    bgColorIndex: Number,
    preview: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  watch: {
    '$route.params': function() {
      this.$emit('reloadEventData')
    }
  },
  methods: {
    displayDate: displayDate,
    getChild() {
      if (this.preview) return;

      this.$router.push({name: names.EVENTS.DETAIL, params: {idEvent: this.item.slug}})
    },
  },
  beforeRouteUpdate(to, from, next) {
    next()
  },
  created() {

  }
}
</script>

<style scoped lang="scss">
.table-col {
  padding: 10px 0;
}
.table-col__name {
  text-align: left;
  width: 40%;
  padding-left: 2%
}

.table-col__detail {
  width: 20%;
}

.table-col__date {
  width: 20%;
}

.table-col__date--end {
  padding-right: 2%
}

.flex-container--row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.element--white {
  background-color: white;
}

.element--grey {
  background-color: #F6F6F6;
}

.event-name {
  display:-webkit-box;
  -webkit-line-clamp:2;
  -webkit-box-orient:vertical;
  overflow: hidden;
  color: #1D71B8;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.event-date {
  color: #676767;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  border-radius: 45px;
  max-width: 250px;
  width: 174px;
  border: 1px solid #1D71B8;
  color: #1D71B8;
  height: 40px;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-transform: none;
}

.button__detail {
  cursor: pointer;
  &:hover, &:active {
    color: white;
    background: #1D71B8;
  }
}

.card__name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40px;
  margin-bottom: 12px;
  @media (min-width: 425px) and (max-width: 599px) {
    margin-bottom: 16px;
  }
}

.card__divider {
  height: 2px;
  background: #CECECE;
  margin-bottom: 25px;
}

.card__dates {
  margin: 0 0 25px 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  text-align: left;
  @media (max-width: 599px)  {
    justify-content: space-around;
    text-align: center;
  }
}

.name {
  display:-webkit-box;
  -webkit-line-clamp:2;
  -webkit-box-orient:vertical;
  overflow: hidden;
  color: #1D71B8;
  text-decoration: underline;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  @media (min-width: 425px) and (max-width: 599px) {
    font-size: 21px;
  }
}

.date {
  color: #404040;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (min-width: 425px) and (max-width: 599px) {
    font-size: 18px;
  }
}

.date-title {
  color: #7C7C7C;
  font-size: 12px;
  margin-bottom: 8px;
  @media (max-width: 325px)  {
    font-size: 11px;
  }
  @media (min-width: 425px) and (max-width: 599px) {
    font-size: 15px;
  }
}
</style>
