<template>
  <div :class="leftMenuOpened ? 'shadow--left-menu-opened' : ''" class="shadow">
    <v-card
        class="window rounded-lg elevation-0"
        :color="'rgba(0, 0, 0, 0)'"
        :width="$vuetify.breakpoint.xsOnly ? '90vw' : '500px'"
    >
      <v-container class="container px-6 py-0">
        <v-container class="header ma-0 px-0">
          {{ headerText }}
        </v-container>
        <div class="divider"></div>
        <v-container class="main ma-0">
          {{ questionText }}
        </v-container>
        <v-container class="footer ma-0 px-0">
          <v-btn
              class="footer__save"
              :color="applyColor"
              text
              @click="$emit('close', true)"
          >
            {{ applyText }}
          </v-btn>
          <v-btn
              class="footer__cancel"
              :color="cancelColor"
              text
              @click="$emit('close', false)"
          >
            {{ cancelText }}
          </v-btn>
        </v-container>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CustomApplyDialog",
  props: {
    headerText: {
      type: String,
      default: 'Подтвердите действие',
      required: false,
    },
    questionText: {
      type: String,
      default: 'Вы уверены?',
      required: false,
    },
    cancelText: {
      type: String,
      default: 'Отменить',
      required: false,
    },
    applyText: {
      type: String,
      default: 'Подтвердить',
      required: false,
    },
    cancelColor: {
      type: String,
      default: '#404040',
      required: false,
    },
    applyColor: {
      type: String,
      default: '#1D71B8',
      required: false,
    }
  },
  data(){
    return {

    }
  },
  computed: {
    ...mapGetters({
      leftMenuOpened: 'leftmenu/getLeftMenuShow'
    })
  },
}
</script>

<style scoped lang="scss">
.shadow {
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.35);
  position: absolute;
  top: -60px;
  left: 0;
  z-index: 200;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.shadow--left-menu-opened {
  left: -300px;
}
.window {
  background: rgba(0, 0, 0, 0.0);
  border-radius: 11px;
  color: #404040;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 16px!important;
  font-style: normal;
  font-weight: 400;
  @media (max-width: 500px) {
    font-size: 14px!important;
  }
}

.container {
  background: white;
}

.header {
  @media (max-width: 500px) {
    font-size: 16px!important;
  }
}

.divider {
  height: 2px;
  background: #CECECE;
}

.main {
  color: #7C7C7C;
}

.footer {
  display: flex;
  justify-content: center;
  border-radius: 0 0 11px 11px;
}

.v-btn {
  color: #1D71B8;
  text-transform: uppercase;
  @media (max-width: 350px) {
    font-size: 12px!important;
  }
}

.footer__save {
  margin-right: 17px;
  @media (max-width: 420px) {
    margin-right: 10px;
  }
  @media (max-width: 380px) {
    margin-right: 0;
  }
}

.footer__cancel {
  margin-left: 17px;
  @media (max-width: 420px) {
    margin-left: 10px;
  }
  @media (max-width: 380px) {
    margin-left: 0;
  }
}
</style>
