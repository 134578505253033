const VIEWER= {
    BASE: 'Viewer',
    MAIN: undefined,
    EVENTS: {
        _BASE: undefined,
        LIST: undefined,
        DETAIL: undefined,
        PREVIEW: {
            _BASE: undefined,
            MAIN: undefined,
        },
        INTERVALS: {
            _BASE: undefined,
            LIST: undefined,
            DETAIL: undefined,
        },
        SLOTS: {
            _BASE: undefined,
            LIST: undefined,
            DETAIL: undefined,
        },
        VISITS: {
            _BASE: undefined,
            LIST: undefined,
            DETAIL: undefined,
        },
        INVITES: {
            _BASE: undefined,
            LIST: undefined,
            DETAIL: undefined,
        },
        BLOCKS: {
            _BASE: undefined,
            LIST: undefined,
            DETAIL: undefined,
        },
    },
    VISITORS: {
        _BASE: undefined,
        LIST: undefined,
        DETAIL: undefined,
    },
};

VIEWER.MAIN = `${VIEWER.BASE}|Main`

VIEWER.EVENTS._BASE = `${VIEWER.BASE}|Events`
VIEWER.EVENTS.LIST = `${VIEWER.EVENTS._BASE}|List`
VIEWER.EVENTS.DETAIL = `${VIEWER.EVENTS._BASE}|Detail`

VIEWER.EVENTS.PREVIEW._BASE = `${VIEWER.EVENTS.DETAIL}|Preview`
VIEWER.EVENTS.PREVIEW.MAIN = `${VIEWER.EVENTS.PREVIEW._BASE}|Main`

VIEWER.EVENTS.INTERVALS._BASE = `${VIEWER.EVENTS.DETAIL}|Intervals`
VIEWER.EVENTS.INTERVALS.LIST = `${VIEWER.EVENTS.INTERVALS._BASE}|List`
VIEWER.EVENTS.INTERVALS.DETAIL = `${VIEWER.EVENTS.INTERVALS._BASE}|Detail`

VIEWER.EVENTS.SLOTS._BASE = `${VIEWER.EVENTS.DETAIL}|Slots`
VIEWER.EVENTS.SLOTS.LIST = `${VIEWER.EVENTS.SLOTS._BASE}|List`
VIEWER.EVENTS.SLOTS.DETAIL = `${VIEWER.EVENTS.SLOTS._BASE}|Detail`

VIEWER.EVENTS.VISITS._BASE = `${VIEWER.EVENTS.DETAIL}|Visits`
VIEWER.EVENTS.VISITS.LIST = `${VIEWER.EVENTS.VISITS._BASE}|List`
VIEWER.EVENTS.VISITS.DETAIL = `${VIEWER.EVENTS.VISITS._BASE}|Detail`

VIEWER.EVENTS.INVITES._BASE = `${VIEWER.EVENTS.DETAIL}|Invites`
VIEWER.EVENTS.INVITES.LIST = `${VIEWER.EVENTS.INVITES._BASE}|List`
VIEWER.EVENTS.INVITES.DETAIL = `${VIEWER.EVENTS.INVITES._BASE}|Detail`

VIEWER.EVENTS.BLOCKS._BASE = `${VIEWER.EVENTS.DETAIL}|Blocks`
VIEWER.EVENTS.BLOCKS.LIST = `${VIEWER.EVENTS.BLOCKS._BASE}|List`
VIEWER.EVENTS.BLOCKS.DETAIL = `${VIEWER.EVENTS.BLOCKS._BASE}|Detail`

VIEWER.VISITORS._BASE = `${VIEWER.BASE}|Visitors`
VIEWER.VISITORS.LIST = `${VIEWER.VISITORS._BASE}|List`
VIEWER.VISITORS.DETAIL = `${VIEWER.VISITORS._BASE}|Detail`

export default {
    ...VIEWER
}