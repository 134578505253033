<template>
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="cancel">
      <path id="Vector" d="M14.4375 29.4L21 22.8375L27.5625 29.4L29.4 27.5625L22.8375 21L29.4 14.4375L27.5625 12.6L21 19.1625L14.4375 12.6L12.6 14.4375L19.1625 21L12.6 27.5625L14.4375 29.4ZM21 38.5C18.6083 38.5 16.3479 38.0406 14.2188 37.1219C12.0896 36.2031 10.2302 34.949 8.64062 33.3594C7.05104 31.7698 5.79688 29.9104 4.87812 27.7812C3.95937 25.6521 3.5 23.3917 3.5 21C3.5 18.5792 3.95937 16.3042 4.87812 14.175C5.79688 12.0458 7.05104 10.1937 8.64062 8.61875C10.2302 7.04375 12.0896 5.79688 14.2188 4.87812C16.3479 3.95937 18.6083 3.5 21 3.5C23.4208 3.5 25.6958 3.95937 27.825 4.87812C29.9542 5.79688 31.8062 7.04375 33.3813 8.61875C34.9562 10.1937 36.2031 12.0458 37.1219 14.175C38.0406 16.3042 38.5 18.5792 38.5 21C38.5 23.3917 38.0406 25.6521 37.1219 27.7812C36.2031 29.9104 34.9562 31.7698 33.3813 33.3594C31.8062 34.949 29.9542 36.2031 27.825 37.1219C25.6958 38.0406 23.4208 38.5 21 38.5ZM21 35.875C25.1417 35.875 28.6562 34.424 31.5437 31.5219C34.4312 28.6198 35.875 25.1125 35.875 21C35.875 16.8583 34.4312 13.3438 31.5437 10.4562C28.6562 7.56875 25.1417 6.125 21 6.125C16.8875 6.125 13.3802 7.56875 10.4781 10.4562C7.57604 13.3438 6.125 16.8583 6.125 21C6.125 25.1125 7.57604 28.6198 10.4781 31.5219C13.3802 34.424 16.8875 35.875 21 35.875Z" fill="#BCBCBC"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "CancelFalse"
}
</script>

<style scoped>

</style>
