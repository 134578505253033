const ADMIN= {
    BASE: 'Admin',
    MAIN: undefined,
    ROLES: {
        _BASE: undefined,
        LIST: undefined,
        DETAIL: undefined,
    },
    EVENTS: {
        _BASE: undefined,
        LIST: undefined,
        DETAIL: undefined,
        PREVIEW: {
            _BASE: undefined,
            MAIN: undefined,
        },
        INTERVALS: {
            _BASE: undefined,
            LIST: undefined,
            DETAIL: undefined,
        },
        SLOTS: {
            _BASE: undefined,
            LIST: undefined,
            DETAIL: undefined,
        },
        VISITS: {
            _BASE: undefined,
            LIST: undefined,
            DETAIL: undefined,
        },
        INVITES: {
            _BASE: undefined,
            LIST: undefined,
            DETAIL: undefined,
        },
        BLOCKS: {
            _BASE: undefined,
            LIST: undefined,
            DETAIL: undefined,
        },
    },
    VISITORS: {
        _BASE: undefined,
        LIST: undefined,
        DETAIL: undefined,
    },
    FIND_VISITS:{
        _BASE: undefined,
        LIST: undefined,
    }
};

ADMIN.MAIN = `${ADMIN.BASE}|Main`

ADMIN.ROLES._BASE = `${ADMIN.BASE}|Roles`
ADMIN.ROLES.LIST = `${ADMIN.ROLES._BASE}|List`
ADMIN.ROLES.DETAIL = `${ADMIN.ROLES._BASE}|Detail`

ADMIN.EVENTS._BASE = `${ADMIN.BASE}|Events`
ADMIN.EVENTS.LIST = `${ADMIN.EVENTS._BASE}|List`
ADMIN.EVENTS.DETAIL = `${ADMIN.EVENTS._BASE}|Detail`

ADMIN.EVENTS.PREVIEW._BASE = `${ADMIN.EVENTS.DETAIL}|Preview`
ADMIN.EVENTS.PREVIEW.MAIN = `${ADMIN.EVENTS.PREVIEW._BASE}|Main`

ADMIN.EVENTS.INTERVALS._BASE = `${ADMIN.EVENTS.DETAIL}|Intervals`
ADMIN.EVENTS.INTERVALS.LIST = `${ADMIN.EVENTS.INTERVALS._BASE}|List`
ADMIN.EVENTS.INTERVALS.DETAIL = `${ADMIN.EVENTS.INTERVALS._BASE}|Detail`

ADMIN.EVENTS.SLOTS._BASE = `${ADMIN.EVENTS.DETAIL}|Slots`
ADMIN.EVENTS.SLOTS.LIST = `${ADMIN.EVENTS.SLOTS._BASE}|List`
ADMIN.EVENTS.SLOTS.DETAIL = `${ADMIN.EVENTS.SLOTS._BASE}|Detail`

ADMIN.EVENTS.VISITS._BASE = `${ADMIN.EVENTS.DETAIL}|Visits`
ADMIN.EVENTS.VISITS.LIST = `${ADMIN.EVENTS.VISITS._BASE}|List`
ADMIN.EVENTS.VISITS.DETAIL = `${ADMIN.EVENTS.VISITS._BASE}|Detail`

ADMIN.EVENTS.INVITES._BASE = `${ADMIN.EVENTS.DETAIL}|Invites`
ADMIN.EVENTS.INVITES.LIST = `${ADMIN.EVENTS.INVITES._BASE}|List`
ADMIN.EVENTS.INVITES.DETAIL = `${ADMIN.EVENTS.INVITES._BASE}|Detail`

ADMIN.EVENTS.BLOCKS._BASE = `${ADMIN.EVENTS.DETAIL}|Blocks`
ADMIN.EVENTS.BLOCKS.LIST = `${ADMIN.EVENTS.BLOCKS._BASE}|List`
ADMIN.EVENTS.BLOCKS.DETAIL = `${ADMIN.EVENTS.BLOCKS._BASE}|Detail`

ADMIN.VISITORS._BASE = `${ADMIN.BASE}|Visitors`
ADMIN.VISITORS.LIST = `${ADMIN.VISITORS._BASE}|List`
ADMIN.VISITORS.DETAIL = `${ADMIN.VISITORS._BASE}|Detail`

ADMIN.FIND_VISITS._BASE = `${ADMIN.BASE}|FindVisits`
ADMIN.FIND_VISITS.LIST = `${ADMIN.FIND_VISITS._BASE}|List`

export default {
    ...ADMIN
}
