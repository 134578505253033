import { render, staticRenderFns } from "./SpacerBlock.vue?vue&type=template&id=13ab3c7d&scoped=true"
import script from "./SpacerBlock.vue?vue&type=script&lang=js"
export * from "./SpacerBlock.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13ab3c7d",
  null
  
)

export default component.exports