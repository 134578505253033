<template>
  <div>
    <v-toolbar-items v-if="editMode" :class="editMode ? 'mb-5' : ''">
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="blockType === 5 || blockType === 7">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              class="dark-primary ml-1 mr-0"
              @click="$emit('addEmptyEntry', blockId)"
              v-bind="attrs"
              v-on="on"
          >
            add
          </v-icon>
        </template>
        <span>Добавить пустую запись</span>
      </v-tooltip>
      <v-tooltip bottom v-if="!(blockType === 1 || blockType === 2)">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              class="dark-primary mx-1 rotate-icon-90"
              @click="$emit('extendBlock', blockId)"
              v-bind="attrs"
              v-on="on"
          >
            unfold_more
          </v-icon>
        </template>
        <span>Расширить блок</span>
      </v-tooltip>
      <v-tooltip bottom v-if="!(blockType === 1 || blockType === 2)">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              class="dark-primary mx-1 rotate-icon-90"
              @click="$emit('compressBlock', blockId)"
              v-bind="attrs"
              v-on="on"
          >
            unfold_less
          </v-icon>
        </template>
        <span>Сузить блок</span>
      </v-tooltip>
      <v-tooltip bottom v-if="!(blockType === 1 || blockType === 2) && movingLeftAllowed">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              class="dark-primary mx-1"
              @click="$emit('moveBlockLeft', blockId)"
              v-bind="attrs"
              v-on="on"
          >
            arrow_back
          </v-icon>
        </template>
        <span>Сдвинуть блок влево</span>
      </v-tooltip>
      <v-tooltip bottom v-if="!(blockType === 1 || blockType === 2) && movingRightAllowed">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="dark-primary mx-1"
            @click="$emit('moveBlockRight', blockId)"
            v-bind="attrs"
            v-on="on"
          >
            arrow_forward
          </v-icon>
        </template>
        <span>Сдвинуть блок вправо</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              class="dark-primary ml-1 mr-0"
              @click="$emit('deleteBlock', blockId)"
              v-bind="attrs"
              v-on="on"
          >
            delete
          </v-icon>
        </template>
        <span>Удалить блок</span>
      </v-tooltip>
    </v-toolbar-items>
    <slot name="block"></slot>
  </div>
</template>

<script>
export default {
  props: {
    editMode: Boolean,
    blockId: {},
    blockType: Number,
    movingLeftAllowed: Boolean,
    movingRightAllowed: Boolean,
  },
  name: "BlockButtonsWrapper"
}
</script>

<style scoped>
.rotate-icon-90 {
  transform: rotate(90deg);
}
</style>
