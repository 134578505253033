<template>
  <scroll-center-dialog v-model="opened" :loading="save_loading" width="760" @close="close" @apply="save" :title="form_title" :scrolled="false">
    <div v-if="loading">
      <v-row class="pa-0 ma-0 mt-4">
        <v-col cols="12" sm="6" class="pa-0 ma-0">
          <v-text-field
              outlined
              type="date"
              v-model="interval.start"
              :class="$vuetify.breakpoint.smAndUp ? 'mr-2' : ''"
              :error="hasError('start')"
              :errorMessages="getError('start')"
          >
            <template v-slot:label>
              Начало интервала<span class="red--text"> *</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="pa-0 ma-0">
          <v-text-field
              outlined
              type="date"
              v-model="interval.end"
              :class="$vuetify.breakpoint.smAndUp ? 'ml-2' : ''"
              :error="hasError('end')"
              :errorMessages="getError('end')"
          >
            <template v-slot:label>
              Окончание интервала<span class="red--text"> *</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" class="pa-0 ma-0">
          <v-checkbox
              class="py-0 my-0"
              label="Исключающий интервал"
              v-model="interval.is_exclusive"
              :error="hasError('is_exclusive')"
              :errorMessages="getError('is_exclusive')"
          ></v-checkbox>
        </v-col>
        <v-col cols="12">
          <v-alert
              v-show="hasError('event')"
              color="red"
              class="elevation-2"
              type="error"
          >{{ String(getError('event')) }}</v-alert>
        </v-col>
      </v-row>
    </div>
  </scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import ScrollCenterDialog from "@/modules/templates/ScrollCenterDialog.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "OrganiserIntervalsEditDialog",
  props: {
    opened: Boolean,
    edit: Boolean,
    intervalId: Number,
  },
  mixins: [FormErrorsMixin],
  components: {
    ScrollCenterDialog,
  },
  data() {
    return {
      loading: false,
      save_loading: false,
      interval: {
        id: null,
        start: null,
        end: null,
        is_exclusive: false,
        event: null,
      }
    }
  },
  computed: {
    form_title() {
      return !this.edit ? 'Добавить интервал' : 'Редактировать интервал'
    },
    ...mapGetters({
      item: 'organiser/getIntervalsDetail'
    }),
  },
  methods: {
    ...mapActions({
      createInterval: 'organiser/createIntervals',
      changeInterval: 'organiser/changeIntervals',
      loadIntervalDetail: 'organiser/loadIntervalDetail',
    }),
    close() {
      this.$emit('close', false)
    },
    save() {
      this.save_loading = true;
      let data = {
        is_exclusive: this.interval.is_exclusive,
        start: this.interval.start,
        end: this.interval.end,
      }
      if (this.edit) {
        this.changeIntervalFunction(data);
      }
      else {
        this.createIntervalFunction(data);
      }
    },
    changeIntervalFunction(data) {
      this.changeInterval({
        event_id: this.$route.params.idEvent,
        id: this.intervalId,
        data: data,
        finalizer: () => {
          this.save_loading = false;
          this.close();
        },
        catcher: (val) => {
          this.mapErrors(val)
          this.save_loading = false
        },
      })
    },
    createIntervalFunction(data) {
      this.createInterval({
        event_id: this.$route.params.idEvent,
        data: data,
        finalizer: () => {
          this.save_loading = false;
          this.close();
        },
        catcher: (val) => {
          this.mapErrors(val)
          this.save_loading = false
        },
      })
    },
    loadData(url = undefined) {
      this.loadIntervalDetail({
        url: url,
        event_id: this.$route.params.idEvent,
        id: this.intervalId,
        finalizer: () => {
          this.interval = this.item;
          this.loading = true;
        }
      })
    }
  },
  created() {
    if (this.edit) {
      this.loadData();
    }
    else {
      this.loading = true;
    }
  }
}
</script>

<style scoped>

</style>
