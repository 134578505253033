import {sendPostRequest, sendPatchRequest, sendDeleteRequest, sendGetRequest, sendPutRequest} from "@/helper/requests";
import admin from "@/urls/roles/admin";
import {executeRequest} from "@/store/utils";

const state = {
    visitors:{
        currentUrl: null,
        items: [],
        loading: false,
        count: 0,
        detail: [],
        error: null,
    }
}

const getters = {
    getVisitorsList: state => state.visitors.items,
    getVisitorsCount: state => state.visitors.count,
    getVisitorsCurrentUrl: state => state.visitors.currentUrl,
    getVisitorsDetail: state => state.visitors.detail,
    isLoadingVisitors: state => state.visitors.loading,
    saveVisitorsError: state => state.visitors.error,
}

const actions = {
    loadVisitorsList: ({commit, getters}, {url, urlAppend, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) {
            url = admin.ADMIN.VISITORS.LIST()
            if (urlAppend) url = urlAppend(url)
        }
        if (!getters.isLoadingVisitors){
            commit('SET_VISITORS_LOADING');
            sendGetRequest(
                url, (data, url) => {
                    commit('SET_VISITORS_LIST', data['results']);
                    commit('SET_VISITORS_COUNT', data['count']);
                    commit('SET_VISITORS_CURRENT_URL', url);
                    commit('SET_VISITORS_LOADING_END');
                    finalizer(data, url);
                }, (data, url) => {
                    commit('SET_VISITORS_LOADING_END');
                    commit('SAVE_VISITORS_ERROR', data);
                    catcher(data, url);
                }
            )
        }
    },
    loadVisitorsDetail: ({commit}, {url, id, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) url = admin.ADMIN.VISITORS.DETAIL(id)
        sendGetRequest(
            url, (data, url) => {
                commit('SET_VISITORS_DETAIL', data);
                finalizer(data, url);
            }, (data, url) => {
                commit('SAVE_VISITORS_ERROR', data);
                catcher(data, url);
            }
        )
    },
    createVisitors: ({dispatch, commit, getters}, {data, finalizer=()=>{}, catcher=()=>{}}) => {
        let url = admin.ADMIN.VISITORS.CREATE()
        executeRequest('Roles', sendPostRequest, {url, data, finalizer, catcher}, {dispatch, commit, getters});
    },
    changeVisitors: ({dispatch, commit, getters}, {data, id, finalizer,catcher}) => {
        let url = admin.ADMIN.VISITORS.UPDATE(id)
        executeRequest('Roles', sendPatchRequest, {url, data, finalizer, catcher}, {dispatch, commit, getters});
    },
    deleteVisitors: ({dispatch, commit, getters}, {id, finalizer, catcher}) => {
        let url = admin.ADMIN.VISITORS.DELETE(id)
        executeRequest('Roles', sendDeleteRequest, {url, finalizer, catcher}, {dispatch, commit, getters});
    },
}

const mutations = {
    SET_VISITORS_LIST: (state, visitors) => {
        state.visitors.items = visitors
    },
    SET_VISITORS_DETAIL: (state, value) => {
        state.visitors.detail = value
    },
    SET_VISITORS_COUNT: (state, value) => {
        state.visitors.count = value
    },
    SET_VISITORS_CURRENT_URL: (state, value) => {
        state.visitors.currentUrl = value
    },
    SET_VISITORS_LOADING: (state) => {
        state.visitors.loading = true
    },
    SET_VISITORS_LOADING_END: (state) => {
        state.visitors.loading = false
    },
    SAVE_VISITORS_ERROR: (state, error) => {
        state.visitors.error = error
    },
}


export default {
    namespaced: false,
    state,
    mutations,
    actions,
    getters
}
