<template>
  <div>
    <v-col cols="12">
      <v-row
          class="mx-1 text-h6 font-weight-bold mt-2 mb-1"
          :class="($vuetify.breakpoint.smAndUp ? '' : ' justify-center')"
      >
        Посещения и записи
      </v-row>
    </v-col>
    <v-data-table
        class="mx-4"
        :item-class="getExpandedItemClass"
        :headers="headers"
        :items="visits"
        :loading="isLoadingVisits"
        :page.sync="pagination.page"
        :expanded.sync="expanded"
        show-expand
        single-expand
        :items-per-page.sync="pagination.size"
        :options.sync="options"
        :footer-props="{'items-per-page-options':[10,25,50]}"
    >
      <template v-slot:item.event.start="{ item }">
        {{ displayDate(item.event.start) }}
      </template>
      <template v-slot:item.event.end="{ item }">
        {{ displayDate(item.event.end) }}
      </template>
      <template v-slot:item.is_enter="{ item }">
        <v-icon
            :color="checkEnterFlag(item.is_enter).color"
        >
          {{ checkEnterFlag(item.is_enter).icon }}
        </v-icon>
      </template>
      <template v-slot:item.is_cancel="{ item }">
        <v-icon
            :color="checkCancelFlag(item.is_cancel).color"
        >
          {{ checkCancelFlag(item.is_cancel).icon }}
        </v-icon>
      </template>
      <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
        <v-tooltip bottom v-if="!item.is_cancel && !item.is_end && !item.is_enter && !item.has_entered_records">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                class="mr-2"
                @click="cancelDialog(item)"
                v-bind="attrs"
                v-on="on"
            >
              cancel
            </v-icon>
          </template>
          <span>Отменить посещение</span>
        </v-tooltip>
          <v-tooltip bottom v-if="!item.is_cancel && !item.is_enter && !item.is_end">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  class="mr-2"
                  @click="enterVisit(item)"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
              >
                add_task
              </v-icon>
            </template>
            <span>Зафиксировать посещение мероприятия мероприятия</span>
          </v-tooltip>
          <v-tooltip bottom v-if="!item.is_cancel && !item.is_end && item.is_enter && !item.has_entered_records">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  class="mr-2"
                  @click="backVisit(item)"
                  v-bind="attrs"
                  v-on="on"
              >
                undo
              </v-icon>
            </template>
            <span>Отменить фиксацию посещения мероприятия</span>
          </v-tooltip>
          <v-btn
              v-if="item.have_records"
              icon
              @click="expand(!isExpanded)"
              :ref="`expand_button_${item.id}`">
            <v-icon v-if="isExpanded">
              expand_less
            </v-icon>
            <v-icon v-else>
              expand_more
            </v-icon>
          </v-btn>
      </template>
      <template v-slot:expanded-item="{ item }">
        <RecordsList
            :outer-headers-length="headers.length"
            @cancelDialog="cancelDialog"
            @backDialog="backRecordDialog"
            @enterDialog="enterRecordDialog"
            :visit-canceled="item.is_cancel"
            :is-end="item.is_end"
        ></RecordsList>
      </template>
    </v-data-table>
    <ApplyDialog
        v-if="cancel_dialog_active"
        :opened="cancel_dialog_active"
        :get-text="() => dialog_text"
        @close="closeCancelDialog"
    ></ApplyDialog>
    <ApplyDialog
        v-if="enter_dialog_active"
        :opened="enter_dialog_active"
        :get-text="() => `Вы уверены, что хотите зафиксировать посещение слота ?`"
        @close="closeEnterRecordDialog"
    ></ApplyDialog>
    <ApplyDialog
        v-if="dialog_back"
        :opened="dialog_back"
        :get-text="() => `Вы уверены, что хотите отменить фиксацию посещения ?`"
        @close="closeBackDialog"
    ></ApplyDialog>
    <ApplyDialog
        v-if="dialog_record_back"
        :opened="dialog_record_back"
        :get-text="() => `Вы уверены, что хотите отменить фиксацию посещения слота ?`"
        @close="closeBackRecordDialog"
    ></ApplyDialog>
    <ApplyDialog
        v-if="dialog_enter"
        :opened="dialog_enter"
        :get-text="() => `Вы уверены, что хотите зафиксировать посещение мероприятия?`"
        @close="closeEnterDialog"
    ></ApplyDialog>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import formErrorsMixin from "@/mixins/FormErrorsMixin";
import ApplyDialog from "@/modules/core/components/ApplyDialog";
import RecordsList from "@/modules/admin/visitors/components/RecordsList";
import {displayDate} from "@/helper";

export default {
  name: "AdminVisitorsVisitsList",
  components: {RecordsList, ApplyDialog},
  mixins: [PaginatedDataMapperMixin, formErrorsMixin],
  data() {
    return {
      headers: [
        {text: 'Событие', value: 'event.name', sortable: false, align: 'start', width: '35%'},
        {text: 'Дата начала', value: 'event.start', sortable: false, align: 'center', width: '15%'},
        {text: 'Дата окончания', value: 'event.end', sortable: false, align: 'center', width: '15%'},
        {text: 'Посещено', value: 'is_enter', sortable: false, align: 'center', width: '10%'},
        {text: 'Отменено', value: 'is_cancel', sortable: false, align: 'center', width: '10%'},
        {text: 'Действия', value: 'data-table-expand', sortable: false, align: 'center', width: '15%'},
      ],
      expanded: [],
      selected_item: null,
      cancel_dialog_active: false,
      dialog_text: '',
      dialog_enter: false,
      need_reload_records: false,
      enter_dialog_active: false,
      record_id: undefined,
      dialog_back: false,
      dialog_record_back: false
    }
  },
  computed: {
    ...mapGetters({
      visits: 'admin/getVisitsList',
      count: 'admin/getVisitsCount',
      isLoadingVisits: 'admin/isLoadingVisits'
    }),
  },
  watch: {
    expanded: function (newVal) {
      newVal.forEach(el => {
        if (el.id !== undefined) {
          this.loadRecordsData(el.id);
        }
      });
    },
  },
  methods: {
    displayDate: displayDate,
    ...mapActions({
      loadVisitsList: 'admin/loadVisitsList',
      loadRecordsList: 'admin/loadRecordsList',
      cancelVisits: 'admin/cancelVisits',
      cancelRecords: 'admin/cancelRecords',
      enterVisits: 'admin/enterVisits',
      enterRecords: 'admin/enterRecords',
      backVisits: 'admin/rollBackEnterVisits',
      backRecords: 'admin/rollBackEnterRecords'
    }),
    getExpandedItemClass(item) {
      if (this.expanded.length > 0 && this.expanded[0].id === item.id || this.expanded.length === 0) {
        return "white";
      }
      return "grey lighten-3";
    },
    backRecordDialog(item) {
      this.dialog_record_back = true;
      this.selected_item = item;
    },
    closeBackRecordDialog(record) {
      if (record) {
        this.backRecords({
          id: this.selected_item.id,
          finalizer: () => {
            if (this.expanded && this.expanded.length > 0) {
              this.loadRecordsData(this.expanded[0].id)
            }
            this.dialog_record_back = false;
            this.selected_item = undefined;
            this.loadData()
          },
        })
      } else {
        this.dialog_record_back = false;
        this.selected_item = undefined;
      }

    },
    enterVisit(item) {
      this.dialog_enter = true
      this.visit_data = item
    },
    closeEnterDialog(visit) {
      if (visit) {
        this.enterVisits({
          id: this.visit_data.id,
          finalizer: (val) => {
            this.visit_data.is_enter = true
            this.dialog_enter = false
            this.visit_data = undefined
            this.loadData()
          },
        })
      } else {
        this.dialog_enter = false
      }
    },
    checkEnterFlag(flag) {
      if (flag) return {icon: "check_circle", color: "green"}
      else return {icon: "not_interested", color: "grey"}
    },
    checkCancelFlag(flag) {
      if (flag) return {icon: "check_circle", color: "yellow darken-3"}
      else return {icon: "not_interested", color: "grey"}
    },
    loadRecordsData(id) {
      this.loadRecordsList({
        url: undefined,
        urlAppend: (url) => {
          return this.addPageParameter(
              this.addSizeParameter(
                  this.addGetParameter(url, 'visit', id)
              )
          )
        },
        // finalizer: (data, url) => {
          // this.setCurrentLoadUrl(url);
        // }
      })
    },
    cancelVisit() {
      this.cancelVisits({
        id: this.selected_item.id,
        finalizer: () => {
          if (this.expanded && this.expanded.length > 0) {
            this.loadRecordsData(this.expanded[0].id)
          }
          this.loadData();
          this.cancel_dialog_active = false;
          this.selected_item = undefined;
        },
      })
    },
    cancelVisitRecord() {
      this.cancelRecords({
        id: this.selected_item.id,
        finalizer: () => {
          if (this.expanded && this.expanded.length > 0) {
            this.loadRecordsData(this.expanded[0].id)
          }
          this.cancel_dialog_active = false;
          this.selected_item = undefined;
        },
      })
    },
    cancelDialog(item) {
      if (item.date) {
        this.dialog_text = `Вы уверены, что хотите отменить посещение данной записи ?`
      } else {
        this.dialog_text = `Вы уверены, что хотите отменить посещение мероприятия ?`;
      }
      this.cancel_dialog_active = true
      this.selected_item = item
    },
    backVisit(item) {
      this.dialog_back = true
      this.visit_data = item
    },
    closeBackDialog(visit) {
      if (visit) {
        this.backVisits({
          id: this.visit_data.id,
          finalizer: (val) => {
            this.visit_data.is_enter = false
            this.dialog_back = false
            this.visit_data = undefined
            this.loadData()
          },
        })
      } else {
        this.dialog_back = false
      }
    },
    enterRecordDialog(item) {
      this.record_id = item.id;
      this.enter_dialog_active = true;
    },
    closeEnterRecordDialog(enter_applied) {
      if (enter_applied) {
        this.enterRecords({
          id: this.record_id,
          finalizer: () => {
            this.record_id = undefined;
            this.enter_dialog_active = false;
            if (this.expanded && this.expanded.length > 0) {
              this.loadRecordsData(this.expanded[0].id)
            }
            this.loadData()
          },
        })
      } else {
        this.record_id = undefined;
        this.enter_dialog_active = false;
      }
    },
    closeCancelDialog(delete_applied) {
      if (delete_applied) {
        if (this.selected_item.date) {
          this.cancelVisitRecord();
        } else {
          this.cancelVisit();
        }
      } else {
        this.cancel_dialog_active = false;
        this.selected_item = undefined;
      }
    },
    loadData(url = undefined) {
      this.loadVisitsList({
        url: url,
        urlAppend: (url) => {
          return this.addPageParameter(
              this.addSizeParameter(
                  this.addGetParameter(url, 'visitor', this.$route.params.idVisitor)
              )
          )
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
        }
      })
    },
  },
  created() {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
