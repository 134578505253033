<template>
  <scroll-center-dialog v-model="opened" :loading="save_loading" width="760" @close="close" @apply="save"
                        :title="form_title" :scrolled="false">
    <div v-if="loading">
      <v-select
          v-if="!edit"
          label="Тип слота"
          class="dark-primary mt-5"
          outlined
          v-model="slot_type"
          :items="slot_choices"
      ></v-select>
      <v-text-field
          v-if="slot_type === 0"
          outlined
          :class="edit ? 'mt-5' : ''"
          label="Дата слота"
          type="date"
          v-model="slot.date"
          :disabled="formIsRestricted"
          :error="hasError('date')"
          :errorMessages="getError('date')"
      ></v-text-field>
      <v-row class="ma-0 pa-0" :class="edit && (slot_type === 1 || slot_type === 2) ? 'mt-5' : ''">
        <v-col cols="12" sm="6" class="pa-0 ma-0">
          <v-text-field
              outlined
              label="Начало слота"
              type="time"
              v-model="slot.start"
              :class="$vuetify.breakpoint.smAndUp ? 'mr-2' : ''"
              :disabled="formIsRestricted"
              :error="hasError('start')"
              :errorMessages="getError('start')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="pa-0 ma-0">
          <v-text-field
              outlined
              label="Окончание слота"
              type="time"
              v-model="slot.end"
              :class="$vuetify.breakpoint.smAndUp ? 'ml-2' : ''"
              :disabled="formIsRestricted"
              :error="hasError('end')"
              :errorMessages="getError('end')"
          ></v-text-field>
        </v-col>
      </v-row>
      <div v-if="slot_type === 1">
        <v-col cols="12" class="text-h6 pt-0 mt-0" style="white-space: nowrap; text-align: start;">
          Добавьте связанные интервалы
        </v-col>
        <v-data-table
            :items="intervalsItems"
            :headers="headers"
            disable-pagination
            hide-default-footer
            class="elevation-2 mb-5"
        >
          <template
              v-slot:item.fio="{ item }"
          >
            <v-row v-if="item.adding" class="px-3"></v-row>
            <v-row v-else-if="item.editable" class="px-3" :class="item.error ? ' mb-1' : ' mb-0'">
              <loading-autocomplete
                  v-model="item.interval"
                  @input="changeEditableState(item)"
                  :url="intervalsSelector"
                  :error="item.error.length > 0"
                  :error-messages="item.error"
                  :no-size-parameter="true"
                  :return-object="true"
                  :load-fios="false"
                  :static-event-id-parameter="$route.params.idEvent"
                  search-parameter="date"
                  placeholder="Начните вводить даты интервала"
              ></loading-autocomplete>
            </v-row>
            <v-row v-else class="px-3">
              {{ item.interval.text }}
            </v-row>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-row no-gutters justify="space-around" align="center" v-show="item.adding">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      :disabled="formIsRestricted"
                      @click="addTableInterval"
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon>
                      add_circle_outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Добавить пустое поле</span>
              </v-tooltip>
            </v-row>
            <v-row no-gutters justify="space-around" align="center" v-show="!item.adding">
              <v-tooltip bottom >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      :disabled="formIsRestricted"
                      @click="deleteTableInterval(item)"
                      v-bind="attrs"
                      v-on="on"
                  >
                    delete
                  </v-icon>
                </template>
                <span>Удалить поле</span>
              </v-tooltip>
            </v-row>
          </template>
        </v-data-table>
      </div>
      <v-checkbox
          v-if="!haveVisits || edit"
          class="pa-0 ma-0"
          label='Слот "По умолчанию"'
          v-model="slot.is_default"
          :disabled="formIsRestricted"
          :error="hasError('is_default')"
          :errorMessages="getError('is_default')"
      ></v-checkbox>
      <v-text-field
          v-if="!slot.is_default"
          outlined
          label="Максимальное количество человек"
          type="number"
          v-model="slot.count"
          :error="hasError('count')"
          :errorMessages="getError('count')"
      ></v-text-field>
      <v-alert
          v-if="hasError('event')"
          color="red"
          class="elevation-2"
          type="error"
      >{{ String(getError('event')) }}
      </v-alert>
      <v-alert
          v-if="empty_intervals_alert"
          color="red"
          class="elevation-2"
          type="error"
      >{{ empty_intervals_alert }}</v-alert>
    </div>
  </scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import ScrollCenterDialog from "@/modules/templates/ScrollCenterDialog.vue";
import {mapActions, mapGetters} from "vuex";
import selectors from "@/urls/selectors";
import LoadingAutocomplete from "@/modules/core/components/LoadingAutocomplete.vue";
import {displayDate} from "@/helper";

export default {
  name: "AdminSlotsEditDialog",
  props: {
    opened: Boolean,
    edit: Boolean,
    slotId: Number,
    haveVisits: Boolean,
  },
  mixins: [FormErrorsMixin],
  components: {
    ScrollCenterDialog,
    LoadingAutocomplete,
  },
  data() {
    return {
      loading: false,
      save_loading: false,
      slot: {
        id: null,
        date: null,
        start: null,
        intervals: [],
        end: null,
        count: 0,
        is_default: false,
      },
      slot_choices: [
        {text: 'Обычный слот', value: 0},
        {text: 'Интервальный слот', value: 1},
        {text: 'Слот события', value: 2},
      ],
      headers: [
        {text: 'Интервал', value: 'fio', sortable: false, width: '85%', align: 'center'},
        {text: 'Действия', value: 'actions', sortable: false, width: '15%', align: 'center'},
      ],
      slot_type: 0,
      date: '',
      empty_item: {
        id: null,
        interval: null,
        editable: false,
        adding: true,
        error: '',
      },
      interval_items: [],
      empty_intervals_alert: '',
    }
  },
  computed: {
    form_title() {
      return !this.edit ? 'Добавить слот' : 'Редактировать слот'
    },
    intervalsSelector() {
      return selectors.SELECTORS.INTERVALS.ALL()
    },
    intervalsItems() {
      return this.interval_items
    },
    formIsRestricted() {
      return this.slot.is_default && this.slot.have_visits || !this.slot.is_default && this.slot.have_records
    },
    ...mapGetters({
      slotDetail: 'organiser/getSlotsDetail',
    }),
  },
  methods: {
    displayDate: displayDate,
    ...mapActions({
      createSlot: 'organiser/createSlots',
      changeSlot: 'organiser/changeSlots',
      loadSlotsDetail: 'organiser/loadSlotsDetail'
    }),
    changeEditableState(item) {
      item.editable = !item.editable
    },
    checkEmptyFields() {
      let errors_flag = false

      for (let i = 0; i < this.interval_items.length - 1; i++) {
        if (this.interval_items[i].editable) {
          this.interval_items[i].error = 'Нельзя оставлять незаполненные поля';
          errors_flag = true;
        } else {
          this.interval_items[i].error = '';
        }
      }

      if (this.interval_items[0].adding) {
        this.empty_intervals_alert = 'Необходимо добавить хотя бы один интервал'
        errors_flag = true;
      } else {
        this.empty_intervals_alert = ''
      }

      return errors_flag
    },
    addTableInterval() {
      this.interval_items.push(Object.assign({}, this.empty_item))
      this.interval_items.at(-1).index = this.interval_items.length - 1;
      this.interval_items.at(-2).adding = false;
      this.interval_items.at(-2).editable = true;
    },
    deleteTableInterval(item) {
      const index = this.interval_items.indexOf(item)
      this.interval_items.splice(index, 1)

      this.interval_items.forEach((item, index) => {
        item.id = index
      })
    },
    close() {
      this.$emit('close', false)
    },
    save() {
      this.save_loading = true;
      let data = {
        start: this.slot.start,
        end: this.slot.end,
        is_default: this.slot.is_default
      }
      // у слотов по умолчанию нет количества мест
      if (!data.is_default) {
        data.count = this.slot.count
      } else {
        data.count = null
      }
      // у обычных слотов есть дата, но нет интервалов
      if (this.slot_type === 0) {
        data.date = this.slot.date
      }
      // у интервальных слотов нет даты, есть интервалы
      if (this.slot_type === 1) {
        if (this.checkEmptyFields()) {
          this.save_loading = false;
          return
        }
        data.intervals = this.prepareIntervalsData()
      }
      // у слотов длиной в событие нет ни даты, ни интервалов

      if (this.edit) {
        if (this.formIsRestricted) {
          delete data.start;
          delete data.end;
        }
        this.changeSlotFunction(data);
      } else {
        this.createSlotFunction(data);
      }
    },
    prepareIntervalsData() {
      let intervals = []
      for (let i = 0; i < this.interval_items.length - 1; i++) {
        intervals.push(this.interval_items[i].interval.value)
      }
      return intervals
    },
    changeSlotFunction(data) {
      this.changeSlot({
        data: data,
        event_id: this.$route.params.idEvent,
        id: this.slotId,
        finalizer: () => {
          this.save_loading = false;
          this.close();
        },
        catcher: (val) => {
          this.mapErrors(val)
          this.save_loading = false
        },
      })
    },
    createSlotFunction(data) {
      this.createSlot({
        event_id: this.$route.params.idEvent,
        data: data,
        finalizer: () => {
          this.save_loading = false;
          this.close();
        },
        catcher: (val) => {
          this.mapErrors(val)
          this.save_loading = false
        },
      })
    },
    loadData() {
      if (this.edit) {
        this.loadSlotsDetail({
          event_id: this.$route.params.idEvent,
          id: this.slotId,
          finalizer: () => {
            this.slot = this.slotDetail;
            this.slot_type = this.getSlotType(this.slot)
            this.createEditForm();
            this.loading = true;
          }
        })
      } else {
        this.createForm();
        this.loading = true;
      }
    },
    getSlotType(item) {
      if (item.date) {
        return 0
      }

      if (item.intervals && item.intervals.length > 0) {
        return 1
      }

      return 2
    },
    createForm() {
      for (let i = 0; i < 2; i++) {
        this.interval_items.push(Object.assign({}, this.empty_item))
        this.interval_items[i].id = i;
      }
      this.interval_items[0].editable = true
      this.interval_items[0].adding = false
    },
    createEditForm() {
      for (let i = 0; i <= this.slot.intervals.length; i++) {
        this.interval_items.push(Object.assign({}, this.empty_item))
        this.interval_items[i].id = i;
        if (i !== this.slot.intervals.length) {
          this.interval_items[i].adding = false;
          this.interval_items[i].interval = {}
          this.interval_items[i].interval.value = this.slot.intervals[i].id
          this.interval_items[i].interval.text = this.displayDate(this.slot.intervals[i].start) + '-' + this.displayDate(this.slot.intervals[i].end)
        }
      }
    }
  },
  created() {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
