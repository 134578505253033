<template>
  <v-container style="height: 100%">
    <v-card
        flat
        class="mt-4"
    >
      <v-data-iterator
          :items=name_list
          hide-default-footer
          no-data-text="Нет данных"
      >
        <v-row class="align-center">
          <v-col
              cols="12"
              sm="6"
              lg="4"
              v-for="(item, index) in getLeftMenu(name_list)"
              :key="`card_${index}`"
          >
            <router-link style="text-decoration: none" :to="getRouterParam(item)">
              <v-card
                  min-height="150"
                  elevation="6"
                  class="ma-3 d-flex"
                  color="style-color-blue-light"
                  rounded
              >
                <v-container class="my-auto">
                  <v-row class="my-auto">
                    <v-col class="my-auto title">
                      <span style="font-size: 26px">{{item.title }}</span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </router-link>
          </v-col>
        </v-row>
      </v-data-iterator>
    </v-card>
  </v-container>
</template>

<script>
import names from "./routers/names";

export default {
  name: "AdminMain",
  props: ['name_list'],
  data() {
    return {
    }
  },
  methods: {
    getLeftMenu(list) {
      let left = []
      let mass = list
      for (let i in mass) {
        if (!mass[i].subs) {
          left.push(mass[i])
        }
        else {
          if (mass[i].subs) {
            left = left.concat(this.getLeftSubs(mass[i].subs))
          }
        }
      }
      return left
    },
    getLeftSubs(second_menu) {
      let subs = []
      for (let i in second_menu) {
        subs.push(second_menu[i])
      }
      return subs
    },
    getRouterParam(item){
      if(item.router.name !== undefined)
        return {name: item.router.name};
      else
        return {name: item.router}
    },
  },
  created() {
  }
}
</script>

<style scoped>

</style>