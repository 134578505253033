import { render, staticRenderFns } from "./VisitorDetail.vue?vue&type=template&id=7f2952e2&scoped=true"
import script from "./VisitorDetail.vue?vue&type=script&lang=js"
export * from "./VisitorDetail.vue?vue&type=script&lang=js"
import style0 from "./VisitorDetail.vue?vue&type=style&index=0&id=7f2952e2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f2952e2",
  null
  
)

export default component.exports