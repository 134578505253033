<template>
  <div v-if="$route.name === names.EVENTS.DETAIL && loading">
    <EventDetail
        :needReload="need_reload"
        @notNeedReload="notNeedReload"
    >
    </EventDetail>
  </div>
  <router-view v-else></router-view>
</template>

<script>
import EventDetail from "@/modules/organiser/events/pages/Detail";
import names from "@/modules/organiser/routers/names";
import organiser from "@/urls/roles/organiser";
import names_core from "@/modules/core/routers/names";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "OrganiserBreadcrumbsEvent",
  components: {EventDetail},
  data() {
    return {
      loading: false,
      name: "",
      parent: false,
      children: false,
      need_reload: false,
      left_menu_event_main_list: [
        {title: 'Событие', icon: 'event', included: false, router: {name: names.EVENTS.DETAIL, params: {}}},
        {title: 'Предпросмотр', icon: 'preview', included: true, router: {name: names.EVENTS.PREVIEW.MAIN, params: {}}},
      ],
      left_menu_event_leaf_list: [
        {title: 'Интервалы', icon: 'view_timeline', included: true, router: {name: names.EVENTS.INTERVALS.LIST, params: {}}},
        {title: 'Слоты', icon: 'apps', included: true, router: {name: names.EVENTS.SLOTS.LIST, params: {}}},
      ],
      left_menu_event_operator_list: [
        {title: 'Роли', icon: 'manage_accounts', included: true, router: {name: names.EVENTS.ROLES.LIST, params: {}}},
      ],
      left_menu_event_parent_list: [
        {title: 'Посетители', icon: 'group', included: true, router: {name: names.EVENTS.VISITS.LIST, params: {}}},
        {title: 'Приглашения', icon: 'email', included: true, router: {name: names.EVENTS.INVITES.LIST, params: {}}},
      ],
      left_main_menu_list: [
        {title: 'Главное меню', icon: 'view_quilt', subs: [
            {title: 'События', icon: 'calendar_month', included: false, router: {name: names.EVENTS.LIST, params: {}}},
            {title: 'Найти посещение', icon: 'person_search', included: true, router: {name:  names.FIND_VISITS.LIST, params:{}}},
          ]
        },
      ]
    }
  },
  watch: {
    needEventMenuReload: function (newVal) {
      if (newVal) {
        this.loading = false;
        this.loadData()
        this.changeEventMenuReloadState({value: false})
      }
    }
  },
  computed: {
    ...mapGetters({
      item: 'organiser/getEventsLightDetail',
      item_detail: 'organiser/getEventsDetail',
      needEventMenuReload: 'leftmenu/needEventMenuReload',
    }),
    names: () => names,
  },
  methods: {
    ...mapActions({
      loadEventLightDetail: 'organiser/loadEventsLightDetail',
      changeEventDetailLoadingState: 'leftmenu/changeEventDetailLoadingState',
      changeEventMenuReloadState: 'leftmenu/changeNeedEventMenuReloadState',
    }),
    loadLeftmenu(){
      this.$store.commit('leftmenu/replaceItems', this.getLeftMenu());
    },
    loadData(url=undefined){
      this.changeEventDetailLoadingState({loading_state: true})
      this.loadEventLightDetail({
        url: url,
        id: this.$route.params.idEvent,
        finalizer: (data) => {
          this.name = data.name
          if (data.parent) {
            this.parent = data.parent.id
          } else {
            this.parent = undefined
          }
          if (data.have_children) {
            this.children = data.have_children
          } else {
            this.children = undefined
          }

          this.allow_update = data.allow_update
          this.is_organiser = data.is_organiser
          this.loadLeftmenu()
          this.loading = true
        }
      })
    },
    getLeftMenu() {
      let menu = []
      if (this.parent) {
        menu.push(
            {title: 'Назад', is_back: true, icon: 'arrow_back', included: false, router: {name: names.EVENTS.DETAIL, params: {idEvent: this.parent}}},
        )
      } else {
        menu.push(
            {title: 'Назад', is_back: true, icon: 'arrow_back', included: false, router: {name: names.EVENTS.LIST, params: {}}},
        )
      }
      for (let el in this.left_menu_event_main_list){
        menu.push(this.left_menu_event_main_list[el])
      }
      if (!this.children) {
        for (let el in this.left_menu_event_leaf_list){
          menu.push(this.left_menu_event_leaf_list[el])
        }
      }
      if (!this.parent && this.is_organiser){
        for (let el in this.left_menu_event_parent_list){
          menu.push(this.left_menu_event_parent_list[el])
        }
      }
      if (this.is_organiser){
        for (let el in this.left_menu_event_operator_list){
          menu.push(this.left_menu_event_operator_list[el])
        }
      }
      menu.push(this.left_main_menu_list[this.left_main_menu_list.length-1])
      return menu
    },
    notNeedReload(){
      this.need_reload = false
    }
  },
  beforeRouteUpdate(to, from, next) {
    let old_id = this.$route.params.idEvent
    next()
    let new_id = this.$route.params.idEvent
    if (old_id !== new_id) {
      this.loadData()
      this.need_reload = true
    }
  },
  created() {
    if (organiser.ORGANISER.ALLOWED()) {
      this.loadData(undefined)
    } else{
      this.$router.push({name: names_core.MAIN})
    }
  }
}
</script>

<style scoped>

</style>
