<template>
  <div :class="leftMenuOpened ? 'shadow--left-menu-opened' : ''" class="shadow">
    <v-card
        class="window rounded-lg elevation-0"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : width"
        :color="'rgba(0, 0, 0, 0)'"
    >
      <v-container class="header pa-0 ma-0" :width="$vuetify.breakpoint.smAndDown ? '100%' : width">
        <div class="header__image">
          <div class="header__image--text">
            Заполните форму записи на событие
          </div>
          <div class="header__image--close">
            <v-icon
                @click="closeDialog(false)"
                color="white"
            >
              close
            </v-icon>
          </div>
        </div>
      </v-container>
      <v-container class="main pa-0 ma-0 rounded-0">
        <v-container class="scroll-container">
          <slot>

          </slot>
        </v-container>
      </v-container>
      <v-container class="footer ma-0">
        <v-btn
            class="footer__cancel"
            :color="'#1D71B8'"
            text
            :loading="loading"
            :readonly="loading"
            @click="closeDialog(false)"
        >
          {{ cancelText }}
        </v-btn>
        <v-btn
            class="footer__save"
            :color="'#1D71B8'"
            text
            :loading="loading"
            :readonly="loading"
            @click="closeDialog(true)"
        >
          {{ applyText }}
        </v-btn>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CustomScrollCenterDialog",
  props: {
    width: {
      type: Number,
      required: false,
      default: 760
    },
    cancelText: {
      type: String,
      default: 'Отмена',
      required: false,
    },
    applyText: {
      type: String,
      default: 'Сохранить',
      required: false,
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data(){
    return {

    }
  },
  computed: {
    ...mapGetters({
      leftMenuOpened: 'leftmenu/getLeftMenuShow'
    })
  },
  watch: {
  },
  methods: {
    closeDialog(apply) {
      if (apply)
        this.$emit('apply')
      else
        this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.shadow {
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.35);
  position: absolute;
  top: -60px;
  left: 0;
  z-index: 200;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.shadow--left-menu-opened {
  left: -300px;
}
.window {
  background: rgba(0, 0, 0, 0.0);
  border-radius: 11px;
}
.scroll-container {
  overflow-y: scroll;
  overflow-x: inherit;
  scrollbar-width: thin;
  background: white;
  max-height: calc(100vh - 78px - 61px);
  padding-bottom: 0;
}
.header {
  height: 78px;
}
.header__image {
  display: flex;
  align-items: center;
  text-align: start;
  justify-content: space-between;
  height: 78px;
  width: inherit;
  border-radius: 11px 11px 0 0;
  background: url("../../../../assets/customDialogHeaderImage.png");
}
.header__image--text {
  margin-left: 30px;
  color: #FFF;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 27px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
  @media (max-width: 500px) {
    font-size: 21px;
  }
}
.header__image--close {
  margin-right: 31px;
  margin-left: 10px;
  margin-top: 4px;
}
.main {
  background: white;
}
.footer {
  display: flex;
  justify-content: flex-end;
  padding: 20px 32px 20px 0;
  background: white;
  border-radius: 0 0 11px 11px;
}
.v-btn {
  color: #1D71B8;
  text-transform: uppercase;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
</style>
