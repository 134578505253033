<template>
  <tr
    v-if="$vuetify.breakpoint.lgAndUp"
    class="table-row"
    :class="bgColorIndex === 0 ? 'element--white' : 'element--grey'"
  >
    <td class="table-col table-col__name">
      <div class="visit-name">
        {{ item.event.name }}
      </div>
    </td>
    <td class="table-col table-col__detail">
      <div
          class="button button__detail"
          @click="getDetailVisit(item.id)"
      >
        Подробнее
      </div>
    </td>
    <td class="table-col table-col__date">
      <div class="visit-event-date">
        {{ displayDate(item.event.start) }}
      </div>
    </td>
    <td class="table-col table-col__date">
      <div class="visit-event-date">
        {{ displayDate(item.event.end) }}
      </div>
    </td>
    <td class="table-col table-col__icon">
      <v-icon size="40px">
        {{ item.have_records ? '$vuetify.icons.check_circle_true' : '$vuetify.icons.check_circle_false' }}
      </v-icon>
    </td>
    <td class="table-col table-col__icon">
      <v-icon size="40px">
        {{ item.is_enter ? '$vuetify.icons.check_circle_true' : '$vuetify.icons.check_circle_false' }}
      </v-icon>
    </td>
    <td class="table-col table-col__icon">
      <v-icon size="40px">
        {{ item.is_cancel ? '$vuetify.icons.cancel_true' : '$vuetify.icons.cancel_false' }}
      </v-icon>
    </td>
    <td class="table-col table-col__cancel">
      <div
          v-if="visitCanBeCancelled"
          class="button button__cancel--active"
          @click="$emit('cancelVisit', item)"
      >
        Отменить посещение
      </div>
      <div
          v-else
          class="button button__cancel--disabled"
      >
        Отменить посещение
      </div>
    </td>
  </tr>
  <div v-else :class="bgColorIndex === 0 ? 'element--white' : 'element--grey'" class="container">
    <div class="card__name">
      <div class="name" @click="getDetailVisit(item.id)">
        {{ item.event.name }}
      </div>
    </div>
    <div class="card__divider"></div>
    <v-row class="card__dates">
      <div class="date">
        <div class="date-title">
          Дата начала события
        </div>
        {{ displayDate(item.event.start) }}
      </div>
      <div class="date">
        <div class="date-title">
          Дата конца события
        </div>
        {{ displayDate(item.event.end) }}
      </div>
    </v-row>
    <v-row class="card__icons">
      <div>
        <div class="icons-text">Есть записи</div>
        <v-icon size="38px">
          {{ item.have_records ? '$vuetify.icons.check_circle_true' : '$vuetify.icons.check_circle_false' }}
        </v-icon>
      </div>
      <div>
        <div class="icons-text">Посещено</div>
        <v-icon size="38px">
          {{ item.is_enter ? '$vuetify.icons.check_circle_true' : '$vuetify.icons.check_circle_false' }}
        </v-icon>
      </div>
      <div>
        <div class="icons-text">Отменено</div>
        <v-icon size="38px">
          {{ item.is_cancel ? '$vuetify.icons.cancel_true' : '$vuetify.icons.cancel_false' }}
        </v-icon>
      </div>
    </v-row>
    <v-row class="card__cancel">
      <div
          v-if="visitCanBeCancelled"
          class="button button__cancel--active"
          @click="$emit('cancelVisit', item)"
      >
        Отменить посещение
      </div>
      <div
          v-else
          class="button button__cancel--disabled"
      >
        Отменить посещение
      </div>
    </v-row>
  </div>

</template>

<script>
import {displayDate} from "@/helper";
import names from "@/modules/visitor/routers/names";

export default {
  name: "SingleVisit",
  props: {
    item: Object,
    bgColorIndex: Number,
  },
  data() {
    return {

    }
  },
  computed: {
    visitCanBeCancelled() {
      return !this.item.is_enter && !this.item.is_cancel && !(this.item.is_end && !this.item.is_draft) && !this.item.has_entered_records;
    }
  },
  methods: {
    displayDate: displayDate,
    getDetailVisit(id) {
      this.$router.push({name: names.VISITS.DETAIL, params: {idVisit: id}})
    },
  }
}
</script>

<style scoped lang="scss">
.table-row {
  width: 100%;
}
.table-col {
  margin-right: 2%;
  margin-left: 0;
  line-height: 90px;
  padding: 0 5px;
}
.table-col__name {
  text-align: left;
  width: 25%;
  padding-left: 2%;
  @media (min-width: 1454px) and (max-width: 1712px) {
    width: 21%;
  }
  @media (min-width: 1713px) {
    width: 27%;
  }
}

.table-col__detail {
  width: 11%;
  @media (min-width: 1454px) and (max-width: 1712px) {
    width: 9%;
  }
  @media (min-width: 1713px) {
    width: 8%;
  }
}

.table-col__date {
  width: 8%;
}

.table-col__icon {
  width: 7%;
}

.table-col__cancel {
  width: 21%;
  @media (min-width: 1454px) and (max-width: 1712px) {
    width: 16%;
  }
  @media (min-width: 1713px) {
    width: 13%;
  }
  padding-right: 2%;
}

.element--white {
  background-color: white;
}
.element--grey {
  background-color: #F6F6F6;
}

.visit-name {
  display:-webkit-box;
  -webkit-line-clamp:2;
  -webkit-box-orient:vertical;
  overflow: hidden;
  color: #1D71B8;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.visit-event-date {
  color: #676767;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  border-radius: 45px;
  border: 1px solid #1D71B8;
  color: #1D71B8;
  height: 40px;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-transform: none;
}

.button__detail {
  cursor: pointer;
  &:hover, &:active {
    color: white;
    background: #1D71B8;
  }
}

.button__cancel--active {
  border-radius: 44px;
  border-color: #D64141;
  color: #D64141;
  cursor: pointer;

  &:hover, &:active {
    color: white;
    background: #D64141;
  }
}
.button__cancel--disabled {
  border-color: #BCBCBC;
  color: #BCBCBC;
}

.container {
  padding: 25px 30px;
}

.card__name {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 38px;
  margin-bottom: 12px;
  @media (min-width: 425px) and (max-width: 599px) {
    margin-bottom: 16px;
  }
}

.card__divider {
  height: 2px;
  background: #CECECE;
  margin-bottom: 25px;
}

.card__dates {
  margin: 0 0 25px 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  text-align: left;
  @media (max-width: 599px)  {
    justify-content: space-around;
    text-align: center;
  }
}

.card__icons {
  margin: 0 0 25px 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  @media (max-width: 599px)  {
    justify-content: space-around;
  }
}

.card__cancel {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.name {
  display:-webkit-box;
  -webkit-line-clamp:2;
  -webkit-box-orient:vertical;
  overflow: hidden;
  color: #1D71B8;
  text-decoration: underline;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  @media (min-width: 425px) and (max-width: 599px) {
    font-size: 21px;
  }
}

.date {
  color: #404040;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (min-width: 425px) and (max-width: 599px) {
    font-size: 18px;
  }
}

.date-title {
  color: #7C7C7C;
  font-size: 12px;
  margin-bottom: 8px;
  @media (max-width: 325px)  {
    font-size: 11px;
  }
  @media (min-width: 425px) and (max-width: 599px) {
    font-size: 15px;
  }
}

.icons-text {
  color: #7C7C7C;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
  @media (min-width: 425px) and (max-width: 599px) {
    font-size: 15px;
  }
}
</style>
