<template>
  <div>
    <div class="header-text">Список вложенных мероприятий</div>
    <table v-if="$vuetify.breakpoint.lgAndUp">
      <thead>
        <tr class="table-row">
          <td class="table-col table-col__name">
            Название события
          </td>
          <td class="table-col table-col__detail">

          </td>
          <td class="table-col table-col__date">
            Дата начала события
          </td>
          <td class="table-col table-col__date table-col__date--end">
            Дата конца события
          </td>
        </tr>
      </thead>
      <tbody>
      <SingleNestedEvent
          v-for="(item, i) in items" :key="i"
          :item="item"
          :preview="preview"
          :bg-color-index="getBackgroundColorIndex(i)"
          @reloadEventData="() => $emit('reloadEventData')"
      ></SingleNestedEvent>
      </tbody>
    </table>
    <v-row v-else class="pa-0 ma-0">
      <v-col cols="12" sm="6" md="4" class="pa-0 ma-0" v-for="(item, i) in items" :key="i">
        <SingleNestedEvent
            :item="item"
            :bg-color-index="getBackgroundColorIndex(i)"
            :preview="preview"
            @reloadEventData="() => $emit('reloadEventData')"
        ></SingleNestedEvent>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SingleNestedEvent from "@/modules/templates/visitor/event_detail/SingleNestedEvent.vue";

export default {
  name: "NestedEventsTable",
  components: {SingleNestedEvent},
  props: {
    items: Array,
    preview: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  methods: {
    getBackgroundColorIndex(index) {
      // Функция страшной математики для шахматного порядка цветов на любом разрешении
      let cards_in_row = 1;

      if (this.$vuetify.breakpoint.sm) cards_in_row = 2
      else if (this.$vuetify.breakpoint.md) cards_in_row = 3
      else if (this.$vuetify.breakpoint.lgAndUp) cards_in_row = 1

      let row_number = Math.floor(index / cards_in_row)
      if (cards_in_row % 2 === 1 && row_number % 2 === 1) row_number++;
      return (index + row_number + 1) % 2
    },
  }
}
</script>

<style scoped lang="scss">
table {
  width: 100%;
  border-spacing: 0;
}
.table-row {
  width: 100%;
  padding: 25px 0;
  color: #7C7C7C;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.table-col {
  margin-right: 2%;
  padding: 10px 5px;
}

.table-col__name {
  text-align: left;
  width: 40%;
  padding-left: 2%
}

.table-col__detail {
  width: 20%;
}

.table-col__date {
  width: 20%;
}

.table-col__date--end {
  padding-right: 2%
}

.header-text {
  color: #484848;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding: 0 24px;
}
</style>
