const TYPES = {
    TEXT: 0,
    DATE_TIME: 1,
    DATE: 2,
    TIME: 3,
    LIST_STRING: 4,
    NUMBER: 6,
    FLAG: 8,
    SELECTOR: 9,
};
export default TYPES;
