const OPERATOR= {
    BASE: 'Operator',
    MAIN: undefined,
    EVENTS: {
        _BASE: undefined,
        LIST: undefined,
        DETAIL: undefined,
        INTERVALS: {
            _BASE: undefined,
            LIST: undefined,
            DETAIL: undefined,
        },
        SLOTS: {
            _BASE: undefined,
            LIST: undefined,
            DETAIL: undefined,
        },
        VISITS: {
            _BASE: undefined,
            LIST: undefined,
            DETAIL: undefined,
        },
    },
    FIND_VISITS: {
        _BASE: undefined,
        LIST: undefined,
    },
};

OPERATOR.MAIN = `${OPERATOR.BASE}|Main`

OPERATOR.EVENTS._BASE = `${OPERATOR.BASE}|Events`
OPERATOR.EVENTS.LIST = `${OPERATOR.EVENTS._BASE}|List`
OPERATOR.EVENTS.DETAIL = `${OPERATOR.EVENTS._BASE}|Detail`

OPERATOR.EVENTS.INTERVALS._BASE = `${OPERATOR.EVENTS.DETAIL}|Intervals`
OPERATOR.EVENTS.INTERVALS.LIST = `${OPERATOR.EVENTS.INTERVALS._BASE}|List`
OPERATOR.EVENTS.INTERVALS.DETAIL = `${OPERATOR.EVENTS.INTERVALS._BASE}|Detail`

OPERATOR.EVENTS.SLOTS._BASE = `${OPERATOR.EVENTS.DETAIL}|Slots`
OPERATOR.EVENTS.SLOTS.LIST = `${OPERATOR.EVENTS.SLOTS._BASE}|List`
OPERATOR.EVENTS.SLOTS.DETAIL = `${OPERATOR.EVENTS.SLOTS._BASE}|Detail`

OPERATOR.EVENTS.VISITS._BASE = `${OPERATOR.EVENTS.DETAIL}|Visits`
OPERATOR.EVENTS.VISITS.LIST = `${OPERATOR.EVENTS.VISITS._BASE}|List`
OPERATOR.EVENTS.VISITS.DETAIL = `${OPERATOR.EVENTS.VISITS._BASE}|Detail`

OPERATOR.FIND_VISITS._BASE = `${OPERATOR.BASE}|FindVisits`
OPERATOR.FIND_VISITS.LIST = `${OPERATOR.FIND_VISITS._BASE}|List`

export default {
    ...OPERATOR
}