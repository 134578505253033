import {sendGetRequest} from "@/helper/requests";
import viewer from "@/urls/roles/viewer";

const state = {
    blocks:{
        currentUrl: null,
        items: [],
        loading: false,
        count: 0,
        detail: [],
        error: null,
    }
}

const getters = {
    getBlocksList: state => state.blocks.items,
    getBlocksCount: state => state.blocks.count,
    getBlocksCurrentUrl: state => state.blocks.currentUrl,
    getBlocksDetail: state => state.blocks.detail,
    isLoadingBlocks: state => state.blocks.loading,
    saveBlocksError: state => state.blocks.error,
}

const actions = {
    loadBlocksList: ({commit, getters}, {url, event_id, urlAppend, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) {
            url = viewer.VIEWER.EVENTS.BLOCKS.LIST(event_id)
            if (urlAppend) url = urlAppend(url)
        }
        if (!getters.isLoadingBlocks || getters.getBlocksCurrentUrl !== url){
            commit('SET_BLOCKS_LOADING');
            sendGetRequest(
                url, (data, url) => {
                    commit('SET_BLOCKS_LIST', data);
                    commit('SET_BLOCKS_COUNT', data.length);
                    commit('SET_BLOCKS_CURRENT_URL', url);
                    commit('SET_BLOCKS_LOADING_END');
                    finalizer(data, url);
                }, (data, url) => {
                    commit('SET_BLOCKS_LOADING_END');
                    commit('SAVE_BLOCKS_ERROR', data);
                    catcher(data, url);
                }
            )
        }
    },
    loadBlocksDetail: ({commit}, {url, event_id, id, finalizer=()=>{}, catcher=()=>{}}) => {
        if (!url) url = viewer.VIEWER.EVENTS.BLOCKS.DETAIL(event_id, id)
        sendGetRequest(
            url, (data, url) => {
                commit('SET_BLOCKS_DETAIL', data);
                finalizer(data, url);
            }, (data, url) => {
                commit('SAVE_BLOCKS_ERROR', data);
                catcher(data, url);
            }
        )
    },
}

const mutations = {
    SET_BLOCKS_LIST: (state, blocks) => {
        state.blocks.items = blocks
    },
    SET_BLOCKS_DETAIL: (state, value) => {
        state.blocks.detail = value
    },
    SET_BLOCKS_COUNT: (state, value) => {
        state.blocks.count = value
    },
    SET_BLOCKS_CURRENT_URL: (state, value) => {
        state.blocks.currentUrl = value
    },
    SET_BLOCKS_LOADING: (state) => {
        state.blocks.loading = true
    },
    SET_BLOCKS_LOADING_END: (state) => {
        state.blocks.loading = false
    },
    SAVE_BLOCKS_ERROR: (state, error) => {
        state.blocks.error = error
    }
}


export default {
    namespaced: false,
    state,
    mutations,
    actions,
    getters
}
