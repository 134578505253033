<template>
  <div v-if="!edit" class="fill-height">
    <v-img
        v-for="(item, i) in data.files"
        :key="i"
        :gradient=" !item.src ?
                  '41deg, rgba(29,59,242,1) 0%, rgba(197,113,233,1) 50%, rgba(197,113,233,1) 78%, rgba(186,234,254,1) 100%'
                  : ''"
        :src="item.src"
        :aspect-ratio="1"
        contain
        max-height="500"
        style="background: #BBBBBB"
        class="ma-0"
    ></v-img>
  </div>
  <div v-else>
    <v-card-text class="text-start pa-0 my-4 ml-4 text-subtitle-1 text-sm-h6 font-weight-bold" v-if="data.all_files.length > 0">Загруженное изображение</v-card-text>
    <v-row class="mb-4 mx-1 pa-0">
      <file-chip
        v-for="(item, i) in data.all_files"
        :key="i"
        :i="i"
        :data="data"
        @moveFileUp="moveFileUp"
        @moveFileDown="moveFileDown"
        @deleteOneFile="deleteOneFile"
        :moving-files-allowed="false"
      ></file-chip>
    </v-row>
    <v-card-text class="text-start pa-0 my-4 ml-4 text-subtitle-1 text-sm-h6 font-weight-bold">Загрузите изображение</v-card-text>
    <v-file-input
        v-model="data.new_files"
        outlined
        show-size
        :error="data.errors.file.length > 0"
        :error-messages="data.errors.file"
        @change="$emit('loadFilesFromInput', data.id)"
    >
      <template v-slot:label>
        Загрузите изображение<span class="red--text"> *</span>
      </template>
    </v-file-input>
  </div>
</template>

<script>
import FileChip from "@/modules/templates/description_blocks/blocks/blocks_components/FileChip.vue";

export default {
  props: {
    edit: Boolean,
    data: Object,
  },
  name: "ImageBlock",
  components: { FileChip },
  methods: {
    moveFileUp(id, file_index) {
      this.$emit('moveFileUp', id, file_index)
    },
    moveFileDown(id, file_index) {
      this.$emit('moveFileDown', id, file_index)
    },
    deleteOneFile(id, file_index) {
      this.$emit('deleteOneFile', id, file_index)
    },
  },
  created() {

  }
}
</script>

<style scoped>

</style>
