<template>
  <div v-if="value" :class="leftMenuOpened ? 'shadow--left-menu-opened' : ''" class="shadow">
    <v-card
        class="window rounded-lg elevation-0"
        :color="'rgba(0, 0, 0, 0)'"
        :width="$vuetify.breakpoint.xsOnly ? '100vw' : '1000px'"
    >
      <v-container class="container px-3 pl-6">
        <div class="header">
          <div class="header-text">
            Для продолжения работы в системе необходимо авторизоваться
          </div>
          <v-btn
              class="icon-close"
              icon
              @click="close"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </div>
        <div class="divider"></div>
        <div class="main">
          <div class="button button-muctr" @click="goPortal">
            Войти как корпоративный пользователь
          </div>
          <div class="text-or">
            или
          </div>
          <div class="text-data">
            Введите данные и войдите как внешний пользователь
          </div>
          <v-row class="pa-0 ma-0 width-100">
            <v-col class="pa-0 ma-0" cols="12">
              <v-text-field
                  class="mt-4 mb-0"
                  outlined
                  v-model="visitor.email"
                  :error="hasError('email')"
                  :errorMessages="getError('email')"
              >
                <template v-slot:label>
                  Email<span class="red--text"> *</span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0 width-100">
            <v-col class="pa-0 ma-0" cols="12" md="4">
              <v-text-field
                :class="$vuetify.breakpoint.mdAndUp ? 'mr-2' : ''"
                outlined
                v-model="visitor.lastname"
                :error="hasError('lastname')"
                :errorMessages="getError('lastname')"
              >
                <template v-slot:label>
                  Фамилия<span class="red--text"> *</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col class="pa-0 ma-0" cols="12" md="4">
            <v-text-field
                :class="$vuetify.breakpoint.mdAndUp ? 'mx-2' : ''"
                outlined
                v-model="visitor.firstname"
                :error="hasError('firstname')"
                :errorMessages="getError('firstname')"
              >
                <template v-slot:label> Имя<span class="red--text"> *</span> </template>
              </v-text-field>
            </v-col>
            <v-col class="pa-0 ma-0" cols="12" md="4">
            <v-text-field
                :class="$vuetify.breakpoint.mdAndUp ? 'ml-2' : ''"
                outlined
                v-model="visitor.midname"
                :error="hasError('midname')"
                :errorMessages="getError('midname')"
              >
                <template v-slot:label> Отчество </template>
              </v-text-field>
            </v-col>
          </v-row>
          <div class="button-block">
            <div class="button button-visitor" @click="login">
              Войти как внешний пользователь
            </div>
          </div>
          <v-alert
            v-if="hasError('error')"
            color="red"
            class="elevation-2 mt-3 py-2 alert-text"
            type="error"
          >
            {{ String(getError('error')) }}
          </v-alert>
          <div class="text-help">
            Если у вас возникли трудности с использованием системы, вопросы или
            предложения, обратитесь в службу технической поддержки
            <a href="mailto:support@muctr.ru">support@muctr.ru</a>.
          </div>
        </div>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import names from "@/modules/visitor/routers/names";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "LoginDialog",
  props: {
    value: Boolean,
  },
  mixins: [FormErrorsMixin],
  data() {
    return {
      loading: false,
      form_title: "",
      help_title: "Для продолжения работы в системе необходимо авторизоваться",
      visitor: {
        email: "",
        firstname: "",
        midname: "",
        lastname: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      leftMenuOpened: 'leftmenu/getLeftMenuShow',
      visitor_token: 'visitor/getVisitorToken',
    }),
    names: () => names,
  },
  methods: {
    ...mapActions({
      loginVisitorViaEvent: "visitor/loginVisitorViaEvent",
      loginVisitor: "visitor/loginVisitor",
    }),
    login() {
      this.loading = true;
      let data = this.visitor
      this.loginVisitor({
        data,
        finalizer: (val) => {
          this.loading = false;
          window.$cookies.set("visitor_token", val.EventToken);
          this.loginVisitorViaEvent({token : val.EventToken})
          this.$emit("closelogin", false);
        },
        catcher: (val) => {
          this.loading = false;
          this.mapErrors(val);
        },
      });
    },
    goPortal() {
      document.location.href = process.env.VUE_APP_LOGIN_HOST + String(`?from=${document.location.href}`);
      this.close()
    },
    close() {
      this.$emit("close", false);
    },
  },
};
</script>

<style scoped lang="scss">
.shadow {
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.35);
  position: absolute;
  left: 0;
  z-index: 200;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.shadow--left-menu-opened {
  left: -300px;
}
.window {
  background: rgba(0, 0, 0, 0.0);
  border-radius: 11px;
  color: #404040;
  font-family: 'MADE Evolve Sans normal', serif;
  font-size: 16px!important;
  font-style: normal;
  font-weight: 400;
  @media (max-width: 500px) {
    font-size: 14px!important;
  }
}

.container {
  background: white;
  overflow-y: scroll;
  overflow-x: inherit;
  scrollbar-width: thin;
  max-height: calc(100vh);
  padding-bottom: 0;
}

.header {
  @media (max-width: 450px) {
    font-size: 14px!important;
  }
  @media (max-width: 320px) {
    font-size: 13px!important;
  }
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header-text {
  width: 100%;
  text-align: center;
  padding: 0 45px;
  @media (max-width: 400px) {
    padding: 0 45px 0 0;
    text-align: left;
  }
}

.icon-close {
  width: 0!important;
  position: relative;
  right: 22px;
}

.divider {
  height: 2px;
  background: #CECECE;
}

.main {
  background: white;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.button-muctr {
  padding: 0 10px;
}

.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 45px;
  border: 1px solid #1D71B8;
  color: #1D71B8;
  height: 40px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-transform: none;
  cursor: pointer;
  &:hover {
    background: #1D71B8;
    color: #FFF;
  }
  @media (max-width: 450px) {
    font-size: 12px;
  }
}

.alert-text {
  @media (max-width: 450px) {
    font-size: 13px;
  }
}

.text-or {
  color: #CECECE;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 12px 0;
  text-transform: uppercase;
}

.text-data {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #404040;
  margin-bottom: 10px;
}

.button-block {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button-visitor {
  width: 249px;
}


.width-100 {
  width: 100%;
}

.text-help {
  color: #7C7C7C;
  text-align: center;
  font-family: 'Arial normal', serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 27px 0;
}

</style>
