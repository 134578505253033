<template>
  <div>
    <v-row class="justify-space-between my-1" v-if="change_file">
      <v-col class="ml-3 text-start text-subtitle-2 text-md-subtitle-1" cols="8">
        {{ value.file_name }}
      </v-col>
      <v-col cols="3">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="deleteFile()">
              <v-icon
                class="dark-primary"
                v-bind="attrs"
                v-on="on"
              >
                close</v-icon>
            </v-btn>
          </template>
          <span>Удалить файл</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <a :href="getFile()" target="_blank">
              <v-icon
                class="dark-primary ml-1 mr-3"
                v-bind="attrs"
                v-on="on"
              >
                download
              </v-icon>
            </a>
          </template>
          <span>Скачать файл</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <div v-else>
      <v-file-input
        v-model="value.file_create"
        outlined
        :error="fileError && fileError.length > 0"
        :errorMessages="fileError"
      >
        <template v-slot:label>
          Выберите файл (.pdf)
        </template>
      </v-file-input>
      <div style="border: none; border-radius: 4px; border : 2px dotted black;" @dragover.prevent @drop.prevent>
        <div @drop="addDropFile" style=" margin-bottom:6px; padding:6px;">
          Или перетащите файл сюда
        </div>
      </div>
    </div>
    <v-text-field
      class="pt-6"
      outlined
      v-model="value.file_name"
      :hint="'Имя файла в системе'"
      persistent-hint
      :error="fileNameError && fileNameError.length > 0"
      :errorMessages="fileNameError"
    >
      <template v-slot:label>
        Имя файла
      </template>
    </v-text-field>
    <v-text-field
      outlined
      v-model="value.text"
      :hint="'Этот текст будет выводиться рядом с флагом'"
      persistent-hint
      :error="textError && textError.length > 0"
      :errorMessages="textError"
    >
      <template v-slot:label>
        Текст рядом с флагом<span class="red--text"> *</span>
      </template>
    </v-text-field>
    <v-text-field
      outlined
      v-model="value.sub_str"
      :hint="'Часть текста, при нажатию на которую скачивается файл'"
      persistent-hint
      :error="subStrError && subStrError.length > 0"
      :errorMessages="subStrError"
    >
      <template v-slot:label>
        Подстрока
      </template>
    </v-text-field>
  </div>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin"
import admin from "@/urls/roles/admin";
export default {
  name: "FlagFile",
  props: {
    value: {
      type: Object,
      default: () => {return {}},
    },
    subStrError: Array,
    textError: Array,
    fileError: Array,
    fileNameError: Array
  },
  mixins: [FormErrorsMixin],
  components: {},
  data(){
    return {
      change_file: null,
    }
  },
  watch: {},
  computed: {},
  methods: {
    addDropFile(e) {
      this.value.file_create = e.dataTransfer.files[0];
    },
    getFile(){
      let urls = {
        basename: this.value.file_edit.name,
        action: this.value.file_edit.action,
        params: this.value.file_edit.params,
      }
      return admin.getByRecievedUrlData(urls, this.value.file_edit.get_params)
    },
    deleteFile(){
      this.change_file = null
      delete this.value.file_edit
    }
  },
  created() {
    this.change_file = this.value.file_edit
  }
}
</script>

<style scoped>

</style>
