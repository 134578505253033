<template>
  <v-card v-if="!detail_data_is_loading">
    <VisitorDetail
      :item="item"
      @close="closeDetail"
    >
      <template v-slot:btn>
        <v-btn
            class="dark-primary ml-0"
            icon
            small
            @click="closeDetail"
        >
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-items v-if="$vuetify.breakpoint.smAndUp && !item.user">
          <v-btn
              text
              @click="editVisitor(item.id)"
              class="dark-primary mr-0 rounded-tr-lg"
          >
            Редактировать посетителя
          </v-btn>
        </v-toolbar-items>
        <v-btn
            v-if="$vuetify.breakpoint.xsOnly && !item.user"
            icon
            class="dark-primary mr-0"
            @click="editVisitor(item.id)"
        >
          <v-icon>edit</v-icon>
        </v-btn>
      </template>
    </VisitorDetail>
    <EditDialog
        v-if="dialog_add_edit"
        :opened="dialog_add_edit"
        :edit="edit_status"
        :visitor-id="visitor_id"
        @close="closeEditDialog"
    ></EditDialog>
    <v-divider class="mx-4"></v-divider>
    <VisitsList></VisitsList>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import admin from "@/urls/roles/admin";
import names_core from "@/modules/core/routers/names";
import names from "@/modules/admin/routers/names";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import formErrorsMixin from "@/mixins/FormErrorsMixin";
import VisitorDetail from "@/modules/templates/VisitorDetail";
import VisitsList from "@/modules/admin/visitors/components/VisitsList";
import EditDialog from "@/modules/admin/visitors/modals/VisitorsEditDialog.vue"

export default {
  mixins: [TitledPageMixin, PaginatedDataMapperMixin, formErrorsMixin],
  components: {VisitsList, VisitorDetail, EditDialog},
  name: "AdminVisitorsDetail",
  data() {
    return {
      page_title: "Детальное представление посетителя",
      detail_data_is_loading: true,
      visitor_id: undefined,
      edit_status: undefined,
      dialog_add_edit: false,
    }
  },
  computed: {
    ...mapGetters({
      item: 'admin/getVisitorsDetail',
    }),
  },
  methods: {
    ...mapActions({
      loadVisitorDetail: 'admin/loadVisitorsDetail',
    }),
    loadData(url = undefined) {
      this.loadVisitorDetail({
        url: url,
        id: this.$route.params.idVisitor,
        finalizer: () => {
          this.detail_data_is_loading = false;
        }
      })
    },
    closeDetail() {
      this.$router.push({name: names.VISITORS.LIST})
    },
    editVisitor(id) {
      this.dialog_add_edit = true
      this.edit_status = true
      this.visitor_id = id
    },
    closeEditDialog() {
      this.loadData()
      this.editing_expanded_item = false;
      this.dialog_add_edit = false
      this.user_id = undefined
    }
  },
  created() {
    if (admin.ADMIN.ALLOWED()) {
      if(this.$route.name === names.VISITORS.DETAIL){
        this.loadData()
      }
    } else {
      this.$router.push({name: names_core.MAIN})
    }
  },
}
</script>

<style scoped>

</style>
