<template>
  <div v-if="$route.name === names.EVENTS.PREVIEW.MAIN">
    <div v-if="blocks_loaded && event_detail_loaded && fields_loaded && regexp_types_loaded">
      <VisitorEventPagePreview
          v-if="blocks_loaded"
          :detail="detail"
          :blocks="blocks"
          :light-detail="light_detail"
          :regex-types="regexp_types"
      ></VisitorEventPagePreview>
    </div>
    <div class="action mt-8 mb-4 align-center" v-else>
      <semipolar-spinner
          class="d-inline-block"
          :animation-duration="2000"
          :size="115"
          color="#1935ff"
      />
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import names_core from "@/modules/core/routers/names";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import {SemipolarSpinner} from "epic-spinners";
import names from "@/modules/viewer/routers/names";
import viewer from "@/urls/roles/viewer";
import VisitorEventPagePreview from "@/modules/templates/VisitorEventPagePreview.vue";

export default {
  name: "ViewerEventsPreview",
  mixins: [ TitledPageMixin ],
  components: {VisitorEventPagePreview, SemipolarSpinner },
  data() {
    return {
      page_title: 'Предпросмотр',
      event_detail_loaded: false,
      blocks_loaded: false,
      block_types_loaded: false,
      rows: [],
      rows_amount: 0,
      block_types: null,
      edit: false,
      new_row_id: 1,
      new_block_id: 1,
      detail: undefined,
      fields: undefined,
      fields_loaded: false,
      blocks: [],
      regexp_types: null,
      regexp_types_loaded: false,
    }
  },
  computed: {
    ...mapGetters({
      light_detail: 'viewer/getEventsLightDetail',
    }),
    names: () => names,
  },
  watch: {
    block_types_loaded() {
      if (this.block_types_loaded) {
        this.loadData();
      }
    },
  },
  methods: {
    ...mapActions({
      loadBlocksList: 'viewer/loadBlocksList',
      loadEventDetail: 'viewer/loadEventsDetail',
      loadBlockTypeChoices: "choices/loadBlockTypeChoices",
      loadLightDetail: 'viewer/loadEventsLightDetail',
      updatePreviewOpenedState: 'mainpage/updatePreviewOpenedState',
      loadFieldsList: "viewer/loadNonPaginatedFieldsList",
      changeEventDetailLoadingState: 'leftmenu/changeEventDetailLoadingState',
      loadRegexpTypeChoices: 'choices/loadRegexpTypeChoices',
    }),
    loadChoices() {
      this.loadRegexpTypeChoices({
        finalizer: (val) => {
          this.regexp_types = val;
          this.regexp_types_loaded = true;
        }
      })
      this.loadBlockTypeChoices({
        finalizer: (val) => {
          this.block_types = val;
          this.block_types_loaded = true;
        }
      })
      this.loadEventDetail({
        url: undefined,
        id: this.$route.params.idEvent,
        finalizer: (data) => {
          this.detail = data
          if (this.fields_loaded) {
            this.detail.fields = this.fields
          }
          this.event_detail_loaded = true;
        }
      })
      this.loadFieldsList({
        url: undefined,
        event_id: this.$route.params.idEvent,
        finalizer: (data) => {
          this.fields = data
          if (this.event_detail_loaded) {
            this.detail.fields = this.fields
          }
          this.fields_loaded = true
        }
      })
    },
    loadData(finalizer) {
      this.loadBlocksList({
        url: undefined,
        event_id: this.$route.params.idEvent,
        finalizer: (data) => {
          this.blocks = data
          this.rebuildBlocksToRows();
          this.blocks_before_changes = structuredClone(this.blocks)
          this.blocks_loaded = true;
          this.changeEventDetailLoadingState({loading_state: false})
          finalizer()
        }
      })
    },
    rebuildBlocksToRows() {
      for (let i = 0; i < this.blocks.length; i++)
      {
        // берём положение блока на странице
        let row = this.blocks[i].data.row;
        let col = this.blocks[i].data.col;

        // изменяем количество строчек, если положение по строке выходит за границы
        if (row + 1 > this.rows_amount) this.rows_amount = row + 1;

        // если такой строки ещё не существует, то создаём её
        if (!this.rows[row]) {
          this.rows[row] = {};
          this.rows[row].error = '';
        }

        // если массива колонок строки ещё не существует, то добавляем его
        if (!this.rows[row].cols) {
          this.rows[row].cols = [];
        }

        // добавляем строке необходимые свойства
        this.rows[row].cols[col] = structuredClone(this.blocks[i]);
        this.rows[row].cols[col].errors = structuredClone(this.empty_errors);
        this.rows[row].cols[col].new_files = [];
        this.rows[row].cols[col].files_delete = [];
        this.rows[row].id = i;
        this.rows[row].cols_amount = this.rows[row].cols[col].data.cols_amount;
        this.rows[row].row_number = this.rows[row].cols[col].data.row;
        // загружаем все файлы блока
        for (let file of this.rows[row].cols[col].files) {
          file.src = this.getImage(file.file);
        }
      }
    },
    getImage(image) {
      if (!image) return null;

      let urls = {
        basename: image.name,
        action: image.action,
        params: image.params,
      }

      return viewer.getByRecievedUrlData(urls, image.get_params)
    },
    updatePageTitle() {
      this.loadLightDetail({
        id: this.$route.params.idEvent,
        finalizer: (data) => {
          this.page_title = this.page_title + ': ' + data.name
        }
      })
    },
  },
  created() {
    if (viewer.VIEWER.ALLOWED()) {
      if(this.$route.name === names.EVENTS.PREVIEW.MAIN){
        this.updatePageTitle()
        this.loadChoices()
        let preview_opened = (this.$route.name.indexOf('|Events|Detail|Preview|Main') !== -1)
        this.updatePreviewOpenedState({
          value: preview_opened
        })
      }
    } else {
      this.$router.push({name: names_core.MAIN})
    }
  }
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0;
}
</style>
