<template>
    <v-dialog persistent v-model="opened" width="500">
        <v-card>
            <v-card-title>Подтвердите изменение</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pt-5">
                <span v-html="getText()" v-if="getText !== undefined && opened"></span>
                <slot v-else></slot>
            </v-card-text>
            <v-card-actions>
                <v-flex>
                    <v-btn
                            @click="applyDialog"
                            color="warning"
                            text
                    >
                        Подтвердить
                    </v-btn>
                    <v-btn
                            @click="closeDialog"
                            text
                    >
                        Отменить
                    </v-btn>
                </v-flex>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "ApplyChangeReadonlyBoolean",
        props: {
            getText: {
                type: Function,
                default: undefined,
            },
            opened: Boolean,
        },
        data: function () {
            return {
                // opened: true
            }
        },
        methods: {
            closeDialog: function () {
                this.$emit('close', false)
            },
            applyDialog: function () {
                this.$emit('close', true)
            }

        }
    }
</script>

<style scoped>

</style>
