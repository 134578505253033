<template>
  <scroll-center-dialog v-model="opened" :loading="save_loading" width="760" @close="close" @apply="save" title="Переотправить билеты" :scrolled="false">
    <v-row class="mt-2 px-2 justify-center align-center">
      <v-col cols="12">
        <v-select
          :items="select_items"
          v-model="is_send_all"
          outlined
        ></v-select>
      </v-col>
      <v-col v-if="is_send_all" class="py-0">
        <v-alert
            color="red"
            class="elevation-2 mt-2"
            type="error"
        >
          ООбращаем ваше внимание, что нажатие кнопки "Сохранить" приведет к повторной отправке письма всем участникам данного мероприятия
        </v-alert>
      </v-col>
      <v-col cols="12" class="text-h6" style="white-space: nowrap; text-align: start;" v-if="!is_send_all">
        Выберите посетителей:
      </v-col>
      <v-col cols="12" v-if="!is_send_all">
        <v-data-table
            :items="visitorsItems"
            :headers="headers"
            disable-pagination
            hide-default-footer
            class="elevation-2"
        >
          <template
              v-slot:item.fio="{ item }"
          >
            <v-row v-if="item.adding" class="px-3"></v-row>
            <v-row v-else-if="item.editable" class="px-3" :class="item.error.length > 0 ? 'mb-1' : 'mb-0'">
              <loading-autocomplete
                  v-model="item.visitor"
                  @input="changeEditableState(item)"
                  :url="visitorsSelector"
                  :error="item.error.length > 0"
                  :error-messages="item.error"
                  :no-size-parameter="true"
                  :return-object="true"
                  :load-fios="false"
                  search-parameter="fio"
              ></loading-autocomplete>
            </v-row>
            <v-row v-else class="px-3">
              {{ item.visitor.text }}
            </v-row>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-row no-gutters justify="space-around" align="center" v-show="item.adding">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      @click="addEmptyItem"
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon>
                      add_circle_outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Добавить пустое поле</span>
              </v-tooltip>
            </v-row>
            <v-row no-gutters justify="space-around" align="center" v-show="!item.adding">
              <v-tooltip bottom >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      @click="deleteEmptyItem(item)"
                      v-bind="attrs"
                      v-on="on"
                  >
                    delete
                  </v-icon>
                </template>
                <span>Удалить поле</span>
              </v-tooltip>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" v-if="hasError('event') && !is_send_all">
        <v-alert
            color="red"
            class="elevation-2"
            type="error"
        >{{ String(getError('event')) }}</v-alert>
      </v-col>
    </v-row>
  </scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import ScrollCenterDialog from "@/modules/templates/ScrollCenterDialog.vue";
import {mapActions} from "vuex";
import selectors from "@/urls/selectors";
import LoadingAutocomplete from "@/modules/core/components/LoadingAutocomplete.vue";

export default {
  name: "AdminResendTicketsDialog",
  props: {
    opened: Boolean,
  },
  mixins: [FormErrorsMixin],
  components: {
    ScrollCenterDialog,
    LoadingAutocomplete,
  },
  data() {
    return {
      save_loading: false,
      items: [],
      select_items: [
        {text: 'Выбрать посетителей', value: false},
        {text: 'Отправить всем', value: true},
      ],
      is_send_all: false,
      empty_item: {
        id: null,
        visitor: null,
        editable: false,
        adding: true,
        error: '',
      },
      visitors: [],
      email_visitors: [],
      headers: [
        {text: 'ФИО', value: 'fio', sortable: false, width: '85%', align: 'center'},
        {text: 'Действия', value: 'actions', sortable: false, width: '15%', align: 'center'},
      ],
      outer_headers: [
        {text: 'E-mail', value: 'email', sortable: false, width: '85%', align: 'center'},
        {text: 'Действия', value: 'actions', sortable: false, width: '15%', align: 'center'},
      ],
    }
  },
  computed: {
    visitorsSelector() {
      return selectors.SELECTORS.VISITORS.ALL({event_id: this.$route.params.idEvent})
    },
    visitorsItems() {
      return this.items
    }
  },
  methods: {
    ...mapActions({
      resendTicket: 'admin/resendTickets',
    }),
    close() {
      this.$emit('close', false)
    },
    getVisitors() {
      this.visitors = [];
      for (let i = 0; i < this.items.length - 1; i++) {
        if (!this.items[i].editable) {
          this.visitors.push(this.items[i].visitor.value)
        }
      }
    },
    save() {
      this.save_loading = true;
      this.getVisitors()
      this.resendTicketsFunction()
    },
    changeEditableState(item) {
      item.editable = !item.editable
    },
    addEmptyItem() {
      this.items.push(Object.assign({}, this.empty_item))
      this.items.at(-1).index = this.items.length - 1;
      this.items.at(-2).adding = false;
      this.items.at(-2).editable = true;
    },
    deleteEmptyItem(item) {
      const index = this.items.indexOf(item)
      this.items.splice(index, 1)

      this.items.forEach((item, index) => {
        item.id = index
      })
    },
    resendTicketsFunction() {
      let data = {}
      if (this.is_send_all) data.is_send_all = true
      else data.visitors = this.visitors
      this.resendTicket({
        data: data,
        id: this.$route.params.idEvent,
        finalizer: () => {
          this.save_loading = false;
          this.close()
        },
        catcher: (val) => {
          this.mapErrors(val)
          this.save_loading = false;
        }
      })
    },
    createForm() {
      for (let i = 0; i < 2; i++) {
        this.items.push(Object.assign({}, this.empty_item))
        this.items[i].id = i;
      }
      this.items[0].editable = true
      this.items[0].adding = false
    },
  },
  created() {
    this.createForm()
  }
}
</script>

<style scoped>

</style>
