<template>
  <v-col
    class="px-0 ma-0 py-2"
    cols="12"
  >
    <v-row
      class="mr-0 ml-0 mt-1 px-3"
      :class="getChipErrorClass(i)"
      style="height: 50px;background: #DADADA"
    >
      <span>
        <v-col>
          {{ getFileText(data.all_files[i]) }}
        </v-col>
      </span>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="data.all_files[i].src">
        <template v-slot:activator="{ on, attrs }">
          <div style="display: flex; justify-content: center; align-items: center;">
            <a :href="data.all_files[i].src" target="_blank">
              <v-icon
                  class="ml-1 mr-0"
                  dense
                  v-bind="attrs"
                  v-on="on"
              >
                download
              </v-icon>
            </a>
          </div>
        </template>
        <span>Скачать файл</span>
      </v-tooltip>
      <v-tooltip bottom v-if="movingFilesAllowed">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="ml-1 mr-0"
            dense
            v-bind="attrs"
            v-on="on"
            @click="$emit('moveFileUp', data.id, i)"
          >
            arrow_upward
          </v-icon>
        </template>
        <span>Сдвинуть файл в начало</span>
      </v-tooltip>
      <v-tooltip bottom v-if="movingFilesAllowed">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="ml-1 mr-0"
            dense
            v-bind="attrs"
            v-on="on"
            @click="$emit('moveFileDown', data.id, i)"
          >
            arrow_downward
          </v-icon>
        </template>
        <span>Сдвинуть файл в конец</span>
      </v-tooltip>
      <v-tooltip bottom v-if="deletingFilesAllowed">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              class="ml-1 mr-0"
              dense
              v-bind="attrs"
              v-on="on"
              @click="$emit('deleteOneFile', data.id, i)"
          >
            delete
          </v-icon>
        </template>
        <span>Удалить файл</span>
      </v-tooltip>
    </v-row>
    <v-row
      class="mr-0 ml-0 mt-0 px-3 rounded-b-xl caption text-left"
      style="height: 35px;background: #DADADA; color: #ff5252;"
      v-if="data.errors.backend_files[i] && data.errors.backend_files[i].length > 0"
    >
      <v-col class="pt-0 pb-1">
        {{ getFileErrors(data.errors.backend_files[i]) }}
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: {
    data: Object,
    i: Number,
    movingFilesAllowed: {
      type: Boolean,
      required: false,
      default: true
    },
    deletingFilesAllowed: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  name: "FileChip",
  methods: {
    getFileErrors(errors) {
      let result = '';
      for (let error of errors) {
        result += error
        result += ''
      }
      return result
    },
    getChipErrorClass(i) {
      if (this.data.errors.backend_files[i] && this.data.errors.backend_files[i].length > 0) {
        return ' rounded-t-xl'
      }
      return ' rounded-xl'
    },
    getFileText(item) {
      let text_size = 13;

      if (this.$vuetify.breakpoint.smAndUp) {
        text_size = 25;
      }

      return item.name.length > text_size ? item.name.slice(0, text_size - 3) + '...' : item.name
    }
  }
}
</script>

<style scoped>

</style>
