const VISITOR_FIELD_COMPONENTS = [
    "VisitorFormTextField",
    "VisitorFormDateTimeField",
    "VisitorFormDateField",
    "VisitorFormTimeField",
    "VisitorFormStringsListField",
    "",
    "VisitorFormNumberField",
    "",
    "VisitorFormFlagField",
    "VisitorFormSelector"
];

export default VISITOR_FIELD_COMPONENTS;
