<template>
  <div v-if="!edit">
    <v-card-title
        class="pa-0 ma-0 ml-3 bold text-left"
        style="word-break: keep-all!important;"
        :class="data.data.data.header ? 'mb-5' : 'mb-0'"
    >
      {{ data.data.data.header }}
    </v-card-title>
    <v-divider v-if="data.data.data.header"></v-divider>
    <div
      v-for="(item, i) in data.files"
      :key="i"
    >
      <v-divider v-if="i"></v-divider>
        <v-row class="justify-space-between my-1">
          <v-col class="ml-3 text-start text-subtitle-2 text-md-subtitle-1" cols="9" style="overflow: hidden">
            {{ item.name }}
          </v-col>
          <v-col cols="2" >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <a :href="item.src" target="_blank">
                    <v-icon
                      class="dark-primary ml-1 mr-3"
                      v-bind="attrs"
                      v-on="on"
                    >
                      download
                    </v-icon>
                  </a>
                </template>
                <span>Скачать файл</span>
              </v-tooltip>
          </v-col>
        </v-row>
    </div>
  </div>
  <div v-else>
    <v-text-field
      outlined
      class="pa-0 ma-0 text-left"
      v-model="data.data.data.header"
      label="Заголовок"
    ></v-text-field>
    <v-card-text
      class="text-start pa-0 mb-4 ml-4 text-subtitle-1 text-sm-h6 font-weight-bold"
      v-if="data.all_files.length > 0"
    >
      Загруженные файлы
    </v-card-text>
    <v-row class="mb-4 mx-1 pa-0">
      <file-chip
        v-for="(item, i) in data.all_files"
        :key="i"
        :i="i"
        :data="data"
        @moveFileUp="moveFileUp"
        @moveFileDown="moveFileDown"
        @deleteOneFile="deleteOneFile"
      ></file-chip>
    </v-row>
    <v-card-text class="text-start pa-0 my-4 ml-4 text-subtitle-1 text-sm-h6 font-weight-bold">Загрузите файлы</v-card-text>
    <v-file-input
      v-model="data.new_files"
      placeholder="Загрузите файлы..."
      outlined
      multiple
      show-size
      :error="data.errors.file.length > 0"
      :error-messages="data.errors.file"
      @change="$emit('loadFilesFromInput', data.id)"
    ></v-file-input>
  </div>
</template>

<script>
import FileChip from "@/modules/templates/description_blocks/blocks/blocks_components/FileChip.vue";

export default {
  props: {
    edit: Boolean,
    data: Object,
  },
  name: "FilesListBlock",
  components: { FileChip },
  methods: {
    moveFileUp(id, file_index) {
      this.$emit('moveFileUp', id, file_index)
    },
    moveFileDown(id, file_index) {
      this.$emit('moveFileDown', id, file_index)
    },
    deleteOneFile(id, file_index) {
      this.$emit('deleteOneFile', id, file_index)
    },
  }
}
</script>

<style scoped>

</style>
