<template>
  <v-app style="overflow: hidden !important; min-height: 100vh">
    <leftmenu class="d-print-none"></leftmenu>
    <topmenu class="d-print-none"
             @loginClick="login_open = true"
             @logoutClick="logoutVisitor"
             :visitor-logout="visitor_logout"
    >
    </topmenu>
    <v-main id="base-content" class="d-print-none">
      <div class="overflow-y-auto" style="height: calc(100vh - 60px)" id="app-scroll-container">
        <router-view />
      </div>
    </v-main>
    <LoginDialog
      v-model="login_open"
      @close="closeLoginDialog"
      @closelogin="closeLoginSuccessDialog"
    ></LoginDialog>
  </v-app>
</template>

<script>
import {mapActions, mapState} from "vuex";
import Leftmenu from "./modules/menus/LeftMenu";
import Topmenu from "./modules/menus/TopMenu";
import names from "@/modules/core/routers/names";
import visitor_names from "@/modules/visitor/routers/names";
import LoginDialog from "@/modules/visitor/login/LoginDialog";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";


export default {
  components: { LoginDialog, Topmenu, Leftmenu },
  mixins: [PaginatedDataMapperMixin],
  data() {
    return {
      clipped: true,
      drawer: true,
      surls: {},
      footer_height: 0,
      login_open: false,
      visitor_logout: false,
      welcome_dialog: false,
    };
  },
  computed: {
    names: () => names,
    ...mapState({
      leftmenu: (state) => state.leftmenu,
      maintoolbar: (state) => state.maintoolbar,
      user: (state) => state.user,
      urls: (state) => state.urls,
    }),
  },
  watch: {
    urls() {
      this.surls = this.urls.urls;
    },
    '$route': function(newVal) {
      if (!newVal || !newVal.name) return;
      let preview_opened = (newVal.name.indexOf('|Events|Detail|Preview|Main') !== -1)
      this.updatePreviewOpenedState({
        value: preview_opened
      })
    }
  },
  methods: {
    ...mapActions({
      loginVisitorViaEvent: "visitor/loginVisitorViaEvent",
      loadEvents: 'visitor/loadEventsList',
      updatePreviewOpenedState: 'mainpage/updatePreviewOpenedState',
      changeNeedReloadSate: 'visitor/changeNeedReloadState',
    }),
    closeLoginDialog() {
      this.login_open = false
    },
    closeLoginSuccessDialog(url = undefined){
      this.login_open = false
      this.changeNeedReloadSate({value: true})
    },
    logoutVisitor(url = undefined){
      window.$cookies.remove("visitor_token",null);
      this.loginVisitorViaEvent({token : null})
      if (this.$route.name !== visitor_names.EVENTS.LIST)
        this.$router.push({name: visitor_names.EVENTS.LIST})
      else
        this.changeNeedReloadSate({value: true})
      this.visitor_logout = true
    },
  },
  created: async function () {
    await this.loginVisitorViaEvent({token : this.$cookies.get('visitor_token')})
  },
};
</script>

<style>
@font-face {
  font-family: 'Inter normal';
  src: url('~@/assets/fonts/Inter/Inter-Regular.otf');
}

@font-face {
  font-family: 'MADE Evolve Sans normal';
  src: url('~@/assets/fonts/MADE Evolve Sans/MADE Evolve Sans Regular (PERSONAL USE).otf');
}

@font-face {
  font-family: 'Arial normal';
  src: url('~@/assets/fonts/Arial/ArialMT.ttf');
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #2cc30e50;*/
  /*height: 100%;*/
}

:root {
  --doc-height: 100vh;
}
</style>
