import { render, staticRenderFns } from "./VisitorsEditDialog.vue?vue&type=template&id=53dda9e4&scoped=true"
import script from "./VisitorsEditDialog.vue?vue&type=script&lang=js"
export * from "./VisitorsEditDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53dda9e4",
  null
  
)

export default component.exports