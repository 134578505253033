<template>
  <svg width="32" height="18" viewBox="0 0 32 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id="Vector" d="M0 2.21053L2.28 0L16 13.5395L29.72 0L32 2.21053L16 18L0 2.21053Z" fill="#1D71B8"/>
  </svg>
</template>

<script>
export default {
  name: "ExpandedFalse"
}
</script>

<style scoped>

</style>
