<template>
  <div>
    <div class="header-label-text mt-1 ml-3">
      {{ name }}
      <span v-if="isRequired" class="red--text">*</span>
    </div>
    <v-text-field
        type="datetime-local"
        class="py-0 my-0 ml-3"
        outlined
        v-model="value"
        :error="!!(errorMessage && errorMessage.length > 0)"
        :errorMessages="errorMessage"
        @change="$emit('update:inputValue', value)"
    >
      <template v-slot:label>
      </template>
      <template v-slot:append-outer>
        <v-tooltip bottom max-width="320px" v-if="settings.dynamic_start || settings.static_start || helpText || settings.static_end">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                class="mr-5 ml-3"
                v-bind="attrs"
                v-on="on"
                color="primary"
            >
              help
            </v-icon>
          </template>
          <span v-html="getHelpText()"></span>
        </v-tooltip>
        <div v-else style="width: 56px"></div>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import {displayDate, getTextForm} from "@/helper";

export default {
  name: "VisitorFormField",
  props: {
    inputValue: String,
    errorMessage: String,
    isRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    name: String,
    helpText: String,
    settings: Object,
  },
  data() {
    return {
      value: undefined,
    }
  },
  methods: {
    displayDate: displayDate,
    getTextForm: getTextForm,
    getHelpText() {
      let text = ''
      if (this.helpText) {
        text += this.helpText + '<br/>'
      }
      if (this.settings.dynamic_start) {
        text += "Введённое значение не должно превышать " +
            this.displayDate(this.settings.dynamic_start, true)
        text += " более чем на "
            + (this.settings.delta.days ? (this.settings.delta.days + " " + this.getTextForm(this.settings.delta.days, ['день', 'дня', 'дней']) + " ") : "")
            + (this.settings.delta.hours ? (this.settings.delta.hours + " " + this.getTextForm(this.settings.delta.hours, ['час', 'часа', 'часов']) + " ") : "")
      } else if (this.settings.static_start || this.settings.static_end) {
        if (this.helpText) {
          text += '<br/>'
        }
        text += "Введённое значение должно лежать в интервале "
        if (this.settings.static_start) {
          text += "от " + this.displayDate(this.settings.static_start, true) + " "
        }
        if (this.settings.static_end) {
          text += "до " + this.displayDate(this.settings.static_end, true)
        }
      }
      return text
    }
  },
  created() {
    this.value = this.inputValue
  }
}
</script>

<style scoped>
.header-label-text {
  text-align: left;
  color: #000;
  font-family: "MADE Evolve Sans normal", serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 10px;
  padding-right: 56px;
}
</style>
