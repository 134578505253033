<template>
  <v-container class="page-content pa-0" style="width: 100%">
    <div v-if="$route.name === names.EVENTS.ROLES.LIST">
      <v-toolbar
          class="removeBtnPaddingRight rounded-t-lg"
          color="#DADADA"
          flat
      >
        <v-select
            v-if="$vuetify.breakpoint.smOnly"
            :items="role_filter_items"
            class="dark-primary my-1"
            dense
            outlined
            label="Роль"
            hide-details
            v-model="selected_role_filter"
        ></v-select>
        <v-text-field
            v-if="$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.mdAndUp"
            v-model="search.value"
            class="dark-primary my-1"
            clearable
            dense
            outlined
            label="Поиск"
            hide-details
            @keydown.enter="searchData"
            @click:clear="clearSearchData"
        ></v-text-field>
        <v-select
            v-if="$vuetify.breakpoint.mdAndUp"
            :items="role_filter_items"
            class="dark-primary my-1 pl-2"
            dense
            outlined
            label="Роль"
            hide-details
            v-model="selected_role_filter"
        ></v-select>
        <v-spacer></v-spacer>
        <v-toolbar-items v-if="$vuetify.breakpoint.smAndUp && light_detail.allow_update && !(light_detail.is_end && !light_detail.is_draft)">
          <v-btn
              text
              @click="newUser"
              class="dark-primary mr-0 rounded-tr-lg"
          >
            Назначить пользователя
          </v-btn>
        </v-toolbar-items>
        <v-btn
            v-if="$vuetify.breakpoint.xsOnly && light_detail.allow_update && !(light_detail.is_end && !light_detail.is_draft)"
            icon
            class="dark-primary mr-0"
            @click="newUser"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </v-toolbar>
      <v-slide-y-transition>
        <v-toolbar
            flat
            color="#DADADA"
            v-if="$vuetify.breakpoint.smAndDown"
            elevation="0"
            :height="extensionHeight"
        >
          <v-row no-gutters>
            <v-col cols="12">
              <v-select
                  v-if="$vuetify.breakpoint.xsOnly"
                  :items="role_filter_items"
                  class="dark-primary my-1"
                  dense
                  outlined
                  label="Роль"
                  hide-details
                  v-model="selected_role_filter"
              ></v-select>
              <v-text-field
                  v-else
                  v-model="search.value"
                  class="dark-primary my-1"
                  clearable
                  dense
                  outlined
                  label="Поиск"
                  hide-details
                  @keydown.enter="searchData"
                  @click:clear="clearSearchData"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
      </v-slide-y-transition>
      <v-data-table
          :headers="headers"
          :items="items"
          :loading="isLoading"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.size"
          :server-items-length="count"
          :options.sync="options"
          :footer-props="{'items-per-page-options':[10,50,100]}"
          class="elevation-1 rounded-b-lg rounded-t-0"
      >
<!--        <template v-slot:item.is_viewer="{ item }">-->
<!--          <v-icon-->
<!--              :color="checkFlag(item.is_viewer).color"-->
<!--          >-->
<!--            {{ checkFlag(item.is_viewer).icon }}-->
<!--          </v-icon>-->
<!--        </template>-->
        <template v-slot:item.is_organiser="{ item }">
          <v-icon
              :color="checkFlag(item.is_organiser).color"
          >
            {{ checkFlag(item.is_organiser).icon }}
          </v-icon>
        </template>
        <template v-slot:item.is_operator="{ item }">
          <v-icon
              :color="checkFlag(item.is_operator).color"
          >
            {{ checkFlag(item.is_operator).icon }}
          </v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom v-if="light_detail.allow_update && !(light_detail.is_end && !light_detail.is_draft)">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  class="mr-2"
                  @click="editUser(item.id)"
                  v-bind="attrs"
                  v-on="on"
              >
                edit
              </v-icon>
            </template>
            <span>Редактировать пользователя</span>
          </v-tooltip>
          <v-tooltip bottom v-if="light_detail.allow_update && !(light_detail.is_end && !light_detail.is_draft)">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  @click="deleteUser(item.id)"
                  v-bind="attrs"
                  v-on="on"
              >
                delete
              </v-icon>
            </template>
            <span>Удалить пользователя</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-btn
              color="primary"
              @click="updateData"
          >
            Обновить
          </v-btn>
        </template>
      </v-data-table>
      <EditDialog
          v-if="dialog_add_edit"
          :opened="dialog_add_edit"
          :edit="edit_status"
          :user-id="user_id"
          @close="closeEditDialog"
      ></EditDialog>
      <ApplyDialog
          v-if="dialog_delete"
          :opened="dialog_delete"
          :get-text="() => `Вы уверены, что хотите удалить данного пользователя ?`"
          @close="closeDeleteDialog"
      ></ApplyDialog>
    </div>
    <router-view v-else></router-view>
  </v-container>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import EditDialog from "@/modules/organiser/events/modals/RolesEditDialog";
import ApplyDialog from "@/modules/core/components/ApplyDialog";
import organiser from "@/urls/roles/organiser";
import names_core from "@/modules/core/routers/names";
import names from "@/modules/organiser/routers/names";

export default {
  name: "OrganiserRolesList",
  components: {EditDialog, ApplyDialog},
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  data() {
    return {
      page_title: "Список ролей",
      dialog_add_edit: false,
      dialog_delete: false,
      edit_status: undefined,
      user_id: undefined,
      search: {
        parameter: 'fio',
      },
      headers: [
        {text: 'ФИО', value: 'fio', sortable: false, align: 'start', width: '40%'},
        {text: 'Организатор', value: 'is_organiser', sortable: false, align: 'center', width: '20%'},
        {text: 'Оператор', value: 'is_operator', sortable: false, align: 'center', width: '20%'},
        {text: 'Действия', value: 'actions', sortable: false, align: 'center', width: '20%'},
      ],
      role_filter_items: [
        { text: 'Все', value: 'all' },
        { text: 'Оператор', value: 'operator' },
        { text: 'Организатор', value: 'organiser' },
      ],
      pagination: {
        filters: {
          is_operator: undefined,
          is_organiser: undefined,
        }
      },
      selected_role_filter: 'all',
    }
  },
  watch: {
    selected_role_filter(newVal) {
      if (newVal === 'all') {
        this.pagination.filters.is_operator = undefined
        this.pagination.filters.is_organiser = undefined
      } else if (newVal === 'operator') {
        this.pagination.filters.is_operator = true
        this.pagination.filters.is_organiser = undefined
      } else if (newVal === 'organiser') {
        this.pagination.filters.is_operator = undefined
        this.pagination.filters.is_organiser = true
      }
    },
  },
  computed: {
    ...mapGetters({
      items: 'organiser/getRolesList',
      count: 'organiser/getRolesCount',
      isLoading: 'organiser/isLoadingRoles',
      light_detail: 'organiser/getEventsLightDetail',
    }),
    names: () => names,
    extensionHeight() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 56
      } else {
        return 0
      }
    },
  },
  methods: {
    ...mapActions({
      loadRolesList: 'organiser/loadRolesList',
      deleteRoles: 'organiser/deleteRoles',
      loadLightDetail: 'organiser/loadEventsLightDetail',
      changeEventDetailLoadingState: 'leftmenu/changeEventDetailLoadingState'
    }),
    updateData() {
      if (this.search.value) {
        this.search.value = ''
      }
      this.loadData()
    },
    loadData(url = undefined) {
      this.loadRolesList({
        url: url,
        event_id: this.$route.params.idEvent,
        urlAppend: (url) => {
          return this.addPageParameter(this.addSizeParameter(url));
        },
        finalizer: (data, url) => {
          this.setCurrentLoadUrl(url);
          this.changeEventDetailLoadingState({loading_state: false})
        }
      })
    },
    newUser() {
      this.dialog_add_edit = true
      this.edit_status = false
      this.user_id = undefined
    },
    editUser(id) {
      this.dialog_add_edit = true
      this.edit_status = true
      this.user_id = id
    },
    deleteUser(id) {
      this.dialog_delete = true
      this.user_id = id
    },
    closeEditDialog() {
      this.loadData()
      this.dialog_add_edit = false
      this.user_id = undefined
    },
    closeDeleteDialog(event) {
      if (event) {
        this.deleteRoles({
          event_id: this.$route.params.idEvent,
          id: this.user_id,
          finalizer: () => {
            this.loadData()
          },
          catcher: (val) => {
            this.mapErrors(val)
          },
        })
      }
      this.dialog_delete = false
      this.user_id = undefined
    },
    checkFlag(flag) {
      if (flag) return {icon: "check_circle", color: "green"}
      else return {icon: "not_interested", color: "gray"}
    },
    updatePageTitle() {
      this.loadLightDetail({
        id: this.$route.params.idEvent,
        finalizer: (data) => {
          this.page_title = this.page_title + ': ' + data.name
        }
      })
    },
  },
  created() {
    if (organiser.ORGANISER.ALLOWED()) {
      if(this.$route.name === names.EVENTS.ROLES.LIST){
        this.updatePageTitle()
        this.loadData()
      }
    } else {
      this.$router.push({name: names_core.MAIN})
    }
  }
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0;
}
</style>
