const state = {
    title: 'Профиль пользователя',
    preview_opened: false,
}

const getters = {
    isPreviewOpened: state => state.preview_opened
}

const actions = {
    updatePreviewOpenedState({commit}, {value}) {
        commit('UPDATE_PREVIEW_OPENED_VALUE', value)
    },
}

const mutations = {
    changeTitle: function (state, title) {
        state.title = title;
    },
    UPDATE_PREVIEW_OPENED_VALUE: (state, value) => {
        state.preview_opened = value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
